import React from 'react';
import mapStateToProps from '../../../datastore/mapper.js';
import mapDispatchToProps from '../../../datastore/dispatcher.js';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import ItemVersionView from './item_version_view.js';

export class ItemVersionList extends React.Component{

    constructor(props){
        super(props)
        this.state = {
            item: null,
            versions: []
        }
    }

    componentDidMount(){
        this.setState({
            item: (this.props.item.length ? this.props.item[0] : null)
        }, ()=>{
            this.getHistory();
        });
    }

    async getHistory(){
        const response = await this.props.getItemHistory(this.state.item.id);
        if(response && response.success){
            this.setState({versions: response.dataset})
        }
    }

    render(){
        const {item, versions } = this.state;
        return (
            <>
                {item && versions && versions.length > 0 ?
                    <>
                        {versions.map(version =>{
                            return (   
                                <details key={version.id}>
                                    <summary>{new Date(version.modifieddate).toDateString()}</summary>
                                    <ItemVersionView versionId={version.id}/>
                                </details>
                            )})
                        }
                    </> 
                :
                    <p>No history data for this item.</p>
                }
            </>
        );       
    }
}

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(ItemVersionList));