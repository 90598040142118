import React from 'react';

import mapStateToProps from '../../datastore/mapper.js';
import mapDispatchToProps from '../../datastore/dispatcher.js';

import {connect} from 'react-redux';

import {withRouter} from 'react-router-dom';

import AppWrapper from '../app_wrapper/index.js'

import ListItems from './list_items.js';
import EditItem from './edit_items.js';

export class ItemsManagement extends React.Component{
    constructor(props){
        super();
    }

    itemsManagementType(_type){
        switch(_type){
            case "create":
            case "clone":
            case "edit":
            case "review":
                return <EditItem clone={_type==="clone"} review={_type==="review"}/>;
            default:
                return <ListItems />;
        }
    }

    parsedURLSubtitle(urlpath){
        const parsedURL = urlpath.split("/");
        if(parsedURL[2]==="edit" || parsedURL[2]==="create" || parsedURL[2]==="clone" || parsedURL[2]==="review")
            return parsedURL[2];
        else
            return "list";
    }


    render(){

        const {location:{pathname}} = this.props;

        const parsedpath = this.parsedURLSubtitle(pathname);
        const ItemsManagementComponent = this.itemsManagementType(parsedpath);

        return(
            <AppWrapper title={'Items Management'} subtitle={parsedpath}>
                <div className="itemsmanagement">
                    {ItemsManagementComponent}
                </div>
            </AppWrapper>
        )
    }
}

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(ItemsManagement));