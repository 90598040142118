import React from 'react';

import mapStateToProps from '../../datastore/mapper.js';
import mapDispatchToProps from '../../datastore/dispatcher.js';

import {connect} from 'react-redux';

import { Segment, Icon, Popup, Grid, Dropdown, Input, Label, Checkbox, Divider, Button } from 'semantic-ui-react';
import autoBind from 'react-autobind';

export class MetadataManager extends React.Component{
    constructor(props){
        super();

        this.state = {
            showCollectionOptionsContainer: true
        };

        autoBind(this);
    }

    componentDidMount(){
        if(!this.props.appProps.languages || !this.props.appProps.proficiencies || !this.props.appProps.skills){
            this.getLanguageProficiencySkill();
        }
    }

    getLanguageProficiencySkill(){

        const {appProps: {languages, proficiencies, skills} } = this.props;

        if(!languages){
            this.props.getLanguages();
        }
        if(!proficiencies){
            this.props.getProficiency();
        }
        if(!skills){
            this.props.getSkills();
        }
    }

    updateCollectionTitle(e, {value}){
        this.updateCurrentCollectionMetadata({
            title: value
        });
    }

    toggleCollectionTimer(){
        this.updateCurrentCollectionMetadata({
            timer: {
                ...this.props.currentCollectionMetadata.timer,
                enabled:!this.props.currentCollectionMetadata.timer.enabled,
                seconds:0,
                allitems:false
            }
        });
    }
    updateCollectionTimerAmount(){
        const minutesinp = document.querySelector("#testlet_timer_minutes");
        const secondsinp = document.querySelector("#testlet_timer_seconds");

        const totalsec = (parseInt(minutesinp.value) * 60) + (secondsinp.value ? parseInt(secondsinp.value) : 0);
        
        this.updateCurrentCollectionMetadata({
            timer: {
                ...this.props.currentCollectionMetadata.timer,
                seconds:parseInt(totalsec)
            }
        });
    }
    toggleCollectionAllItemsFlag(){
        this.updateCurrentCollectionMetadata({
            timer: {
                ...this.props.currentCollectionMetadata.timer,
                allitems:!this.props.currentCollectionMetadata.timer.allitems
            }
        });
    }

    updateCurrentCollectionMetadata(updatedObject){
        this.props.updateCollectionMetaData(updatedObject);
    }

    toggleCollectionOptionsContainer(){
        this.setState((prevState)=>({showCollectionOptionsContainer: !prevState.showCollectionOptionsContainer}));
    }

    render(){

        const {currentCollectionMetadata, appProps: {languages, proficiencies, skills}} = this.props;

        return(
            <Segment raised>
                <Popup content={this.state.showCollectionOptionsContainer?'Minimize Toolset':'Maximize Toolset'} trigger={
                    <Label size='small' attached="top right" as='a' onClick={this.toggleCollectionOptionsContainer}><Icon name={this.state.showCollectionOptionsContainer?'window minimize':'window maximize outline'}/></Label>
                }/>
                <Divider hidden/>
                {this.state.showCollectionOptionsContainer &&
                <Grid>
                    <Grid.Row>
                        <Grid.Column width={10}>
                            <div>Testlet Title:</div>
                            <Input
                                onChange={this.updateCollectionTitle}
                                fluid
                                placeholder='Testlet Title'
                                value={currentCollectionMetadata.title}
                            >
                                <input />
                            </Input>
                        </Grid.Column>
                        <Grid.Column width={2}>
                            <span>Timer</span>
                            <div className="timer-container">
                                <div><Checkbox toggle defaultChecked={currentCollectionMetadata.timer.enabled} onChange={this.toggleCollectionTimer}/></div>
                                {currentCollectionMetadata.timer.enabled ?
                                    <React.Fragment>
                                        <div>
                                            <Input label='Minutes' value={currentCollectionMetadata.timer.seconds ? Math.floor(currentCollectionMetadata.timer.seconds / 60) : ''} placeholder='Minutes' size='mini' onChange={this.updateCollectionTimerAmount}><input id="testlet_timer_minutes" type="number"/></Input>
                                            <Input label='Seconds' value={currentCollectionMetadata.timer.seconds ? Math.round(currentCollectionMetadata.timer.seconds % 60) : ''} placeholder='Seconds' size='mini' onChange={this.updateCollectionTimerAmount}><input id="testlet_timer_seconds" type="number" max="59"/></Input>
                                        </div>
                                        <div>
                                            <Popup content='This toggle when enabled will affect all items in this testlet, or affect only items that have timer enabled when disabled' trigger={
                                                <Checkbox label='All Items' toggle defaultChecked={currentCollectionMetadata.timer.allitems} onChange={this.toggleCollectionAllItemsFlag}/>
                                            }/>
                                        </div>
                                    </React.Fragment>
                                : null}
                            </div>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={4}>
                            <span>Language: </span>
                            <div className="metadata_label">
                                {
                                    (
                                        languages &&
                                        languages.filter(lang=>lang.id===currentCollectionMetadata.language).length &&
                                        languages.filter(lang=>lang.id===currentCollectionMetadata.language)[0].name
                                    ) || ''
                                }
                            </div>
                        </Grid.Column>
                        <Grid.Column width={4}>
                            <span>Proficiency: </span>
                            <div className="metadata_label">
                                {
                                    (
                                        proficiencies &&
                                        proficiencies.filter(prof=>prof.id===currentCollectionMetadata.proficiency).length &&
                                        proficiencies.filter(prof=>prof.id===currentCollectionMetadata.proficiency)[0].label
                                    ) || ''
                                }
                            </div>
                        </Grid.Column>
                        <Grid.Column width={4}>
                            <span>Skill: </span>
                            <div className="metadata_label">
                                {
                                    (
                                        skills &&
                                        skills.filter(skill=>skill.id===currentCollectionMetadata.skill).length &&
                                        skills.filter(skill=>skill.id===currentCollectionMetadata.skill)[0].name
                                    ) || ''
                                }
                            </div>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
                || <Label size='small' attached="top left">Item Options</Label>}
            </Segment>

        )
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(MetadataManager);