/*
Bitbuild Rich Text Editor (BBRTE) Doc:

Import:
Can use any one of these,

a. import BBRTE from '{lib file location}';
b. require('{lib file location}');
c. <script src="{{lib file location}}"></script>

Initialize:

new BBRTE(__OPTIONS__);

__OPTIONS__,
is a javascript object with these properties,

a. container:[Required/Optional] This property contains id of a div container which will contain BBRTE. If not supplied, will throw a warning in the console window and will auto create a div container with random id.
b. toolbar:[Options] Set if toolbar is shown or not.
c. icons:[Optional] Icons list as array of object for toolbar. Out of the box icons will not contain any images, and will only have textual button names.
    icons object format:
        {
            {
                "name":"{name of button}",
                "icon":"{icon classes as string}"
            }
        }
    to get the list of icon names, reference variable 'toolbarSettings' in this file.
d. placeholder:[Optional] This is a default placeholder to show. It is non editable
e. content:[Optional] This is a default content to pass instead of a placeholder. It is editable
f: readonly:[Optional] Set if container is editable

*/
const _MAX_DEPTH_CHECK_ = 50;

function generateUUIDV4(){
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c){
        const r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}

(function() {
    const BBRTE = (function() {
        let currentlySelectedContainer;

        const checkContainerIndex = function(_classNameToCheck, _upward, _container, currentIndex){
            if(currentIndex > _MAX_DEPTH_CHECK_){
                return -1;
            }

            if(
                (
                    _container &&
                    _container.classList &&
                    _container.classList.length &&
                    _container.classList.contains(_classNameToCheck)
                )
            ){
                return currentIndex;
            }
            else if(_container){
                if(_upward){
                    return checkContainerIndex(_classNameToCheck, _upward, _container.parentNode, currentIndex+1);
                }
                else{
                    if(_container.childNodes && _container.childNodes.length){
                        let containsClass = false;
                        _container.childNodes.forEach(_node=>{
                            if(_node.classList && _node.classList.contains(_classNameToCheck)){
                                containsClass = true;
                            }
                        });
                        if(containsClass){
                            return currentIndex;
                        }
                        else{
                            let container_index = -1;
                            _container.childNodes.forEach(_node=>{
                                if(_node.classList && !_node.classList.contains(_classNameToCheck)){
                                    const _containerIndex = checkContainerIndex(_classNameToCheck, _upward, _node, currentIndex+1);
                                    if(_containerIndex!==-1){
                                        container_index = _containerIndex;
                                    }
                                }
                            });
                            return container_index;
                        }
                    }
                    else{
                        return -1;
                    }
                }
            }
        }
        const replaceNodeContent = function(_parent_, _selection_, enablertl){
            if(enablertl){
                const container_index = checkContainerIndex("bbrte_content_container", true, _selection_, 0);
                // need to make sure if the first child does not have wrapped element, make it the parent.
                if(container_index < 2){
                    const newContentSet = _selection_.nodeValue;
                    if(_parent_.childNodes.length){
                        _parent_.childNodes[0].innerHTML = `<span dir="rtl" class="directional-span">${newContentSet}</span>`;
                    }
                    else{
                        _parent_.innerHTML = `<span dir="rtl" class="directional-span">${newContentSet}</span>`;
                    }
                }
                else{
                    const newContentSet = _selection_.nodeValue;
                    _parent_.innerHTML = `<span dir="rtl" class="directional-span">${newContentSet}</span>`;
                    //_parent_.childNodes[0].innerHTML = `<span dir="rtl" class="directional-span">${newContentSet}</span>`;
                }
            }
            else{
                if(_parent_.classList.contains("directional-span")){
                    const spaninnercontent = _parent_.innerHTML;
                    _parent_.parentNode.innerHTML = spaninnercontent;
                }
                else{
                    const container_index = checkContainerIndex("directional-span", false, _selection_, 0);
                    let currentElement = _selection_;
                    for(var i=0; i<container_index; i++){
                        if(i===container_index){
                            break;
                        }
                        currentElement = _selection_.parentNode;
                    }
                    const spaninnercontent = currentElement.children[0].innerHTML;
                    currentElement.innerHTML = spaninnercontent;
                }
            }
        }

        const BBRTE = function(options) {
            const _parent = this;
            _parent.options = undefined;
            _parent._bbrtecontainer = undefined;
            _parent._bbrtecontent = undefined;
            _parent._bbrtetoolbar = undefined;

            setupCSS();

            _parent.options = options ? options : {toolbar:true};
            _parent.options.toolbar = _parent.options.toolbar || true;
            _parent._bbrtecontainer = document.getElementById(`${_parent.options.container}`);
            if(!_parent._bbrtecontainer || _parent._bbrtecontainer.nodeName !== "DIV"){
                console.warn("Options property \"Container\", must have stringified id of a div element.\nA div has been auto generated for BBRTE, and prepended to document body.");
                _parent.options.container = generateUUIDV4();//undefined;
                const newbbrtecontainer = document.createElement("div");
                _parent._bbrtecontainer = newbbrtecontainer;
                document.body.prepend(_parent._bbrtecontainer);
            }
            setupDOM(_parent);
            if(_parent.options.toolbar) setupToolbar(_parent, _parent.options.icons);
            setupContent(_parent);
            allEventListeners(_parent);
        };

        // BBRTE.prototype.refresh = function(){
        //     const previouscontent = this._bbrtecontent.innerHTML ? this._bbrtecontent.innerHTML : this._bbrtecontent.innerText;
        //     const contentishtml = this._bbrtecontent.innerHTML ? true : false;
        //     this._bbrtecontent.innerHTML = '';
        //     setTimeout(function(){
        //         if(contentishtml){
        //             this._bbrtecontent.innerHTML = previouscontent;
        //         }
        //     }, 5);
        // }

        const setupCSS = function(){
            let stylesexists = false;
            const existingstyles = document.querySelectorAll("style");
            const stylesarr = Array.from(existingstyles);
            if(stylesarr && stylesarr.length){
                const filteredexistingstyles = stylesarr.filter(stylenode=>stylenode.innerHTML.includes("bbrte_container"));
                if(filteredexistingstyles.length){
                    stylesexists = true;
                }
            }
            if(!stylesexists){
                const style = document.createElement('style');
                style.innerHTML = stylesheetText;
                const ref = document.querySelector('script');
                ref.parentNode.insertBefore(style, ref);
            }
        }

        const setupDOM = function(_parent){
            console.log("BBRTE initialized");
            //_parent._bbrtecontainer.setAttribute("id", "bitbuildrichtexteditor");     //don't sem to need this anymore?
            _parent._bbrtecontainer.setAttribute("spellcheck", "false");
            _parent._bbrtecontainer.classList.add("bbrte_container");
            _parent.options.placeholder = _parent.options.placeholder || "Write Your Text Here...";
            _parent.options.content = _parent.options.content || '';
        };

        const setupToolbar = function(_parent, iconslist = []){
            const bbrtetoolbarcontainer = document.createElement("div");
            bbrtetoolbarcontainer.classList.add("bbrte_toolbar_container");
            _parent._bbrtetoolbar = bbrtetoolbarcontainer;
            _parent._bbrtecontainer.append(_parent._bbrtetoolbar);
            const toolbarbuttons = toolbarSettings.map(function(setting, btnindex){
                if(setting.name==="fontsize"){
                    const fontsizeselect = document.createElement('select');
                    fontsizeselect.setAttribute("data-title", setting.tooltip);
                    fontsizeselect.classList.add("bbrte_toolbar_select");
                    setting.sizes.forEach(function(sizeobject, _index){
                        const sizeoption = document.createElement('option');
                        sizeoption.value = sizeobject.size;
                        sizeoption.text = sizeobject.label;
                        fontsizeselect.appendChild(sizeoption);

                        if(_index===setting.sizes.length-1){
                            const placeholderoption = document.createElement('option');
                            placeholderoption.value = "placeholder";
                            placeholderoption.text = "Font Sizes";
                            placeholderoption.selected = true;
                            placeholderoption.disabled = true;
                            fontsizeselect.appendChild(placeholderoption);
                        }
                    });
                    fontsizeselect.addEventListener("change", function(event){
                        document.execCommand("fontSize", false, event.target.value);
                    });
                    _parent._bbrtetoolbar.append(fontsizeselect);
                    return fontsizeselect;
                }
                else{
                    const button = document.createElement('button');
                    button.setAttribute("data-title", setting.tooltip);
                    button.classList.add("bbrte_toolbar_button");
                    button.classList.add("fade");
                    if(iconslist.length && iconslist.filter(function(icon){return icon.name===setting.name}).length){
                        const classString = iconslist.filter(function(icon){return icon.name===setting.name})[0].icon;
                        const parsedClassString = classString.split(" ");

                        const button_i = document.createElement('i');
                        button_i.classList.add("icon");
                        parsedClassString.map(function(classstr){
                            button_i.classList.add(classstr);
                        });
                        button.append(button_i);
                    }
                    else{
                        button.innerText = setting.label;
                    }

                    const colorWheelInput = document.createElement("input");
                    colorWheelInput.setAttribute("type", "color");
                    //colorWheelInput.setAttribute("value", "#ff0000");
                    colorWheelInput.style.display = "hidden";
                    colorWheelInput.addEventListener("change", function(event){
                        document.execCommand("foreColor", false, event.target.value);
                    });

                    const hyperlinkInput = document.createElement("input");
                    hyperlinkInput.setAttribute("placeholder", "http://...");
                    hyperlinkInput.classList.add("bbrte_modal-hyperlink_input");

                    const modalclosebutton = document.createElement("span");
                    modalclosebutton.classList.add("bbrte_modal-close");
                    const closebuttonIcon = document.createElement("i");
                    closebuttonIcon.innerHTML = '&times;';
                    if(iconslist.length && iconslist.filter(function(icon){return icon.name==="close"}).length){
                        closebuttonIcon.innerHTML = '';
                        iconslist.filter(function(icon){return icon.name==="close"})[0].icon.split(" ").map(function(classstr){
                            closebuttonIcon.classList.add(classstr);
                        });
                    }
                    modalclosebutton.appendChild(closebuttonIcon);
                    modalclosebutton.addEventListener('click', function(event){
                        if(currentlySelectedContainer){
                            currentlySelectedContainer = null;
                        }
                        hyperlinkModal.style.display = "none";
                    });

                    const modalacceptbutton = document.createElement("span");
                    modalacceptbutton.classList.add("bbrte_modal-accept");
                    const acceptbuttonIcon = document.createElement("i");
                    acceptbuttonIcon.innerHTML = '&check;';
                    if(iconslist.length && iconslist.filter(function(icon){return icon.name==="accept"}).length){
                        acceptbuttonIcon.innerHTML = '';
                        iconslist.filter(function(icon){return icon.name==="accept"})[0].icon.split(" ").map(function(classstr){
                            acceptbuttonIcon.classList.add(classstr);
                        });
                    }
                    modalacceptbutton.appendChild(acceptbuttonIcon);
                    modalacceptbutton.addEventListener('click', function(event){
                        if(hyperlinkInput.value.length){
                            const rtehyperlink = document.createElement("a");
                            rtehyperlink.setAttribute("href", hyperlinkInput.value);
                            rtehyperlink.innerHTML = ''+hyperlinkInput.value;
                            if(currentlySelectedContainer){
                                currentlySelectedContainer.append(rtehyperlink);
                                currentlySelectedContainer = null;
                            }
                        }
                        hyperlinkModal.style.display = "none";
                    })

                    const modalContent = document.createElement("div");
                    modalContent.classList.add("bbrte_modal-content");
                    modalContent.appendChild(modalclosebutton);
                    modalContent.appendChild(modalacceptbutton);
                    modalContent.appendChild(hyperlinkInput);

                    const hyperlinkModal = document.createElement("div");
                    hyperlinkModal.classList.add("bbrte_modal");
                    hyperlinkModal.appendChild(modalContent);
                    if(!document.querySelectorAll(".bbrte_modal").length){
                        document.body.append(hyperlinkModal);
                    }

                    button.addEventListener("click", function(event){
                        switch(setting.name){
                            case "color":
                                colorWheelInput.click();
                                break;
                            case "hyperlink":
                                if(event.target.nodeName === "I"){
                                    currentlySelectedContainer = event.target.parentNode.parentNode.nextSibling;
                                }
                                else if(event.target.nodeName === "BUTTON"){
                                    currentlySelectedContainer = event.target.parentNode.nextSibling;
                                }
                                hyperlinkInput.value = '';
                                hyperlinkModal.style.display = "block";
                                break;
                            case "textdirectionrtl":
                                _parent._bbrtecontent.focus();
                                const currentSelection = document.getSelection();
                                const containerIndex_nondirectional = checkContainerIndex("bbrte_content_container", true, currentSelection.anchorNode, 0);
                                const containerIndex_directional = checkContainerIndex("directional-span", false, currentSelection.anchorNode.parentElement, 0);

                                if(containerIndex_nondirectional!==-1 && containerIndex_directional===-1){
                                    replaceNodeContent(
                                        currentSelection.anchorNode.parentElement,
                                        currentSelection.getRangeAt(0).commonAncestorContainer,
                                        true
                                    );
                                    checkRTLToggle(_parent, event);
                                }
                                else if(containerIndex_directional!==-1){
                                    replaceNodeContent(
                                        currentSelection.anchorNode.parentElement,
                                        currentSelection.getRangeAt(0).commonAncestorContainer,
                                        false
                                    );
                                    checkRTLToggle(_parent, event);
                                }
                                break;
                            default:
                                document.execCommand(setting.execcommand, false, '');
                                break;
                        }
                    });
                    _parent._bbrtetoolbar.append(button);
                    return button;
                }
            });
        }

        const setupContent = function(_parent){
            const bbrtecontentcontainer = document.createElement("div");
            bbrtecontentcontainer.classList.add("bbrte_content_container");
            _parent._bbrtecontent = bbrtecontentcontainer;
            _parent._bbrtecontainer.append(_parent._bbrtecontent);
            if(!_parent.options.content){
                const placeholderelem = document.createElement("p");
                placeholderelem.classList.add("bbrte_content_placeholder");
                placeholderelem.innerText = _parent.options.placeholder;
                _parent._bbrtecontent.append(placeholderelem);
            }
            else{
                _parent._bbrtecontent.innerHTML = _parent.options.content;
            }
            if(!_parent.options.readonly){
                _parent._bbrtecontent.setAttribute("contenteditable", "true");
            }
        }

        const checkRTLToggle = function(_parent, event){
            let isRTLToggled = false;
            const currentSelection = document.getSelection();
            if(currentSelection.anchorNode){
                const containerIndex = checkContainerIndex(
                    "directional-span",
                    false,
                    (currentSelection.anchorNode.parentNode.classList.contains("bbrte_content_container")?
                        currentSelection.anchorNode
                    :
                        currentSelection.anchorNode.parentElement
                    ),
                    0
                );
                if(containerIndex!==-1){
                    isRTLToggled = true;
                }
            }
            _parent._bbrtetoolbar.childNodes.forEach(function(_element){
                if(_element.dataset.title===toolbarSettings.filter(function(setting){return setting.name==="textdirectionrtl"})[0].tooltip){
                    if(!isRTLToggled && _element.classList.contains("bbrte_active")){
                        _element.classList.remove("bbrte_active");
                    }
                    else if(isRTLToggled && !_element.classList.contains("bbrte_active")){
                        _element.classList.add("bbrte_active");
                    }
                }
            });
        }

        const allEventListeners = function(_parent){
            contentContainerEventListener(_parent);
        }

        const contentContainerEventListener = function(_parent){
            _parent._bbrtecontent.addEventListener("focus", function(event){
                if(Array.from(event.target.children).length === 1 && event.target.children[0].innerText === _parent.options.placeholder){
                    event.target.removeChild(event.target.children[0]);
                }
            },false);
            _parent._bbrtecontent.addEventListener("blur", function(event){
                if(event.target.innerHTML === ""){
                    const placeholderelem = document.createElement("p");
                    placeholderelem.classList.add("bbrte_content_placeholder");
                    placeholderelem.innerText = _parent.options.placeholder;
                    event.target.append(placeholderelem);
                }
                _parent._bbrtetoolbar.childNodes.forEach(function(_element){
                    if(_element.dataset.title===toolbarSettings.filter(function(setting){return setting.name==="textdirectionrtl"})[0].tooltip){
                        _element.classList.remove("bbrte_active");
                    }
                });
            },false);
            _parent._bbrtecontent.addEventListener("click", function(event){
                checkRTLToggle(_parent, event);
            }, false);
        }

        BBRTE.prototype.getHTML = function(){
            if(this._bbrtecontent.innerHTML && this._bbrtecontent.innerHTML!=="<p class=\"bbrte_content_placeholder\">"+this.options.placeholder+"</p>"){
                return this._bbrtecontent.innerHTML
            }
            else if(this._bbrtecontent.innerText!==this.options.placeholder){
                return this._bbrtecontent.innerText;
            }
            return "";
        };
        BBRTE.prototype.setHTML = function(_html){
            if(_html){
                this._bbrtecontent.innerHTML = _html;
            }
            else{
                this._bbrtecontent.innerHTML="<p class=\"bbrte_content_placeholder\">"+this.options.placeholder+"</p>"
            }
        };
        BBRTE.prototype.getData = function(){
            //need to do better parsing to get data only
            console.log("Data Retrieved");
        };
        BBRTE.prototype.getOptions = function(){
            return this.options;
        };
        BBRTE.prototype.setOptions = function(newoptions){
            var currentoptions = this.options;
            Object.keys(newoptions).forEach(function(_key){
                currentoptions[_key] = newoptions[_key];
            });
        };
        BBRTE.prototype.destroy = function(){
            this.options = undefined;
            this._bbrtecontainer = undefined;
            this._bbrtecontent = undefined;
            this._bbrtetoolbar = undefined;
        }
        
        return BBRTE;
    })();

    if(module && module.exports){
        module.exports = BBRTE;
    }
    else{
        if(typeof define === "function" && define.amd){
            define([], function(){
                return BBRTE;
            });
        }
        else{
            window.BBRTE = BBRTE;
        }
    }
    
})();


const toolbarSettings = [
    {
        name: "fontsize",
        tooltip: "Font Sizes",
        sizes: [
            {
                label: "Smallest",
                size: "1"
            },
            {
                label: "Small",
                size: "2"
            },
            {
                label: "Normal",
                size: "3"
            },
            {
                label: "Big",
                size: "4"
            },
            {
                label: "Bigger",
                size: "5"
            },
            {
                label: "Huge",
                size: "6"
            },
            {
                label: "Massive",
                size: "7"
            }
        ],
        execcommand: 'fontSize'
    },
    {
        name: "underline",
        tooltip: "Underline",
        label: "U",
        execcommand: 'underline'
    },
    {
        name: "italic",
        tooltip: "Italic",
        label: "I",
        execcommand: 'italic'
    },
    {
        name: "bold",
        tooltip: "Bold",
        label: "B",
        execcommand: 'bold'
    },
    {
        name: "strikethrough",
        tooltip: "Strikethrough",
        label: "S",
        execcommand: 'strikeThrough'
    },
    {
        name: "color",
        tooltip: "Selection Color",
        label: "C"
    },
    {
        name: "hyperlink",
        tooltip: "Add Hyperlink",
        label: "L"
    },
    {
        name: "selectall",
        tooltip: "Select All",
        label: "SA",
        execcommand: 'selectAll'
    },
    {
        name: "cut",
        tooltip: "Cut",
        label: "Cu",
        execcommand: 'cut'
    },
    {
        name: "copy",
        tooltip: "Copy",
        label: "Co"
    },
    {
        name: "paste",
        tooltip: "Paste",
        label: "Pa"
    },
    {
        name: "delete",
        tooltip: "Delete",
        label: "D",
        execcommand: 'delete'
    },
    {
        name: "undo",
        tooltip: "Undo",
        label: "Un",
        execcommand: 'undo'
    },
    {
        name: "redo",
        tooltip: "Redo",
        label: "Re",
        execcommand: 'redo'
    },
    {
        name: "justifyleft",
        tooltip: "Justify Left",
        label: "JL",
        execcommand: 'justifyLeft'
    },
    {
        name: "justifycenter",
        tooltip: "Justify Center",
        label: "JC",
        execcommand: 'justifyCenter'
    },
    {
        name: "justifyright",
        tooltip: "Justify Right",
        label: "JR",
        execcommand: 'justifyRight'
    },
    {
        name: "textdirectionrtl",
        tooltip: "Right To Left Text Direction",
        label: "TDRTL"
    }
]


const stylesheetText = `

.bbrte_container{
    min-width: 50vw;
    min-height: 10vh;
    max-width: 100vw;
    max-height: 60vh;
    display: block;
    position: relative;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.bbrte_content_container{
    display: block;
    position: relative;
    min-width: 50vw;
    min-height: 20vh;
    padding: 10px;
    border: 1px solid gray;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.bbrte_content_container:focus{
    border:none;
    box-shadow: 5px 5px 10px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.bbrte_content_placeholder{
    color: gray;
    font-style: italic;
}
.bbrte_toolbar_container{
    display: flex;
    flex-direction: row;
    min-width: 50vw;
    justify-content: center;
    padding: 10px 5px 5px 5px;
}

.bbrte_toolbar_button{
    border: 1px solid black;
    margin: 5px;
    width: 30px;
    height: 30px;
    background-color: #555555;
    color: #fff;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.bbrte_toolbar_button:before,
.bbrte_toolbar_button:after {
  display: block;
  opacity: 0;
  pointer-events: none;
  position: absolute;
}
.bbrte_toolbar_button:before {
  background: rgba(0,0,0,.75);
  border-radius: 2px;
  color: #fff;
  content: attr(data-title);
  font-size: 14px;
  padding: 6px 10px;
    top: 26px;
  white-space: nowrap;
}

.bbrte_toolbar_button.fade:after,
.bbrte_toolbar_button.fade:before {
  transform: translate3d(0,-10px,0);
  transition: all .15s ease-in-out;
}
.bbrte_toolbar_button.fade:hover:after,
.bbrte_toolbar_button.fade:hover:before {
  opacity: 1;
  transform: translate3d(0,0,0);
}

.bbrte_active {
    background-color: #059dda;
}

.bbrte_modal-hyperlink_input {
    width: 80%;
    padding: 8px 5px 8px 5px;
}
.bbrte_modal {
    display: none;
    position: fixed;
    z-index: 99;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0,0,0);
    background-color: rgba(0,0,0,0.4);
}
.bbrte_modal-content {
    background-color: #fefefe;
    margin: 15% auto;
    padding: 20px;
    border: 1px solid #888;
    width: 60%;
}

.bbrte_modal-close, .bbrte_modal-accept {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
    cursor:pointer;
    text-decoration:none;
}
.bbrte_modal-close:hover,
.bbrte_modal-close:focus {
    color: red;
}
.bbrte_modal-accept:hover,
.bbrte_modal-accept:focus {
    color: green;
}

.bbrte_toolbar_select{
    width: 150px;
    height: 30px;
    margin: 5px;
}

`