import React from 'react';

import mapStateToProps from '../../datastore/mapper.js';
import mapDispatchToProps from '../../datastore/dispatcher.js';

import {connect} from 'react-redux';

import {withRouter} from 'react-router-dom';

import AppWrapper from '../app_wrapper/index.js'

import ListEvents from './list_events.js';
import EditEvent from './edit_event.js';

export class EventsManagement extends React.Component{
    constructor(props){
        super();
    }

    eventsManagementType(_type){
        switch(_type){
            case "create":
            case "clone":
            case "edit":
                return <EditEvent clone={_type==="clone"}/>;
            default:
                return <ListEvents />;
        }
    }

    parsedURLSubtitle(urlpath){
        const parsedURL = urlpath.split("/");
        if(parsedURL[2]==="edit" || parsedURL[2]==="create" || parsedURL[2]==="clone")
            return parsedURL[2];
        else
            return "list";
    }


    render(){

        const {location:{pathname}} = this.props;

        const parsedpath = this.parsedURLSubtitle(pathname);
        const EventsManagementComponent = this.eventsManagementType(parsedpath);

        return(
            <AppWrapper title={'Events Management'} subtitle={parsedpath}>
                <div className="eventssmanagement">
                    {EventsManagementComponent}
                </div>
            </AppWrapper>
        )
    }
}

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(EventsManagement));