//Error spec
/*
type: 101 -> api endpoint put error

*/

const initState = {
    error:{
        type: null,
        message: null
    },
    user:null,
    jwt:null,
    userManagement:{
        availableRoles:null,
        availablePermissions:null,
        pendingInvites:null,
        userList:null
    },
    tags:null,
    languages:null,
    proficiencies:null,
    skills:null,
    media:{
        schema:null,
        allAssets:null
    },
    items:null,
    collections:null,
    testevents:null,
    scores: null,
    organizations: null,
    status: {
        items: null,
        collections: null,
        testevents: null,
        organizations: null
    },
    eventtemplate: null
};
  
const AppReducer = (state=initState, action) => {
    switch(action.type){
        case "UPDATE_ERROR":
            state = {
                ...state,
                error: action.payload
            };
            break;
        case "UPDATE_USERINFO":
            state = {
                ...state,
                user: action.payload
            };
            break;
        case "UPDATE_JWT":
            state = {
                ...state,
                jwt: action.payload
            };
            break;
        case "UPDATE_AVAILABLE_PERMISSIONS":
            state={
                ...state,
                userManagement:{
                    ...state.userManagement,
                    availablePermissions:action.payload
                }
            };
            break;
        case "UPDATE_ROLES":
            state={
                ...state,
                userManagement:{
                    ...state.userManagement,
                    availableRoles:action.payload
                }
            };
            break;
        case "UPDATE_PENDING_INVITES":
            state={
                ...state,
                userManagement:{
                    ...state.userManagement,
                    pendingInvites:action.payload
                }
            };
            break;
        case "UPDATE_LANGUAGES":
            state={
                ...state,
                languages:action.payload
            };
            break;
        case "UPDATE_PROFICIENCY":
            state={
                ...state,
                proficiencies:action.payload
            };
            break;
        case "UPDATE_SKILLS":
            state={
                ...state,
                skills:action.payload
            };
            break;
        case "UPDATE_TAGS":
            const tagsactionpayload = !Array.isArray(action.payload) ? [action.payload] : action.payload;
            const currentTags = new Array();
            if(!state.tags){
                Array.prototype.push.apply(currentTags, tagsactionpayload);
            }
            else{
                Array.prototype.push.apply(currentTags, state.tags); 
                for(var i=0; i<tagsactionpayload.length; i++){
                    const idExists = state.tags.filter(tag=>tag.id===tagsactionpayload[i].id).length;
                    if(!idExists && (action.status==="new" || !action.status)){
                        currentTags.push(tagsactionpayload[i]);
                    }
                    else if(idExists && (action.status==="update" || action.status==="delete")){
                        const tagIndex = state.tags.findIndex(tag=>tag.id===tagsactionpayload[i].id);
                        action.status==="update" ? currentTags.splice(tagIndex, 1, tagsactionpayload[i]) : currentTags.splice(tagIndex, 1);
                    }
                }
            }
            
            state={
                ...state,
                tags: currentTags
            };
            break;
        case "UPDATE_MEDIA_SCHEMA":
            state={
                ...state,
                media:{
                    ...state.media,
                    schema:action.payload
                }
            };
            break;
        case "UPDATE_MEDIA_ASSETS":
            const allAvailableMediaAssets = state.media.allAssets || [];
            
            action.payload.forEach(payloadasset=>{
                if(action.status && action.status==="update"){
                    allAvailableMediaAssets.forEach(mediaasset=>{
                        if(mediaasset.id===payloadasset.id){
                            Object.keys(payloadasset).forEach(_key=>{
                                mediaasset[_key] = payloadasset[_key];
                            });
                        }
                    });
                }
                else if(action.status && action.status==="delete"){
                    allAvailableMediaAssets.splice(allAvailableMediaAssets.findIndex(media=>media.id===action.payload[0].id), 1);
                }
                else if(!allAvailableMediaAssets.filter(_asset=>_asset.id===payloadasset.id).length){
                    allAvailableMediaAssets.push(payloadasset);
                }
            });

            state={
                ...state,
                media:{
                    ...state.media,
                    allAssets: allAvailableMediaAssets
                }
            };
            break;
        case "UPDATE_ITEMS":
            const itemactionpayload = !Array.isArray(action.payload) ? [action.payload] : action.payload;
            const currentItems = new Array();
            if(!state.items){
                Array.prototype.push.apply(currentItems, itemactionpayload);
            }
            else{
                Array.prototype.push.apply(currentItems, state.items); 
                for(var i=0; i<itemactionpayload.length; i++){
                    const idExists = state.items.filter(item=>item.id===itemactionpayload[i].id).length;
                    if(!idExists && (action.status==="new" || !action.status)){
                        currentItems.push(itemactionpayload[i]);
                    } 
                    else if(idExists && action.status==="delete"){
                        const itemIndex = state.items.findIndex(item=>item.id===itemactionpayload[i].id);
                        currentItems.splice(itemIndex, 1);
                    }
                    else if(idExists && (action.status==="update" || !action.status)){
                        const itemIndex = state.items.findIndex(item=>item.id===itemactionpayload[i].id);
                        currentItems.splice(itemIndex, 1, itemactionpayload[i]);
                    }
                }
            }

            state={
                ...state,
                items: currentItems
            };
            break;
        case "UPDATE_COLLECTIONS":
            const collectionactionpayload = !Array.isArray(action.payload) ? [action.payload] : action.payload;
            const currentCollections = new Array();
            if(!state.collections){
                Array.prototype.push.apply(currentCollections, collectionactionpayload); 
            }
            else{
                Array.prototype.push.apply(currentCollections, state.collections);
                for(var i=0; i<collectionactionpayload.length; i++){
                    const idExists = state.collections.filter(col=>col.id===collectionactionpayload[i].id).length;
                    if(!idExists && (action.status==="new" || !action.status)){
                        currentCollections.push(collectionactionpayload[i]);
                    } 
                    else if(idExists && action.status==="delete"){
                        const collectionIndex = state.collections.findIndex(col=>col.id===collectionactionpayload[i].id);
                        currentCollections.splice(collectionIndex, 1);
                    }
                    else if(idExists && action.status==="update"){
                        const collectionIndex = state.collections.findIndex(col=>col.id===collectionactionpayload[i].id);
                        currentCollections.splice(collectionIndex, 1, collectionactionpayload[i]);
                    }
                }
            }

            state={
                ...state,
                collections: currentCollections
            };
            break;
        case "UPDATE_EVENTS":
            const eventactionpayload = !Array.isArray(action.payload) ? [action.payload] : action.payload;
            const currentEvents = new Array();
            if(!state.testevents){
                Array.prototype.push.apply(currentEvents, eventactionpayload); 
            }
            else{
                Array.prototype.push.apply(currentEvents, state.testevents); 
                for(var i=0; i<eventactionpayload.length; i++){
                    const idExists = state.testevents.filter(_event=>_event.id===eventactionpayload[i].id).length;
                    if(!idExists && (action.status==="new" || !action.status)){
                        currentEvents.push(eventactionpayload[i]);
                    } 
                    else if(idExists && action.status==="delete"){
                        const eventIndex = state.testevents.findIndex(_event=>_event.id===eventactionpayload[i].id);
                        currentEvents.splice(eventIndex, 1);
                    }
                    else if(idExists && action.status==="update"){
                        const eventIndex = currentEvents.findIndex(_event=>_event.id===eventactionpayload[i].id);
                        currentEvents.splice(eventIndex, 1, eventactionpayload[i]);
                    }
                }
            }

            state={
                ...state,
                testevents: currentEvents
            };
            break;
        case "UPDATE_SCORES":
            state={
                ...state,
                scores: action.payload
            };
            break;
        case "UPDATE_ITEMS_REPORT":
            state={
                ...state,
                itemsreport: action.payload
            };
            break;
        case "UPDATE_TESTLETS_REPORT":
            state={
                ...state,
                testletsreport: action.payload
            };
            break;
        case "UPDATE_ORGANIZATIONS":
            const orgactionpayload = !Array.isArray(action.payload) ? [action.payload] : action.payload;
            const currentOrganizations = new Array();
            if(!state.organizations){
                Array.prototype.push.apply(currentOrganizations, orgactionpayload);
            }
            else{
                Array.prototype.push.apply(currentOrganizations, state.organizations); 
                for(var i=0; i<orgactionpayload.length; i++){
                    const idExists = state.organizations.filter(org=>org.id===orgactionpayload[i].id).length;
                    if(!idExists && (action.status==="new" || !action.status)){
                        currentOrganizations.push(orgactionpayload[i]);
                    } 
                    else if(idExists && action.status==="delete"){
                        const orgIndex = state.organizations.findIndex(org=>org.id===orgactionpayload[i].id);
                        currentOrganizations.splice(orgIndex, 1);
                    }
                    else if(idExists && (action.status==="update" || !action.status)){
                        const orgIndex = state.organizations.findIndex(org=>org.id===orgactionpayload[i].id);
                        currentOrganizations.splice(orgIndex, 1, orgactionpayload[i]);
                    }
                }
            }

            state={
                ...state,
                organizations: currentOrganizations
            };
            break;
        case "UPDATE_STATUSES":
            state.status[action.statustype] = action.payload;
            break;
        case "UPDATE_EVENT_TEMPLATE":
            state={
                ...state,
                eventtemplate:action.payload
            };
            break;
    }
    return state;
}
  
export default AppReducer;