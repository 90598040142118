import React from 'react';
import mapStateToProps from '../../../datastore/mapper.js';
import mapDispatchToProps from '../../../datastore/dispatcher.js';
import {connect} from 'react-redux';
import {withRouter, Link} from 'react-router-dom';
import {Button, Popup} from 'semantic-ui-react';

export class ItemHistoryButton extends React.Component {
    render(){
        const {itemId} = this.props;
        return(
            <Link to={{pathname: `/items/history/${itemId}`}}>
                <Popup content='View Version History' trigger={
                    <Button circular icon='history' size='tiny' color='teal'/>
                }/>
            </Link>
        )
    }
}

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(ItemHistoryButton));