import React from 'react';

import mapStateToProps from '../../datastore/mapper.js';
import mapDispatchToProps from '../../datastore/dispatcher.js';

import {connect} from 'react-redux';

import { Segment, Button, Icon, Popup, Message, Grid, Input, List, Label, Divider } from 'semantic-ui-react';
import autoBind from 'react-autobind';

export class TagManager extends React.Component{
    constructor(props){
        super();

        this.state={
            tagsearchpattern: null,
            loadingTags: false,
            showTags:true,
            showTagContainer:true
        };

        autoBind(this);
    }

    componentDidMount(){
        if(
            this.props.currentCollectionTags &&
            this.props.currentCollectionTags.length &&
            (
                !this.props.appProps.tags ||
                !this.props.appProps.tags.length ||
                !this.props.appProps.tags.filter(tagid=>this.props.currentCollectionTags.includes(tagid).length)
            )
        ){
            this.getTags();
        }
    }

    getTags(){
        this.props.currentCollectionTags.forEach(async tagid=>{
            try{
                const tagsretrieved = await this.props.getTags(null, tagid);
            }catch(err){}
        })
    }

    updateTagSearchPattern(event){
        const inputval = event.target.value;
        this.setState({tagsearchpattern: inputval});
    }

    async searchTags(){
        this.setState({loadingTags:true});
        try{
            const tagsretrieved = await this.props.getTags(this.state.tagsearchpattern, null);
        }catch(err){}
        this.setState({loadingTags:false, showTags:true});
    }

    clearSearchedTags(){
        this.setState({showTags:false, tagsearchpattern: null});
    }

    toggleTagContainer(){
        this.setState((prevState)=>({showTagContainer: !prevState.showTagContainer}));
    }

    render(){

        const {appProps:{tags}, currentCollectionTags} = this.props;

        return(
            <Segment raised>
                <Divider hidden/>
                <Popup content={this.state.showTagContainer?'Minimize Toolset':'Maximize Toolset'} trigger={
                    <Label size='small' attached="top right" as='a' onClick={this.toggleTagContainer}><Icon name={this.state.showTagContainer?'window minimize':'window maximize outline'}/></Label>
                }/>
                {this.state.showTagContainer && 
                    <React.Fragment>
                        {tags && tags.length && currentCollectionTags && currentCollectionTags.length &&
                            <Message>
                                <Message.Header>Tags</Message.Header>
                                <p>
                                {currentCollectionTags.map(tag=>{
                                    if(tags.filter(ftag=>ftag.id===tag).length){
                                        return (
                                            <Label onClick={()=>this.props.removeSelectedTag(tag)} key={`item-tags-${tag}`} as='a' tag>{tags.filter(ftag=>ftag.id===tag)[0].label}</Label>
                                        )
                                    }
                                }).filter(item=>item)}
                                </p>
                            </Message>
                        || null}
                        <Input fluid icon action actionPosition='left'>
                            <Button onClick={this.clearSearchedTags}>Clear</Button>
                            <Popup content='Search Tags' trigger={
                                <React.Fragment>
                                    {!this.state.loadingTags?
                                        <Icon name='search' inverted circular link onClick={this.searchTags}/>
                                    :
                                        <Icon loading name='asterisk' link inverted circular/>
                                    }
                                </React.Fragment>
                            } />
                            <input placeholder='Search Tags' value={this.state.tagsearchpattern || ''} onChange={this.updateTagSearchPattern}/>
                        </Input>
                        {tags && tags.length && this.state.showTags ?
                            <div className="list-container">
                                <List>
                                    {tags.map(tag=>{
                                        return (
                                            <List.Item key={`user-${tag.id}`} onClick={()=>this.props.addSelectedTag(tag)}>
                                                <List.Content>
                                                    <Grid columns='equal'>
                                                        <Grid.Row>
                                                            <Grid.Column>
                                                                <Label as='a' tag>{tag.label}</Label>
                                                            </Grid.Column>
                                                            <Grid.Column>
                                                                <List horizontal>
                                                                {tag.user &&
                                                                    <List.Item>
                                                                        <Icon name='user' />
                                                                        <span>{`${tag.user.lastname},${tag.user.firstname}`}</span>
                                                                    </List.Item>
                                                                }
                                                                {tag.role &&
                                                                    <List.Item>
                                                                        <Icon name='chess pawn' />
                                                                        <span>{tag.role.role_name}</span>
                                                                    </List.Item>
                                                                }
                                                                {tag.language &&
                                                                    <List.Item>
                                                                        <Icon name='language' />
                                                                        <span>{tag.language.name}</span>
                                                                    </List.Item>
                                                                }
                                                                {tag.proficiency &&
                                                                    <List.Item>
                                                                        <Icon name='trophy' />
                                                                        <span>{tag.proficiency.label[0].toUpperCase() + tag.proficiency.label.slice(1)}</span>
                                                                    </List.Item>
                                                                }
                                                                </List>
                                                            </Grid.Column>
                                                        </Grid.Row>
                                                    </Grid>
                                                </List.Content>
                                            </List.Item>
                                        )
                                    })}
                                </List>
                            </div>
                        :null}
                    </React.Fragment>
                || 
                    <Label size='small' attached="top left">Tags</Label>
                }
            </Segment>
        )
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(TagManager);