import React from 'react';

import mapStateToProps from '../../datastore/mapper.js';
import mapDispatchToProps from '../../datastore/dispatcher.js';

import {connect} from 'react-redux';

import {withRouter} from 'react-router-dom';

import autoBind from 'react-autobind';

import {Tab, Segment, Grid, Header, Icon, Message, Form, TextArea, Divider, Button, Table} from 'semantic-ui-react';

export class MLTester extends React.Component{
    constructor(props){
        super();
        this.state = {
            autoGrading: {
                isGrading: false,
                isUploading: false,
                text:{
                    value: null,
                    error: null,
                    score: null
                },
                audio: {
                    error: null,
                    scores: null
                }
            }
        }

        this.gradingIntervals = null;
        this.uploadFormData = new FormData();

        autoBind(this);
    }

    componentDidMount(){
        this.setupGradingCheckIntervals();
    }
    componentWillUnmount(){
        clearInterval(this.gradingIntervals);
        this.gradingIntervals = null;
    }

    setupGradingCheckIntervals(){
        this.getAudioGrades();
        this.gradingIntervals = setInterval(()=>{
            this.getAudioGrades();
        }, 30000);
    }

    async getAudioGrades(){
        try{
            const audioGrades = await this.props.checkAudioAutoGrading();
            const parsedAudioGrades = JSON.parse(audioGrades);
            this.setState((prevState)=>({
                autoGrading: {
                    ...prevState.autoGrading,
                    audio: {
                        error: null,
                        scores: parsedAudioGrades.dataset
                    }
                }
            }));
        }catch(err){
            this.setState((prevState)=>({
                autoGrading: {
                    ...prevState.autoGrading,
                    audio: {
                        scores: null,
                        error: null
                    }
                }
            }));
        }
    }

    updateTextToGrader(event){
        const textval = event.target.value;
        this.setState((prevState)=>({
            autoGrading: {
                ...prevState.autoGrading,
                text: {
                    ...prevState.autoGrading.text,
                    value: textval
                }
            }
        }));
    }

    testAutoGraderForText(){
        if(this.state.autoGrading.text.value && this.state.autoGrading.text.value.length){
            this.setState((prevState)=>({
                autoGrading: {
                    ...prevState.autoGrading,
                    isGrading: true
                }
            }), async()=>{
                try{
                    const textGrades = await this.props.checkTextAutoGrading({
                        text: this.state.autoGrading.text.value
                    });
                    const parseGradesResponse = JSON.parse(textGrades);
                    if(parseGradesResponse.success){
                        this.setState((prevState)=>({
                            autoGrading: {
                                ...prevState.autoGrading,
                                text: {
                                    ...prevState.autoGrading.text,
                                    error: null,
                                    score: parseGradesResponse.score
                                },
                                isGrading: false
                            }
                        }));
                    }
                }catch(err){
                    this.setState((prevState)=>({
                        autoGrading: {
                            ...prevState.autoGrading,
                            text: {
                                ...prevState.autoGrading.text,
                                error: 'Could Not Auto Grade Text'
                            },
                            isGrading: false
                        }
                    }));
                }
            });
        }
    }

    uploadAudioFile(){
        this.setState((prevState)=>({
            autoGrading: {
                ...prevState.autoGrading,
                isUploading: true
            }
        }), ()=>{

            const uploadInputNode = document.createElement('INPUT');
            uploadInputNode.setAttribute("type", "file");
            uploadInputNode.setAttribute("accept", "audio/wav");
            document.body.appendChild(uploadInputNode);
            uploadInputNode.style.display = 'none';
            uploadInputNode.click();
            uploadInputNode.addEventListener("change", async (event)=>{

                const _audiofile = event.target.files[0];

                const fileType = event.target.files[0].type;
                const fileName = event.target.files[0].name;

                if(this.uploadFormData.has("audiofile")){
                    this.uploadFormData.delete("audiofile");
                }
                this.uploadFormData.append("audiofile", _audiofile);
                uploadInputNode.remove();

                try{
                    const autoGraderAudioUploaded =  await this.props.uploadAutoGradingAudio(this.uploadFormData);
                    console.log(autoGraderAudioUploaded);
                    const parsedResponse = JSON.parse(autoGraderAudioUploaded);
                    const currentAudioScores = this.state.autoGrading.audio.scores;
                    if(currentAudioScores && currentAudioScores.length){
                        currentAudioScores.push({
                            batchName: parsedResponse.batchname,
                            tokenID: parsedResponse.batchtoken,
                            resultsReady: false
                        });
                    }
                    else if(!currentAudioScores || !currentAudioScores.length){
                        currentAudioScores = [
                            {
                                batchName: parsedResponse.batchname,
                                tokenID: parsedResponse.batchtoken,
                                resultsReady: false
                            }
                        ]
                    }
                    this.setState((prevState)=>({
                        autoGrading: {
                            ...prevState.autoGrading,
                            isUploading: false,
                            audio: {
                                ...prevState.autoGrading.audio,
                                error: null,
                                scores: currentAudioScores
                            }
                        }
                    }));
                }catch(err){
                    console.log(err);
                    this.setState((prevState)=>({
                        autoGrading: {
                            ...prevState.autoGrading,
                            isUploading: false,
                            audio: {
                                ...prevState.autoGrading.audio,
                                error: "Could Not Upload Audio To Be Auto Graded"
                            }
                        }
                    }));
                }

            });

        });

    }

    render(){
        return(
            <Segment attached>
                <Grid>
                    <Grid.Row>
                        <Grid.Column>
                            <div>
                                <Header as='h4'>
                                    <Icon name='stopwatch' />
                                    <Header.Content>Test Writing Auto Grading</Header.Content>
                                </Header>
                            </div>
                            <Divider hidden/>
                            <div>
                                {this.state.autoGrading.text.error ?
                                    <Message attached="top" negative>
                                        <p><b>Error: </b>{this.state.autoGrading.text.error}</p>
                                    </Message>
                                :
                                    <Message attached="top" info>
                                        <p>Type or paste text you wish to test auto grading on, then click on "Test"</p>
                                    </Message>
                                }
                                <Form attached="true">
                                    <textarea placeholder='Auto Grading Text' onChange={this.updateTextToGrader}></textarea>
                                </Form>
                                <Message attached="bottom" size="large">
                                    <small>
                                        {this.state.autoGrading.text.score ?
                                        <span><b>Score: </b>{this.state.autoGrading.text.score}</span>
                                        :
                                        <span>Click Test To Check Auto Grading Score</span>
                                        }
                                    </small>
                                    {this.state.autoGrading.isGrading ?
                                        <Button floated='right' loading>loading</Button>
                                    :
                                        <Button color='green' floated='right' onClick={this.testAutoGraderForText}>Test</Button>
                                    }
                                </Message>
                            </div>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column>
                            <div>
                                <Header as='h4'>
                                    <Icon name='stopwatch' />
                                    <Header.Content>Test Audio Auto Grading</Header.Content>
                                </Header>
                            </div>
                            <Message size="big" info>
                                <span>Upload audio file to the queue, to test auto grading.</span>
                                {this.state.autoGrading.isUploading?
                                    <Button loading floated="right">loading</Button>
                                :
                                    <Button disabled floated="right" color="blue" onClick={/*this.uploadAudioFile*/null}>Upload</Button>
                                }
                            </Message>
                        </Grid.Column>
                    </Grid.Row>
                    {/*
                    <Grid.Row>
                        <Grid.Column>
                            {this.state.autoGrading.audio.scores && this.state.autoGrading.audio.scores.length ?
                                <Table celled>
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell>Batch Name</Table.HeaderCell>
                                            <Table.HeaderCell>Token</Table.HeaderCell>
                                            <Table.HeaderCell>Ready</Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>

                                    <Table.Body>
                                        {this.state.autoGrading.audio.scores.map((_score, score_index)=>{
                                            return (
                                                <Table.Row key={`table-row-${score_index}`}>
                                                    <Table.Cell>{_score.batchName}</Table.Cell>
                                                    <Table.Cell>{_score.tokenID}</Table.Cell>
                                                    <Table.Cell>
                                                        <Icon
                                                            name={_score.resultsReady ? "check" : "minus circle"}
                                                            color={_score.resultsReady ? "green" : "red"}
                                                        />
                                                    </Table.Cell>
                                                </Table.Row>
                                            )
                                        })}
                                    </Table.Body>
                                </Table>
                            :
                                <Table celled>
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell colSpan='3'>No Audio Scores Available</Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>
                                </Table>
                            }
                        </Grid.Column>
                    </Grid.Row>
                    */}
                </Grid>
            </Segment>
        )
    }
}

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(MLTester));