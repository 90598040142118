import React from 'react';

import mapStateToProps from '../../datastore/mapper.js';
import mapDispatchToProps from '../../datastore/dispatcher.js';

import {connect} from 'react-redux';

import { Container, Form, Button, Icon, Input, Checkbox, Message, Grid } from 'semantic-ui-react'
import validator from 'validator';
import autoBind from 'react-autobind';

export class Login extends React.Component{
    constructor(props){
        super();
        this.state={
            showpass:false,
            showerror:false,
            showReset: false,
            resetError: false,
            resetSuccess: false,
        }

        autoBind(this);
    }

    loginUser(){
        const loginform = document.forms[0];
        const loginformelems = loginform.elements;
        const _email = Array.from(loginformelems).find(item=>item.id==="_email").value;
        const _password = Array.from(loginformelems).find(item=>item.id==="_pw").value;
        const _remember = Array.from(loginformelems).find(item=>item.id==="_rm").checked;
        if(!_email.length || !_password.length/* || !this.validateEmail(_email)*/){
            this.setState({showerror: true});
        }
        else{
            this.setState({showerror: false}, ()=>{
                this.props.loginUser({email:_email, password:_password, rememberme:_remember});
            });
        }
    }

    validateEmail(_emailadd){
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(_emailadd).toLowerCase());
    }

    toggleShowPass(){
        this.setState((prevState)=>({
            showpass: !prevState.showpass
        }));
    }

    toggleShowReset(){
        this.setState((prevState)=>({
            showReset: !prevState.showReset,
            resetSuccess: false,
        }));
    }

    requestPasswordReset(){
        this.setState({resetError: false});
        const email = document.getElementById("reset_email").value;
        if(!validator.isEmail(email, {require_tld: false})){
            this.setState({resetError: "Please enter a valid email address"});
            return;
        } 
        this.props.requestPasswordReset(email).then(data=>{
            try{
                data = JSON.parse(data);
                if(data.success){
                    this.setState({resetError: false, resetSuccess: true});
                }else if(data.error){
                    this.setState({resetError: data.error.message});
                }
                else{
                    this.setState({resetError: "An unknown error occured"});
                }
            }catch(e){
                this.setState({resetError: "A communication error occured"});
            }
        },
        error=>{
            this.setState({resetError: error.message});
        });
    }

    render(){
        return(
            <Grid verticalAlign='middle'>
                <Grid.Column>
                    <Container>
                        <div>
                            <Message attached>
                                <Message.Header>BYU Proficiency Placement System</Message.Header>
                                <Message.List>
                                    <Message.Item>Please Login Below To Start Using This Platform</Message.Item>
                                    <Message.Item>If you just want to view API Docs, <a className="docs-link" href="/docs">Click Here</a></Message.Item>
                                </Message.List>
                            </Message>
                            {!this.state.showReset ?
                                <React.Fragment>
                                    <Form className='attached fluid segment'>
                                        <Form.Field>
                                            <label>Email</label>
                                            <Input id="_email" placeholder='email' />
                                        </Form.Field>
                                        <Form.Field>
                                            <label>Password</label>
                                            <Input id="_pw" placeholder='Password'
                                                type={this.state.showpass?'text':'password'}
                                                icon={
                                                    <Icon onClick={this.toggleShowPass} name={this.state.showpass?'eye':'eye slash'} circular link />
                                                }
                                            />
                                        </Form.Field>
                                        <Form.Field>
                                            <Checkbox id="_rm" label='Remember Me' />
                                        </Form.Field>
                                        <Button onClick={this.loginUser}>Login</Button>
                                    </Form>
                                    {this.state.showerror || this.props.appProps.error.type &&
                                        <Message attached='bottom' error>
                                            <Icon name='exclamation' />
                                            {this.props.appProps.error.type? this.props.appProps.error.message : 'Please fill out all inputs correctly before submitting'}
                                        </Message>
                                    }
                                </React.Fragment>
                                :
                                <React.Fragment>
                                    <Form className='attached fluid segment'>
                                        <Form.Field>
                                            <label>Email</label>
                                            <Input id="reset_email" placeholder='email address' />
                                        </Form.Field>
                                        <Button disabled={this.state.resetSuccess} onClick={this.requestPasswordReset}>Submit</Button>
                                    </Form>
                                    {this.state.resetError &&
                                        <Message attached='bottom' error>
                                            <Icon name='exclamation' />
                                            {this.state.resetError}
                                        </Message>
                                    }
                                    {this.state.resetSuccess &&
                                        <Message attached='bottom' positive>
                                            <Icon name='exclamation' />
                                            Success! Please check your email.
                                        </Message>
                                    }
                                </React.Fragment>
                            }
                            <Message attached='bottom'>
                                <Button onClick={this.toggleShowReset} className="resetPasswordToggle">{this.state.showReset? "Back to Login" : "Forgot Password?"}</Button>
                            </Message>
                        </div>
                    </Container>
                </Grid.Column>
            </Grid>

        )
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(Login);