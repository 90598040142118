import React from 'react';

import mapStateToProps from '../../datastore/mapper.js';
import mapDispatchToProps from '../../datastore/dispatcher.js';

import {connect} from 'react-redux';

import {withRouter} from 'react-router-dom';
import { Message, Button, Grid, Header, Input, Form } from 'semantic-ui-react';

import autoBind from 'react-autobind';

export class Register extends React.Component {

    constructor(props){
        super();
        this.state = {
            firstName: "",
            lastName: "",
            password: "",
            repeatpassword: "",
            formError: null,
        }

        autoBind(this);
    }

    componentDidMount(){
        if(this.props.appProps.user){
            this.props.logoutUser();
        }
        else{
            this.props.clearSession();
        }
    }
    componentDidUpdate(){
        if(this.props.appProps.user){
            this.props.logoutUser();
        }
    }

    setFirstName(event){
        this.setState({firstName: event.target.value})
    }

    setLastName(event){
        this.setState({lastName: event.target.value})
    }

    setPassword(event){
        const pass = event.target.value;
        this.setState((prevState)=>({
            password: pass,
            formError: pass!==prevState.repeatpassword ? {type: 1, message: 'Passwords Must Match'} : null
        }));
    }
    setRepeatPassword(event){
        const repeatpass = event.target.value;
        this.setState((prevState)=>({
            repeatpassword: repeatpass,
            formError: repeatpass!==prevState.password ? {type: 1, message: 'Passwords Must Match'} : null
        }));
    }

    submit(){
        if(!this.state.formError && this.state.password !== "" && this.state.firstName !=="" && this.state.lastName !== ""){
            const encodedpass = window.btoa(this.state.password);
            this.props.createUser({
                "id": this.props.match.params.uuid,
                "password": encodedpass,
                "firstname": this.state.firstName,
                "lastname": this.state.lastName
            }).then(data=>{
                this.setState({formError: {type: 0, message: 'Success'}});
            },
            error=>{
                console.log(error);
                this.setState({formError: {type: -1, message: 'Unknown Error'}});
            });
        }
        else{
            this.setState({formError: {type: 2, message: 'Please Fill Out All Fields'}});
        }
    }

    redirectToLogin(){
        window.location.href = "/";
    }

    render(){
        return(
            <Grid className="registerContainer">
                <Grid.Column className="registColumn">
                    <Header className="registerHeader" as='h2'>
                        Proficiency Placement
                    </Header>
                    {this.state.formError && this.state.formError.type===0 ?
                        <React.Fragment>
                            <Message
                                success
                                header='Your registration was successful'
                                content='You may now login with your email'
                            />
                            <Button color="blue" onClick={this.redirectToLogin}>Click Here To Go To Login Page</Button>
                        </React.Fragment>
                    :
                        <React.Fragment>
                            <p>Finish setting up your account.</p>
                            {this.state.formError && (this.state.formError.type>0 || this.state.formError.type===-1) &&
                                <>
                                    <Message negative>
                                        <Message.Header>Form Error</Message.Header>
                                        <p>{this.state.formError.message}</p>
                                    </Message>
                                </>
                            }
                            <Form>
                                <Form.Field>
                                    <label className="registerLabel">First Name</label>
                                    <input value={this.state.firstName} onChange={this.setFirstName} placeholder='First Name' />
                                </Form.Field>
                                <Form.Field>
                                    <label className="registerLabel">Last Name</label>
                                    <input value={this.state.lastName} onChange={this.setLastName} placeholder='Last Name' />
                                </Form.Field>
                                <Form.Field>
                                    <label className="registerLabel">Enter New Password</label>
                                    <Input value={this.state.password} onChange={this.setPassword} type='password' />
                                </Form.Field>
                                <Form.Field>
                                    <label className="registerLabel">Repeat New Password</label>
                                    <Input
                                        value={this.state.repeatpassword}
                                        onChange={this.setRepeatPassword}
                                        type='password'
                                        error={this.state.formError && this.state.formError.type===1}
                                    />
                                </Form.Field>
                                <Button type='submit' onClick={this.submit}>Submit</Button>
                            </Form>
                        </React.Fragment>
                    }
                </Grid.Column>
            </Grid>
        );
    }
}

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(Register));