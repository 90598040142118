import React from 'react';

import mapStateToProps from '../../datastore/mapper.js';
import mapDispatchToProps from '../../datastore/dispatcher.js';

import {withRouter} from 'react-router-dom';

import {connect} from 'react-redux';

import AppWrapper from '../app_wrapper/index.js'

import DocManager from './doc_manager.js';

import { Icon } from 'semantic-ui-react';

export class Docs extends React.Component{
    constructor(props){
        super();
    }

    render(){
        return(
            <React.Fragment>
            {this.props.appProps.user ?
                <AppWrapper title={'Docs'} subtitle={null}>
                    <DocManager jwt={this.props.appProps.jwt}/>
                </AppWrapper>
                :
                <div>
                    <div className="back-to-login" onClick={()=>{window.location.href="/"}}>
                        <Icon name="arrow alternate circle left outline"/>
                        <span>Back To Login</span>
                    </div>
                    <DocManager />
                </div>
            }
            </React.Fragment>
        )
    }
}

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(Docs));