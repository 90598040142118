import React from 'react';

import { Checkbox, Table, Popup, Icon, Grid, Input, Button, Divider, Dropdown } from 'semantic-ui-react';

import autoBind from 'react-autobind';

import GeneralUtility from '../../utilities/generalUtility.js';

export class HashManager extends React.Component{
    constructor(props){
        super();
        this.state={
            editing: null,
            currenthashes: [],
            organizations: [],
            hashgenoptions: {
                count: 1,
                max_uses: null,
                isDebug: false,
                organization_id: null
            }
        };
        autoBind(this);
    }

    componentDidMount(){
        this.setState({
            currenthashes: this.props.hashes && this.props.hashes.length ? this.props.hashes : [],
            organizations: this.props.organizations && this.props.organizations.length ? this.props.organizations : []
        }, ()=>{
            if(!this.state.currenthashes.length){
                this.addHash();
            }
        });
    }
    componentDidUpdate(){
        if(
            this.props.hashes.filter(this.compareArray(this.state.currenthashes)).length &&
            this.state.currenthashes.filter(this.compareArray(this.props.hashes)).length
        ){
            this.setState({currenthashes: this.props.hashes});
        }
    }

    addHash(){
        for(var i=0; i<this.state.hashgenoptions.count; i++){
            const currenthashes = this.state.currenthashes;
            currenthashes.push({
                id: GeneralUtility.generateUUIDV4(),
                event_id: this.props.eventid,
                hash: GeneralUtility.randomString(21),
                status: null,
                max_uses: this.state.hashgenoptions.max_uses,
                isDebug: this.state.hashgenoptions.isDebug,
                organization_id: this.state.hashgenoptions.organization_id
            });
            this.setState({
                currenthashes
            }, ()=>{
                this.props.updateHashes(this.state.currenthashes);
            });
        }
    }

    updateHashTitle(e, {value}){
        const currenthashes = this.state.currenthashes;
        currenthashes[this.state.editing].hash = value;
        this.setState({
            currenthashes
        }, ()=>{
            this.props.updateHashes(this.state.currenthashes);
        });
    }

    deleteHash(_index){
        this.setState((prevState)=>({
            currenthashes: prevState.currenthashes.filter((_,index)=>index!==_index)
        }), ()=>{
            this.props.updateHashes(this.state.currenthashes);
        });
    }

    toggleInputEditor(index){
        this.setState({editing: index});
    }

    compareArray(_array){
        return (current)=>{
            return _array.filter((other)=>{
                return other.value == current.value && other.display == current.display
            }).length == 0;
        }
    }

    generateHash(_index){
        const currenthashes = this.state.currenthashes;
        currenthashes[_index].hash = GeneralUtility.randomString(21);
        this.setState({
            currenthashes
        }, ()=>{
            this.props.updateHashes(this.state.currenthashes);
        });
    }

    toggleFlags(flagtype, _index){
        const currenthashes = this.state.currenthashes;
        if(flagtype==="max_uses"){
            console.log(currenthashes[_index].max_uses);
            currenthashes[_index].max_uses = currenthashes[_index].max_uses && !isNaN(currenthashes[_index].max_uses) ? null : 1;
            currenthashes[_index].isDebug = false;
        }
        else if(flagtype==="isDebug"){
            currenthashes[_index].isDebug = !currenthashes[_index].isDebug;
            currenthashes[_index].max_uses = null;
        }
        this.setState({
            currenthashes
        }, ()=>{
            this.props.updateHashes(this.state.currenthashes);
        });
    }

    updateHashLimit(e, data){
        const currenthashes = this.state.currenthashes;
        currenthashes[data.hashindex].max_uses = parseInt(data.value);
        this.setState({
            currenthashes
        }, ()=>{
            this.props.updateHashes(this.state.currenthashes);
        });
    }

    updateHashGenOptions(e, data){
        var currenthashgenoptions = this.state.hashgenoptions;
        if(data.name==="count"){
            currenthashgenoptions.count = parseInt(data.value);
        }
        else if(data.name==="max_uses"){
            currenthashgenoptions.max_uses = data.checked ? 1 : null;
        }
        else if(data.name==="limit"){
            currenthashgenoptions.max_uses = parseInt(data.value);
        }
        else if(data.name==="isDebug"){
            currenthashgenoptions.isDebug = data.checked;
        }
        else if(data.id ==="organizations"){
            currenthashgenoptions.organization_id = data.value;
        }
        this.setState({
            hashgenoptions: currenthashgenoptions
        });
    }

    updateHashOrg(e, data){
        const currenthashes = this.state.currenthashes;
        currenthashes[data.hashindex].organization_id = data.value;
        this.setState({
            currenthashes
        }, ()=>{
            this.props.updateHashes(this.state.currenthashes);
        });
    }

    async copyHashesToClipboard(){
        try{
            var hashes = this.state.currenthashes.length ? this.state.currenthashes.map(hashObj => {return hashObj.hash}).join(',') : ''
            await navigator.clipboard.writeText(hashes);
            this.setState({
                showcopiedtoast: true
            }, ()=>{
                setTimeout(()=>{
                    this.setState({showcopiedtoast: false});
                }, 1000);
            });
        }
        catch(err){console.error(err);}
    }

    render(){
        return(
            <React.Fragment>
                <Table celled>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>
                                <Popup
                                    position='right center'
                                    trigger={
                                        <Icon link name="plus circle" color="green" size="big"/>
                                    }
                                    on='click'
                                >
                                    <Grid divided columns='equal'>
                                        <Grid.Row>
                                            <Grid.Column>
                                                <Input min='1' label='Count' type='number' size='small' name="count" onChange={this.updateHashGenOptions} value={this.state.hashgenoptions.count} />
                                            </Grid.Column>
                                        </Grid.Row>
                                        <Grid.Row>
                                            <Grid.Column>
                                                <Checkbox name="max_uses" checked={!isNaN(this.state.hashgenoptions.max_uses) && this.state.hashgenoptions.max_uses>0} onChange={this.updateHashGenOptions} toggle label="Limit" />
                                                {this.state.hashgenoptions.max_uses && this.state.hashgenoptions.max_uses>0?
                                                    <React.Fragment>
                                                        <Divider hidden/>
                                                        <Input style={{width: '80px'}} min='1' type='number' size='small' name="limit" onChange={this.updateHashGenOptions} value={this.state.hashgenoptions.max_uses} />
                                                    </React.Fragment>
                                                :null}
                                            </Grid.Column>
                                            <Grid.Column>
                                                <Checkbox name="isDebug" checked={this.state.hashgenoptions.isDebug} onChange={this.updateHashGenOptions} toggle label="Debug" />
                                            </Grid.Column>
                                        </Grid.Row>
                                        <Grid.Row>
                                            <Grid.Column>
                                                <Button content='Add Hashes' color='green' onClick={this.addHash} />
                                            </Grid.Column>
                                        </Grid.Row>
                                        <Grid.Row>
                                            <Grid.Column>
                                                <Dropdown
                                                    id="organizations"
                                                    onChange={this.updateHashGenOptions}
                                                    placeholder='Organizations'
                                                    fluid
                                                    selection
                                                    value={this.state.organizations[0]?.organization_id ||null}
                                                    options={this.state.organizations
                                                        .map(org=>{
                                                            return {
                                                                key: org.organization_id,
                                                                value: org.organization_id,
                                                                text: org.name
                                                            }
                                                        })
                                                    }
                                                />
                                            </Grid.Column>
                                        </Grid.Row>
                                    </Grid>
                                </Popup>
                                <Popup content='Copy All Hashes' trigger={
                                    <Icon onClick={this.copyHashesToClipboard} link color="green" name="clipboard outline" size="big" />
                                }/>
                            </Table.HeaderCell>
                            <Table.HeaderCell>Hash</Table.HeaderCell>
                            <Table.HeaderCell>Options</Table.HeaderCell>
                            <Table.HeaderCell>Organization</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    {this.state.currenthashes.length?
                        <Table.Body>
                            {this.state.currenthashes.map((hashobj, hash_index)=>{
                                return (
                                    <Table.Row key={`event-hash-${hash_index}`}>
                                        <Table.Cell>
                                            {this.state.currenthashes.length>1?
                                                <Popup content='Delete Hash' trigger={
                                                    <Icon onClick={()=>this.deleteHash(hash_index)} link name="minus circle" color="red" size="big"/>
                                                }/>
                                            :null}
                                        </Table.Cell>
                                        <Table.Cell selectable>
                                            {!isNaN(this.state.editing) && this.state.editing===hash_index?
                                                <React.Fragment>
                                                    <Popup content='Generate Hash' trigger={
                                                        <Icon onClick={()=>this.generateHash(hash_index)} link name="sync alternate" color="blue" size="large"/>
                                                    }/>
                                                    <Input placeholder='Hash' value={hashobj.hash} onChange={this.updateHashTitle} />
                                                    <Popup content='Set Hash' trigger={
                                                        <Icon onClick={()=>this.toggleInputEditor(null)} link name="check square" color="green" size="big"/>
                                                    }/>
                                                </React.Fragment>
                                            :
                                                <React.Fragment>
                                                    <Popup content='Generate Hash' trigger={
                                                        <Icon onClick={()=>this.generateHash(hash_index)} link name="sync alternate" color="blue" size="large"/>
                                                    }/>
                                                    <span onDoubleClick={()=>this.toggleInputEditor(hash_index)}>{!hashobj.hash ? <i>No Hash Title</i> : hashobj.hash}</span>
                                                </React.Fragment>
                                            }
                                        </Table.Cell>
                                        <Table.Cell>
                                            <Grid columns='equal'>
                                                <Grid.Row>
                                                    <Grid.Column>
                                                        <Checkbox checked={!isNaN(hashobj.max_uses) && hashobj.max_uses>0} onClick={()=>this.toggleFlags("max_uses", hash_index)} toggle label="Limit" />
                                                        {!isNaN(hashobj.max_uses) && hashobj.max_uses>0?
                                                            <Input type="number" min="1" placeholder='Limit' value={hashobj.max_uses} hashindex={hash_index} onChange={this.updateHashLimit} />
                                                        :null}
                                                    </Grid.Column>
                                                    <Grid.Column><Checkbox checked={hashobj.isDebug} onClick={()=>this.toggleFlags("isDebug", hash_index)} toggle label="Debug" /></Grid.Column>
                                                </Grid.Row>
                                            </Grid>
                                        </Table.Cell>
                                        <Table.Cell>
                                                <Dropdown
                                                    id="organizations"
                                                    onChange={this.updateHashOrg}
                                                    placeholder='Organizations'
                                                    fluid
                                                    selection
                                                    hashindex={hash_index}
                                                    value={hashobj.organization_id ||null}
                                                    options={this.state.organizations
                                                        .map(org=>{
                                                            return {
                                                                key: org.organization_id,
                                                                value: org.organization_id,
                                                                text: org.name
                                                            }
                                                        })
                                                    }
                                                />
                                        </Table.Cell>
                                    </Table.Row>
                                )
                            })}
                        </Table.Body>
                    :null}
                </Table>
            </React.Fragment>
        )
    }
}

export default HashManager;