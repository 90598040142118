import React from 'react';

import mapStateToProps from '../../datastore/mapper.js';
import mapDispatchToProps from '../../datastore/dispatcher.js';

import {connect} from 'react-redux';

import {withRouter} from 'react-router-dom';

import { Segment, Message, Grid, Icon, Popup, Label, Divider } from 'semantic-ui-react';
import autoBind from 'react-autobind';

export class ReviewerNotes extends React.Component{
    constructor(props){
        super();
        this.state={
            htmlContent: null,
            showReviewerNotesContainer: true
        };
        autoBind(this);
    }

    updateRTEContent(event){
        const newcontenthtml = event.target.innerHTML;
        this.setState({
            htmlContent: newcontenthtml
        }, ()=>{
            this.props.setReviewerNotesHTML(newcontenthtml);
        });
    }

    toggleReviewerNotesContainer(){
        this.setState((prevState)=>({showReviewerNotesContainer: !prevState.showReviewerNotesContainer}),
            ()=>{
                if(this.state.showReviewerNotesContainer){
                    this.props.showReviewerNotes();
                }
            });
    }
    render(){

        return(

            <Segment raised>
                {this.state.showReviewerNotesContainer ?
                    <Label size="small" attached="top left"><Label.Detail>Reviewer Notes</Label.Detail></Label>
                :null}
                <Divider hidden/>
                <Popup content={this.state.showReviewerNotesContainer?'Minimize Toolset':'Maximize Toolset'} trigger={
                    <Label size='small' attached="top right" as='a' onClick={this.toggleReviewerNotesContainer}>
                        <Icon name={this.state.showReviewerNotesContainer?'window minimize':'window maximize'}/>
                    </Label>
                }/>
                {this.state.showReviewerNotesContainer ?
                    <React.Fragment>
                        <div
                            id={`reviewernotes-texteditor`}
                            className={''}
                            onInput={this.updateRTEContent}
                        ></div>
                    </React.Fragment>
                    :
                    <React.Fragment>
                        <Label size='small' attached="top left">Reviewer Notes</Label>
                    </React.Fragment>
                }
            </Segment>
        )
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(ReviewerNotes);