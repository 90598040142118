import React from 'react';

import mapStateToProps from '../../datastore/mapper.js';
import mapDispatchToProps from '../../datastore/dispatcher.js';

import {connect} from 'react-redux';

import { Button, Input, Select, Grid, Dropdown, Divider, List, Popup, Header } from 'semantic-ui-react'

import autoBind from 'react-autobind';

export class ListUser extends React.Component{
    constructor(props){
        super();

        this.state={
            allUsers:[],
            selectedRole:0,
            modifiedUsers:[]
        }

        autoBind(this);
    }

    componentDidMount(){
        const {appProps:{userManagement:{availableRoles, userList}}} = this.props;

        if(!availableRoles) this.props.getRoles();
    }

    prepRoleSet(){
        const {appProps:{userManagement:{availableRoles}}} = this.props;

        if(availableRoles && availableRoles.length){
            return availableRoles.map(role=>{return {key:role.id, text:role.role_name, value:role.id}});
        }
        else{
            return [];
        }
    }

    updateRoleSelection(event, {value}){
        const {appProps:{userManagement:{availableRoles}}} = this.props;

        const chosenIndex = availableRoles.findIndex(role=>role.id===value);
        this.setState({selectedRole:chosenIndex});
    }

    async getSearchedUsers(roleid, searchPattern){
        try{
            const allSearchedUsers = await this.props.getUsers(roleid, searchPattern);
            this.setState({
                allUsers: allSearchedUsers && allSearchedUsers.success ? allSearchedUsers.dataset : []
            });
        }catch(err){}
    }

    searchUsers(){
        const selectedRole = this.prepRoleSet()[this.state.selectedRole].key;
        const inputdom = document.getElementById("searchpattern");
        if(inputdom.value){
            this.getSearchedUsers(selectedRole, inputdom.value)
        }
    }

    getAllUsers(){
        this.getSearchedUsers();
    }

    modifyUser(event, {value}, userid){
        const currentModifiedUsers = this.state.modifiedUsers;
        const userIDIndex = currentModifiedUsers.findIndex(user=>user.id===userid);
        if(userIDIndex>-1){
            const splicedModifiedUser = currentModifiedUsers.splice(userIDIndex, 1);
            splicedModifiedUser.push({
                id:userid,
                role:value
            })
            this.setState({modifiedUsers:splicedModifiedUser})
        }
        else{
            this.setState((prevState)=>({
                modifiedUsers:[
                    ...prevState.modifiedUsers,
                    {
                        id:userid,
                        role:value
                    }
                ]
            }))
        }
    }

    async confirmRoleUpdate(userid){
        const selectedmodifieduser = this.state.modifiedUsers.filter(muser=>muser.id===userid)[0];
        try{
            await this.props.updateUserRole({id:selectedmodifieduser.id, roleid:selectedmodifieduser.role});
            this.removeUserFromModifiedList(selectedmodifieduser);
        }
        catch(err){}
    }

    removeUserFromModifiedList(modifieduser){
        const preppedRoles = this.prepRoleSet();
        const filteredPreppedRoles = preppedRoles.filter(role=>role.value===modifieduser.role || role.text===modifieduser.role);

        const currentAllUsers = this.state.allUsers;
        const userIndextoModify = currentAllUsers.findIndex(user=>user.userid===modifieduser.id || user.userid===modifieduser.userid);
        currentAllUsers[userIndextoModify].role = (filteredPreppedRoles.length && filteredPreppedRoles[0].text) || 'NA';

        this.setState({modifiedUsers:[], allUsers:currentAllUsers});
    }

    render(){

        const {appProps:{userManagement:{availableRoles}}} = this.props;

        return(
            <div className="listuser">
                <Grid>
                    <Grid.Row>
                        <Grid.Column width={13}>
                            {availableRoles && availableRoles.length>0 &&
                                <Input placeholder='Search by first name, last name, or id' action>
                                    <input size="50" id="searchpattern"/>
                                    <Dropdown
                                        onChange={this.updateRoleSelection}
                                        fluid
                                        floating
                                        search
                                        selection
                                        options={this.prepRoleSet()}
                                        defaultValue={((this.prepRoleSet()).length && (this.prepRoleSet())[0].value) || 'NA'}
                                    />
                                    <Button onClick={this.searchUsers}>Search</Button>
                                </Input>
                            }
                        </Grid.Column>
                        <Grid.Column width={3}>
                            <div className="list_user-getall">
                                <Button onClick={this.getAllUsers}>Get All</Button>
                            </div>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
                <Divider hidden />
                <List divided verticalAlign='middle'>
                    {availableRoles && availableRoles.length && this.state.allUsers.length && this.state.allUsers.map(_user=>{
                        const preppedRoles = this.prepRoleSet();
                        const roleid = this.state.modifiedUsers.filter(muser=>muser.id===_user.userid).length ? this.state.modifiedUsers.filter(muser=>muser.id===_user.userid)[0].role.role_id : preppedRoles.filter(role=>role.value===_user.role.role_id)[0].value;
                        return (
                            <List.Item key={`user-${_user.userid}`}>
                                <List.Content floated='right'>
                                    <Grid columns={2}>
                                        <Grid.Column width={this.state.modifiedUsers.filter(muser=>muser.id===_user.userid).length?10:16}>
                                            <Dropdown
                                                onChange={(e, value)=>this.modifyUser(e, value, _user.userid)}
                                                fluid
                                                floating
                                                search
                                                selection
                                                options={preppedRoles}
                                                value={roleid}
                                            />
                                        </Grid.Column>
                                        <Grid.Column width={6}>
                                            {this.state.modifiedUsers.length && this.state.modifiedUsers.filter(muser=>muser.id===_user.userid).length?
                                                <>
                                                    <Popup content='Update' trigger={<Button onClick={()=>this.confirmRoleUpdate(_user.userid)} icon='checkmark' size='mini' color='green' />} />
                                                    <Popup content='Cancel' trigger={<Button onClick={()=>this.removeUserFromModifiedList(_user)} icon='close' size='mini' color='red' />} />
                                                </>
                                            :
                                                <>{null/*<Popup content='Delete User' trigger={<Button icon='trash alternate' size='mini' color='red' />} />*/}</>
                                            }
                                        </Grid.Column>
                                    </Grid>
                                </List.Content>
                                <List.Content><Header as="h3">{_user.firstname}</Header></List.Content>
                            </List.Item>
                        )
                    }) || ''}
                </List>
            </div>
        )
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(ListUser);