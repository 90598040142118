import React from 'react';
import mapStateToProps from '../../../datastore/mapper.js';
import mapDispatchToProps from '../../../datastore/dispatcher.js';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import ItemPreview from '../item_preview/item_preview.js';
import { Card } from 'semantic-ui-react';

export class ItemVersionView extends React.Component{

    constructor(props){
        super(props)
        this.state = {
            versionId: props.versionId,
            item: props.item
        }
    }

    componentDidMount(){
        if(!this.props.item && !!this.props.versionId){
            this.getItemVersion();
        }
    }

    async getItemVersion(){
        try{
            const response = await this.props.getItemVersion(this.state.versionId);
            if(response && response.success){
                this.setState({item: response.dataset})
            } else {
                throw new Error("Response Failed, while getting item version")
            }
        }catch(e) {
            console.error("Could not get version", e)
        }
    }

    render(){
        const {item} = this.state;
        const raw = JSON.stringify(item, null, 1)
        return (
            <>
                {item &&
                    <>
                        <h1>{item.title}</h1>
                        <div className="historyColumnContainer">
                            <div className="historyColumn">
                                <details>
                                    <summary>Meta </summary>
                                    <ul>
                                        <li><strong>Version Created Date:</strong> {item.modified && new Date(item.modifieddate).toDateString()}</li>
                                        <li><strong>Item ID:</strong> {item.id}</li>
                                        <li><strong>Publish Status:</strong> {item.status}</li>
                                        <li><strong>Version:</strong> {item.version}</li>
                                        <li><strong>Version ID:</strong> {item.versionid}</li>
                                    </ul>
                                </details>
                            </div>
                            {item.modified &&
                                <div className="historyColumn">
                                    <details>
                                        <summary>Version Author: </summary>
                                        <ul>
                                            <li><strong>Name:</strong> {item.modified.by.firstname} {item.modified.by.lastname}</li>
                                            <li><strong>Role:</strong> {item.modified.by.role.role_name}</li>
                                        </ul>
                                    </details>
                                </div>
                            }
                        </div>
                        <div className="historyColumnContainer">
                            <div className="historyColumn">
                                <details>
                                    <summary>Proficiency:</summary>
                                    <ul>
                                        <li><strong>Label:</strong> {item.proficiency && item.proficiency.label}</li>
                                        <li><strong>ID:</strong> {item.proficiency && item.proficiency.id}</li>
                                        <li><strong>Difficulty Designation:</strong> {item.proficiency && item.proficiency.difficultydesignation}</li>
                                    </ul>
                                </details>
                            </div>
                            <div className="historyColumn">
                                <details>
                                    <summary>Timer</summary>
                                    <ul>
                                        <li><strong>Enabled:</strong> {(item.timer && item.timer.enabled)? "Yes" : "No" }</li>
                                        <li><strong>Time in minutes:</strong> {item.timer && item.timer.minutes}</li>
                                    </ul>
                                </details>
                            </div>
                        </div>
                        <div className="historyColumnContainer">
                            {item && item.features &&
                                <div className="historyColumn">
                                    <details>
                                        <summary>Features:</summary>
                                            <Card.Group items={item.features.map(feature => {
                                                return {
                                                    header: feature.type,
                                                    description: feature.content.html,
                                                    meta: feature.content.status
                                                }
                                            })}/>
                                    </details>
                                </div>
                            }
                            <div className="historyColumn">
                                <details>
                                    <summary>Tags</summary>
                                    <ul>
                                        {item && item.tags && item.tags.length && item.tags.map((tag, tag_index)=><li key={`tag-${tag.id}-${tag_index}`}>{tag.label}</li>)}
                                    </ul>
                                </details>
                            </div>
                        </div>
                        <div className="historyColumnContainer">
                            <div className="historyColumn">
                                <details>
                                    <summary>Preview</summary>
                                    <ItemPreview itemDetail={item}/>
                                </details>
                                <details>
                                    <summary>Raw Data:</summary>
                                    <pre>
                                        <code>
                                            {raw}
                                        </code>
                                    </pre>
                                </details>
                            </div>
                        </div>
                    </>
                }
            </>
        );       
    }
}

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(ItemVersionView));