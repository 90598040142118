import React from 'react';
import mapStateToProps from '../../../datastore/mapper.js';
import mapDispatchToProps from '../../../datastore/dispatcher.js';
import {connect} from 'react-redux';

import APPENV from '../../../../env.json';

export class ItemPreview extends React.Component{
    constructor(props){
        super();
        this.state = {
            showPassage: true
        }
    }

    generateContent(featurearray){
        if(featurearray){
            const processedfeatures = featurearray.map((feature, findex)=>{
                if(feature.type==="texteditor" && feature.content && feature.content.html){
                    return <div key={`feature-${feature.id}-${findex}`} dangerouslySetInnerHTML={{__html: feature.content.html}}></div>
                }
                else if(feature.type==="media"){
                    if(feature.content.type==="video"){
                        return (
                            <video key={`feature-${feature.id}-${findex}`} autoPlay muted loop className="thumb_content">
                                <source src={`${APPENV.APIEndpoint}/downloads/media/${feature.content.filename}`}/>
                                <p>Your browser doesn't support HTML5 video. Here is a <a href={`${APPENV.APIEndpoint}/downloads/media/${feature.content.filename}`}>link to the video</a> instead.</p> 
                            </video>
                        );
                    }
                    else if(feature.content.type==="audio"){
                        return (
                            <audio
                                key={`feature-${feature.id}-${findex}`}
                                controls
                                src={`${APPENV.APIEndpoint}/downloads/media/${feature.content.filename}`}
                                controlsList="nodownload"
                            >
                                Your browser does not support the
                                <code>audio</code> element.
                            </audio>
                        );
                    }
                    else if(feature.content.type==="image"){
                        return (
                            <img key={`feature-${feature.id}-${findex}`} className="thumb_content" src={`${APPENV.APIEndpoint}/downloads/media/${feature.content.filename}`} />
                        );
                    }
                }
            });
            return processedfeatures;
        }
        return ''
    }

    render(){
        const {itemDetail} = this.props;
        
        if(itemDetail){
            const filteredpassages = itemDetail.features ? itemDetail.features.filter(feature=>(feature.content.status==="passage" || feature.content.for==="passage") && !feature.allowUserInput) : [];
            const passagecontents = this.generateContent(filteredpassages);

            const filteredstem = itemDetail.features ? itemDetail.features.filter(feature=>(feature.content.status==="stem" || feature.content.for==="stem") && !feature.allowUserInput) : [];
            const stemcontents = this.generateContent(filteredstem);

            const answerFeature = itemDetail.features ? itemDetail.features.find(feature => feature.allowUserInput) : null;
            
            return(
                <div className="itemPreviewContainer">
                    <div className="desktopView">
                        <div className="headContainer">
                            <div className="headLeft">
                                <div className="pageName">Questions</div>
                                <div className="testName">{itemDetail?.title}</div>
                            </div>
                            <div className="headRight">
                                <img src={'/client/assets/images/connection.svg'}/>
                            </div>
                        </div>
                        <div className="main">
                            <div className="tabContainer">
                                <span className="tab">Passage</span>
                                <span className="tab">Question</span>
                            </div> 
                            <div className="columnContainer">
                                <div className="column passage">{passagecontents}</div>
                                <div className="column question">
                                    <div className="stem">{stemcontents}</div>
                                    <div className="answers">
                                        {(()=>{
                                            if(answerFeature && answerFeature.type){
                                                switch(answerFeature.type){
                                                    case "optionselector":
                                                        return answerFeature.content.options.map(answer => <button className="answerButton" key={`answerbutton-${answer.id}`}>{answer.text}</button>);
                                                    case "texteditor":
                                                        return(
                                                            <div className="textAnswer">
                                                                <div className="toolbar">
                                                                    <button>B</button>
                                                                    <button><em>I</em></button>
                                                                    <button><u>U</u></button>
                                                                </div>
                                                                <textarea></textarea>
                                                            </div>
                                                        );
                                                    case "audiorecorder":
                                                        return (
                                                            <div className="audioAnswer">
                                                                <button className="recorder">
                                                                    <img className="" src={'/client/assets/images/recorder.svg'}/>
                                                                </button>
                                                                <audio className="player" controls></audio>
                                                            </div>
                                                        );
                                                }
                                            }
                                        })()}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="footerContainer">
                            <button className="nextButton">Next</button>
                        </div>
                    </div>
                    <div className="viewSeperator">
                        <h3>Mobile View</h3>
                        <p>iPhone 5/SE Dimensions Aproximated</p>
                    </div>
                    <div className="mobileView">
                        <div className="headContainer">
                            <div className="headLeft">
                                <div className="pageName">Questions</div>
                                <div className="testName">{itemDetail?.title}</div>
                            </div>
                            <div className="headRight">
                                <img src={'/client/assets/images/connection.svg'}/>
                            </div>
                        </div>
                        <div className="main">
                            <div className="tabContainer">
                                <span className={`tab${this.state.showPassage ? " selected" : ""}`} onClick={()=>{this.setState({showPassage: true})}}>Passage</span>
                                <span className={`tab${this.state.showPassage ? "" : " selected"}`} onClick={()=>{this.setState({showPassage: false})}}>Question</span>
                            </div> 
                            <div className="columnContainer">
                                {this.state.showPassage ?
                                    <div className="column passage">{passagecontents}</div>
                                    :
                                    <div className="column question">
                                        <div className="stem">{stemcontents}</div>
                                        <div className="answers">
                                            {(()=>{
                                                if(answerFeature && answerFeature.type){
                                                    switch(answerFeature.type){
                                                        case "optionselector":
                                                            return answerFeature.content.options.map(answer=><button className="answerButton" key={`answerbutton-${answer.id}`}>{answer.text}</button>);
                                                        case "texteditor":
                                                            return(
                                                                <div className="textAnswer">
                                                                    <div className="toolbar">
                                                                        <button>B</button>
                                                                        <button><em>I</em></button>
                                                                        <button><u>U</u></button>
                                                                    </div>
                                                                    <textarea></textarea>
                                                                </div>
                                                            );
                                                        case "audiorecorder":
                                                            return (
                                                                <div className="audioAnswer">
                                                                    <button className="recorder">
                                                                        <img className="" src={'/client/assets/images/recorder.svg'}/>
                                                                    </button>
                                                                    <audio className="player" controls></audio>
                                                                </div>
                                                            );
                                                    }
                                                }
                                            })()}
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                        <div className="footerContainer">
                            <button className="nextButton">Next</button>
                        </div>
                    </div>
                </div>
            )
        }
        else{return null}
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(ItemPreview);