import React from 'react';

import mapStateToProps from '../../datastore/mapper.js';
import mapDispatchToProps from '../../datastore/dispatcher.js';

import {connect} from 'react-redux';

import {withRouter} from 'react-router-dom';

import ListReviewItems from './list_item_reviews.js';
import AppWrapper from '../app_wrapper/index.js'
import {Divider} from 'semantic-ui-react';

export class Home extends React.Component{
    constructor(props){
        super();
    }

    render(){
        return(
            <AppWrapper title={'Home'}>
                <ListReviewItems type="toreview"/>
                <Divider></Divider>
                <ListReviewItems type="pending"/>
                <Divider></Divider>
                <ListReviewItems type="reviewcomplete"/>
                <Divider></Divider>
            </AppWrapper>
        )
    }
}

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(Home));