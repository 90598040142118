import React from 'react';

import mapStateToProps from '../../datastore/mapper.js';
import mapDispatchToProps from '../../datastore/dispatcher.js';

import {connect} from 'react-redux';
import { Segment, List, Popup, Button, Header, Input, Icon, Grid, Dropdown, Container, Divider, Message } from 'semantic-ui-react';

import autoBind from 'react-autobind';

export class InviteUser extends React.Component{
    constructor(props){
        super();

        this.state = {
            selectedRole:0
        }

        autoBind(this);
    }

    componentDidMount(){
        this.props.getRoles();
        this.props.getPendingInvites();
    }

    prepRoles(){
        const {appProps:{userManagement:{availableRoles}}} = this.props;

        if(availableRoles.length) return availableRoles.map(role=>{return {key:role.id, text:role.role_name, value:role.id}})
        else return [];
    }

    updateRoleSelection(event, {value}){
        const {appProps:{userManagement:{availableRoles}}} = this.props;

        const chosenIndex = availableRoles.findIndex(role=>role.id===value);
        this.setState({selectedRole:chosenIndex});
    }

    async inviteUser(){
        const {appProps:{userManagement:{availableRoles}}} = this.props;

        const useremaildom = document.getElementById("useremail");
        if(!useremaildom.value){
            this.setState({invitation:{error:true, message:'Please Enter a Valid Email Address'}});
            return;
        }
        const userDetails = {
            email: useremaildom.value,
            roleid: (this.prepRoles())[this.state.selectedRole].value
        }
        try{
            const userInvited = await this.props.inviteUser(userDetails);
            const parseduserinvitation = JSON.parse(userInvited);
            useremaildom.value = '';
            this.setState({
                invitation:{
                    type: parseduserinvitation.invitationDetail.type,
                    success: true,
                    message: (parseduserinvitation.invitationDetail.type==='creation' ? 'User successfully invited' : 'Invitation Successfully Sent')
                }
            });
            this.props.getRoles();
            this.props.getPendingInvites();
        }
        catch(err){console.log(err);this.setState({invitation:{error:true, message:'Could Not Invite User'}});}
    }

    async cancelInvite(_id){
        try{
            const inviteCanceled = await this.props.cancelInvitation(_id);
            this.setState({invitation:{type:'cancel', success:true, message:'Invitation Successfully Canceled'}});
            this.props.getRoles();
            this.props.getPendingInvites();
        }
        catch(err){this.setState({invitation:{error:true, message:'Could Not Cancel Invite'}});}
    }


    render(){

        const {appProps:{userManagement:{availableRoles, pendingInvites}}} = this.props;

        return(
            <div className="inviteuser">
                <Container>
                    <Segment attached>
                        <Grid padded columns="equal">
                            <Grid.Column>
                                {availableRoles && availableRoles.length &&
                                    <Input action fluid iconPosition='left' placeholder='user@email'>
                                        <input id="useremail"/>
                                        <Dropdown
                                            onChange={this.updateRoleSelection}
                                            floating
                                            search
                                            selection
                                            options={this.prepRoles()}
                                            defaultValue={this.prepRoles()[0].value}
                                        />
                                    </Input>
                                }
                            </Grid.Column>
                            <Grid.Column>
                                <Button color="blue" onClick={this.inviteUser}>Send Invitation</Button>
                            </Grid.Column>
                        </Grid>
                    </Segment>
                    {this.state.invitation && this.state.invitation.error?
                        <Message attached='bottom' negative>
                            {this.state.invitation.message}
                        </Message>
                    :
                        (this.state.invitation && this.state.invitation.success &&
                            <Message attached='bottom' positive>
                                {this.state.invitation.type==='cancel'
                                ?
                                    'Invitation Successfully Cancelled'
                                :
                                    this.state.invitation.message
                                }
                            </Message>
                        )
                    }
                </Container>
                <Divider hidden />
                {pendingInvites && pendingInvites.length && availableRoles && availableRoles.length?
                    <Container>
                        <Header as='h3' attached='top'>
                            Pending Invitations
                        </Header>
                        <Segment attached>
                            <List divided verticalAlign='middle'>
                                {pendingInvites.map(invitation=>{
                                    return (
                                        <List.Item key={`pending-invitation-${invitation.id}`}>
                                            <List.Content floated='right'>
                                                <Popup content='Cancel pending invitation' trigger={<Button icon='close' size='mini' circular color='red' onClick={()=>this.cancelInvite(invitation.id)}/>} />
                                            </List.Content>
                                            <List.Content><Header as="h3">{invitation.email}</Header></List.Content>
                                            <List.Content><Header as="h4">{((this.prepRoles()).filter(item=>item.value===invitation.role_id)[0].text) || 'Role Unknown'}</Header></List.Content>
                                            <List.Content>Sent On {invitation.date && new Date(invitation.date.split(".")[1]+"Z").toUTCString()}</List.Content>
                                        </List.Item>
                                    )
                                })}
                            </List>
                        </Segment>
                    </Container>
                :null}
            </div>
        )
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(InviteUser);