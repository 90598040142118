import React from 'react';

import mapStateToProps from '../../datastore/mapper.js';
import mapDispatchToProps from '../../datastore/dispatcher.js';

import {connect} from 'react-redux';

import {withRouter} from 'react-router-dom';

import AppWrapper from '../app_wrapper/index.js'

import ListOrganizations from './list_organizations.js';
import EditOrganization from './edit_organization.js';

export class OrganizationsManagement extends React.Component{
    constructor(props){
        super();
    }

    orgManagementType(_type){
        switch(_type){
            case "create":
            case "edit":
                return <EditOrganization />;
            default:
                return <ListOrganizations />;
        }
    }

    parsedURLSubtitle(urlpath){
        const parsedURL = urlpath.split("/");
        if(parsedURL[2]==="edit" || parsedURL[2]==="create")
            return parsedURL[2];
        else
            return "list";
    }


    render(){

        const {location:{pathname}} = this.props;

        const parsedpath = this.parsedURLSubtitle(pathname);
        const OrgManagementComponent = this.orgManagementType(parsedpath);

        return(
            <AppWrapper title={'Organizations Management'} subtitle={parsedpath}>
                <div className="mediamanagement">
                    {OrgManagementComponent}
                </div>
            </AppWrapper>
        )
    }
}

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(OrganizationsManagement));