import React from 'react';

import mapStateToProps from '../../datastore/mapper.js';
import mapDispatchToProps from '../../datastore/dispatcher.js';

import {connect} from 'react-redux';

import {withRouter} from 'react-router-dom';

import { Container, Divider, Button, Grid, Input, Dropdown, Label, Icon, Popup, List, Message } from 'semantic-ui-react'

import autoBind from 'react-autobind';

export class EditTag extends React.Component{
    constructor(props){
        super();

        this.state={
            loadingUsers:false,
            searchedUser:null,
            selectedUser:null,
            usersearchpattern:null,
            currentTagSettings:{
                label:null,
                language:null,
                proficiency:null,
                skill:null,
                role:null,
                user:null
            },
            error:false,
            isEditing:false
        }

        autoBind(this);
    }

    componentDidMount(){
        this.getLanguageProficiencySkills();
        this.parseQueryParam();
    }

    parseQueryParam(){
        const {location:{search}} = this.props;
        const parsedQueryParam = search.split("?");
        if(parsedQueryParam && parsedQueryParam.length===2){
            this.prepEdit(parsedQueryParam[1]);
        }
    }

    async prepEdit(idString){
        const {appProps:{tags}} = this.props;

        const parsedId = idString.split("id=");
        if(!parsedId.length || parsedId.length !== 2){
            return;
        }

        const editingtag = tags && tags.filter(tag=>tag.id===parsedId[1]);

        if(!tags || !editingtag){
            const tagfetched = await this.props.getTags(null,parsedId[1]);
            if(tagfetched && tagfetched.success && tagfetched.dataset.length){
                const currentlyediting = tagfetched.dataset.map(tag=>{
                    return {
                        label:tag.label,
                        language:(tag.language && tag.language.id) || null,
                        proficiency:(tag.proficiency && tag.proficiency.id) || null,
                        skill:(tag.skill && tag.skill.id) || null,
                        role:(tag.role && tag.role.id) || null,
                        user:(tag.user && tag.user.id) || null
                    }
                })[0];
                const selectedUser = tagfetched.dataset[0].user;
                this.setState({currentTagSettings:currentlyediting, selectedUser, isEditing:true});
            }
        }
        else if(editingtag && editingtag.length){
            const currentlyediting = editingtag.map(tag=>{
                return {
                    label:tag.label,
                    language:(tag.language && tag.language.id) || null,
                    proficiency:(tag.proficiency && tag.proficiency.id) || null,
                    skill:(tag.skill && tag.skill.id) || null,
                    role:(tag.role && tag.role.id) || null,
                    user:(tag.user && tag.user.id) || null
                }
            })[0];
            const selectedUser = editingtag[0].user;
            this.setState({currentTagSettings:currentlyediting, selectedUser, isEditing:true});
        }
    }

    getLanguageProficiencySkills(){

        const {appProps:{languages, proficiencies, skills, userManagement:{availableRoles}}} = this.props;

        if(!languages){
            this.props.getLanguages();
        }
        if(!proficiencies){
            this.props.getProficiency();
        }
        if(!skills){
            this.props.getSkills();
        }
        if(!availableRoles){
            this.props.getRoles();
        }
    }

    prepLanguagesDropDown(){
        const {appProps:{languages}} = this.props;

        if(languages && languages.length){
            return languages.map(lang=>{return {key:lang.shortname, text:(lang.name[0].toUpperCase() + lang.name.slice(1)), value:lang.id}});
        }
    }

    prepProficiencyDropDown(){
        const {appProps:{proficiencies}} = this.props;

        if(proficiencies && proficiencies.length){
            return proficiencies.map(prof=>{return {key:prof.difficultydesignation, text:(prof.label[0].toUpperCase() + prof.label.slice(1)), value:prof.id}});
        }
    }

    prepSkillsDropDown(){
        const {appProps:{skills}} = this.props;

        if(skills && skills.length){
            return skills.map(skill=>{return {key:skill.id, text:(skill.name[0].toUpperCase() + skill.name.slice(1)), value:skill.id}});
        }
    }

    prepAvailableRolesDropDown(){
        const {appProps:{userManagement:{availableRoles}}} = this.props;

        if(availableRoles && availableRoles.length){
            return availableRoles.map(role=>{return {key:role.id, text:(role.role_name[0].toUpperCase() + role.role_name.slice(1)), value:role.id}});
        }
    }

    async searchUser(event, data){
        this.setState({loadingUsers:true});
        const searchinputelem = Array.from(event.target.parentNode.children).filter(node=>node.tagName === "INPUT")[0];
        if(searchinputelem.value){
            try{
                const allUsers = await this.props.getUsers(null, searchinputelem.value);
                if(allUsers && allUsers.success){
                    this.setState({searchedUser:allUsers.dataset});
                }
            }
            catch(err){}
        }
        this.setState({loadingUsers:false});
    }

    updateSelectedUser(userdata){
        this.setState((prevState)=>({
            selectedUser:userdata,
            searchedUser:null,
            usersearchpattern:null,
            currentTagSettings:{
                ...prevState.currentTagSettings,
                user:userdata?userdata.userid:null
            }
        }));
    }

    updateUserSearchPattern(event){
        this.setState({usersearchpattern:event.target.value});
    }

    updateLanguageSelection(event, {value}){
        this.setState((prevState)=>({
            currentTagSettings:{
                ...prevState.currentTagSettings,
                language:!value.length || value===""?null:value
            }
        }));
    }

    updateProficiencySelection(event, {value}){
        this.setState((prevState)=>({
            currentTagSettings:{
                ...prevState.currentTagSettings,
                proficiency:!value.length || value===""?null:value
            }
        }));
    }

    updateSkillSelection(event, {value}){
        this.setState((prevState)=>({
            currentTagSettings:{
                ...prevState.currentTagSettings,
                skill:!value.length || value===""?null:value
            }
        }));
    }

    updateRoleSelection(event, {value}){
        this.setState((prevState)=>({
            currentTagSettings:{
                ...prevState.currentTagSettings,
                role:!value.length || value===""?null:value
            }
        }));
    }

    updateTagLabel(event, {value}){
        this.setState((prevState)=>({
            currentTagSettings:{
                ...prevState.currentTagSettings,
                label:!value.length || value===""?null:value
            }
        }));
    }

    async updateCurrentTag(){
        if(!this.state.currentTagSettings.label){
            this.setState({error:{type:'label'}})
        }
        else{
            if(!this.state.isEditing){
                try{
                    const tagcreated = await this.props.createTag(this.state.currentTagSettings);
                    this.props.history.push("/tags");
                }
                catch(err){this.setState({error:{type:'api'}})}
            }
            else{
                const parsedTagId = this.props.location.search.split("?")[1].split("id=")[1];
                const updateTagObject = {
                    id:parsedTagId,
                    label:this.state.currentTagSettings.label,
                    language:this.state.currentTagSettings.language,
                    proficiency:this.state.currentTagSettings.proficiency,
                    skill:this.state.currentTagSettings.skill,
                    role:this.state.currentTagSettings.role,
                    user:this.state.currentTagSettings.user
                };
                try{
                    const tagUpdated = await this.props.updateTag(updateTagObject);
                    this.props.history.push("/tags?id="+parsedTagId);
                }
                catch(err){console.log(err);this.setState({error:{type:'apiupdate'}})}

            }
        }
    }

    render(){

        const {appProps:{languages, proficiencies, skills, userManagement:{availableRoles}, error}} = this.props;

        const languagesOptions = languages && this.prepLanguagesDropDown();
        const proficiencyOptions = proficiencies && this.prepProficiencyDropDown();
        const skillOptions = skills && this.prepSkillsDropDown();
        const roleOptions = availableRoles && this.prepAvailableRolesDropDown();

        return(
            <div className="createtag">
                <Container>
                    {(this.state.error && (this.state.error.type==='api' || this.state.error.type==='apiupdate')) || (error && error.type===102 && error.message==="Could Not Create Tag")?
                        <Message negative>
                            Could Not {this.state.error.type==='api'?'Create':'Update'} Tag At This Moment
                        </Message>
                    :null}
                    <Grid>
                        <Grid.Row>
                            <Grid.Column>
                                <Input fluid placeholder='Tag Label' onChange={this.updateTagLabel} defaultValue={this.state.currentTagSettings.label?this.state.currentTagSettings.label:''}/>
                                {this.state.error && this.state.error.type==='label'?
                                    <Label pointing='left' basic color='red'>
                                        Must Have A Tag Label
                                    </Label>
                                :null}
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row columns='equal'>
                            <Grid.Column>
                                <Dropdown value={this.state.currentTagSettings.language} onChange={this.updateLanguageSelection} placeholder='Select Language' clearable options={languagesOptions} selection />
                            </Grid.Column>
                            <Grid.Column>
                                <Dropdown value={this.state.currentTagSettings.proficiency} onChange={this.updateProficiencySelection} placeholder='Select Proficiency' clearable options={proficiencyOptions} selection />
                            </Grid.Column>
                            <Grid.Column>
                                <Dropdown value={this.state.currentTagSettings.skill} onChange={this.updateSkillSelection} placeholder='Select Skill' clearable options={skillOptions} selection />
                            </Grid.Column>
                            <Grid.Column>
                                <Dropdown value={this.state.currentTagSettings.role} onChange={this.updateRoleSelection} placeholder='Select Role' clearable options={roleOptions} selection />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width={8}>
                                {this.state.selectedUser?
                                    <Input fluid iconPosition='left'>
                                        <Popup content='Remove User' trigger={<Icon name='close' color='red' inverted circular link onClick={()=>this.updateSelectedUser(null)}/>}/>
                                        <input value={this.state.selectedUser.lastname+", "+this.state.selectedUser.firstname}/>
                                    </Input>
                                :
                                    <Input fluid icon>
                                        <Popup content='Search User To Assign Tag To' trigger={
                                            <React.Fragment>
                                                {!this.state.loadingUsers?
                                                    <Icon name='search' inverted circular link onClick={this.searchUser}/>
                                                :
                                                    <Icon loading name='asterisk' link inverted circular/>
                                                }
                                            </React.Fragment>
                                        } />
                                        <input placeholder='Search User' value={this.state.usersearchpattern || ''} onChange={this.updateUserSearchPattern}/>
                                    </Input>
                                }
                                {this.state.searchedUser && this.state.searchedUser.length && !this.state.selectedUser &&
                                    <List celled>
                                        {this.state.searchedUser.map(user=>{
                                            return (
                                                <List.Item as='a' key={`user-${user.userid}`} onClick={()=>this.updateSelectedUser(user)}>
                                                    <List.Content>
                                                        <List.Header>{user.lastname+", "+user.firstname}</List.Header>
                                                        {user.role.role_name}
                                                    </List.Content>
                                                </List.Item>
                                            )
                                        })}
                                    </List>
                                }
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Container>
                <Divider hidden />
                <Message>
                    <p>
                        <Button color='yellow' onClick={()=>this.props.history.push("/tags")}>Cancel</Button>
                        <Button floated='right' color='green' onClick={this.updateCurrentTag}>{this.state.isEditing?'Update':'Create'}</Button>
                    </p>
                </Message>
            </div>
        )
    }
}

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(EditTag));