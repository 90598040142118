import React from 'react';

import mapStateToProps from '../../datastore/mapper.js';
import mapDispatchToProps from '../../datastore/dispatcher.js';

import {connect} from 'react-redux';

import {withRouter} from 'react-router-dom';

import { Grid, Input, Button, Divider, Table, Dimmer, Loader, Segment, Dropdown, Popup, Header, Icon, Modal, Confirm } from 'semantic-ui-react';

import autoBind from 'react-autobind';

import METADATA from '../../utilities/metadata.json';

export class ListCollections extends React.Component{
    constructor(props){
        super();
        this.state={
            currentCollectionSettings:{
                pattern: null,
                proficiency: null,
                language: null,
                skill: null,
                id: null
            },
            deleteCollection:{
                showConfirmation: false,
                collectionInfo:null
            },
            sortedby: null
        };
        autoBind(this);
    }

    componentDidMount(){
        this.checkCollectionMetaDataAvailability();
    }

    async checkCollectionMetaDataAvailability(){
        const {appProps:{languages, proficiencies, skills, status}} = this.props;

        try{
            if(!languages || !languages.length) await this.props.getLanguages();
            if(!proficiencies || !proficiencies.length) await this.props.getProficiency();
            if(!skills || !skills.length) await this.props.getSkills();
            if(!status.collections || !!status.collections.length) await this.props.getCollectionStatuses();
        }
        catch(err){
            console.log(err);
        }
    }

    updateSearchPattern(e, {value}){
        this.setState({currentCollectionSettings:{pattern: value && value.length ? value : ''}});
    }

    updateCollectionId(e, {value}){
        this.setState({currentCollectionSettings:{id: value && value.length ? value : ''}});
    }

    updateLanguageSelection(event, {value}){
        this.setState((prevState)=>({
            currentCollectionSettings:{
                ...prevState.currentCollectionSettings,
                language:!value.length || value===""?null:value
            }
        }));
    }

    updateProficiencySelection(event, {value}){
        this.setState((prevState)=>({
            currentCollectionSettings:{
                ...prevState.currentCollectionSettings,
                proficiency:!value.length || value===""?null:value
            }
        }));
    }

    updateSkillSelection(event, {value}){
        this.setState((prevState)=>({
            currentCollectionSettings:{
                ...prevState.currentCollectionSettings,
                skill:!value.length || value===""?null:value
            }
        }));
    }

    formatedDate(dateStr) {
        let theDate = new Date(dateStr);
        return (theDate.getMonth() + 1) + '/' + theDate.getDate() + '/' + theDate.getFullYear();
    }

    editCollection(collection){
        this.props.history.push(`/testlets/edit?id=${collection.id}`);
    }
    cloneCollection(collection){
        this.props.history.push(`/testlets/clone?id=${collection.id}`);
    }

    async getCollections(all){
        try{
            if(typeof all==='boolean' && all){
                const allCollections = await this.props.getCollections();
            }
            else{
                const searchedCollection = await this.props.getCollections(this.state.currentCollectionSettings);
            }
        }catch(err){console.log(err);}
    }

    toggleDeleteConfirmation(collectionID){
        const {appProps:{collections}} = this.props;

        if(typeof collectionID!=='boolean' && collectionID && collections && collections.length){
            this.setState({
                deleteCollection:{
                    showConfirmation: true,
                    collectionInfo: collections.filter(collection=>collection.id===collectionID).length && collections.filter(collection=>collection.id===collectionID)[0]
                }
            });
        }
        else{
            this.setState({
                deleteCollection:{
                    showConfirmation: false,
                    collectionInfo: null
                }
            });
        }
    }

    sortItemBy(propname){
        var newsortedby;
        if(propname && (!this.state.sortedby || (this.state.sortedby && this.state.sortedby.property!==propname))){
            newsortedby = {
                ascending: true,
                property: propname
            }
        }
        else if(propname && this.state.sortedby.property===propname){
            newsortedby = {
                ascending: !this.state.sortedby.ascending,
                property: propname
            }
        }
        this.setState({
            sortedby: newsortedby
        });
    }
    

    render(){
        const {appProps:{collections, languages, proficiencies, skills}} = this.props;

        return(
            <React.Fragment>
                <div className="listcollections">

                    <Grid columns='equal'>
                        <Grid.Row>
                            <Grid.Column>
                                <Input fluid placeholder='Search Testlet By Title' onChange={this.updateSearchPattern} />
                            </Grid.Column>
                            <Grid.Column>
                                <Input fluid placeholder='Search Testlet By Id' onChange={this.updateCollectionId} />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column>
                                <Dropdown
                                    key={this.props.appProps.proficiencies}
                                    value={this.state.currentCollectionSettings.proficiency}
                                    onChange={this.updateProficiencySelection}
                                    fluid
                                    floating
                                    search
                                    selection
                                    clearable
                                    placeholder='Proficiency'
                                    options={proficiencies && proficiencies.map(prof=>{
                                        return{
                                            key:prof.difficultydesignation,
                                            text:(prof.label[0].toUpperCase() + prof.label.slice(1)),
                                            value:prof.id
                                        }
                                    })}
                                />
                            </Grid.Column>
                            <Grid.Column>
                                <Dropdown
                                    value={this.state.currentCollectionSettings.language}
                                    onChange={this.updateLanguageSelection}
                                    fluid
                                    floating
                                    search
                                    selection
                                    clearable
                                    placeholder='Language'
                                    options={languages && languages.map(lang=>{
                                        return {
                                            key:lang.shortname,
                                            text:(lang.name[0].toUpperCase() + lang.name.slice(1)),
                                            value:lang.id
                                        }
                                    })}
                                />
                            </Grid.Column>
                            <Grid.Column>
                                <Dropdown
                                    value={this.state.currentCollectionSettings.skill}
                                    onChange={this.updateSkillSelection}
                                    fluid
                                    floating
                                    search
                                    selection
                                    clearable
                                    placeholder='Skill'
                                    options={skills && skills.map(skill=>{
                                        return{
                                            key:skill.id,
                                            text:(skill.name[0].toUpperCase() + skill.name.slice(1)),
                                            value:skill.id
                                        }
                                    })}
                                />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column>
                                <Button onClick={()=>this.getCollections()}>Search</Button>
                            </Grid.Column>
                            <Grid.Column>
                                <Button floated="right" onClick={()=>{this.props.history.push(`/testlets/create`)}} color='green'>Create</Button>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>

                    <Divider hidden />
                    {collections && collections.length &&
                        <Segment raised>
                            <Table compact celled definition>
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell />
                                        {Object.keys(METADATA.MAPPEDITEMTABLEHEADERS).map((header, _index)=>{
                                            return (
                                                <Table.HeaderCell key={`itemlist-headers-${_index}`} onClick={()=>this.sortItemBy(header)}>
                                                    <div className="item_table_headers">
                                                        {METADATA.MAPPEDITEMTABLEHEADERS[header]}
                                                        {_index===Object.keys(METADATA.MAPPEDITEMTABLEHEADERS).length - 1 ?
                                                        <React.Fragment>
                                                            <br/>(MM/DD/YYYY)
                                                        </React.Fragment>
                                                        :null}
                                                        <Popup content={`Sort Items By ${METADATA.MAPPEDITEMTABLEHEADERS[header]}`} trigger={
                                                            <Icon
                                                                name={
                                                                    !this.state.sortedby || (this.state.sortedby && this.state.sortedby.property!==header)?
                                                                    "sort"
                                                                    :
                                                                    (
                                                                        this.state.sortedby.property===header && this.state.sortedby.ascending ?
                                                                        "sort down"
                                                                        :
                                                                        "sort up"
                                                                    )
                                                                }
                                                                link
                                                            />
                                                        }/>
                                                    </div>
                                                </Table.HeaderCell>
                                            )
                                        })}
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                {collections
                                .sort((col_a, col_b)=>{
                                    if(!this.state.sortedby){
                                        return 0;
                                    }
                                    else{
                                        if(this.state.sortedby.ascending && col_a[this.state.sortedby.property]){
                                            return col_a[this.state.sortedby.property].localeCompare(col_b[this.state.sortedby.property]);
                                        }
                                        else if(col_a[this.state.sortedby.property]){
                                            return -1 * col_a[this.state.sortedby.property].localeCompare(col_b[this.state.sortedby.property]);
                                        }
                                    }
                                })
                                .map(collection=>{
                                        return (
                                            <Table.Row key={`collection-${collection.id}`}>
                                                <Table.Cell collapsing>
                                                    <Popup content='Archive Testlet' trigger={
                                                        <Button circular icon='trash' color='red' size='tiny' onClick={()=>this.toggleDeleteConfirmation(collection.id)}></Button>
                                                    }/>
                                                    <Popup content='Edit Testlet' trigger={
                                                        <Button circular icon='pencil' color='yellow' size='tiny' onClick={()=>this.editCollection(collection)}></Button>
                                                    }/>
                                                    <Popup content='Clone Testlet' trigger={
                                                        <Button circular icon='clone outline' color='blue' size='tiny' onClick={()=>this.cloneCollection(collection)}></Button>
                                                    }/>
                                                </Table.Cell>
                                                <Table.Cell>
                                                    <span>{collection.title}</span>
                                                    {collection.cloned_from &&
                                                        <Popup size="mini" content={`This Testlet Was Cloned From Another Testlet: ${collection.cloned_from}`} trigger={
                                                            <Icon size='small' color="olive" name="clone" />
                                                        }/>
                                                    }
                                                </Table.Cell>
                                                <Table.Cell>
                                                    <span className="metadata_label">{
                                                        proficiencies.filter(prof=>prof.id===collection.proficiency)[0].label[0].toUpperCase() +
                                                        proficiencies.filter(prof=>prof.id===collection.proficiency)[0].label.slice(1)
                                                    }</span>
                                                </Table.Cell>
                                                <Table.Cell>
                                                    <span className="metadata_label">{
                                                        languages.filter(lang=>lang.id===collection.language)[0].name[0].toUpperCase() +
                                                        languages.filter(lang=>lang.id===collection.language)[0].name.slice(1)
                                                    }</span>
                                                </Table.Cell>
                                                <Table.Cell>
                                                    <span className="metadata_label">{
                                                        skills &&
                                                        skills.filter(skill=>skill.id===collection.skill)[0].name[0].toUpperCase() +
                                                        skills.filter(skill=>skill.id===collection.skill)[0].name.slice(1)
                                                    }</span>
                                                </Table.Cell>
                                                <Table.Cell><span>{collection.modifiedby}</span></Table.Cell>
                                                <Table.Cell><span>{this.formatedDate(collection.modifieddate)}</span></Table.Cell>
                                            </Table.Row>
                                        )
                                    })}
                                </Table.Body>
                            </Table>
                        </Segment>
                        || null
                    }
                </div>

                <Confirm
                    open={this.state.deleteCollection.showConfirmation}
                    header="Delete Testlet"
                    content={`Are You Sure You Want To Delete Collection: ${this.state.deleteCollection.collectionInfo ? this.state.deleteCollection.collectionInfo.id + ", "+this.state.deleteCollection.collectionInfo.title : 'Testlet Info Not Found'}?`}
                    cancelButton='No'
                    confirmButton="Yes"
                    onCancel={()=>this.toggleDeleteConfirmation(false)}
                    onConfirm={()=>{this.props.archiveCollection(this.state.deleteCollection.collectionInfo);this.toggleDeleteConfirmation(false)}}
                />

            </React.Fragment>
        )
    }
}

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(ListCollections));