import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom'; 

import Register from './components/register';
import PasswordReset from './components/password_reset';
import Home from './components/home';
import AccountSettings from './components/account_settings';
import UserManagement from './components/user_management';
import TagsManagement from './components/tags_management';
import ItemsManagement from './components/items_management';
import MediaManagement from './components/media_management';
import ItemHistory from './components/items_management/item_history/item_history';
import CollectionsManagement from './components/collections_management';
import EventManagement from './components/event_management';
import Reports from './components/reports';
import OrganizationsManagement from './components/organizations_management';

import __404__ from './components/404';

import Docs from './components/docs';

export default class Routes extends Component {
    render() {
        return (
            <React.Fragment>
                <Switch>
                    <Route path="/account_settings" component={AccountSettings}/>
                    <Route path="/docs" component={Docs}/>
                    <Route path="/register/:uuid" component={Register}/>
                    <Route path="/password_reset/:email/:key" component={PasswordReset}/>
                    <Route exact path="/" component={Home}/>
                    <Route path="/user" component={UserManagement}/>
                    <Route path="/tags" component={TagsManagement}/>
                    <Route path="/items/history/:uuid" component={ItemHistory}/>
                    <Route path="/items" component={ItemsManagement}/>
                    <Route path="/media" component={MediaManagement}/>
                    <Route path="/testlets" component={CollectionsManagement}/>
                    <Route path="/events" component={EventManagement}/>
                    <Route path="/reports" component={Reports}/>
                    <Route path="/organizations" component={OrganizationsManagement}/>
                    
                    <Route path='*' component={__404__}/>
                </Switch>
            </React.Fragment>
        );
    }
}