import React from 'react';

import mapStateToProps from '../../datastore/mapper.js';
import mapDispatchToProps from '../../datastore/dispatcher.js';

import {connect} from 'react-redux';

import { Icon, Modal, Button, Grid, Container, List, Label, Popup, Header, Segment, Message, Input, Checkbox, Form, Loader, Divider, Table, Confirm } from 'semantic-ui-react';

import autoBind from 'react-autobind';

import METADATA from '../../utilities/metadata.json';

export class RoleManager extends React.Component{
    constructor(props){
        super();

        this.state={
            currentlyEditing:null,
            selectedRole:null,
            showDeletionModal:false,
            readyToDelete:null
        }

        autoBind(this);
    }

    componentDidMount(){
        this.props.getRoles();
        this.props.getAvailablePermissions();
    }

    editRole(roleid){

        const {appProps:{userManagement:{availableRoles}}} = this.props;

        this.setState({currentlyEditing:"loading"});
        const currentSelectedRole = availableRoles.find(role=>role.id==roleid);
        setTimeout(()=>{
            this.setState({
                currentlyEditing:roleid?{
                    id:currentSelectedRole.id,
                    role_name:currentSelectedRole.role_name,
                    permissions:[...currentSelectedRole.permissions]
                }:
                {
                    id:null,
                    role_name:"New Role",
                    permissions:[]
                },
                selectedRole:roleid?currentSelectedRole:null
            });
        }, 250);
    }
    cancelEdit(){
        this.setState((prevState)=>({
            currentlyEditing:null,
            selectedRole:null
        }));
    }
    toggleDeletionModal(toggleState, deleteId){
        this.setState({
            showDeletionModal: toggleState,
            readyToDelete: deleteId
        });
    }
    async deleteRole(){
        try{
            const deleterole = await this.props.deleteRole(this.state.readyToDelete);
            this.setState({
                showDeletionModal: false,
                readyToDelete: null
            });
            this.props.getRoles();
        }catch(err){}
    }
    async createRole(){
        try{
            const createrole = await this.props.createRole(this.state.currentlyEditing);
            this.setState({currentlyEditing:null,selectedRole:null});
            this.props.getRoles();
        }catch(err){}
    }
    async updateRole(){
        try{
            const updaterole = await this.props.updateRole(this.state.currentlyEditing);
            this.setState({currentlyEditing:null,selectedRole:null});
            this.props.getRoles();
        }catch(err){}
    }

    addRemovePermissions(permissionid){
        const currentRolePermissions = this.state.currentlyEditing.permissions;
        const permissionidIndex = (currentRolePermissions.length && currentRolePermissions.findIndex(per=>per===permissionid)) || -1;
        if(permissionidIndex===-1){
            currentRolePermissions.push(permissionid);
        }
        else{
            currentRolePermissions.splice(permissionidIndex, 1);
        }
        this.setState((prevState)=>({
            currentlyEditing:{
                ...prevState.currentlyEditing,
                permissions:currentRolePermissions
            }
        }));
    }

    updateRoleName(event){
        const newName = event.target.value;
        this.setState((prevState)=>({currentlyEditing:{...prevState.currentlyEditing, role_name: newName}}));
    }

    render(){

        const {appProps:{userManagement:{availableRoles, availablePermissions}}} = this.props;
        
        return(
            <React.Fragment>
                <div className="rolemanager">
                    <Container>
                        <Grid>
                            <Grid.Column floated='left' width={5}>
                                <Popup content='Add New Role' trigger={
                                    <Button content="Add New Role" icon='add' color='green' labelPosition="left" onClick={()=>this.editRole()}/>
                                } />
                            </Grid.Column>
                            <Grid.Column floated='right' width={5}>
                                {METADATA.PERMISSIONS_COLORSET.map(_color=>{
                                    return (
                                        <Label key={`permission-types-identifiers-${_color.type}`} size="mini" color={_color.color}><span className="colorset-label">{_color.type}</span></Label>
                                    );
                                })}
                            </Grid.Column>
                        </Grid>
                        {availablePermissions && availablePermissions.length && availableRoles && availableRoles.length &&
                            <Table compact celled definition>
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell />
                                        <Table.HeaderCell>Name</Table.HeaderCell>
                                        <Table.HeaderCell>Permissions</Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                {availableRoles.map(role=>{
                                        return (
                                            <Table.Row key={`roles-${role.id}`}>
                                                <Table.Cell collapsing>
                                                    <Popup content={`Edit ${role.role_name}`} trigger={
                                                        <Button icon='pencil alternate' color='yellow' size='mini' onClick={()=>this.editRole(role.id)}/>
                                                    } />
                                                    <Popup content={`Delete ${role.role_name}`} trigger={
                                                        <Button icon='trash alternate' color='red' size='mini' onClick={()=>this.toggleDeletionModal(true, role.id)}/>
                                                    } />
                                                </Table.Cell>
                                                <Table.Cell><span>{role.role_name}</span></Table.Cell>
                                                <Table.Cell>
                                                    {role.codes
                                                        .filter(permissioncode=>!permissioncode.includes("organization"))
                                                        .map(permissioncode=>{
                                                            const colorSet = METADATA.PERMISSIONS_COLORSET.filter(_color=>{
                                                                return availablePermissions
                                                                .find(rolepermission=>rolepermission.code === permissioncode)
                                                                .label.toLowerCase()
                                                                .match(new RegExp(_color.type));
                                                            });
                                                            return (
                                                                <span key={`role-${role.id}-permission-${permissioncode}`} className="permission-container">
                                                                    <Label color={colorSet && colorSet.length && colorSet[0].color || 'grey'} tag>
                                                                        {availablePermissions.find(rolepermission=>rolepermission.code === permissioncode).label}
                                                                    </Label>
                                                                </span>
                                                            )
                                                    })}
                                                </Table.Cell>
                                            </Table.Row>
                                        )
                                    })}
                                </Table.Body>
                            </Table>
                        }
                    </Container>
                </div>
                <Divider hidden />
                <div className="role_editor">
                    {availablePermissions && availablePermissions.length && this.state.currentlyEditing && this.state.currentlyEditing!=="loading"?
                        <Segment raised>
                            <Form>
                                <Grid>
                                    <Grid.Row>
                                        <Container>
                                            <Input
                                                label='Role Name'
                                                defaultValue={this.state.currentlyEditing.role_name}
                                                onChange={this.updateRoleName}
                                            />
                                        </Container>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Container>
                                            <List horizontal>
                                                {availablePermissions.map(permissionobj=>{
                                                    return(
                                                        <List.Item key={`role-access-${permissionobj.code}`}>
                                                            <Checkbox
                                                                id={permissionobj.code}
                                                                label={permissionobj.label}
                                                                defaultChecked={
                                                                    this.state.currentlyEditing.permissions.filter(indivpermission=>indivpermission === permissionobj.id).length > 0
                                                                }
                                                                onChange={()=>this.addRemovePermissions(permissionobj.id)}
                                                            />
                                                        </List.Item>
                                                    )
                                                })}
                                            </List>
                                        </Container>
                                    </Grid.Row>
                                </Grid>
                            </Form>
                            <Message attached='bottom'>
                                <Button onClick={this.cancelEdit}>Cancel</Button><Button onClick={!this.state.currentlyEditing.id?this.createRole:this.updateRole} color='green'>{!this.state.currentlyEditing.id?'Create':'Update'}</Button>
                            </Message>
                        </Segment>
                    :
                        <React.Fragment>
                            {this.state.currentlyEditing==="loading"?
                                <Loader active inline='centered' />
                            :null}
                        </React.Fragment>
                    }
                </div>

                <Confirm
                    open={this.state.showDeletionModal}
                    header="Delete Role"
                    content={`Are You Sure You Want To Delete Role: ${this.state.currentlyEditing ? this.state.currentlyEditing.role_name : 'Role Info Not Found'}?`}
                    cancelButton='No'
                    confirmButton="Yes"
                    onCancel={()=>this.toggleDeletionModal(false)}
                    onConfirm={this.deleteRole}
                />

            </React.Fragment>
        )
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(RoleManager);