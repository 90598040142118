import React from 'react';

import mapStateToProps from '../../datastore/mapper.js';
import mapDispatchToProps from '../../datastore/dispatcher.js';

import {connect} from 'react-redux';

import {withRouter} from 'react-router-dom';

import { Segment, Button, Icon, Popup, Message, Grid, Dropdown, Input, List, Checkbox } from 'semantic-ui-react';
import autoBind from 'react-autobind';

import GeneralUtility from '../../utilities/generalUtility.js';

import METADATA from '../../utilities/metadata.json';

const optionSelectorOptions = METADATA.OPTIONSELECTOR_STATUS;
const optionSelectorType = METADATA.OPTIONSELECTOR_TYPES;

export class OptionSelector extends React.Component{
    constructor(props){
        super();

        this.state = {
            optionSelectorFeature: null,
            currentlyUpdatingOption:null,
            showAddOptionError: false
        };

        autoBind(this);
    }

    componentDidMount(){
        if(this.props.optionSelectorFeature && !this.state.optionSelectorFeature){
            this.setState({optionSelectorFeature : this.props.optionSelectorFeature});
        }
    }
    componentDidUpdate(){
        if(this.props.optionSelectorFeature && !this.state.optionSelectorFeature){
            this.setState({optionSelectorFeature : this.props.optionSelectorFeature});
        }
    }

    deleteFromOptionSelector(optionid){
        let currentOptionSet = this.state.optionSelectorFeature.content.options;
        const isOptionCorrect = currentOptionSet.filter(option=>option.id===optionid)[0].isCorrect;
        const spliceIndex = currentOptionSet.findIndex(option=>option.id===optionid);
        currentOptionSet.splice(spliceIndex, 1);

        if(isOptionCorrect){
            currentOptionSet.forEach(option=>{if(option.id==='idontknow') option.isCorrect=true;});
        }

        this.setState((prevState)=>({
            optionSelectorFeature: {
                ...prevState.optionSelectorFeature,
                content: {
                    ...prevState.optionSelectorFeature.content,
                    options: currentOptionSet
                }
            }
        }),
        ()=>{this.updateFeature();});
    }
    addToOptionSelector(){
        const newoptiontextinputelem = document.getElementById("newoptiontext");
        const newoptioniscorrectradio = document.getElementById("optionselectoriscorrecttoggle");
        if(newoptiontextinputelem.value){

            let currentOptionSet = this.state.optionSelectorFeature.content.options;
            if(this.state.optionSelectorFeature.content.type==='single-select' && newoptioniscorrectradio.checked && currentOptionSet.filter(option=>option.isCorrect).length){
                currentOptionSet.forEach(option=>{
                    option.isCorrect = false;
                });
            }
            currentOptionSet.splice(-1, 0, {
                id: GeneralUtility.generateUUIDV4(),
                text: newoptiontextinputelem.value,
                isCorrect: newoptioniscorrectradio.checked
            });

            this.setState((prevState)=>({
                optionSelectorFeature:{
                    ...prevState.optionSelectorFeature,
                    content:{
                        ...prevState.optionSelectorFeature.content,
                        options: currentOptionSet
                    }
                },
                showAddOptionError: false
            }),
            ()=>{this.updateFeature();});
        }
        else{
            this.setState({showAddOptionError:true});
        }
    }
    updateOptionSelectionText(e, {value}){
        let currentOptionSet = this.state.optionSelectorFeature.content.options;
        currentOptionSet.forEach(option=>{
            if(option.id===this.state.currentlyUpdatingOption){
                option.text = value;
            }
        });
        this.setState((prevState)=>({
            optionSelectorFeature:{
                ...prevState.optionSelectorFeature,
                content:{
                    ...prevState.optionSelectorFeature.content,
                    options: currentOptionSet
                }
            }
        }),
        ()=>{this.updateFeature();});
    }
    updateOptionSelectionCorrect(optionid){
        let currentOptionSet = this.state.optionSelectorFeature.content.options;
        if(this.state.optionSelectorFeature.content.type==='single-select'){
            let idkindex = -1;
            currentOptionSet.forEach(option=>{
                if(optionid==='idontknow' && option.id===optionid && option.isCorrect){
                    //do nothing
                }
                else if(option.id===optionid && option.isCorrect){
                    option.isCorrect = !option.isCorrect;
                    idkindex = currentOptionSet.findIndex(o=>o.id==='idontknow');
                }
                else if(option.id===optionid && !option.isCorrect){
                    option.isCorrect = true;
                }
                else{
                    option.isCorrect = false;
                }
            });
            if(idkindex>=0){
                currentOptionSet[idkindex].isCorrect = true;
            }
        }
        else{
            currentOptionSet.forEach(option=>{
                if(option.id===optionid){
                    option.isCorrect = !option.isCorrect;
                }
            });
            if(!currentOptionSet.filter(o=>o.isCorrect).length){
                currentOptionSet.forEach(option=>{
                    if(option.id==='idontknow'){
                        option.isCorrect = true;
                    }
                });
            }
        }
        this.setState((prevState)=>({
            optionSelectorFeature:{
                ...prevState.optionSelectorFeature,
                content:{
                    ...prevState.optionSelectorFeature.content,
                    options: currentOptionSet
                }
            }
        }),
        ()=>{this.updateFeature();});
    }

    toggleOptionTextUpdatable(optionid){
        this.setState({
            currentlyUpdatingOption: !optionid ? null : optionid
        });
    }

    toggleAddOptionError(){
        this.setState({showAddOptionError:false});
    }

    updateOptionSelectorType(_type){
        let currentOptionSet = this.state.optionSelectorFeature.content.options;
        if(_type==='single-select' && currentOptionSet.filter(o=>o.isCorrect).length>1){
            currentOptionSet.forEach(option=>{
                if(option.id==='idontknow')
                    option.isCorrect = true;
                else
                    option.isCorrect = false;
            })
        }
        this.setState((prevState)=>({
            optionSelectorFeature:{
                ...prevState.optionSelectorFeature,
                content:{
                    ...prevState.optionSelectorFeature.content,
                    type: _type
                }
            }
        }),
        ()=>{this.updateFeature();});
    }

    updateFeature(){
        this.props.updateOptionSelectorFeature(this.state.optionSelectorFeature);
    }

    render(){

        const {optionSelectorFeature} = this.props;

        return(
            <React.Fragment>
                <Message attached='top'>
                    <Grid columns='equal'>
                        <Grid.Column>
                            <div className="featureid_label">Feature ID:</div>
                            <div className="feature_id">{optionSelectorFeature.id}</div>
                        </Grid.Column>
                        <Grid.Column>
                            <span>Type: </span>
                            <Dropdown id={`type-${optionSelectorFeature.id}`} onChange={(e, {value})=>this.updateOptionSelectorType(value)} placeholder={optionSelectorFeature.content && optionSelectorFeature.content.type ? optionSelectorType.filter(oso=>oso.value===optionSelectorFeature.content.type)[0].text : optionSelectorType[1].text} search selection options={optionSelectorType}/>
                        </Grid.Column>
                        <Grid.Column>
                            <span>Allow Student Input: </span>
                            <Checkbox
                                id={`answerable-${optionSelectorFeature.id}`}
                                toggle
                                onChange={()=>this.props.updateFeatureAllowInput(optionSelectorFeature.id, !optionSelectorFeature.allowUserInput)}
                                checked={optionSelectorFeature && optionSelectorFeature.allowUserInput}
                            />
                        </Grid.Column>
                    </Grid>
                </Message>
                <Message attached info>Double click option text to edit them.</Message>
                <Segment attached>
                    <List verticalAlign='middle'>
                        {optionSelectorFeature.content && optionSelectorFeature.content.options.map(option=>{
                            return(
                                <List.Item key={option.id}>
                                    <List.Content floated='right'>
                                        <div>Is Correct: <Checkbox toggle required checked={option.isCorrect} onChange={()=>this.updateOptionSelectionCorrect(option.id)}/></div>
                                    </List.Content>
                                    {optionSelectorFeature.content.options.length > 5 && option.id!=="idontknow" &&
                                        <Popup content='Delete Option' trigger={<Icon onClick={()=>this.deleteFromOptionSelector(option.id)} className="trashicon_button" name='trash alternate' color="red" />} />
                                    }
                                    <List.Content>
                                        {this.state.currentlyUpdatingOption && this.state.currentlyUpdatingOption===option.id?
                                            <React.Fragment>
                                                <Input
                                                    value={option.text}
                                                    action={
                                                        <Button icon='check' color='green' onClick={this.toggleOptionTextUpdatable}></Button>
                                                    }
                                                    onChange={this.updateOptionSelectionText}
                                                />
                                            </React.Fragment>
                                        :
                                            <span onDoubleClick={option.id!=="idontknow" ? ()=>this.toggleOptionTextUpdatable(option.id) : null}>{option.text}</span>
                                        }
                                        <div className="optionselector_options-info">{option.id}</div>
                                    </List.Content>
                                </List.Item>
                            )
                        })}
                    </List>
                </Segment>
                {this.state.showAddOptionError &&
                    <Message attached error>Option Text Must Not Be Empty</Message>
                }
                <Segment attached>
                    <div className="optionselector_options"><Input onChange={this.state.showAddOptionError ? this.toggleAddOptionError : null} error={this.state.showAddOptionError}><input id="newoptiontext" placeholder='Option Text'/></Input></div>
                    <div className="optionselector_options">Is Correct: <Checkbox id="optionselectoriscorrecttoggle" toggle/></div>
                    <div className="optionselector_options"><Button onClick={()=>this.addToOptionSelector()}>Add Option</Button></div>
                </Segment>
            </React.Fragment>
        )
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(OptionSelector);