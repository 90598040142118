import React from 'react';

import mapStateToProps from '../../datastore/mapper.js';
import mapDispatchToProps from '../../datastore/dispatcher.js';
import GeneralUtility from '../../utilities/generalUtility.js';

import {connect} from 'react-redux';

import {withRouter} from 'react-router-dom';

import { Segment, Icon, Popup, Grid, Dropdown, Input, Label, Checkbox, Divider, Button } from 'semantic-ui-react';
import autoBind from 'react-autobind';

import METADATA from '../../utilities/metadata.json';

export class MetadataManager extends React.Component{
    constructor(props){
        super();

        this.state = {
            availableLanguages: null,
            availableProficiencies: null,
            availableSkills: null,
            showItemOptionsContainer: true,
            currentItemTags:null,
            selectedLanguage: null,
            selectedProficiency: null,
            selectedDomain: null,
            availableDomains: METADATA.ITEMAVAILABLEDOMAINS
        };

        autoBind(this);
    }

    componentDidMount(){
        if(!this.props.appProps.languages || !this.props.appProps.proficiencies || !this.props.appProps.skills){
            this.getLanguageProficiencySkill();
        }
        else{
            const isTagListEmpty = this.props.currentItemTags && this.props.currentItemTags.length <= 0;
            this.setState({
                currentItemTags: this.props.currentItemTags
            }, ()=>{
                this.prepMetadata();
                if(!isTagListEmpty) this.generateTitleByTag(true);
            });
        }
    }
    componentDidUpdate(){
        if(
                !this.state.availableLanguages &&
                !this.state.availableProficiencies &&
                !this.state.availableSkills &&
                this.props.appProps.languages &&
                this.props.appProps.proficiencies &&
                this.props.appProps.skills
        ){
            this.prepMetadata();
        }
        else if(
            this.props.currentItemTags &&
            this.state.currentItemTags!==this.props.currentItemTags &&
            this.props.appProps.languages &&
            this.props.appProps.proficiencies &&
            this.props.appProps.skills
        ){
            const isTagListEmpty = this.props.currentItemTags && this.props.currentItemTags.length <= 0;
            this.setState({
                currentItemTags: this.props.currentItemTags
            }, ()=>{
                this.prepMetadata();
                if(!isTagListEmpty) this.generateTitleByTag();
            });
        }
    }

    prepMetadata(){
        const {appProps:{languages, proficiencies, skills, tags}, currentItemMetadata} = this.props;

        let allTagProficiencies, allTagLanguages, allSkills;
        if(this.state.currentItemTags && this.state.currentItemTags.length && tags && tags.length){
            for(var t=0; t<this.state.currentItemTags.length; t++){
                const filtered_current_tag_detail = tags.filter(tag=>tag.id===this.state.currentItemTags[t]);
                if(filtered_current_tag_detail && filtered_current_tag_detail.length){
                    allTagProficiencies = [...new Set(filtered_current_tag_detail.map(filteredTag => {if (filteredTag.proficiency && filteredTag.proficiency.id) return filteredTag.proficiency.id}).filter(_prof=>_prof))];
                    allTagLanguages = [...new Set(filtered_current_tag_detail.map(filteredTag => {if (filteredTag.language && filteredTag.language.id) return filteredTag.language.id}).filter(_lang=>_lang))];
                    //need to have a tag
                }
            }
        }

        this.setState({
            availableLanguages: languages.map(lang=>{
                const languageSet = {
                    key: lang.shortname,
                    text: (lang.name[0].toUpperCase() + lang.name.slice(1)),
                    value: lang.id
                };
                if(allTagLanguages && allTagLanguages.length && !allTagLanguages.includes(lang.id)){
                    languageSet['disabled'] = true;
                }
                else if(allTagLanguages && allTagLanguages.length && allTagLanguages.includes(lang.id) && currentItemMetadata.language!==lang.id){
                    this.updateCurrentItemMetadata({
                        ...currentItemMetadata,
                        language:lang.id
                    });
                }
                return languageSet;
            }),
            availableProficiencies: proficiencies.map(prof=>{
                const proficiencySet = {
                    key: prof.difficultydesignation,
                    text: (prof.label[0].toUpperCase() + prof.label.slice(1)),
                    value: prof.id
                };
                if(allTagProficiencies && allTagProficiencies.length && !allTagProficiencies.includes(prof.id)){
                    proficiencySet['disabled'] = true;
                }
                else if(allTagProficiencies && allTagProficiencies.length && allTagProficiencies.includes(prof.id) && currentItemMetadata.proficiency!==prof.id){
                    this.updateCurrentItemMetadata({
                        ...currentItemMetadata,
                        proficiency:prof.id
                    });
                }
                return proficiencySet;
            }),
            availableSkills: skills.map(_skill=>{
                const skillSet = {
                    key: _skill.id,
                    text: (_skill.name[0].toUpperCase() + _skill.name.slice(1)),
                    value: _skill.id
                };
                // if(allTagLanguages && allTagLanguages.length && !allTagLanguages.includes(lang.id)){
                //     languageSet['disabled'] = true;
                // }
                // else if(allTagLanguages && allTagLanguages.length && allTagLanguages.includes(lang.id) && currentItemMetadata.language!==lang.id){
                //     this.updateCurrentItemMetadata({
                //         ...currentItemMetadata,
                //         language:lang.id
                //     });
                // }
                return skillSet;
            })
        })
    }

    async generateTitleByTag(isFresh){
        const {appProps:{languages, proficiencies, skills, tags}, currentItemMetadata} = this.props;

        let allTagProficiencies, allTagLanguages, allTagSkills;
        if(this.state.currentItemTags && this.state.currentItemTags.length && tags && tags.length){

            const filtered_current_tag_detail = tags.filter(tag=>this.state.currentItemTags.includes(tag.id));
            if(filtered_current_tag_detail && filtered_current_tag_detail.length){

                allTagProficiencies = [
                    ...new Set(
                        filtered_current_tag_detail
                        .map(filteredTag => {
                            if (filteredTag.proficiency && filteredTag.proficiency.id){
                                return filteredTag.proficiency.id;
                            }
                        })
                        .filter(_prof=>_prof)
                    )
                ];
                allTagLanguages = [
                    ...new Set(
                        filtered_current_tag_detail
                        .map(filteredTag => {
                            if (filteredTag.language && filteredTag.language.id){
                                return filteredTag.language.id
                            }
                        })
                        .filter(_lang=>_lang)
                    )
                ];
                allTagSkills = [
                    ...new Set(
                        filtered_current_tag_detail
                        .map(filteredTag => {
                            if (filteredTag.skill && filteredTag.skill.id){
                                return filteredTag.skill.id
                            }
                        })
                        .filter(_skill=>_skill)
                    )
                ];
            }

        }

        let _lang = currentItemMetadata.language;
        let _prof = currentItemMetadata.proficiency;
        let _skill = currentItemMetadata.skill;
        let autoTitle = (allTagLanguages && allTagLanguages.length) || (allTagProficiencies && allTagProficiencies.length) || (allTagSkills && allTagSkills.length) ? '' : currentItemMetadata.title;

        if(languages && languages.length){
            const filtered_languages = allTagLanguages && allTagLanguages.length ? 
                languages.filter(lang=>allTagLanguages.includes(lang.id)) :
                languages.filter(lang=>lang.id === _lang);

            if(filtered_languages && filtered_languages.length){
                autoTitle += filtered_languages[0].name.slice(0, 4).toLowerCase();
                _lang = filtered_languages[0].id;
            }
        }

        if(skills && skills.length){
            const filtered_skills = allTagSkills && allTagSkills.length ? 
                skills.filter(skill=>allTagSkills.includes(skill.id)) : 
                skills.filter(skill=>skill.id === _skill);

            if(filtered_skills && filtered_skills.length){
                autoTitle += (autoTitle.length ? '_' : '') + filtered_skills[0].name.slice(0, 1).toLowerCase();
                _skill = filtered_skills[0].id;
            }
        }

        if(proficiencies && proficiencies.length){
            const filtered_proficiencies = allTagProficiencies && allTagProficiencies.length ? 
                proficiencies.filter(prof=>allTagProficiencies.includes(prof.id)) :
                proficiencies.filter(prof=>prof.id === _prof);

            if(filtered_proficiencies && filtered_proficiencies.length){
                autoTitle += (autoTitle.length ? '_' : '') + filtered_proficiencies[0].difficultydesignation;
                _prof = filtered_proficiencies[0].id;
            }
        }
        var itemNum = currentItemMetadata.itemnumber; 
        if(!itemNum){
            var itemNumDS = await this.props.getNextItemNumber({
                language:_lang,
                proficiency: _prof,
                skill: _skill
            });
            itemNum = String(Number(itemNumDS.itemnumber) + 1).padStart(4, '0');
        }
        autoTitle += (autoTitle.length ? '_' : '') + itemNum;

        this.props.updateItemMetaData({
            ...currentItemMetadata,
            language: _lang,
            proficiency: _prof,
            skill: _skill,
            itemnumber: itemNum,
            title: (isFresh && autoTitle!==currentItemMetadata.title ? currentItemMetadata.title : autoTitle)
        });
    }

    async generateTitleByDropDown(newDS){
        const {appProps:{languages, proficiencies, skills, tags}} = this.props;

        let autoTitle = '';

        if(languages && languages.length && newDS && newDS.language){
            const filtered_languages = languages.filter(lang=>lang.id===newDS.language);
            if(filtered_languages && filtered_languages.length) autoTitle = filtered_languages[0].name.slice(0, 4).toLowerCase() + (autoTitle.length ? `_${autoTitle}` : '');
        }

        if(skills && skills.length && newDS && newDS.skill){
            const filtered_skills = skills.filter(_skill=>_skill.id===newDS.skill);
            if(filtered_skills && filtered_skills.length) autoTitle = (autoTitle.length ? `${autoTitle}_` : '') + filtered_skills[0].name.slice(0, 1).toLowerCase();
        }

        if(proficiencies && proficiencies.length && newDS && newDS.proficiency){
            const filtered_proficiencies = proficiencies.filter(prof=>prof.id===newDS.proficiency);
            if(filtered_proficiencies && filtered_proficiencies.length) autoTitle = (autoTitle.length ? `${autoTitle}_` : '') + filtered_proficiencies[0].difficultydesignation;
        }

        var itemNum = newDS.itemnumber; 
        if(!itemNum && newDS.language && newDS.proficiency && newDS.skill){
            var itemNumDS = await this.props.getNextItemNumber({
                language:newDS.language,
                proficiency: newDS.proficiency,
                skill: newDS.skill
            });
            itemNum = String(Number(itemNumDS.itemnumber) + 1).padStart(4, '0');
            autoTitle += (autoTitle.length ? '_' : '') + itemNum;
        }
        
        this.props.updateItemMetaData({
            ...newDS,
            title: autoTitle,
            itemnumber: itemNum
        });
    }

    getLanguageProficiencySkill(){

        const {appProps: {languages, proficiencies, skills} } = this.props;

        if(!languages){
            this.props.getLanguages();
        }
        if(!proficiencies){
            this.props.getProficiency();
        }
        if(!skills){
            this.props.getSkills();
        }
    }

    updateItemTitle(e, {value}){
        this.updateCurrentItemMetadata({
            ...this.props.currentItemMetadata,
            title: value
        });
    }

    updateSelectedLanguage(e, {value}){
        this.generateTitleByDropDown({
            ...this.props.currentItemMetadata,
            language:value
        });
    }
    updateSelectedProficiency(e, {value}){
        this.generateTitleByDropDown({
            ...this.props.currentItemMetadata,
            proficiency:value
        });
    }
    updateSelectedDomain(e, {value}){
        this.updateCurrentItemMetadata({
            ...this.props.currentItemMetadata,
            domain:value
        });
    }
    updateSelectedSkill(e, {value}){
        this.generateTitleByDropDown({
            ...this.props.currentItemMetadata,
            skill:value
        });
    }
    toggleItemTimer(){
        this.updateCurrentItemMetadata({
            ...this.props.currentItemMetadata,
            timer: {
                ...this.props.currentItemMetadata.timer,
                enabled:!this.props.currentItemMetadata.timer.enabled
            }
        });
    }
    updateItemTimerAmount(){
        const minutesinp = document.querySelector("#item_timer_minutes");
        const secondsinp = document.querySelector("#item_timer_seconds");

        const totalsec = (parseInt(minutesinp.value) * 60) + (secondsinp.value ? parseInt(secondsinp.value) : 0);

        this.updateCurrentItemMetadata({
            ...this.props.currentItemMetadata,
            timer: {
                ...this.props.currentItemMetadata.timer,
                seconds: parseInt(totalsec)
            }
        });
    }
    updateItemDifficulty(e, {value}){
        const fixed = parseFloat(value).toFixed(2).toString()
        if (fixed.length < parseFloat(value).toString().length){
            value = fixed;
            e.target.value = fixed;
        }
        this.updateCurrentItemMetadata({
            ...this.props.currentItemMetadata,
            difficulty: value
        });
    }

    togglePracticeFlag(){
        this.updateCurrentItemMetadata({
            ...this.props.currentItemMetadata,
            ispractice: !this.props.currentItemMetadata.ispractice
        });
    }

    updateCurrentItemMetadata(updatedObject){
        this.props.updateItemMetaData(updatedObject);
    }

    toggleItemOptionsContainer(){
        this.setState((prevState)=>({showItemOptionsContainer: !prevState.showItemOptionsContainer}));
    }

    render(){

        const {currentItemMetadata} = this.props;

        return(
            <Segment raised>
                <Popup content='Current Item Version' trigger={
                    <Label size="small" attached="top left">
                        Version:
                        <Label.Detail>{currentItemMetadata.version}</Label.Detail>
                    </Label>
                }/>
                <Popup content={this.state.showItemOptionsContainer?'Minimize Toolset':'Maximize Toolset'} trigger={
                    <Label size='small' attached="top right" as='a' onClick={this.toggleItemOptionsContainer}><Icon name={this.state.showItemOptionsContainer?'window minimize':'window maximize outline'}/></Label>
                }/>
                <Divider hidden/>
                {this.state.showItemOptionsContainer &&
                <Grid>
                    <Grid.Row>
                        <Grid.Column width={12}>
                            <div>Item Title:</div>
                            <Input
                                onChange={this.updateItemTitle}
                                fluid
                                placeholder='Item Title'
                                value={currentItemMetadata.title}
                            >
                                <input />
                            </Input>
                        </Grid.Column>
                        <Grid.Column width={4}>
                            <span>Skill: </span>
                            <div><Dropdown
                                selection
                                options={this.state.availableSkills}
                                value={currentItemMetadata.skill}
                                onChange={this.updateSelectedSkill}
                            /></div>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={4}>
                            <span>Language: </span>
                            <div><Dropdown
                                selection
                                options={this.state.availableLanguages}
                                value={currentItemMetadata.language}
                                onChange={this.updateSelectedLanguage}
                            /></div>
                        </Grid.Column>
                        <Grid.Column width={4}>
                            <span>Proficiency: </span>
                            <div><Dropdown
                                selection
                                options={this.state.availableProficiencies}
                                value={currentItemMetadata.proficiency}
                                onChange={this.updateSelectedProficiency}
                            /></div>
                        </Grid.Column>
                        <Grid.Column width={4}>
                            <span>Domain: </span>
                            <div><Dropdown
                                selection
                                options={this.state.availableDomains}
                                value={currentItemMetadata.domain}
                                onChange={this.updateSelectedDomain}
                            /></div>
                        </Grid.Column>
                        <Grid.Column width={4}>
                            <span>Difficulty: </span>
                            <div><Input value={currentItemMetadata.difficulty ? currentItemMetadata.difficulty : ''} onChange={this.updateItemDifficulty}>
                                <input id="difficulty" type="number" step=".01"/>
                            </Input></div>                            
                        </Grid.Column>
                        <Grid.Column width={2}>
                            <span>Timed</span>
                            <div className="timer-container">
                                <div><Checkbox toggle defaultChecked={currentItemMetadata.timer.enabled} onChange={this.toggleItemTimer}/></div>
                                {currentItemMetadata.timer.enabled ?
                                    <React.Fragment>
                                        <div><Input value={currentItemMetadata.timer.seconds ? Math.floor(currentItemMetadata.timer.seconds / 60) : ''} placeholder='Minutes' size='mini' onChange={this.updateItemTimerAmount}><input id="item_timer_minutes" type="number"/></Input></div>
                                        <div><Input value={currentItemMetadata.timer.seconds ? Math.round(currentItemMetadata.timer.seconds % 60) : ''} placeholder='Seconds' size='mini' onChange={this.updateItemTimerAmount}><input id="item_timer_seconds" type="number" max="59"/></Input></div>
                                    </React.Fragment>
                                : null}
                            </div>
                        </Grid.Column>
                        <Grid.Column width={2}>
                            <span>Is Practice</span>
                            <div>
                                <Popup content={'Exclude this item from scoring test events, and instead use it as practice item.'} trigger={
                                    <Checkbox toggle defaultChecked={currentItemMetadata.ispractice} onChange={this.togglePracticeFlag}/>
                                }/>
                            </div>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
                || <Label size='small' attached="top left">Item Options</Label>}
            </Segment>

        )
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(MetadataManager);