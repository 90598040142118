import React from 'react';

import mapStateToProps from '../../datastore/mapper.js';
import mapDispatchToProps from '../../datastore/dispatcher.js';

import {connect} from 'react-redux';

import {withRouter} from 'react-router-dom';

import { Segment, Message, Grid, Dropdown, Input, Header, List, Popup } from 'semantic-ui-react';
import autoBind from 'react-autobind';

import APPENV from '../../../env.json';
import METADATA from '../../utilities/metadata.json';

export class MediaSelector extends React.Component{
    constructor(props){
        super();
        this.state={
            searchPattern: null,
            selectedType: null,
            mediafor: null
        }
        autoBind(this);
    }

    componentDidMount(){
        this.prepFeatureAsset();
    }

    prepFeatureAsset(){
        const {appProps:{media:{allAssets}}, mediaFeature} = this.props;

        if(
            (
                allAssets &&
                allAssets.length &&
                !allAssets.filter(_asset=>_asset.id===mediaFeature.content.id).length
            ) ||
            !allAssets ||
            !allAssets.length
        ){
            this.getMediaFiles(mediaFeature.content.id);
        }
        //remove mediafor null coalesce check in the future?
        //console.log("Media For: ", `--${METADATA.MEDIA_FOR[0].value}--`);
        this.setState({selectedType: mediaFeature.content.type, mediafor: mediaFeature.content.for || METADATA.MEDIA_FOR[0].value});
    }

    async getMediaFiles(_id){
        try{
            if(this.state.searchPattern){
                const allSearchedMediaAssets = await this.props.getMediaAssets(this.state.searchPattern, (_id ? _id : null), this.state.selectedType);
            }
            else if(_id){
                const allSearchedMediaAssets = await this.props.getMediaAssets(null, _id, null);
            }
        }catch(err){}
    }

    updateSelectedMediaType(e, {value}){
        this.setState({
            selectedType: value
        });
    }

    updateSelectedMediaFor(e, {value}){
        const {mediaFeature} = this.props;

        this.setState({
            mediafor: value
        }, ()=>{
            const updatedMediaFeature = mediaFeature;
            updatedMediaFeature.content.for = this.state.mediafor;
            this.updateMediaFeature(updatedMediaFeature);
        });
    }

    updateSearchPattern(e, {value}){
        this.setState({searchPattern: value});
    }

    updateSelectedMedia(assetInfo){
        const {mediaFeature} = this.props;

        const updatedMediaFeature = mediaFeature;
        updatedMediaFeature.content.filename = assetInfo.fileName;
        updatedMediaFeature.content.id = assetInfo.id;
        updatedMediaFeature.content.type = assetInfo.mimeType.split("/")[0];

        this.updateMediaFeature(updatedMediaFeature);
    }

    updateMediaFeature(updatedMediaFeature){
        this.props.updateMediaFeature(updatedMediaFeature)
    }

    getMediaDOM(mediatype, filename){
        if(mediatype==="video"){
            return (
                <video autoPlay muted loop className="thumb_content">
                    <source src={`${APPENV.APIEndpoint}/downloads/media/${filename}#t=1,2`}/>
                    <p>Your browser doesn't support HTML5 video. Here is a <a href={`${APPENV.APIEndpoint}/downloads/media/${filename}`}>link to the video</a> instead.</p> 
                </video>
            );
        }
        else if(mediatype==="audio"){
            return (
                <audio
                    controls
                    src={`${APPENV.APIEndpoint}/downloads/media/${filename}#t=1,5`}
                    controlsList="nodownload"
                >
                    Your browser does not support the
                    <code>audio</code> element.
                </audio>
            )
        }
        else if(mediatype==="image"){
            return (
                <img className="thumb_content" src={`${APPENV.APIEndpoint}/downloads/media/${filename}`} />
            )
        }
    }

    render(){

        const {appProps:{media:{allAssets}}, mediaFeature} = this.props

        return(
            <React.Fragment>
                <Message attached='top'>
                    <Grid columns='equal'>
                        <Grid.Row>
                            <Grid.Column>
                                <div className="featureid_label">Feature ID:</div>
                                <div className="feature_id">{mediaFeature.id}</div>
                            </Grid.Column>
                            <Grid.Column>
                                <div>
                                    <span>Media Type: </span>
                                    <Dropdown onChange={this.updateSelectedMediaType} defaultValue={mediaFeature.content.type} placeholder='Media Type' search selection options={METADATA.MEDIA_TYPES} />
                                </div>
                            </Grid.Column>
                            <Grid.Column>
                                <div>
                                    <span>For: </span>
                                    <Dropdown onChange={this.updateSelectedMediaFor} value={mediaFeature.content.for || this.state.mediafor} placeholder='For' selection options={METADATA.MEDIA_FOR} />
                                </div>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Message>
                {mediaFeature.content && mediaFeature.content.filename &&
                    <Segment attached>
                        <div className="media-selector">
                            <div className="asset-container">{this.getMediaDOM(mediaFeature.content.type, mediaFeature.content.filename)}</div>
                            <div><small className="asset-filename">{mediaFeature.content.filename}</small></div>
                        </div>
                    </Segment>
                }
                <Message attached="bottom">
                    <div className="media-selector-options">
                        <Header as='h5' color="blue">Search for relevant media files using forms below, OR, Select from available table below</Header>
                        <Input
                            onChange={this.updateSearchPattern}
                            fluid
                            placeholder={`Search ${mediaFeature.type} Files By Title`}
                            action={{
                                color: 'teal',
                                labelPosition: 'right',
                                icon: 'search',
                                content: 'Search',
                                onClick: ()=>this.getMediaFiles()
                            }}
                        />
                    </div>
                    <div className="media-selector-list">
                        <List divided verticalAlign='middle'>
                            {allAssets && allAssets.filter(asset=>{
                                if(asset.mimeType.split("/")[0]===this.state.selectedType){
                                    const _matching = this.state.searchPattern ? (
                                        (asset.title.toLowerCase()).replace(" ",""))
                                        .match(new RegExp(
                                            (this.state.searchPattern.toLowerCase())
                                            .replace(" ","")
                                        )
                                    ) : null;
                                    return (
                                        (this.state.searchPattern && _matching)
                                        ||
                                        !this.state.searchPattern
                                    )
                                }
                                return false;
                            }).map((asset, assetindex)=>{
                                return (
                                    <List.Item key={`media-${asset.id}`}>
                                        {asset.id===mediaFeature.content.id ?
                                            <List.Icon link name='dot circle' color="green" size="big"/>
                                        :
                                            <List.Icon onClick={()=>this.updateSelectedMedia(asset)} link name='circle outline' color="blue" size="big"/>
                                        }
                                        <List.Content>
                                            <List.Header>
                                                <Popup content={METADATA.MEDIA_MIME_ICONS[asset.mimeType] ? METADATA.MEDIA_MIME_ICONS[asset.mimeType].tooltip : METADATA.MEDIA_MIME_ICONS.placeholder.tooltip} trigger={
                                                        <List.Icon name={METADATA.MEDIA_MIME_ICONS[asset.mimeType] ? METADATA.MEDIA_MIME_ICONS[asset.mimeType].icon : METADATA.MEDIA_MIME_ICONS.placeholder.icon} />
                                                } />
                                                <span>{asset.title}</span>
                                            </List.Header>
                                            <small className="asset-filename">{asset.fileName}</small>
                                            <div className="asset-container">{this.getMediaDOM(asset.mimeType.split("/")[0], asset.fileName)}</div>
                                        </List.Content>
                                    </List.Item>
                                )
                            }) || null}
                        </List>
                    </div>
                </Message>
            </React.Fragment>
        )
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(MediaSelector);