import React from 'react';

import mapStateToProps from '../../datastore/mapper.js';
import mapDispatchToProps from '../../datastore/dispatcher.js';

import {connect} from 'react-redux';

import {withRouter} from 'react-router-dom';

import { Segment, Divider, Button, Grid, Input, Popup, Message, Dropdown, Label, Checkbox } from 'semantic-ui-react'

import autoBind from 'react-autobind';

import GeneralUtility from '../../utilities/generalUtility.js';

import APPENV from '../../../env.json';

export class EditOrganization extends React.Component{
    constructor(props){
        super();

        this.state={
            currentlyEditingOrganization:{
                id: null,
                name: null,
                address: null,
                logo: null,
                theme: {
                    color: null,
                    bgcolor: null
                },
                relations: [],
                disableTestNav: false
            },
            error:false,
            isEditing:false
        };

        this.orgformdata = new FormData();

        autoBind(this);
    }

    componentDidMount(){
        this.getOrganizationStatuses();
        this.parseQueryParam();
    }

    async getOrganizationStatuses(){
        const {appProps:{status}} = this.props;
        try{
            const allorgstatuses = await this.props.getOrganizationStatuses();
        }
        catch(err){}
    }

    async parseQueryParam(){
        const {location:{search}, appProps:{status}} = this.props;

        try{
            if(!status.organization){
                await this.props.getOrganizationStatuses();
            }

            const parsedQueryParam = search.split("?");
            if(parsedQueryParam && parsedQueryParam.length === 2){
                const orgId = parsedQueryParam[1].split('id=')[1];
                if (orgId) {
                    this.prepOrganizationEdit(orgId);
                }
            }
            else{
                this.prepOrganizationEdit();
            }
        }catch(err){
            this.prepOrganizationEdit();
        }
    }

    async prepOrganizationEdit(orgid){
        const {appProps:{organizations}} = this.props;

        const _uuid4 = GeneralUtility.generateUUIDV4();

        const preppedOrgDetail = {
            id: orgid ? orgid : _uuid4,
            name: "",
            address: "",
            logo: null,
            theme: {
                color: "#000000",
                bgcolor: "#000000"
            },
            relations: [],
            disableTestNav: false
        };

        if(orgid){
            try{
                var orgDetail;
                if(organizations && organizations.find(org=>org.id===orgid)){
                    orgDetail = JSON.parse(JSON.stringify(organizations.find(org=>org.id===orgid)));
                }
                else{
                    const allorgs = await this.props.getOrganizations({id: orgid});
                    if(allorgs.success && allorgs.dataset && allorgs.dataset.length && allorgs.dataset[0].relations.length){
                        for(var relorgc=0; relorgc<allorgs.dataset[0].relations.length; relorgc++){
                            if(!this.props.appProps.organizations.find(org=>org.id===allorgs.dataset[0].relations[relorgc])){
                                await this.props.getOrganizations({id: allorgs.dataset[0].relations[relorgc]});
                            }
                        }
                    }
                    orgDetail = JSON.parse(JSON.stringify(allorgs.dataset.find(ds=>ds.id===orgid)));
                }

                this.setState({
                    currentlyEditingOrganization: orgDetail,
                    isEditing: true
                }, ()=>{
                    this.orgformdata.set("id", orgDetail.id);
                    this.orgformdata.set("name", orgDetail.name);
                    this.orgformdata.set("address", orgDetail.address);
                    this.orgformdata.set("theme", JSON.stringify(orgDetail.theme));
                    this.orgformdata.set("relations", JSON.stringify(orgDetail.relations));
                    this.orgformdata.set("disableTestNav", orgDetail.disableTestNav);
                });
            }
            catch(err){console.error(err);}
        }
        else{
            this.setState({
                currentlyEditingOrganization: preppedOrgDetail,
                isEditing: false
            }, ()=>{
                this.orgformdata.set("id", preppedOrgDetail.id);
                this.orgformdata.set("name", preppedOrgDetail.name);
                this.orgformdata.set("address", preppedOrgDetail.address);
                this.orgformdata.set("theme", JSON.stringify(preppedOrgDetail.theme));
                this.orgformdata.set("relations", JSON.stringify(preppedOrgDetail.relations));
                this.orgformdata.set("disableTestNav", preppedOrgDetail.disableTestNav);
            });
        }
    }

    prepLogoFile(){
        const uploadInputNode = document.createElement('INPUT');
        uploadInputNode.setAttribute("type", "file");
        uploadInputNode.setAttribute("accept", "image/png");
        document.body.appendChild(uploadInputNode);
        uploadInputNode.style.display = 'none';
        uploadInputNode.click();
        uploadInputNode.addEventListener("change", async (event)=>{
            const _logofile = event.target.files[0];

            const fileType = event.target.files[0].type;
            const fileName = event.target.files[0].name;

            if(this.orgformdata.has("orglogo")){
                this.orgformdata.set("orglogo", _logofile);
            }
            else{
                this.orgformdata.append("orglogo", _logofile);
            }

            uploadInputNode.remove();

            const base64imagedataurl = await this.convertFileToDataUrl(_logofile);

            this.setState((prevState)=>({
                currentlyEditingOrganization:{
                    ...prevState.currentlyEditingOrganization,
                    logo: base64imagedataurl
                }
            }));
        });
    }

    convertFileToDataUrl(file){
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    }

    updateOrgInfo(e, data){
        const currentorginfo = JSON.parse(JSON.stringify(this.state.currentlyEditingOrganization));
        currentorginfo[data.orginfotype] = data.value;
        this.setState({
            currentlyEditingOrganization: currentorginfo
        }, ()=>{
            if(this.orgformdata.has(data.orginfotype)){
                this.orgformdata.set(data.orginfotype, data.value);
            }
            else{
                this.orgformdata.append(data.orginfotype, data.value);
            }
        });
    }

    updateDeadline(e){
        if(!e){
            const currentorginfo = JSON.parse(JSON.stringify(this.state.currentlyEditingOrganization));
            currentorginfo.disableTestNav = !currentorginfo.disableTestNav;
            this.setState(()=>({
                currentlyEditingOrganization: currentorginfo
            }), ()=>{
                if(this.orgformdata.has("disableTestNav")){
                    this.orgformdata.set("disableTestNav", this.state.currentlyEditingOrganization.disableTestNav);
                }
                else{
                    this.orgformdata.append("disableTestNav", this.state.currentlyEditingOrganization.disableTestNav);
                }
            });
        }
        else{
            const currentorginfo = JSON.parse(JSON.stringify(this.state.currentlyEditingOrganization));
            currentorginfo.disableTestNav = e.target.value;
            this.setState({
                currentlyEditingOrganization: currentorginfo
            }, ()=>{
                if(this.orgformdata.has("disableTestNav")){
                    this.orgformdata.set("disableTestNav", this.state.currentlyEditingOrganization.disableTestNav);
                }
                else{
                    this.orgformdata.append("disableTestNav", this.state.currentlyEditingOrganization.disableTestNav);
                }
            });
        }
    }

    updateOrgTheme(e, prop){
        const currentorginfo = JSON.parse(JSON.stringify(this.state.currentlyEditingOrganization));
        currentorginfo.theme[prop] = e.target.value;
        this.setState({
            currentlyEditingOrganization: currentorginfo
        }, ()=>{
            if(this.orgformdata.has("theme")){
                this.orgformdata.set("theme", JSON.stringify(this.state.currentlyEditingOrganization.theme));
            }
            else{
                this.orgformdata.append("theme", JSON.stringify(this.state.currentlyEditingOrganization.theme));
            }
        });
    }

    updateOrgRelations(e, data){
        const currentorginfo = JSON.parse(JSON.stringify(this.state.currentlyEditingOrganization));
        currentorginfo.relations = data.value;
        this.setState({
            currentlyEditingOrganization: currentorginfo
        }, ()=>{
            if(this.orgformdata.has("relations")){
                this.orgformdata.set("relations", JSON.stringify(data.value));
            }
            else{
                this.orgformdata.append("relations", JSON.stringify(data.value));
            }
        });
    }

    async createOrganization(){
        try{
            //console.log(this.state.currentlyEditingOrganization);
            if(this.state.isEditing){
                const organizationUpdated = await this.props.updateOrganization(this.orgformdata, JSON.parse(JSON.stringify(this.state.currentlyEditingOrganization)));
            }
            else{
                const organizationCreated = await this.props.createOrganization(this.orgformdata, JSON.parse(JSON.stringify(this.state.currentlyEditingOrganization)));
            }
            if(this.state.currentlyEditingOrganization.id===this.props.appProps.user.organization.organization_id){
                await this.props.getLoggedInUserInfo();
            }
            this.props.history.push("/organizations");
        }catch(err){
            console.error(err);
            this.setState({
                error: {
                    type: "BackEnd Error",
                    message: `Could not ${this.state.isEditing? 'update' : 'create'} Organization.`
                },
                showConfirmation: false
            });
        }
    }

    render(){

        const {appProps:{status, error, organizations}} = this.props;

        return(
            <div className="editorganization">
                {(this.state.error && (this.state.error.type==='api' || this.state.error.type==='apiupdate')) || (error && error.type===102 && error.message==="Could Not Create Organization")?
                    <Message negative>
                        Could Not {this.state.error.type==='api'?'Create':'Update'} Organization At This Moment
                    </Message>
                :null}
                <Segment>
                    <Label color='grey' ribbon>id: {this.state.currentlyEditingOrganization.id}</Label>
                    <Grid columns='equal'>
                        <Grid.Row>
                            <Grid.Column>
                                Name: <Input orginfotype="name" fluid placeholder='Organization Name' onChange={this.updateOrgInfo} value={this.state.currentlyEditingOrganization.name?this.state.currentlyEditingOrganization.name:''} />
                            </Grid.Column>
                            <Grid.Column>
                                Address: <Input orginfotype="address" fluid placeholder='Organization Address' onChange={this.updateOrgInfo} value={this.state.currentlyEditingOrganization.address?this.state.currentlyEditingOrganization.address:''} />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column>
                                Logo: <img className="orglogoimgcontainer" onClick={this.prepLogoFile} src={`${APPENV.APIEndpoint}/downloads/organizations/logos/${this.state.currentlyEditingOrganization.logo}`} />
                            </Grid.Column>
                            <Grid.Column>
                                <Popup content='Background Color' trigger={
                                    <input onChange={(e=>this.updateOrgTheme(e, "bgcolor"))} type="color" value={this.state.currentlyEditingOrganization.theme.bgcolor || '#000000'} />
                                }/>
                                <Popup content='Foreground Color' trigger={
                                    <input onChange={(e=>this.updateOrgTheme(e, "color"))} type="color" value={this.state.currentlyEditingOrganization.theme.color || '#000000'} />
                                }/>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column>
                                Related Organizations: 
                                <Dropdown
                                    onChange={this.updateOrgRelations}
                                    placeholder='Related Organizations'
                                    multiple
                                    search
                                    selection
                                    value={this.state.currentlyEditingOrganization.relations}
                                    options={organizations && organizations.length ? organizations
                                        .filter(org=>org.id!==this.state.currentlyEditingOrganization.id)
                                        .map(org=>{
                                            return {
                                                key: org.id,
                                                value: org.id,
                                                text: org.name,
                                                image: { avatar: true, src: `${APPENV.APIEndpoint}/downloads/organizations/logos/${org.logo}` }
                                            }
                                        })
                                        :
                                        []
                                    }
                                />
                            </Grid.Column>
                            <Grid.Column>
                                <Checkbox
                                    toggle
                                    label='Disable Test Navigation'
                                    onChange={()=>this.updateDeadline()}
                                    checked={this.state.currentlyEditingOrganization.disableTestNav || false}
                                />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Segment>
                <Divider hidden />
                <Message>
                    <p>
                        <Button color='yellow' onClick={()=>this.props.history.push("/organizations")}>Cancel</Button>
                        <Button floated='right' color='green' onClick={this.createOrganization}>{this.state.isEditing?'Update':'Create'}</Button>
                    </p>
                </Message>
            </div>
        )
    }
}

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(EditOrganization));