import React from 'react';

import mapStateToProps from '../../datastore/mapper.js';
import mapDispatchToProps from '../../datastore/dispatcher.js';

import {withRouter} from 'react-router-dom';

import {connect} from 'react-redux';

import { Grid, Input, Button, Divider, Table, Label, Icon, Header, Popup, Modal, Confirm } from 'semantic-ui-react'

import autoBind from 'react-autobind';

export class ListTags extends React.Component{
    constructor(props){
        super();

        this.state={
            tagdeletion: null,
            searchpattern: null
        }

        autoBind(this);
    }

    async searchTags(){
        try{
            await this.props.getTags(this.state.searchpattern, this.state.searchpattern);
        }catch(err){}
    }

    editTag(tag){
        this.props.history.push("/tags/edit?id="+tag.id);
    }

    async deleteTag(tagid){
        try{
            const tagdeleted = await this.props.deleteTag(tagid);
            this.setState({tagdeletion:null});
        }
        catch(err){}
    }
    toggleDeletionModal(tagid){
        const {appProps:{tags}} = this.props;
        if(tagid){
            const filteredtag = tags.filter(tag=>tag.id===tagid);
            this.setState({tagdeletion:filteredtag[0]});
        }
        else{
            this.setState({tagdeletion:null});
        }
    }

    render(){

        const {appProps:{tags}} = this.props;

        return(
            <React.Fragment>
                <div className="listtags">
                    <Grid>
                        <Grid.Row>
                            <Grid.Column width={10}>
                                <Input fluid placeholder='Search by tag label or tag id' action>
                                    <input id="searchpattern"/>
                                    <Button onClick={this.searchTags}>Search</Button>
                                </Input>
                            </Grid.Column>
                            <Grid.Column width={3}>
                                <div className="list_tags-create">
                                    <Button onClick={()=>{this.props.history.push("/tags/create");}} color='green'>Create</Button>
                                </div>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                    <Divider hidden />
                    {tags && tags.length &&
                        <Table compact celled definition>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell />
                                    <Table.HeaderCell>Label</Table.HeaderCell>
                                    <Table.HeaderCell>User</Table.HeaderCell>
                                    <Table.HeaderCell>Role</Table.HeaderCell>
                                    <Table.HeaderCell>Language</Table.HeaderCell>
                                    <Table.HeaderCell>Proficiency</Table.HeaderCell>
                                    <Table.HeaderCell>Skill</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {tags.map((tag, tagindex)=>{
                                    return (
                                        <Table.Row key={`tag-${tag.id}`}>
                                            <Table.Cell collapsing>
                                                <Popup content='Delete Tag' trigger={
                                                    <Button circular icon='trash' color='red' size='tiny' onClick={()=>this.toggleDeletionModal(tag.id)}></Button>
                                                }/>
                                                <Popup content='Edit Tag' trigger={
                                                    <Button circular icon='pencil' color='yellow' size='tiny' onClick={()=>this.editTag(tag)}></Button>
                                                }/>
                                            </Table.Cell>
                                            <Table.Cell><Label tag color="brown">{tag.label}</Label></Table.Cell>
                                            <Table.Cell>
                                                {tag.user &&
                                                    <span>{`${tag.user.lastname}, ${tag.user.firstname}`}</span>
                                                }
                                            </Table.Cell>
                                            <Table.Cell>
                                                {tag.role &&
                                                    <span>{tag.role.name}</span>
                                                }
                                            </Table.Cell>
                                            <Table.Cell>
                                                {tag.language &&
                                                    <span>{tag.language.name}</span>
                                                }
                                            </Table.Cell>
                                            <Table.Cell>
                                                {tag.proficiency &&
                                                    <span>{tag.proficiency.label[0].toUpperCase() + tag.proficiency.label.slice(1)}</span>
                                                }
                                            </Table.Cell>
                                            <Table.Cell>
                                                {tag.skill &&
                                                    <span>{tag.skill.name[0].toUpperCase() + tag.skill.name.slice(1)}</span>
                                                }
                                            </Table.Cell>
                                        </Table.Row>
                                    )
                                })}
                            </Table.Body>
                        </Table>
                    }
                </div>

                <Confirm
                    open={this.state.tagdeletion ? true : false}
                    header="Delete Item"
                    content={`Are You Sure You Want To Delete Tag: ${this.state.tagdeletion ? this.state.tagdeletion.label : 'Tag Info Not Found'}?`}
                    cancelButton='No'
                    confirmButton="Yes"
                    onCancel={()=>this.toggleDeletionModal(null)}
                    onConfirm={()=>this.deleteTag(this.state.tagdeletion.id)}
                />

            </React.Fragment>
        )
    }
}

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(ListTags));