import APIConnector from '../../utilities/apiConnector.js';
import CookieUtility from '../../utilities/cookieUtility.js';

export function logError(newError = {type: null, message: null}){
    return dispatch => {
        dispatch({
            type: "UPDATE_ERROR",
            payload: newError
        });
    }
}

export function loginUser(userInfo){
    return async dispatch => {
        try{
            const jwtcookie = CookieUtility.getCookieValueByKey("JWT");
            if(jwtcookie){
                APIConnector.setBearerToken(jwtcookie);
                const loginUserDS = await APIConnector.validateJWT();
                const userinfo = await APIConnector.getMyUserInfo();
                const parsedLoginUserDS = JSON.parse(userinfo);
                dispatch({
                    type: "UPDATE_ERROR",
                    payload: {type:null, message:null}
                });
                dispatch({
                    type: "UPDATE_USERINFO",
                    payload: parsedLoginUserDS.dataset
                });
                dispatch({
                    type: "UPDATE_JWT",
                    payload: jwtcookie
                });
            }
            else if(userInfo){
                APIConnector.removeBearerToken();
                const loginUserDS = await APIConnector.loginUser(userInfo.email, userInfo.password);
                const parsedLoginUserDS = JSON.parse(loginUserDS);

                if(userInfo.rememberme){
                    window.localStorage.setItem('remember', 'true');
                    CookieUtility.setCookie("JWT", parsedLoginUserDS.jwt, parsedLoginUserDS.expires);
                }
                APIConnector.setBearerToken(parsedLoginUserDS.jwt);
                dispatch({
                    type: "UPDATE_ERROR",
                    payload: {type:null, message:null}
                });
                dispatch({
                    type: "UPDATE_USERINFO",
                    payload: parsedLoginUserDS.userProfile
                });
                dispatch({
                    type: "UPDATE_JWT",
                    payload: parsedLoginUserDS.jwt
                });
                window.localStorage.setItem('userloggedin', 'true');
            }
            else{
                APIConnector.removeBearerToken();
                window.localStorage.removeItem('userloggedin');
                window.localStorage.removeItem('remember');
                CookieUtility.deleteCookie("JWT");
            }
        }
        catch(exception){
            APIConnector.removeBearerToken();
            CookieUtility.deleteCookie("JWT");
            window.localStorage.removeItem('remember');
            window.localStorage.removeItem('userloggedin');

            dispatch({
                type: "UPDATE_ERROR",
                payload: {
                    type: 102,
                    message: 'Could Not Login'
                }
            });
        }
    }
}
export function logoutUser(redirectUrl){
    return dispatch => {
        try{
            APIConnector.removeBearerToken();
            CookieUtility.deleteCookie("JWT");
            window.localStorage.getItem('remember');
            window.localStorage.removeItem('userloggedin');
            dispatch({
                type: "UPDATE_ERROR",
                payload: {type:null, message:null}
            });
            dispatch({
                type: "UPDATE_USERINFO",
                payload: null
            });
            dispatch({
                type: "UPDATE_JWT",
                payload: null
            });
            if(redirectUrl && typeof redirectUrl==="string") window.location.href = redirectUrl;
        }
        catch(err){
            console.log(err);
        }
    }
}

export function clearSession(){
    APIConnector.removeBearerToken();
    CookieUtility.deleteCookie("JWT");
}

export function getAvailablePermissions(){
    return async dispatch => {
        try{
            const allPermissions = await APIConnector.getAvailablePermissions();
            const parsedPermissionsDS = JSON.parse(allPermissions);
            dispatch({
                type: "UPDATE_AVAILABLE_PERMISSIONS",
                payload: parsedPermissionsDS.dataset
            })
        }
        catch(exception){
            dispatch({
                type: "UPDATE_ERROR",
                payload: {
                    type: 102,
                    message: 'Could Not Fetch Available Permissions'
                }
            });
        }
    }
}

export function getRoles(id){
    return async dispatch => {
        try{
            const allRoles = await APIConnector.getRoles(id);
            const parsedRolesDS = JSON.parse(allRoles);
            dispatch({
                type: "UPDATE_ROLES",
                payload: parsedRolesDS.dataset
            })
        }
        catch(exception){
            dispatch({
                type: "UPDATE_ERROR",
                payload: {
                    type: 102,
                    message: 'Could Not Fetch Roles'
                }
            });
        }
    }
}

export function createRole(roleObj){
    return async dispatch => {
        try{
            const newRole = await APIConnector.createRole(JSON.stringify(roleObj));
            const parsedRolesDS = JSON.parse(newRole);
            // dispatch({
            //     type: "UPDATE_ROLES",
            //     payload: parsedRolesDS.dataset
            // });
        }
        catch(exception){
            dispatch({
                type: "UPDATE_ERROR",
                payload: {
                    type: 102,
                    message: 'Could Not Create Roles'
                }
            });
        }
    }
}

export function updateRole(roleObj){
    return async dispatch => {
        try{
            const updateRole = await APIConnector.updateRole(roleObj);
            return updateRole;
        }
        catch(exception){
            dispatch({
                type: "UPDATE_ERROR",
                payload: {
                    type: 102,
                    message: 'Could Not Update Role'
                }
            });
        }
    }
}

export function deleteRole(roleid){
    return async dispatch => {
        try{
            const deleteRole = await APIConnector.deleteRole(roleid);
            return deleteRole;
        }
        catch(exception){
            dispatch({
                type: "UPDATE_ERROR",
                payload: {
                    type: 102,
                    message: 'Could Not Update Role'
                }
            });
        }
    }
}

export function getPendingInvites(){
    return async dispatch => {
        try{
            const userInvites = await APIConnector.getUserInvites();
            const parsedInvites = JSON.parse(userInvites);
            dispatch({
                type: "UPDATE_PENDING_INVITES",
                payload: parsedInvites.invitations
            });
        }
        catch(exception){
            dispatch({
                type: "UPDATE_PENDING_INVITES",
                payload: null
            });
            dispatch({
                type: "UPDATE_ERROR",
                payload: {
                    type: 102,
                    message: 'Could Not Retrieve Pending Invites'
                }
            });
        }
    }
}

export function inviteUser(userObj){
    return async dispatch => {
        try{
            const userInvited = await APIConnector.inviteUser(userObj);
            return userInvited;
        }
        catch(exception){
            dispatch({
                type: "UPDATE_ERROR",
                payload: {
                    type: 102,
                    message: 'New User Could Not Be Invited'
                }
            });
        }
    }
}

export function getUsers(roleid, searchPattern){
    return async dispatch => {
        try{
            const searchedUser = await APIConnector.searchUser(roleid, searchPattern);
            return JSON.parse(searchedUser);
        }
        catch(exception){
            dispatch({
                type: "UPDATE_ERROR",
                payload: {
                type: 102,
                message: 'Users could not be searched'
                }
            });
        }
    }
}

export function cancelInvitation(invitationID){
    return async dispatch => {
        try{
            const invitationCancellation = await APIConnector.cancelInvitation(invitationID);
            return invitationCancellation;
        }
        catch(exception){
            dispatch({
                type: "UPDATE_ERROR",
                payload: {
                    type: 102,
                    message: 'Invitation Could Not Be Cancelled'
                }
            });
        }
    }
}

export function getLoggedInUserInfo(){
    return async dispatch => {
        try{
            const userinfo = await APIConnector.getMyUserInfo();
            const parsedLoginUserDS = JSON.parse(userinfo);
            dispatch({
                type: "UPDATE_ERROR",
                payload: {type:null, message:null}
            });
            dispatch({
                type: "UPDATE_USERINFO",
                payload: parsedLoginUserDS.dataset
            });
        }
        catch(exception){
            dispatch({
                type: "UPDATE_ERROR",
                payload: {
                    type: 102,
                    message: 'Could Not Get Userr Info'
                }
            });
        }
    }
}

export function setDefaultOrganization(orgid){
    return APIConnector.setDefaultOrganization(orgid);
}

export function createUser(userObj){
    return APIConnector.createUser(userObj);
}

export function updateUserRole(userRoleObj){
    return APIConnector.updateUserRole(userRoleObj);
}

export function requestPasswordReset(email){
    return APIConnector.requestPasswordReset(email);
}

export function updateUserPassword(email, oldPassword, newPassword, key=false){
    return APIConnector.updateUserPassword(email, oldPassword, newPassword, key);
}

export function getUserProfile(){
    return APIConnector.getUserProfile();
}

export function getLanguages(){
    return async dispatch => {
        try{
            const allLanguages = await APIConnector.getLanguages();
            const parsedLanguagesDS = JSON.parse(allLanguages);
            dispatch({
                type: "UPDATE_LANGUAGES",
                payload: parsedLanguagesDS.dataset
            })
        }
        catch(exception){
            dispatch({
                type: "UPDATE_ERROR",
                payload: {
                    type: 102,
                    message: 'Could Not Fetch Languages'
                }
            });
        }
    }
}

export function getProficiency(){
    return async dispatch => {
        try{
            const allProficiencies = await APIConnector.getProficiency();
            const parsedProficiencyDS = JSON.parse(allProficiencies);
            dispatch({
                type: "UPDATE_PROFICIENCY",
                payload: parsedProficiencyDS.dataset
            })
        }
        catch(exception){
            dispatch({
                type: "UPDATE_ERROR",
                payload: {
                    type: 102,
                    message: 'Could Not Fetch Proficiencies'
                }
            });
        }
    }
}

export function getSkills(){
    return async dispatch => {
        try{
            const allSkills = await APIConnector.getSkills();
            const parsedSkillsDS = JSON.parse(allSkills);
            dispatch({
                type: "UPDATE_SKILLS",
                payload: parsedSkillsDS.dataset
            })
        }
        catch(exception){
            dispatch({
                type: "UPDATE_ERROR",
                payload: {
                    type: 102,
                    message: 'Could Not Fetch Available Item Skills'
                }
            });
        }
    }
}

export function getTags(searchPattern, id){
    return async dispatch => {
        try{
            const allTags = await APIConnector.getTags(searchPattern, id);
            const parsedTagsDS = JSON.parse(allTags);
            dispatch({
                type: "UPDATE_TAGS",
                payload: parsedTagsDS.dataset
            })
            return parsedTagsDS;
        }
        catch(exception){
            dispatch({
                type: "UPDATE_ERROR",
                payload: {
                    type: 102,
                    message: 'Could Not Fetch Tags'
                }
            });
        }
    }
}

export function createTag(tagObj){
    return async dispatch => {
        try{
            const newTag = await APIConnector.createTag(tagObj);
            const parsedTagDS = JSON.parse(newTag);
            dispatch({
                type: "UPDATE_TAGS",
                payload: parsedTagDS.tag,
                status: "new"
            });
            return parsedTagDS;
        }
        catch(exception){
            dispatch({
                type: "UPDATE_ERROR",
                payload: {
                    type: 102,
                    message: 'Could Not Create Tag'
                }
            });
            throw new Error('Tag could Not Be Created')
        }
    }
}

export function updateTag(updatedTagObj){
    return async dispatch => {
        try{
            const updatedTag = await APIConnector.updateTag(updatedTagObj);
            const parsedTagDS = JSON.parse(updatedTag);
            dispatch({
                type: "UPDATE_TAGS",
                payload: parsedTagDS.tag,
                status: "update"
            });
            return parsedTagDS;
        }
        catch(exception){
            dispatch({
                type: "UPDATE_ERROR",
                payload: {
                    type: 102,
                    message: 'Could Not Update Tag'
                }
            });
            throw new Error('Tag could Not Be updated')
        }
    }
}

export function deleteTag(tagid){
    return async dispatch => {
        try{
            const tagDeleted = await APIConnector.deleteTag(tagid);
            dispatch({
                type: "UPDATE_TAGS",
                payload: {id: tagid},
                status: "delete"
            });
            return tagDeleted;
        }
        catch(exception){
            dispatch({
                type: "UPDATE_ERROR",
                payload: {
                    type: 102,
                    message: 'Could Not Delete Tag'
                }
            });
        }
    }
}

export function createItem(itemObj){
    return async dispatch => {
        try{
            const itemInserted = await APIConnector.createItem(itemObj);
            dispatch({
                type: "UPDATE_ITEMS",
                payload: JSON.parse(itemInserted).dataset,
                status: "new"
            });
            return itemInserted;
        }
        catch(exception){
            dispatch({
                type: "UPDATE_ERROR",
                payload: {
                    type: 102,
                    message: 'Could Not Create Item'
                }
            });
            throw new Error('Item Could Not Be Created');
        }
    }
}

export function updateItem(itemObj){
    return async dispatch => {
        try{
            const itemUpdated = await APIConnector.updateItem(itemObj);
            dispatch({
                type: "UPDATE_ITEMS",
                payload: JSON.parse(itemUpdated).dataset,
                status: "update"
            });
            return itemUpdated;
        }
        catch(exception){
            dispatch({
                type: "UPDATE_ERROR",
                payload: {
                    type: 102,
                    message: 'Could Not Update Item'
                }
            });
            throw new Error('Item Could Not Be Updated');
        }
    }
}

export function archiveItem(iteminfo){
    return async dispatch => {
        try{
            const itemArchived = await APIConnector.archiveItem(iteminfo.id);
            dispatch({
                type: "UPDATE_ITEMS",
                payload: {id: iteminfo.id},
                status: "delete"
            });
            return itemArchived;
        }
        catch(exception){
            dispatch({
                type: "UPDATE_ERROR",
                payload: {
                    type: 102,
                    message: 'Could Not Archive Item'
                }
            });
            throw new Error('Item Could Not Be Archived');
        }
    }
}

export function getItems(itemObj){
    return async dispatch => {
        try{
            const items = await APIConnector.getItems(itemObj);
            const parsedItemsDS = JSON.parse(items);
            dispatch({
                type: "UPDATE_ITEMS",
                payload: parsedItemsDS.dataset
            });
            return parsedItemsDS;
        }
        catch(exception){
            dispatch({
                type: "UPDATE_ERROR",
                payload: {
                    type: 102,
                    message: 'Could Not Get Items'
                }
            });
        }
    }
}

export function getItemStatuses(){
    return async dispatch => {
        try{
            const itemStatuses = await APIConnector.getItemStatuses();
            const parsedItemStatusesDS = JSON.parse(itemStatuses);
            dispatch({
                type: "UPDATE_STATUSES",
                payload: parsedItemStatusesDS.dataset,
                statustype: "items"
            })
        }
        catch(exception){
            dispatch({
                type: "UPDATE_ERROR",
                payload: {
                    type: 102,
                    message: 'Could Not Fetch Items Statuses'
                }
            });
        }
    }
}


export function getItemHistory(id){
    return async dispatch => {
        try{
            const items = await APIConnector.getItemHistory(id);
            const parsedItemsDS = JSON.parse(items);
            return parsedItemsDS;
        }
        catch(exception){
            dispatch({
                type: "UPDATE_ERROR",
                payload: {
                    type: 102,
                    message: 'Could not get Item History'
                }
            });
        }
    }
}

export function getItemVersion(id){
    return async dispatch => {
        try{
            const items = await APIConnector.getItemVersion(id);
            const parsedItemsDS = JSON.parse(items);
            return parsedItemsDS;
        }
        catch(exception){
            dispatch({
                type: "UPDATE_ERROR",
                payload: {
                    type: 102,
                    message: 'Could not get Item Version'
                }
            });
        }
    }
}

export function getItemInfo(itemObj){
    return async dispatch => {
        try{
            const items = await APIConnector.getItems(itemObj);
            const parsedItemDS = JSON.parse(items);
            dispatch({
                type: "UPDATE_ITEMS",
                payload: parsedItemDS.dataset
            });
            return parsedItemDS.dataset;
        }
        catch(exception){
            dispatch({
                type: "UPDATE_ERROR",
                payload: {
                    type: 102,
                    message: 'Could Not Retrieve Item Info'
                }
            });
        }
    }
}

export function getMediaSchema(){
    return async dispatch => {
        try{
            const mediaSchema = await APIConnector.getMediaSchema();
            const parsedMediaDS = JSON.parse(mediaSchema);
            dispatch({
                type: "UPDATE_MEDIA_SCHEMA",
                payload: parsedMediaDS.dataset
            })
            return parsedMediaDS;
        }
        catch(exception){
            dispatch({
                type: "UPDATE_ERROR",
                payload: {
                    type: 102,
                    message: 'Could Not Fetch Media Schema'
                }
            });
        }
    }
}

export function getMediaAssets(searchPattern, id, type){
    return async dispatch => {
        try{
            const paramObject = {
                pattern: searchPattern,
                id,
                type
            }
            const mediaAssets = await APIConnector.getMediaAssets(paramObject);
            const parsedMediaDS = JSON.parse(mediaAssets);
            dispatch({
                type: "UPDATE_MEDIA_ASSETS",
                payload: parsedMediaDS.dataset
            })
            return parsedMediaDS;
        }
        catch(exception){
            dispatch({
                type: "UPDATE_ERROR",
                payload: {
                    type: 102,
                    message: 'Could Not Fetch Media Assets'
                }
            });
        }
    }
}

export function uploadMedia(formData){
    return async dispatch => {
        try{
            const mediaUploaded = await APIConnector.uploadMediaAsset(formData);
            return mediaUploaded;
        }
        catch(exception){
            dispatch({
                type: "UPDATE_ERROR",
                payload: {
                    type: 102,
                    message: 'Could Not Upload Media'
                }
            });
        }
    }
}

export function updateMedia(mediaobj){
    return async dispatch => {
        try{
            const mediaUpdated = await APIConnector.updateMediaAsset(mediaobj);
            dispatch({
                type: "UPDATE_MEDIA_ASSETS",
                payload: [mediaobj],
                status: "update"
            });
            return mediaUpdated;
        }
        catch(exception){
            dispatch({
                type: "UPDATE_ERROR",
                payload: {
                    type: 102,
                    message: 'Could Not Update Media Title'
                }
            });
        }
    }
}

export function archiveMedia(mediaid){
    return async dispatch => {
        try{
            const mediaDeleted = await APIConnector.archiveMediaAsset(mediaid);
            dispatch({
                type: "UPDATE_MEDIA_ASSETS",
                payload: [{id: mediaid}],
                status: "delete"
            });
            return mediaDeleted;
        }
        catch(exception){
            dispatch({
                type: "UPDATE_ERROR",
                payload: {
                    type: 102,
                    message: 'Could Not Archive Media'
                }
            });
        }
    }
}

export function getCollections(collectionObj){
    return async dispatch => {
        try{
            const collections = await APIConnector.getCollections(collectionObj);
            const parsedCollectionsDS = JSON.parse(collections);
            dispatch({
                type: "UPDATE_COLLECTIONS",
                payload: parsedCollectionsDS.dataset
            });
            return parsedCollectionsDS;
        }
        catch(exception){
            dispatch({
                type: "UPDATE_ERROR",
                payload: {
                    type: 102,
                    message: 'Could Not Fetch Collections'
                }
            });
        }
    }
}

export function getCollectionStatuses(){
    return async dispatch => {
        try{
            const collectionStatuses = await APIConnector.getCollectionStatuses();
            const parsedCollectionStatusesDS = JSON.parse(collectionStatuses);
            dispatch({
                type: "UPDATE_STATUSES",
                payload: parsedCollectionStatusesDS.dataset,
                statustype: "collections"
            })
        }
        catch(exception){
            dispatch({
                type: "UPDATE_ERROR",
                payload: {
                    type: 102,
                    message: 'Could Not Fetch Collection Statuses'
                }
            });
        }
    }
}

export function createCollection(collectionObj){
    return async dispatch => {
        try{
            const collectionInserted = await APIConnector.createCollection(collectionObj);
            const createdDS = JSON.parse(collectionInserted);
            dispatch({
                type: "UPDATE_COLLECTIONS",
                payload: createdDS.dataset,
                status: "new"
            });
            return collectionInserted;
        }
        catch(exception){
            dispatch({
                type: "UPDATE_ERROR",
                payload: {
                    type: 102,
                    message: 'Could Not Create Collection'
                }
            });
            throw new Error('Collection Could Not Be Created');
        }
    }
}

export function updateCollection(collectionObj){
    return async dispatch => {
        try{
            const collectionUpdated = await APIConnector.updateCollection(collectionObj);
            const updatedDS = JSON.parse(collectionUpdated);
            dispatch({
                type: "UPDATE_COLLECTIONS",
                payload: updatedDS.dataset,
                status: "update"
            });
            return collectionUpdated;
        }
        catch(exception){
            dispatch({
                type: "UPDATE_ERROR",
                payload: {
                    type: 102,
                    message: 'Could Not Update Collection'
                }
            });
            throw new Error('Collection Could Not Be Updated');
        }
    }
}

export function archiveCollection(collectioninfo){
    return async dispatch => {
        try{
            const collectionArchived = await APIConnector.archiveCollection(collectioninfo.id);
            dispatch({
                type: "UPDATE_COLLECTIONS",
                payload: {id: collectioninfo.id},
                status: "delete"
            });
            return collectionArchived;
        }
        catch(exception){
            dispatch({
                type: "UPDATE_ERROR",
                payload: {
                    type: 102,
                    message: 'Could Not Archive Collection'
                }
            });
            throw new Error('Collection Could Not Be Archived');
        }
    }
}

export function uploadAutoGradingAudio(formData){
    return async dispatch => {
        try{
            const audioUploaded = await APIConnector.uploadAutoGradingAudio(formData);
            return audioUploaded;
        }
        catch(exception){
            dispatch({
                type: "UPDATE_ERROR",
                payload: {
                    type: 102,
                    message: 'Could Not Upload Audio'
                }
            });
        }
    }
}

export function checkTextAutoGrading(autoGradingTextObj){
    return async dispatch => {
        try{
            const textGrades = await APIConnector.checkTextAutoGrading(autoGradingTextObj);
            return textGrades;
        }
        catch(exception){
            dispatch({
                type: "UPDATE_ERROR",
                payload: {
                    type: 102,
                    message: 'Could Not Grade Text'
                }
            });
        }
    }
}

export function checkAudioAutoGrading(autoGradingAudioTokenObj){
    return async dispatch => {
        try{
            const audioGrades = await APIConnector.checkAudioAutoGrading(autoGradingAudioTokenObj);
            return audioGrades;
        }
        catch(exception){
            dispatch({
                type: "UPDATE_ERROR",
                payload: {
                    type: 102,
                    message: 'Could Not Get Audio Grades'
                }
            });
        }
    }
}

export function getEvents(eventObj){
    return async dispatch => {
        try{
            const testevents = await APIConnector.getEvents(eventObj);
            const parsedEventDS = JSON.parse(testevents);
            dispatch({
                type: "UPDATE_EVENTS",
                payload: parsedEventDS.dataset,
                status: "new"
            });
            return parsedEventDS;
        }
        catch(exception){
            dispatch({
                type: "UPDATE_ERROR",
                payload: {
                    type: 102,
                    message: 'Could Not Fetch Events'
                }
            });
        }
    }
}

export function generateEventFile(eventObj){
    return async dispatch => {
        try{
            const testeventfile = await APIConnector.generateEventFile(eventObj);
            return JSON.parse(testeventfile);
        }
        catch(exception){
            dispatch({
                type: "UPDATE_ERROR",
                payload: {
                    type: 102,
                    message: 'Could Not Fetch Event File'
                }
            });
        }
    }
}

export function getEventStatuses(){
    return async dispatch => {
        try{
            const testeventStatuses = await APIConnector.getEventStatuses();
            const parsedEventStatusesDS = JSON.parse(testeventStatuses);
            dispatch({
                type: "UPDATE_STATUSES",
                payload: parsedEventStatusesDS.dataset,
                statustype: "testevents"
            })
        }
        catch(exception){
            dispatch({
                type: "UPDATE_ERROR",
                payload: {
                    type: 102,
                    message: 'Could Not Fetch Test Event Statuses'
                }
            });
        }
    }
}

export function createEvent(eventObj){
    return async dispatch => {
        try{
            const eventInserted = await APIConnector.createEvent(eventObj);
            const createdDS = JSON.parse(eventInserted);
            dispatch({
                type: "UPDATE_EVENTS",
                payload: createdDS.dataset,
                status: "new"
            });
            return eventInserted;
        }
        catch(exception){
            console.log(exception);
            dispatch({
                type: "UPDATE_ERROR",
                payload: {
                    type: 102,
                    message: 'Could Not Create Event'
                }
            });
            throw new Error('Event Could Not Be Created');
        }
    }
}

export function updateEvent(eventObj){
    return async dispatch => {
        try{
            const eventUpdated = await APIConnector.updateEvent(eventObj);
            const updatedDS = JSON.parse(eventUpdated);
            dispatch({
                type: "UPDATE_EVENTS",
                payload: updatedDS.dataset,
                status: "update"
            });
            return eventUpdated;
        }
        catch(exception){
            dispatch({
                type: "UPDATE_ERROR",
                payload: {
                    type: 102,
                    message: 'Could Not Update Events'
                }
            });
            throw new Error('Event Could Not Be Updated');
        }
    }
}

export function archiveEvent(eventinfo){
    return async dispatch => {
        try{
            const eventArchived = await APIConnector.archiveEvent(eventinfo.id);
            dispatch({
                type: "UPDATE_EVENTS",
                payload: {id: eventinfo.id},
                status: "delete"
            });
            return eventArchived;
        }
        catch(exception){
            dispatch({
                type: "UPDATE_ERROR",
                payload: {
                    type: 102,
                    message: 'Could Not Archive Event'
                }
            });
            throw new Error('Event Could Not Be Archived');
        }
    }
}

export function getScores(scoreObj){
    return async dispatch => {
        try{
            const scores = await APIConnector.getScores(scoreObj);
            const parsedScoreDS = JSON.parse(scores);
            dispatch({
                type: "UPDATE_SCORES",
                payload: parsedScoreDS.dataset
            });
            return parsedScoreDS;
        }
        catch(exception){
            dispatch({
                type: "UPDATE_ERROR",
                payload: {
                    type: 102,
                    message: 'Could Not Fetch Scores'
                }
            });
        }
    }
}

export function getItemsReport(itemsObj){
    return async dispatch => {
        try{
            const scores = await APIConnector.getItemsReport(itemsObj);
            const parsedScoreDS = JSON.parse(scores);
            dispatch({
                type: "UPDATE_ITEMS_REPORT",
                payload: parsedScoreDS.dataset
            });
            return parsedScoreDS;
        }
        catch(exception){
            dispatch({
                type: "UPDATE_ERROR",
                payload: {
                    type: 102,
                    message: 'Could Not Fetch Scores'
                }
            });
        }
    }
}

export function getTestletsReport(testletObj){
    return async dispatch => {
        try{
            const scores = await APIConnector.getTestletsReport(testletObj);
            const parsedScoreDS = JSON.parse(scores);
            dispatch({
                type: "UPDATE_TESTLETS_REPORT",
                payload: parsedScoreDS.dataset
            });
            return parsedScoreDS;
        }
        catch(exception){
            dispatch({
                type: "UPDATE_ERROR",
                payload: {
                    type: 102,
                    message: 'Could Not Fetch Scores'
                }
            });
        }
    }
}

export function getOrganizationStatuses(){
    return async dispatch => {
        try{
            const allOrganizationStatuses = await APIConnector.getOrganizationStatuses();
            const parsedOrgStatusesDS = JSON.parse(allOrganizationStatuses);
            dispatch({
                type: "UPDATE_STATUSES",
                payload: parsedOrgStatusesDS.dataset,
                statustype: "organizations"
            });
        }
        catch(exception){
            dispatch({
                type: "UPDATE_ERROR",
                payload: {
                    type: 102,
                    message: 'Could Not Fetch Organization Statuses'
                }
            });
        }
    }
}

export function getOrganizations(searchparam){
    return async dispatch => {
        try{
            const allOrganizations = await APIConnector.getOrganizations(searchparam);
            const parsedOrgDS = JSON.parse(allOrganizations);
            dispatch({
                type: "UPDATE_ORGANIZATIONS",
                payload: parsedOrgDS.dataset
            });
            return parsedOrgDS;
        }
        catch(exception){
            dispatch({
                type: "UPDATE_ERROR",
                payload: {
                    type: 102,
                    message: 'Could Not Fetch Organizations'
                }
            });
        }
    }
}

export function createOrganization(orgformdata, orgobject){
    return async dispatch => {
        try{
            const orgInserted = await APIConnector.createOrganization(orgformdata);
            const createdDS = JSON.parse(orgInserted);
            dispatch({
                type: "UPDATE_ORGANIZATIONS",
                payload: orgobject,
                status: "new"
            });
            return orgInserted;
        }
        catch(exception){
            console.log(exception);
            dispatch({
                type: "UPDATE_ERROR",
                payload: {
                    type: 102,
                    message: 'Could Not Create Organization'
                }
            });
            throw new Error('Organization Could Not Be Created');
        }
    }
}

export function updateOrganization(orgformdata, orgobject){
    return async dispatch => {
        try{
            const orgUpdated = await APIConnector.updateOrganization(orgformdata);
            const updatedDS = JSON.parse(orgUpdated);
            dispatch({
                type: "UPDATE_ORGANIZATIONS",
                payload: orgobject,
                status: "update"
            });
            return orgUpdated;
        }
        catch(exception){
            dispatch({
                type: "UPDATE_ERROR",
                payload: {
                    type: 102,
                    message: 'Could Not Update Organization'
                }
            });
            throw new Error('Organization Could Not Be Updated');
        }
    }
}

export function archiveOrganization(orgid){
    return async dispatch => {
        try{
            const orgArchived = await APIConnector.archiveOrganization(orgid);
            dispatch({
                type: "UPDATE_ORGANIZATIONS",
                payload: {id: orgid},
                status: "delete"
            });
            return orgArchived;
        }
        catch(exception){
            dispatch({
                type: "UPDATE_ERROR",
                payload: {
                    type: 102,
                    message: 'Could Not Archive Organization'
                }
            });
            throw new Error('Organization Could Not Be Archived');
        }
    }
}

export function getEventTemplate(){
    return async dispatch => {
        try{
            const eventtemplate = await APIConnector.getEventTemplate();
            const parsedeventtemplate = JSON.parse(eventtemplate);
            dispatch({
                type: "UPDATE_EVENT_TEMPLATE",
                payload: parsedeventtemplate.dataset
            });
            return parsedeventtemplate;
        }
        catch(exception){
            dispatch({
                type: "UPDATE_ERROR",
                payload: {
                    type: 102,
                    message: 'Could Not Fetch EventTemplate'
                }
            });
        }
    }
}

export function getNextItemNumber(itemdata){
    return async dispatch => {
        try{
            const itemNum = await APIConnector.getNextItemNumber(itemdata);
            const updatedDS = JSON.parse(itemNum);
            dispatch({
                type: "UPDATE_ITEM_NUMBER",
                payload: updatedDS.dataset,
            });
            return updatedDS.dataset;
        }
        catch(exception){
            dispatch({
                type: "UPDATE_ERROR",
                payload: {
                    type: 102,
                    message: 'Could Not Retrieve Item Info'
                }
            });
        }
    }
}