import React from 'react';

import mapStateToProps from '../../datastore/mapper.js';
import mapDispatchToProps from '../../datastore/dispatcher.js';

import {connect} from 'react-redux';

import { Segment, Button, Icon, Popup, Dropdown, Label, Divider, Message } from 'semantic-ui-react';
import autoBind from 'react-autobind';

import GeneralUtility from '../../utilities/generalUtility.js';

import METADATA from '../../utilities/metadata.json';

const __FEATURES__ = METADATA.FEATURES;
const availableFeatures = METADATA.AVAILABLEFEATURES;
const textEditorOptions = METADATA.TEXTEDITOR_TYPES;
const optionSelectorType = METADATA.OPTIONSELECTOR_TYPES;

export class FeaturesToolbar extends React.Component{
    constructor(props){
        super();

        this.state={
            showToolbarContainer:true
        }

        autoBind(this);
    }

    addFeatureToItem(featureToAdd){
        const selectedFeature = availableFeatures[featureToAdd];
        const _uuid4 = GeneralUtility.generateUUIDV4();
        const newFeature = {
            id: _uuid4,
            type: selectedFeature==="image" || selectedFeature==="video" || selectedFeature==="audio" ? "media" : selectedFeature,
            allowUserInput: false,
            content: {}
        }

        if(selectedFeature==='texteditor'){
            newFeature.content["status"] = textEditorOptions[0].value;
            newFeature.content["html"] = "";
        }
        else if(selectedFeature==="optionselector"){
            const optionSet = [...new Array(4)].map((_, index)=>{
                const optionID = GeneralUtility.generateUUIDV4();
                return {
                    id: optionID,
                    text: `Option${index}`,
                    isCorrect: false
                }
            });
            optionSet.push({id: "idontknow", text: "I Don't Know", isCorrect: true});

            newFeature.content = {
                type: optionSelectorType[0].value,
                options: optionSet
            }
        }
        else if(selectedFeature==="image" || selectedFeature==="audio" || selectedFeature==="video"){
            newFeature.content.type = selectedFeature;
            newFeature.content.filename = null;
            newFeature.content.id = null;
            newFeature.content.for = "passage";
        }

        this.props.addFeatureToItem(newFeature);
        this.setState(
            {featureToAdd: null},
            ()=>{
                window.scrollTo(0,document.body.scrollHeight);
            }
        );
    }

    toggleToolbarContainer(){
        this.setState((prevState)=>({showToolbarContainer: !prevState.showToolbarContainer}));
    }

    render(){

        return(
            
            <Segment raised>
                {this.state.showToolbarContainer ?
                    <Label color="blue" ribbon>Add Features</Label>
                :null}
                <Divider hidden/>
                <Popup content={this.state.showToolbarContainer?'Minimize Toolset':'Maximize Toolset'} trigger={
                    <Label size='small' attached="top right" as='a' onClick={this.toggleToolbarContainer}><Icon name={this.state.showToolbarContainer?'window minimize':'window maximize outline'}/></Label>
                }/>
                {this.props.error && this.props.error.type==='feature' &&
                    <Message negative>
                        <p id="feature-error-message">{this.props.error.message}</p>
                    </Message>
                }
                {this.state.showToolbarContainer ?
                    <React.Fragment>
                        <div className="items_features_container">
                            {__FEATURES__.map((feature,index)=>{
                                if(index===3){
                                    return(
                                        <div key={`features-mediafile`} className="items_features">
                                            <Popup content={`Add Media Files To This Item`} trigger={
                                                <Dropdown
                                                    text={"Media"}
                                                    icon='images'
                                                    labeled
                                                    button
                                                    className='icon'
                                                >
                                                    <Dropdown.Menu>
                                                        <Dropdown.Item onClick={()=>this.addFeatureToItem(index)} icon='image' text='Image' />
                                                        <Dropdown.Item onClick={()=>this.addFeatureToItem(index + 1)} icon='video' text='Video' />
                                                        <Dropdown.Item onClick={()=>this.addFeatureToItem(index + 2)} icon='volume up' text='Audio' />
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            }/>
                                        </div>
                                    )
                                }
                                else{
                                    return (
                                        <div key={`featuress-${index}`} className="items_features">
                                            <Popup content={`Add ${feature.name} To This Item`} trigger={
                                                <Button onClick={()=>this.addFeatureToItem(index)} icon>
                                                    <Icon name={feature.icon} />
                                                </Button>
                                            }/>
                                        </div>
                                    )
                                }
                            })}
                        </div>
                    </React.Fragment>
                :
                    <React.Fragment>
                        <Label size='small' attached="top left">Toolbar</Label>
                    </React.Fragment>
                }
            </Segment>
        )
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(FeaturesToolbar);