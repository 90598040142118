import React from 'react';

import mapStateToProps from '../../datastore/mapper.js';
import mapDispatchToProps from '../../datastore/dispatcher.js';

import {connect} from 'react-redux';

import {withRouter} from 'react-router-dom';
import { Message, Button, Grid, Header, Input, Form } from 'semantic-ui-react';

import autoBind from 'react-autobind';

export class PasswordReset extends React.Component {

    constructor(props){
        super();
        this.state = {
            email: props.match.params.email || props.email,
            key: props.match.params.key,
            oldPassword: "",
            newPassword: "",
            repeatpassword: "",
            formError: null,
        }

        autoBind(this);
    }

    componentDidMount(){
        if(this.props.appProps.user && !this.props.noLogout){
            this.props.logoutUser();
        }
        else if(!this.props.noLogout){
            this.props.clearSession();
        }
    }

    componentDidUpdate(){
        if(this.props.appProps.user && !this.props.noLogout){
            this.props.logoutUser();
        }
    }

    setOldPassword(event){
        const pass = event.target.value;
        this.setState((prevState)=>({
            oldPassword: pass,
            formError: pass=="" ? {type: 2, message: 'Please enter your old password'} : null
        }));
    }

    setNewPassword(event){
        const pass = event.target.value;
        this.setState((prevState)=>({
            newPassword: pass,
            formError: pass!==prevState.repeatpassword ? {type: 1, message: 'Passwords Must Match'} : null
        }));
    }

    setRepeatPassword(event){
        const repeatpass = event.target.value;
        this.setState((prevState)=>({
            repeatpassword: repeatpass,
            formError: repeatpass!==prevState.newPassword ? {type: 1, message: 'Passwords Must Match'} : null
        }));
    }

    submit(){
        if(!this.state.formError && this.state.newPassword !== "" && (this.state.key !== "" || this.state.oldPassword != "")){
            this.props.updateUserPassword(this.state.email, this.state.oldPassword, this.state.newPassword, this.state.key)
                .then(data=>{
                    this.setState({formError: {type: 0, message: 'Success'}});
                },
                error=>{
                    this.setState({formError: {type: -1, message: error.message}});
                });
        }
        else{
            this.setState({formError: {type: 2, message: 'Please Fill Out All Fields'}});
        }
    }

    redirectToLogin(){
        window.location.href = "/";
    }

    render(){

        const form = ( 
            <>
                {this.props.showHeader !== false && 
                    <Header className="registerHeader" as='h2'>
                        Proficiency Placement
                    </Header>
                }
                {this.state.formError && this.state.formError.type===0 ?
                    <React.Fragment>
                        <Message
                            success
                            header='Password update was successful'
                            content='You may now login with your new password'
                        />
                        {!this.props.noLogout &&
                            <Button color="blue" onClick={this.redirectToLogin}>Click Here To Go To Login Page</Button>
                        }
                    </React.Fragment>
                :
                    <React.Fragment>
                        <p>Reset Password for: {this.state.email}</p>
                        {this.state.formError && (this.state.formError.type>0 || this.state.formError.type===-1) &&
                            <>
                                <Message negative>
                                    <Message.Header>Form Error</Message.Header>
                                    <p>{this.state.formError.message}</p>
                                </Message>
                            </>
                        }
                        <Form>
                            {!this.state.key &&
                                <Form.Field>
                                    <label className="registerLabel">Enter Old Password</label>
                                    <Input value={this.state.oldPassword} onChange={this.setOldPassword} type='password' />
                                </Form.Field>
                            }
                            <Form.Field>
                                <label className="registerLabel">Enter New Password</label>
                                <Input value={this.state.newPassword} onChange={this.setNewPassword} type='password' />
                            </Form.Field>
                            <Form.Field>
                                <label className="registerLabel">Repeat New Password</label>
                                <Input
                                    value={this.state.repeatpassword}
                                    onChange={this.setRepeatPassword}
                                    type='password'
                                    error={this.state.formError && this.state.formError.type===1}
                                />
                            </Form.Field>
                            <Button type='submit' onClick={this.submit}>Submit</Button>
                        </Form>
                    </React.Fragment>
                }
            </>
        )

        return(
            <>
                {this.props.showHeader !== false ?
                    <Grid className="registerContainer">
                        <Grid.Column className="registColumn">
                            {form}
                        </Grid.Column>
                    </Grid>
                    :
                    form
                }
            </>
        );
    }
}

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(PasswordReset));