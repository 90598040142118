import React from 'react';

import { Checkbox, Input, Button, Divider, Table, Dropdown, Segment, Popup, Header, Icon, Message, Grid } from 'semantic-ui-react';

import autoBind from 'react-autobind';

import GeneralUtility from '../../utilities/generalUtility.js';

export class RatingsManager extends React.Component{
    constructor(props){
        super();
        this.state={
            currentratings: [],
            viewinggroup: null,
            ismodified: false
        };
        autoBind(this);
    }

    componentDidMount(){
        this.setState({
            currentratings: JSON.parse(JSON.stringify(this.props.ratings)), //This is stupid, but works
            viewinggroup: null,
            ismodified: false
        });
    }

    componentDidUpdate(){
        if(this.props.ratings.length && !this.state.currentratings.length){
            this.setState({
                currentratings: JSON.parse(JSON.stringify(this.props.ratings)),
                viewinggroup: null,
                ismodified: false
            });
        }
    }

    addNewRating(){
        const newrating = {
            id: GeneralUtility.generateUUIDV4(),
            floor: "",
            ceiling: "",
            scores: [{
                id: GeneralUtility.generateUUIDV4(),
                min: 0,
                max: 0,
                group: this.state.viewinggroup
            }],
            cumulative: false,
            group: this.state.viewinggroup
        };
        
        const modifiedcurrentratings = this.state.currentratings;
        modifiedcurrentratings.push(newrating);

        this.setState({currentratings: modifiedcurrentratings, ismodified: true});
    }

    removeRating(_id){
        const filteredrating = this.state.currentratings.filter(rating=>rating.id!==_id);
        this.setState({currentratings: filteredrating, ismodified: true});
    }

    updateRating(event, data){
        if(event) event.preventDefault();

        const inputval = event.target.value;
        const forprop = data && data.forprop ? data.forprop : event.target.getAttribute("forprop");

        const ratingid = forprop==="cumulative" || forprop==="group" ? data.ratingid : event.target.getAttribute("ratingid");

        var newval;
        if(forprop==="cumulative"){
            newval = data.checked;
        }
        else if(forprop==="group"){
            newval = data.value;
        }
        else{
            newval = inputval;
        }

        const modifiedratings = this.state.currentratings
        modifiedratings.forEach(rating=>{
            if(rating.id===ratingid){
                rating[forprop] = newval;
            }
        });

        this.setState({currentratings: modifiedratings, ismodified: true});
    }

    updateRatingScore(event, data){
        if(event) event.preventDefault();

        const inputval = event.target.value;
        const forprop = data && data.forprop ? data.forprop : event.target.getAttribute("forprop");

        const ratingid = forprop==="group" ? data.ratingid : event.target.getAttribute("ratingid");
        const scoreid = forprop==="group" ? data.scoreid : event.target.getAttribute("scoreid");

        var newval;
        if(forprop==="group"){
            newval = data.value;
        }
        else{
            newval = inputval;
        }

        const modifiedratings = this.state.currentratings
        modifiedratings.forEach(rating=>{
            if(rating.id===ratingid){
                rating.scores.forEach(score=>{
                    if(score.id===scoreid){
                        score[forprop] = newval;
                    }
                });
            }
        });

        this.setState({currentratings: modifiedratings, ismodified: true});
    }

    resetChanges(){
        this.setState({currentratings: JSON.parse(JSON.stringify(this.props.ratings)), ismodified: false});
    }

    selectGroupToFilterBy(event, data){
        this.setState({viewinggroup: data.value});
    }

    saveCurrentRatings(){
        this.props.updateGraphMetaInfo({
            ratings: JSON.parse(JSON.stringify(this.state.currentratings))
        }, ()=>{this.setState({ismodified: false})});
    }

    render(){

        const {groups} = this.props;

        return(
            <React.Fragment>
                <Segment raised attached>
                    <Grid>
                        <Grid.Row>
                            <Grid.Column width={1}>
                                {this.state.viewinggroup && this.state.viewinggroup!=="all"?
                                    <Popup content='Add Rating' trigger={
                                        <Icon onClick={this.addNewRating} link size="big" name="plus circle" color="green"/>
                                    }/>
                                :null}
                            </Grid.Column>
                            <Grid.Column width={15}>
                                <Dropdown
                                    value={this.state.viewinggroup}
                                    onChange={this.selectGroupToFilterBy}
                                    floating
                                    search
                                    selection
                                    clearable
                                    placeholder='Select A Group To View'
                                    options={groups.map((group,_groupindex)=>{
                                        return {
                                            key: group.id,
                                            value: group.id,
                                            text: group.title
                                        }
                                    })}
                                />
                            </Grid.Column>
                        </Grid.Row>
                        {this.state.currentratings && this.state.currentratings.length ?
                        this.state.currentratings
                        .filter(rating=>this.state.viewinggroup && rating.group===this.state.viewinggroup)
                        .map(rating=>{
                            return (
                            <Grid.Row>
                                <Grid.Column width={1}>
                                    <Popup content='Remove This Rating' trigger={
                                        <Icon onClick={()=>this.removeRating(rating.id)} link size="big" name="minus circle" color="red"/>
                                    }/>
                                </Grid.Column>
                                <Grid.Column width={15}>
                                    <Segment>
                                        <Grid>
                                            <Grid.Row>
                                                <Grid.Column  width={4}>
                                                    Group:
                                                    <Dropdown
                                                        value={rating.group}
                                                        onChange={this.updateRating}
                                                        floating
                                                        search
                                                        selection
                                                        placeholder='Select A Group'
                                                        forprop='group'
                                                        ratingid={rating.id}
                                                        options={groups.map((group,_groupindex)=>{
                                                            return {
                                                                key: group.id,
                                                                value: group.id,
                                                                text: group.title
                                                            }
                                                        })}
                                                    />
                                                </Grid.Column>
                                                <Grid.Column  width={3}>
                                                    Floor:
                                                    <Input>
                                                        <input
                                                            placeholder="Floor"
                                                            name="ratingfloor"
                                                            value={rating.floor}
                                                            ratingid={rating.id}
                                                            forprop="floor"
                                                            onChange={this.updateRating}
                                                        />
                                                    </Input>
                                                </Grid.Column>
                                                <Grid.Column  width={3}>
                                                    Ceiling:
                                                    <Input>
                                                        <input
                                                            placeholder="Ceiling"
                                                            name="ratingceiling"
                                                            value={rating.ceiling}
                                                            ratingid={rating.id}
                                                            forprop="ceiling"
                                                            onChange={this.updateRating}
                                                        />
                                                    </Input>
                                                </Grid.Column>
                                                <Grid.Column  width={2}>Cumulative: 
                                                    <Checkbox
                                                        toggle
                                                        onChange={this.updateRating}
                                                        ratingid={rating.id}
                                                        forprop="cumulative"
                                                        checked={rating.cumulative}
                                                    />
                                                </Grid.Column>
                                            </Grid.Row>
                                        </Grid>
                                        <Divider hidden/>
                                        Group Scores:
                                        <Segment>
                                            <Grid>
                                            {rating.scores && rating.scores.length ? 
                                            rating.scores.map(score=>{
                                                return (
                                                    <Grid.Row>
                                                        <Grid.Column width={4}>
                                                            <Dropdown
                                                                value={score.group}
                                                                onChange={this.updateRatingScore}
                                                                floating
                                                                search
                                                                selection
                                                                clearable
                                                                ratingid={rating.id}
                                                                scoreid={score.id}
                                                                forprop="group"
                                                                placeholder='Select A Group'
                                                                options={groups.map((group,_groupindex)=>{
                                                                    return {
                                                                        key: group.id,
                                                                        value: group.id,
                                                                        text: group.title
                                                                    }
                                                                })}
                                                            />
                                                        </Grid.Column>
                                                        <Grid.Column width={4}>Min:
                                                            <Input placeholder='0'>
                                                                <input
                                                                    type="number"
                                                                    min="0"
                                                                    max="15"
                                                                    value={score.min}
                                                                    ratingid={rating.id}
                                                                    scoreid={score.id}
                                                                    forprop="min"
                                                                    onChange={this.updateRatingScore}
                                                                />
                                                            </Input>
                                                        </Grid.Column>
                                                        <Grid.Column width={4}>
                                                            Max:
                                                            <Input placeholder='0'>
                                                                <input
                                                                    type="number"
                                                                    min="0"
                                                                    max="15"
                                                                    value={score.max}
                                                                    ratingid={rating.id}
                                                                    scoreid={score.id}
                                                                    forprop="max"
                                                                    onChange={this.updateRatingScore}
                                                                />
                                                            </Input>
                                                        </Grid.Column>
                                                    </Grid.Row>
                                                )}):null}
                                            </Grid>
                                        </Segment>
                                    </Segment>
                                </Grid.Column>
                            </Grid.Row>
                        )}):null}
                    </Grid>
                </Segment>
                {this.state.ismodified?
                    <Message info attached='bottom'>
                        <Button color='red' onClick={this.resetChanges}>Cancel</Button>
                        <Button color="blue" floated="right" onClick={this.saveCurrentRatings}>Save</Button>
                    </Message>
                :null}
            </React.Fragment>
        )
    }
}

export default RatingsManager;