import React from 'react';
import { BrowserRouter as Router} from 'react-router-dom';
import { createBrowserHistory } from 'history';

import mapStateToProps from './datastore/mapper.js';
import mapDispatchToProps from './datastore/dispatcher.js';

import {connect} from 'react-redux';
import autoBind from 'react-autobind';

import Routes from './routes.js';

import Login from './components/login';

class App extends React.Component{
    constructor(props){
        super();
        this.state={
            isRegistrationPage:false,
            isPasswordResetPage: false,
            isDocsPage:false
        };
        autoBind(this);
    }

    componentDidMount(){
        this.loadApp();
    }

    async loadApp(){
        try{
            await this.props.loginUser();
            const isRegistrationPage = window.location.pathname && window.location.pathname.split("/")[1]==="register";
            const isPasswordResetPage = window.location.pathname && window.location.pathname.split("/")[1]==="password_reset";
            const isDocsPage = window.location.pathname && window.location.pathname.split("/")[1]==="docs";
            this.setState({isRegistrationPage, isPasswordResetPage, isDocsPage});
        }catch(err){}
    }

    render(){
        const {appProps:{user}, serverload} = this.props;

        return(
            <React.Fragment>
                {serverload ?
                    <div>Loading App...</div>
                :
                    <React.Fragment>
                        {user || this.state.isRegistrationPage || this.state.isPasswordResetPage || this.state.isDocsPage?
                            <Router history={createBrowserHistory()}>
                                <Routes/>
                            </Router>
                        :
                            <Login />
                        }
                    </React.Fragment>
                }
            </React.Fragment>
        )
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(App);