import React from 'react';

import mapStateToProps from '../../datastore/mapper.js';
import mapDispatchToProps from '../../datastore/dispatcher.js';

import {connect} from 'react-redux';

import {withRouter} from 'react-router-dom';

import { Grid, Input, Button, Divider, Table, Dimmer, Loader, Segment, Popup, Dropdown, Icon, Message, Confirm } from 'semantic-ui-react';

import autoBind from 'react-autobind';

import APPENV from '../../../env.json';

export class ListEvents extends React.Component{
    constructor(props){
        super();
        this.state={
            searchReady:true,
            eventSearchParams:{
                pattern: null,
                id: null
            },
            deleteEvent:{
                showConfirmation: false,
                eventInfo:null
            },
            selectedhash: null,
            error:{
                type: null,
                message: null
            },
            isdownloading: false,
            showcopiedtoast: false
        };
        autoBind(this);
    }

    componentDidMount(){
        if(!this.props.appProps.status.testevents){
            this.props.getEventStatuses();
        }
    }

    updateSearchPattern(e, {value}){
        this.setState({eventSearchParams:{pattern: value && value.length ? value : ''}});
    }

    updateSearchId(e, {value}){
        this.setState({eventSearchParams:{id: value && value.length ? value : ''}});
    }

    formatedDate(dateStr) {
        let theDate = new Date(dateStr);
        return (theDate.getMonth() + 1) + '/' + theDate.getDate() + '/' + theDate.getFullYear();
    }

    editEvent(_event){
        this.props.history.push(`/events/edit?id=${_event.id}`);
    }
    cloneEvent(_event){
        this.props.history.push(`/events/clone?id=${_event.id}`);
    }

    async getEvents(all){
        try{
            if(typeof all==='boolean' && all){
                const allEvents = await this.props.getEvents({all: true});
            }
            else{
                const searchedEvents = await this.props.getEvents(this.state.eventSearchParams);
            }
        }catch(err){console.log(err);}
    }

    toggleDeleteConfirmation(eventID){
        const {appProps:{testevents}} = this.props;

        if(typeof eventID!=='boolean' && eventID && testevents && testevents.length){
            this.setState({
                deleteEvent:{
                    showConfirmation: true,
                    eventInfo: testevents.filter(_event=>_event.id===eventID).length && testevents.filter(_event=>_event.id===eventID)[0]
                }
            });
        }
        else{
            this.setState({
                deleteEvent:{
                    showConfirmation: false,
                    eventInfo: null
                }
            });
        }
    }

    async generateEventLink(eventid){
        try{
            this.setState({isdownloading: true}, async ()=>{
                const generatedeventfile = await this.props.generateEventFile({id: eventid, hash: this.state.selectedhash});
                window.open(`${APPENV.APIEndpoint}/downloads/testevents/${generatedeventfile.filename}`, '_blank');
                this.setState({isdownloading: false});
            });
        }catch(err){
            console.error(err);
            this.setState({
                error: {
                    type: 101,
                    message: 'Please make sure you have enabled popups for this url'
                }
            })
        }
    }

    updateSelectedHash(e, data){
        this.setState({selectedhash: data.value});
    }

    async copyHashToClipboard(){
        try{
            await navigator.clipboard.writeText(this.state.selectedhash);
            this.setState({
                showcopiedtoast: true
            }, ()=>{
                setTimeout(()=>{
                    this.setState({showcopiedtoast: false});
                }, 1000);
            });
        }
        catch(err){console.error(err);}
    }
    

    render(){
        const {appProps:{testevents}} = this.props;

        return(
            <React.Fragment>
                {this.state.searchReady?
                    <div className="listevents">
                        {this.state.error.type && this.state.error.message &&
                            <Message info size="mini">
                                <Message.Header>
                                    {this.state.error.message}
                                </Message.Header>
                            </Message>
                        }
                        <Grid columns='equal'>
                            <Grid.Row>
                                <Grid.Column>
                                    <Input fluid placeholder='Search Event By Title' onChange={this.updateSearchPattern} />
                                </Grid.Column>
                                <Grid.Column>
                                    <Input fluid placeholder='Search Event By Id' onChange={this.updateSearchId} />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column>
                                    <Button onClick={()=>this.getEvents()}>Search</Button>
                                </Grid.Column>
                                <Grid.Column>
                                    <Button onClick={()=>{this.props.history.push(`/events/create`)}} color='green'>Create</Button>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>

                        <Divider hidden />
                        {testevents && testevents.length &&
                            <Segment raised>
                                <Table compact celled definition>
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell />
                                            <Table.HeaderCell>Title</Table.HeaderCell>
                                            <Table.HeaderCell>Modified By</Table.HeaderCell>
                                            <Table.HeaderCell>Modified On<br/>(MM/DD/YYYY)</Table.HeaderCell>
                                            <Table.HeaderCell />
                                            {this.state.selectedhash?<Table.HeaderCell />:null}
                                        </Table.Row>
                                    </Table.Header>
                                    <Table.Body>
                                        {testevents.map(testevent=>{
                                            return (
                                                <Table.Row key={`events-${testevent.id}`}>
                                                    <Table.Cell collapsing>
                                                        <Popup content='Archive Test Event' trigger={
                                                            <Button circular icon='trash' color='red' size='tiny' onClick={()=>this.toggleDeleteConfirmation(testevent.id)}></Button>
                                                        }/>
                                                        <Popup content='Edit Test Event' trigger={
                                                            <Button circular icon='pencil' color='yellow' size='tiny' onClick={()=>this.editEvent(testevent)}></Button>
                                                        }/>
                                                        <Popup content='Clone Test Event' trigger={
                                                            <Button circular icon='clone outline' color='blue' size='tiny' onClick={()=>this.cloneEvent(testevent)}></Button>
                                                        }/>
                                                    </Table.Cell>
                                                    <Table.Cell>
                                                        <section className="title-section">
                                                            <span>{testevent.title}</span>
                                                            <span>
                                                                {testevent.proctored ?
                                                                    <Popup content='Proctored Test' trigger={
                                                                        <Icon name='user secret'/>
                                                                    }/>
                                                                :null}
                                                                {testevent.cloned_from &&
                                                                    <Popup content={`This TestEvent Was Cloned From Another TestEvent: ${testevent.cloned_from}`} trigger={
                                                                        <Icon color="olive" name="clone" />
                                                                    }/>
                                                                || null}
                                                            </span>
                                                        </section>
                                                    </Table.Cell>
                                                    <Table.Cell><span>{testevent.modifiedby}</span></Table.Cell>
                                                    <Table.Cell><span>{this.formatedDate(testevent.modifieddate)}</span></Table.Cell>
                                                    <Table.Cell collapsing>
                                                        {this.state.selectedhash && testevent.hashes.find(hash=>hash.hash===this.state.selectedhash)?
                                                            <Popup content={`Copy hash to clipboard`} trigger={
                                                                <Icon onClick={this.copyHashToClipboard} link color="blue" name="clipboard outline" />
                                                            }/>
                                                        :null}
                                                        <Dropdown
                                                            placeholder="Select Hash"
                                                            onChange={this.updateSelectedHash}
                                                            search
                                                            selection
                                                            clearable
                                                            options={testevent.hashes.map(hash=>{
                                                                return {
                                                                    key: hash.id,
                                                                    text: hash.hash,
                                                                    value: hash.hash
                                                                }
                                                            })}
                                                            value={this.state.selectedhash && testevent.hashes.find(hash=>hash.hash===this.state.selectedhash) ? this.state.selectedhash : null}
                                                        />
                                                    </Table.Cell>
                                                    {this.state.selectedhash?
                                                        <Table.Cell collapsing>
                                                            {!!testevent.hashes.find(hash=>hash.hash===this.state.selectedhash)?
                                                                <Popup content='Download Test Event' trigger={
                                                                    <Button onClick={()=>this.generateEventLink(testevent.id)} circular loading={this.state.isdownloading} icon='cloud download' color={this.state.isdownloading ? 'black' : 'green'} size='tiny'></Button>
                                                                }/>
                                                            :null}
                                                        </Table.Cell>
                                                    :null}
                                                </Table.Row>
                                            )
                                        })}
                                    </Table.Body>
                                </Table>
                            </Segment>
                            || null
                        }
                    </div>
                :
                    <Segment>
                        <Dimmer active>
                            <Loader />
                        </Dimmer>
                    </Segment>
                }

                <Confirm
                    open={this.state.deleteEvent.showConfirmation}
                    header="Delete Event"
                    content={`Are You Sure You Want To Delete Event: ${this.state.deleteEvent.eventInfo ? this.state.deleteEvent.eventInfo.id + ", "+this.state.deleteEvent.eventInfo.title : 'Event Info Not Found'}?`}
                    cancelButton='No'
                    confirmButton="Yes"
                    onCancel={()=>this.toggleDeleteConfirmation(false)}
                    onConfirm={()=>{this.props.archiveEvent(this.state.deleteEvent.eventInfo);this.toggleDeleteConfirmation(false)}}
                />

                {this.state.showcopiedtoast?
                    <div className="snackbar">Copied To Clipboard</div>
                :null}

            </React.Fragment>
        )
    }
}

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(ListEvents));