import React from 'react';

import mapStateToProps from '../../datastore/mapper.js';
import mapDispatchToProps from '../../datastore/dispatcher.js';

import {connect} from 'react-redux';

import { Segment, Button, Icon, Popup, Label, Divider } from 'semantic-ui-react';
import autoBind from 'react-autobind';

import FeaturesManager from './features_manager.js';

class Features extends React.Component{
    constructor(props){
        super();
        autoBind(this);
    }

    updateFeatureIndex(featureid, moveUp){
        const {currentItemFeatures : {features}} = this.props;

        let movemodifier = moveUp ? -1 : 1;

        const indextoshift = features.findIndex(feature=>feature.id===featureid);
        const shiftedtoindex = indextoshift + (1 * movemodifier);
        const featuretype = features[indextoshift].type;

        var realignedFeatureSet = features;
        var tempposition = realignedFeatureSet[indextoshift];
        realignedFeatureSet[indextoshift] = realignedFeatureSet[shiftedtoindex];
        realignedFeatureSet[shiftedtoindex] = tempposition;

        this.props.updateFeatures(realignedFeatureSet, {type: featuretype, fromindex: shiftedtoindex, toindex: indextoshift});
    }

    updateTextEditorFeatureType(e, {value}){
        const {currentItemFeatures : {features}} = this.props;

        let featureid = e.target.parentNode.parentNode.id || e.target.parentNode.parentNode.parentNode.id;
        const updatedFeatures = features;
        for(var i=0; i<updatedFeatures.length; i++){
            if(updatedFeatures[i].type==="texteditor" && updatedFeatures[i].id===featureid){
                // if(updatedFeatures.filter(feature=>feature.content.status===value).length){
                //     this.props.updateError({
                //         type: 'feature',
                //         message: 'Cannot add another text feature of same type'
                //     })
                // }
                // else{
                    updatedFeatures[i].content.status = value;
                    this.props.updateError(null);
                    this.props.updateFeatures(updatedFeatures);
                //}
            }
        }
    }

    updateOptionSelectorFeature(optionSelectorFeature){
        const {currentItemFeatures : {features}} = this.props;

        const updatedFeatures = features.map(feature=>{
            if(feature.id===optionSelectorFeature.id){
                return optionSelectorFeature;
            }
            return feature;
        });
        this.props.updateFeatures(updatedFeatures);
    }

    updateMediaFeature(mediaFeature){
        const {currentItemFeatures : {features}} = this.props;

        const updatedFeatures = features.map(feature=>{
            if(feature.id===mediaFeature.id){
                return mediaFeature;
            }
            return feature;
        });
        this.props.updateFeatures(updatedFeatures);
    }

    updateFeatureAllowInput(_featureid, _status){
        const {currentItemFeatures : {features}} = this.props;

        const updatedFeatures = features;
        for(var i=0; i<updatedFeatures.length; i++){
            if(updatedFeatures[i].id===_featureid){
                updatedFeatures[i].allowUserInput = _status;
            }
            else if(updatedFeatures[i].id!==_featureid && _status && updatedFeatures[i].allowUserInput){
                updatedFeatures[i].allowUserInput = false;
            }
        }
        this.props.updateFeatures(updatedFeatures);
    }

    render(){
        const {currentItemFeatures: {features}} = this.props;
        
        return(
            <div className="itemfeaturescontainer">
                {features && features.length && features.map((feature, feature_index)=>{
                    return (
                        <Segment raised key={`currentitem-feature-${feature_index}`}>
                            {features.length > 1 &&
                                <Label color='grey' ribbon>
                                    {feature_index > 0 &&
                                        <Popup content='Move Feature Up' trigger={
                                            <Button onClick={()=>this.updateFeatureIndex(feature.id, true)} size='tiny' color='teal' icon="arrow alternate circle up"></Button>
                                        }/>
                                    }
                                    {feature_index < features.length - 1 &&
                                        <Popup content='Move Feature Down' trigger={
                                            <Button onClick={()=>this.updateFeatureIndex(feature.id, false)} size='tiny' color='yellow' icon="arrow alternate circle down"></Button>
                                        }/>
                                    }
                                </Label>
                            }
                            <Popup content='Remove This Feature' trigger={
                                <Label onClick={()=>this.props.removeFeatureFromItem(feature.id)} as='a' color='red' attached='top right' size='small'>
                                    <Icon name='window close' size='big'/> Remove
                                </Label>
                            }/>
                            <Divider hidden/>

                            <FeaturesManager
                                itemFeature={{
                                    ...feature
                                }}
                                getTextEditorFeatureHTML={this.props.getTextEditorFeatureHTML}
                                setTextEditorFeatureHTML={this.props.setTextEditorFeatureHTML}
                                updateTextEditorFeatureType={this.updateTextEditorFeatureType}
                                updateOptionSelectorFeature={this.updateOptionSelectorFeature}
                                updateMediaFeature={this.updateMediaFeature}
                                updateFeatureAllowInput={this.updateFeatureAllowInput}
                            />

                        </Segment>
                    )
                }) || null}
            </div>
        )
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(Features);