import React from 'react';

import mapStateToProps from '../../datastore/mapper.js';
import mapDispatchToProps from '../../datastore/dispatcher.js';

import {withRouter} from 'react-router-dom';

import {connect} from 'react-redux';

import { Menu, Label, Popup, Icon } from 'semantic-ui-react';

import autoBind from 'react-autobind';

import APPENV from '../../../env.json';

export class LeftMenu extends React.Component{
    constructor(props){
        super();

        this.state={
            invitecount:0
        }

        autoBind(this);
    }

    componentDidMount(){
        this.prepBadges();
        this.prepAssignedItems();
    }

    async prepBadges(){
        try{
            await this.props.getPendingInvites();
        }catch(err){}
    }

    async prepAssignedItems(){
        const {location:{pathname}} = this.props;

        try{
            await this.props.getItems({assigned:true});
        }catch(err){}
    }

    routeTo(path){
        if(window.localStorage.getItem('userloggedin')===null){
            this.props.clearSession();
            window.location.href = "/";
            //this.props.history.push("/");
        }
        else{
            this.prepBadges();
            this.props.history.push(path);
        }
    }
    takeatest() {
        window.open(APPENV.testEndpoint);
    }
    render(){
        const {location:{pathname}, appProps:{userManagement:{pendingInvites}, user:{permissions, organizations}, items, collections}} = this.props;

        const defaultuserorg = organizations && organizations.length ? organizations.find(org=>org.isDefault) : {};
        
        const _fontcolor = defaultuserorg.theme && defaultuserorg.theme.color ? defaultuserorg.theme.color : '#1d1d1d';
        const _bgcolor = defaultuserorg.theme && defaultuserorg.theme.bgcolor ? defaultuserorg.theme.bgcolor : '#ffffff';

        return(
            <React.Fragment>
                <Menu style={{backgroundColor: _bgcolor}} inverted vertical position='left'>

                    <Menu.Item
                        active={pathname==="/"}
                        onClick={()=>this.routeTo('/')}
                        header
                    >
                        <span style={{color: _fontcolor}} className="menu-label">Home</span>
                        <Icon style={{color: _fontcolor}} name='home'/>
                    </Menu.Item>

                    {(permissions.find(perm=>perm.code==="event_create") || permissions.find(perm=>perm.code==="event_delete") || permissions.find(perm=>perm.code==="event_edit")) &&
                        <Menu.Item
                            active={pathname==="/events"}
                            onClick={()=>this.routeTo('/events')}
                            header
                        >
                            <span style={{color: _fontcolor}} className="menu-label">Events</span>
                            <Icon style={{color: _fontcolor}} name='object group outline'/>
                        </Menu.Item>
                    }

                    {(permissions.find(perm=>perm.code==="collection_create") || permissions.find(perm=>perm.code==="collection_delete") || permissions.find(perm=>perm.code==="collection_edit")) &&
                        <Menu.Item
                            active={pathname==="/testlets"}
                            onClick={()=>this.routeTo('/testlets')}
                            header
                        >
                            <span style={{color: _fontcolor}} className="menu-label">Testlets</span>
                            <Icon style={{color: _fontcolor}} name='window restore outline'/>
                            {collections && collections.length && collections.filter(collection=>collection.assigned).length &&
                                <Popup content={`You have been assigned ${collections.filter(collection=>collection.assigned).length} testlets`} trigger={
                                    <Label color='teal'>{collections.filter(collection=>collection.assigned).length}</Label>
                                }/>
                            || ''}    
                        </Menu.Item>
                    }

                    {(permissions.find(perm=>perm.code==="item_create") || permissions.find(perm=>perm.code==="item_delete") || permissions.find(perm=>perm.code==="item_edit")) &&
                        <Menu.Item
                            active={pathname==="/items"}
                            onClick={()=>this.routeTo('/items')}
                            header
                        >
                            <span style={{color: _fontcolor}} className="menu-label">Items</span>
                            <Icon style={{color: _fontcolor}} name='edit outline'/>
                            {items && items.length && items.filter(item=>item.assigned).length &&
                                <Popup content={`You have been assigned ${items.filter(item=>item.assigned).length} items`} trigger={
                                    <Label color='teal'>{items.filter(item=>item.assigned).length}</Label>
                                }/>
                            || ''}    
                        </Menu.Item>
                    }

                    {permissions.find(perm=>perm.code==="media_edit") &&
                        <Menu.Item
                            active={pathname==="/media"}
                            onClick={()=>this.routeTo('/media')}
                            header
                        >
                            <span style={{color: _fontcolor}} className="menu-label">Media Assets</span>
                            <Icon style={{color: _fontcolor}} name='music'/>
                        </Menu.Item>
                    }
                    
                    {(permissions.find(perm=>perm.code==="tag_create") || permissions.find(perm=>perm.code==="tag_delete") || permissions.find(perm=>perm.code==="tag_edit")) &&
                        <Menu.Item
                            active={pathname==="/tags"}
                            onClick={()=>this.routeTo('/tags')}
                            header
                        >
                            <span style={{color: _fontcolor}} className="menu-label">Tags</span>
                            <Icon style={{color: _fontcolor}} name='tags'/>
                        </Menu.Item>
                    }

                    {permissions.find(perm=>perm.code==="reports") &&
                        <Menu.Item
                            active={pathname==="/reports"}
                            onClick={()=>this.routeTo('/reports')}
                            header
                        >
                            <span style={{color: _fontcolor}} className="menu-label">Reports</span>
                            <Icon style={{color: _fontcolor}} name='chart line'/>
                        </Menu.Item>
                    }

                    {(permissions.find(perm=>perm.code==="user_edit") || permissions.find(perm=>perm.code==="user_delete") || permissions.find(perm=>perm.code==="user_invite")) &&
                        <Menu.Item>
                            <Icon style={{color: _fontcolor}} name='user'/>
                            <Menu.Header style={{color: _fontcolor}}>Users</Menu.Header>
                            <Menu.Menu>
                                {(permissions.find(perm=>perm.code==="user_edit") || permissions.find(perm=>perm.code==="user_delete")) &&
                                    <Menu.Item
                                        name='List'
                                        active={pathname==="/user/list"}
                                        onClick={()=>this.routeTo('/user/list')}
                                        style={{color: _fontcolor}}
                                    />
                                }
                                {permissions.find(perm=>perm.code==="user_invite") &&
                                    <Menu.Item
                                        name='Invite'
                                        active={pathname==="/user/invite"}
                                        onClick={()=>this.routeTo('/user/invite')}
                                        style={{color: _fontcolor}}
                                    >
                                        Invite
                                        {pendingInvites && pendingInvites.length>0?
                                            <Label color='teal'>{pendingInvites.length}</Label>
                                        :null}    
                                    </Menu.Item>
                                }
                                {(permissions.find(perm=>perm.code==="roles_edit") || permissions.find(perm=>perm.code==="roles_delete") || permissions.find(perm=>perm.code==="roles_create")) &&
                                    <Menu.Item
                                        name='Roles'
                                        active={pathname==="/user/roles"}
                                        onClick={()=>this.routeTo('/user/roles')}
                                        style={{color: _fontcolor}}
                                    />
                                }
                            </Menu.Menu>
                        </Menu.Item>
                    }

                    {(permissions.find(perm=>perm.code==="organization_create") || permissions.find(perm=>perm.code==="organization_delete") || permissions.find(perm=>perm.code==="organization_edit")) &&
                        <Menu.Item
                            active={pathname==="/organizations"}
                            onClick={()=>this.routeTo('/organizations')}
                            header
                        >
                            <span style={{color: _fontcolor}} className="menu-label">Organizations</span>
                            <Icon style={{color: _fontcolor}} name='sitemap'/>
                        </Menu.Item>
                    }
                    
                    {(permissions.find(perm=>perm.code==="event_create") || permissions.find(perm=>perm.code==="event_delete") || permissions.find(perm=>perm.code==="event_edit")) &&
                        <Menu.Item
                            onClick={()=>this.takeatest()}
                            header
                        >
                            <span style={{color: _fontcolor}} className="menu-label">Take A Test</span>
                            <Icon style={{color: _fontcolor}} name='file outline'/>
                        </Menu.Item>
                    }
                </Menu>
            </React.Fragment>
        )
    }
}

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(LeftMenu));