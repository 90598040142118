import React from 'react';

import mapStateToProps from '../../datastore/mapper.js';
import mapDispatchToProps from '../../datastore/dispatcher.js';

import {connect} from 'react-redux';

import {withRouter} from 'react-router-dom';

import { Segment, Button, Icon, Message, Modal, Header, Dropdown, Confirm } from 'semantic-ui-react';
import autoBind from 'react-autobind';

import GeneralUtility from '../../utilities/generalUtility.js';

import MetadataManager from './metadata_manager.js';
import ItemsManager from './items_manager.js';
import TagManager from './tag_manager.js';

export class EditCollection extends React.Component{
    constructor(props){
        super();
        this.state={
            currentCollection: null,
            isEditing: false,
            showConfirmation: false,
            error:{
                type: null,
                message: null
            },
            sortedby: null
        }

        autoBind(this);
    }

    componentDidMount(){
        this.parseQueryParam();
    }

    async parseQueryParam(){
        const {location:{search}, appProps:{status}} = this.props;

        try{
            if(!status.collections){
                await this.props.getCollectionStatuses();
            }

            const parsedQueryParam = search.split("?");
            if(parsedQueryParam && parsedQueryParam.length === 2){
                const collectionId = parsedQueryParam[1].split('id=')[1];
                if (collectionId) {
                    this.prepCurrentCollection(collectionId);
                }
            }
            else{
                this.prepCurrentCollection();
            }
        }catch(err){
            this.prepCurrentCollection();
        }
    }

    async prepCurrentCollection(parsedID){
        const {appProps:{items, collections}, clone} = this.props;

        const _uuid4 = GeneralUtility.generateUUIDV4();

        const currentlyEditingCollection = {
            id: _uuid4,
            title: '',
            language: null,
            proficiency: null,
            skill: null,
            timer:{
                enabled: false,
                seconds: 0,
                allitems: false
            },
            items: [],
            tags:[]
        };

        if(parsedID){
            try{
                let isEditing = false;
                if(!clone && parsedID){
                    isEditing = true;
                }
                if(collections && collections.filter(col=>col.id===parsedID).length){
                    const collectionDetail = collections.filter(col=>col.id===parsedID)[0];
                    if(!items || items.filter(item=>collectionDetail.items.includes(item.id)).length!==collectionDetail.items.length){
                        for(var colitem=0; colitem<collectionDetail.items.length; colitem++){
                            try{
                                await this.props.getItems({id: collectionDetail.items[colitem]});
                            }catch(err){}
                        }
                    }
                    if(collectionDetail.timer.enabled && collectionDetail.timer.minutes && collectionDetail.timer.seconds !== collectionDetail.timer.minutes * 60){
                        collectionDetail.timer.seconds = collectionDetail.timer.minutes * 60;
                    }
                    this.setState({
                        currentCollection: {
                            ...collectionDetail,
                            id: clone ? _uuid4 : collectionDetail.id,
                            cloned_from: clone ? collectionDetail.id : null
                        },
                        isEditing
                    });
                }
                else{
                    const collectionDetail = await this.props.getCollections({id: parsedID});
                    if(collectionDetail.dataset.length && collectionDetail.dataset.filter(col=>col.id===parsedID).length){
                        for(var i=0; i<collectionDetail.dataset[0].items.length; i++){
                            try{
                                await this.props.getItems({id: collectionDetail.dataset[0].items[i]});
                            }catch(err){}
                        }
                        const currentCollection = collectionDetail.dataset[0];
                        if(clone){
                            currentCollection.id = _uuid4;
                        }
                        if(collectionDetail.timer.enabled && collectionDetail.timer.minutes && collectionDetail.timer.seconds !== collectionDetail.timer.minutes * 60){
                            collectionDetail.timer.seconds = collectionDetail.timer.minutes * 60;
                        }
                        this.setState({
                            currentCollection,
                            isEditing
                        });
                    }
                }
            }
            catch(err){
                this.setState({
                    currentCollection: currentlyEditingCollection,
                    isEditing: false
                });
                console.log(err);
            }
        }
        else{
            this.setState({
                currentCollection: currentlyEditingCollection,
                isEditing: false
            });
        }

    }

    addSelectedTag(tagObj){
        const {appProps: {tags}} = this.props;

        let _error = null;

        for(var t=0; t<tags.length; t++){
            if(this.state.currentCollection.tags && this.state.currentCollection.tags.length){

                const currentCollectionHasGlobalTag = this.state.currentCollection.tags.includes(tags[t].id);
                const isParamTagObject = tags[t].id===tagObj.id;

                if(isParamTagObject && currentCollectionHasGlobalTag){
                    _error = {type: 'tags', message:'Cannot have duplicate tag'};
                }
                else if(isParamTagObject && !currentCollectionHasGlobalTag){
                    const filteredTags = tags.filter(tag=>this.state.currentCollection.tags.includes(tag.id) || tag.id===tagObj.id);
                    if(filteredTags.length){
                        const uniqueFilteredByLanguage = filteredTags
                            .filter(tag => tag.language && tagObj.language && tag.language.id!==tagObj.language.id);

                        const uniqueFilteredByProficiency = filteredTags
                            .filter(tag => tag.proficiency && tagObj.proficiency && tag.proficiency.id!==tagObj.proficiency.id);

                        if(uniqueFilteredByLanguage.length){
                            _error = {type: 'tags', message:'Cannot have multiple language assigned to an item'};
                        }
                        else if(uniqueFilteredByProficiency.length){
                            _error = {type: 'tags', message:'Cannot have multiple proficiency assigned to an item'};
                        }
                    }
                }

            }
        }

        if(!_error && (this.state.currentCollection.tags && !this.state.currentCollection.tags.includes(tagObj.id)) || !this.state.currentCollection.tags || !this.state.currentCollection.tags.length){
            this.setState((prevState)=>({
                error: null,
                currentCollection:{
                    ...prevState.currentCollection,
                    tags: 
                        prevState.currentCollection.tags && prevState.currentCollection.tags.length ? 
                        [...prevState.currentCollection.tags, tagObj.id]
                        :
                        [tagObj.id]
                }
            }));
        }
        else if(_error){
            this.setState({error: _error},()=>{window.scrollTo(0,0)});
        }
    }
    removeSelectedTag(tagid){
        this.setState((prevState)=>({
            currentCollection:{
                ...prevState.currentCollection,
                tags: prevState.currentCollection.tags.filter(tag=>tag!==tagid)
            }
        }));
    }

    updateCollectionMetaData(metadata){
        this.setState((prevState)=>({
            currentCollection:{
                ...prevState.currentCollection,
                ...metadata
            }
        }));
    }
    generateCollectionTitle(newDS){
        const {appProps:{languages, proficiencies, skills}} = this.props;

        let autoTitle = '';

        if(skills && skills.length && newDS && newDS.skill){
            const filtered_skills = skills.filter(skill=>skill.id===newDS.skill);
            if(filtered_skills && filtered_skills.length) autoTitle += filtered_skills[0].name.slice(0, 3).toUpperCase();
        }

        if(languages && languages.length && newDS && newDS.language){
            const filtered_languages = languages.filter(lang=>lang.id===newDS.language);
            if(filtered_languages && filtered_languages.length) autoTitle += (autoTitle.length ? '_' : '') + filtered_languages[0].name.slice(0, 3).toUpperCase();
        }

        if(proficiencies && proficiencies.length && newDS && newDS.proficiency){
            const filtered_proficiencies = proficiencies.filter(prof=>prof.id===newDS.proficiency);
            if(filtered_proficiencies && filtered_proficiencies.length) autoTitle += (autoTitle.length ? '_' : '') + filtered_proficiencies[0].label.slice(0, 3).toUpperCase();
        }

        this.setState((prevState)=>({
            currentCollection:{
                ...prevState.currentCollection,
                title: autoTitle+'_Testlet'
            }
        }));
    }

    addItemToCollection(itemID){

        const { appProps: {languages, proficiencies, skills, items} } = this.props;

        const itemIDExists = this.state.currentCollection.items.filter(_item=>_item===itemID).length;

        let error = {
            type: null,
            message: null
        };

        let metadata = {
            language: this.state.currentCollection.language,
            proficiency: this.state.currentCollection.proficiency,
            skill: this.state.currentCollection.skill
        }

        if(!itemIDExists){
            const currentCollectionItems = this.state.currentCollection.items;
            if(currentCollectionItems.length && items.filter(_item=>_item.id===currentCollectionItems[0]).length){
                const currentCollectionIdentityItem = items.filter(_item=>_item.id===currentCollectionItems[0])[0];
                const newItem = items.filter(_item=>_item.id===itemID)[0];

                const newItemLanguageIsName = languages.filter(_lang=>_lang.id===newItem.language).length>0;
                const newItemProficiencyIsLabel = proficiencies.filter(_prof=>_prof.id===newItem.proficiency).length>0;
                const newItemSkillIsName = skills.filter(_skill=>_skill.id===newItem.skill).length>0;

                if(
                    (
                        !metadata.language &&
                        currentCollectionIdentityItem &&
                        (
                            (newItemLanguageIsName && !languages.filter(_lang=>_lang.id===newItem.language && _lang.id===currentCollectionIdentityItem.language).length) ||
                            (!newItemLanguageIsName && currentCollectionIdentityItem.language!==newItem.language)
                        )
                    ) ||
                    !languages.filter(_lang=>_lang.id===newItem.language && metadata.language===_lang.id).length
                ){
                    error.type = 0;
                    error.message = "Cannot have multiple languages";
                }
                else if(
                    (
                        !metadata.proficiency &&
                        currentCollectionIdentityItem &&
                        (
                            (newItemProficiencyIsLabel && !proficiencies.filter(_prof=>_prof.id===newItem.proficiency && _prof.id===currentCollectionIdentityItem.proficiency).length) ||
                            (!newItemProficiencyIsLabel && currentCollectionIdentityItem.proficiency!==newItem.proficiency)
                        )
                    ) ||
                    !proficiencies.filter(_prof=>_prof.id===newItem.proficiency && metadata.proficiency===_prof.id).length
                ){
                    error.type = 0;
                    error.message = "Cannot have multiple proficiency";
                }
                else if(
                    (
                        !metadata.skill &&
                        currentCollectionIdentityItem &&
                        (
                            (newItemSkillIsName && !skills.filter(_skill=>_skill.id===newItem.skill && _skill.id===currentCollectionIdentityItem.skill).length) ||
                            (!newItemSkillIsName && currentCollectionIdentityItem.skill!==newItem.skill)
                        )
                    ) ||
                    !skills.filter(_skill=>_skill.id===newItem.skill && metadata.skill===_skill.id).length
                ){
                    error.type = 0;
                    error.message = "Cannot have multiple skills";
                }
            }
            else{
                const newItem = items.filter(_item=>_item.id===itemID)[0];
                if(newItem.language){
                    metadata.language = languages.filter(lang=>lang.id===newItem.language)[0].id;
                }
                if(newItem.proficiency){
                    metadata.proficiency = proficiencies.filter(prof=>prof.id===newItem.proficiency)[0].id;
                }
                if(newItem.skill){
                    metadata.skill = skills.filter(skill=>skill.id===newItem.skill)[0].id;
                }
            }

            if(error.type===null || error.type>0){
                currentCollectionItems.push(itemID);
            }
            else{
                window.scrollTo(0,50);
            }

            this.setState((prevState)=>({
                currentCollection:{
                    ...prevState.currentCollection,
                    items: currentCollectionItems,
                    ...metadata
                },
                error
            }), ()=>{
                if(this.state.currentCollection.items.length>=1){
                    this.generateCollectionTitle(
                        {
                            skill: this.state.currentCollection.skill,
                            language: this.state.currentCollection.language,
                            proficiency: this.state.currentCollection.proficiency
                        }
                    );
                }
            });
        }
    }
    removeItemFromCollection(itemID){
        const itemIDIndex = this.state.currentCollection.items.findIndex(_item=>_item===itemID)
        if(itemIDIndex > -1){
            const currentItems = this.state.currentCollection.items;
            currentItems.splice(itemIDIndex, 1);

            this.setState((prevState)=>({
                currentCollection:{
                    ...prevState.currentCollection,
                    items: currentItems
                }
            }));
        }
    }

    toggleConfirmation(toggleState){
        if(toggleState && this.state.currentCollection.title && this.state.currentCollection.language && this.state.currentCollection.proficiency){
            this.setState({showConfirmation: toggleState});
        }
        else if(toggleState){
            let error = {
                type: null,
                message: null
            };
            if(!this.state.currentCollection.title){
                error.type = 1;
                error.message = "Testlet must have a title.";
            }
            else if(!this.state.currentCollection.language){
                error.type = 2;
                error.message = "Testlet must have a language.";
            }
            else if(!this.state.currentCollection.proficiency){
                error.type = 3;
                error.message = "Testlet must have a proficiency.";
            }

            if(error.type){
                this.setState({error});
            }
        }
    }

    async createCollection(){
        //console.log(JSON.stringify(this.state.currentCollection));
        try{
            if(this.state.isEditing){
                const collectionUpdated = await this.props.updateCollection(this.state.currentCollection);
            }
            else{
                const collectionCreated = await this.props.createCollection(this.state.currentCollection);
            }
            this.props.history.push("/testlets");
        }catch(err){
            console.log(err);
            this.setState({
                error: {
                    type: "BackEnd Error",
                    message: `Could not ${this.state.isEditing? 'update' : 'create'} this testlet.`
                },
                showConfirmation: false
            });
        }
    }

    render(){
        const {appProps:{status}} = this.props;

        return(
            <React.Fragment>

                {this.state.currentCollection ?

                    <React.Fragment>

                        <Message info size="mini">
                            <Message.Header>
                                <span>Testlet Id</span>
                                <span className="id-message-span">{this.state.currentCollection.id}</span>
                            </Message.Header>
                        </Message>

                        <div className="createcancelpanel">

                            {this.state.error && this.state.error.type>0 &&
                                <Message negative>
                                    <p>{this.state.error.message}</p>
                                </Message>
                            || null}
                            
                            <Segment inverted color='grey'>
                                <Button color='red' onClick={()=>this.props.history.push("/testlets")}>Cancel</Button>
                                <Button color='green' onClick={()=>this.toggleConfirmation(true)}>{this.state.isEditing?'Update':'Create'}</Button>
                            </Segment>
                        </div>


                        <div className="createcollection">
                            <TagManager
                                error={this.state.error}
                                currentCollectionTags={this.state.currentCollection.tags}
                                addSelectedTag={this.addSelectedTag}
                                removeSelectedTag={this.removeSelectedTag}
                            />
                            <MetadataManager
                                currentCollectionMetadata={{
                                    title: this.state.currentCollection.title,
                                    language: this.state.currentCollection.language,
                                    proficiency: this.state.currentCollection.proficiency,
                                    skill: this.state.currentCollection.skill,
                                    timer: this.state.currentCollection.timer
                                }}
                                updateCollectionMetaData={this.updateCollectionMetaData}
                            />
                            {this.state.error && this.state.error.type===0 &&
                                <Message negative>
                                    <p>{this.state.error.message}</p>
                                </Message>
                            || null}
                            <ItemsManager
                                addItemToCollection={this.addItemToCollection}
                                removeItemFromCollection={this.removeItemFromCollection}
                                currentCollection={this.state.currentCollection}
                            />
                        </div>
                    
                    </React.Fragment>

                :
                    <Segment loading></Segment>
                }

                <Confirm
                    open={this.state.showConfirmation}
                    header={`${this.state.isEditing ? 'Update' : 'Create'} Testlet`}
                    content={`Are You Sure You Want To ${this.state.isEditing ? 'Update' : 'Create'} This Testlet ?`}
                    cancelButton='No'
                    confirmButton="Yes"
                    onCancel={()=>this.toggleConfirmation(false)}
                    onConfirm={this.createCollection}
                />
                
            </React.Fragment>
        )
    }
}

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(EditCollection));