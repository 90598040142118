import * as Actions from './actions/appactions';

const mapDispatchToProps = (dispatch) => {
    return {
        logError:(error)=>{
            dispatch(Actions.logError(error));
        },
        loginUser:(userInfo)=>{
            dispatch(Actions.loginUser(userInfo));
        },
        logoutUser:(redirectUrl)=>{
            dispatch(Actions.logoutUser(redirectUrl));
        },
        clearSession:()=>{
            Actions.clearSession();
        },
        getLoggedInUserInfo:()=>{
            dispatch(Actions.getLoggedInUserInfo());
        },
        getAvailablePermissions:()=>{
            dispatch(Actions.getAvailablePermissions());
        },
        getRoles:(id)=>{
            dispatch(Actions.getRoles(id));
        },
        createRole:(roleObj)=>{
            return dispatch(Actions.createRole(roleObj));
        },
        updateRole:(roleObj)=>{
            return dispatch(Actions.updateRole(roleObj));
        },
        deleteRole:(roleid)=>{
            return dispatch(Actions.deleteRole(roleid));
        },
        inviteUser:(userObj)=>{
            return dispatch(Actions.inviteUser(userObj));
        },
        getUsers:(roleid, searchPattern)=>{
            return dispatch(Actions.getUsers(roleid, searchPattern));
        },
        getUserProfile:()=>{
            return Actions.getUserProfile();
        },
        cancelInvitation:(userid)=>{
            return dispatch(Actions.cancelInvitation(userid));
        },
        getPendingInvites:()=>{
            dispatch(Actions.getPendingInvites());
        },
        requestPasswordReset:(email)=>{
            return Actions.requestPasswordReset(email);
        },
        setDefaultOrganization:(orgid)=>{
            return Actions.setDefaultOrganization(orgid);
        },
        createUser:(newUserObj)=>{
            return Actions.createUser(newUserObj);
        },
        updateUserPassword:(email, oldPassword, newPassword, key=false)=>{
            return Actions.updateUserPassword(email, oldPassword, newPassword, key);
        },
        updateUserRole:(userRoleObj)=>{
            return Actions.updateUserRole(userRoleObj);
        },
        getLanguages:()=>{
            dispatch(Actions.getLanguages());
        },
        getProficiency:()=>{
            dispatch(Actions.getProficiency());
        },
        getSkills:()=>{
            dispatch(Actions.getSkills());
        },
        getTags:(searchPattern, id)=>{
            return dispatch(Actions.getTags(searchPattern, id));
        },
        createTag:(newTagObj)=>{
            return dispatch(Actions.createTag(newTagObj));
        },
        updateTag:(updatedTagObj)=>{
            return dispatch(Actions.updateTag(updatedTagObj));
        },
        deleteTag:(tagID)=>{
            return dispatch(Actions.deleteTag(tagID));
        },
        createItem:(itemObj)=>{
            return dispatch(Actions.createItem(itemObj));
        },
        updateItem:(itemObj)=>{
            return dispatch(Actions.updateItem(itemObj));
        },
        archiveItem:(iteminfo)=>{
            return dispatch(Actions.archiveItem(iteminfo));
        },
        getItems:(itemParams)=>{
            return dispatch(Actions.getItems(itemParams));
        },
        getItemStatuses:()=>{
            return dispatch(Actions.getItemStatuses());
        },
        getItemInfo:(itemParams)=>{
            return dispatch(Actions.getItemInfo(itemParams));
        },
        getItemHistory:(id) => {
            return dispatch(Actions.getItemHistory(id));
        },
        getItemVersion:(id) => {
            return dispatch(Actions.getItemVersion(id));
        },
        getMediaSchema:()=>{
            return dispatch(Actions.getMediaSchema());
        },
        getMediaAssets:(searchPattern, id, type)=>{
            return dispatch(Actions.getMediaAssets(searchPattern, id, type));
        },
        uploadMedia:(formData)=>{
            return dispatch(Actions.uploadMedia(formData));
        },
        updateMedia:(mediaobject)=>{
            return dispatch(Actions.updateMedia(mediaobject));
        },
        archiveMedia:(mediaid)=>{
            return dispatch(Actions.archiveMedia(mediaid));
        },
        getCollections:(collectionParams)=>{
            return dispatch(Actions.getCollections(collectionParams));
        },
        getCollectionStatuses:()=>{
            return dispatch(Actions.getCollectionStatuses());
        },
        createCollection:(collectionObj)=>{
            return dispatch(Actions.createCollection(collectionObj));
        },
        updateCollection:(collectionObj)=>{
            return dispatch(Actions.updateCollection(collectionObj));
        },
        archiveCollection:(collectionObj)=>{
            return dispatch(Actions.archiveCollection(collectionObj));
        },
        uploadAutoGradingAudio:(formData)=>{
            return dispatch(Actions.uploadAutoGradingAudio(formData));
        },
        checkTextAutoGrading:(autoGradingTextObj)=>{
            return dispatch(Actions.checkTextAutoGrading(autoGradingTextObj));
        },
        checkAudioAutoGrading:(autoGradingAudioTokenObj)=>{
            return dispatch(Actions.checkAudioAutoGrading(autoGradingAudioTokenObj));
        },
        getEvents:(eventParams)=>{
            return dispatch(Actions.getEvents(eventParams));
        },
        generateEventFile:(eventParams)=>{
            return dispatch(Actions.generateEventFile(eventParams));
        },
        getEventStatuses:()=>{
            return dispatch(Actions.getEventStatuses());
        },
        createEvent:(eventObj)=>{
            return dispatch(Actions.createEvent(eventObj));
        },
        updateEvent:(eventObj)=>{
            return dispatch(Actions.updateEvent(eventObj));
        },
        archiveEvent:(eventObj)=>{
            return dispatch(Actions.archiveEvent(eventObj));
        },
        getScores:(scoreParams)=>{
            return dispatch(Actions.getScores(scoreParams));
        },
        getItemsReport:(itemParams)=>{
            return dispatch(Actions.getItemsReport(itemParams));
        },
        getTestletsReport:(testletParams)=>{
            return dispatch(Actions.getTestletsReport(testletParams));
        },
        getOrganizations:(searchparam)=>{
            return dispatch(Actions.getOrganizations(searchparam));
        },
        getOrganizationStatuses:()=>{
            return dispatch(Actions.getOrganizationStatuses());
        },
        createOrganization:(orgformdata, orgobj)=>{
            return dispatch(Actions.createOrganization(orgformdata, orgobj));
        },
        updateOrganization:(orgformdata, orgobj)=>{
            return dispatch(Actions.updateOrganization(orgformdata, orgobj));
        },
        archiveOrganization:(orgid)=>{
            return dispatch(Actions.archiveOrganization(orgid));
        },
        getEventTemplate:()=>{
            return dispatch(Actions.getEventTemplate());
        },
        getNextItemNumber:(itemData)=>{
            return dispatch(Actions.getNextItemNumber(itemData));
        },
    }
}

export default mapDispatchToProps;