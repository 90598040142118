export default class CookieUtility{
    constructor(){}

    static setCookie(name, value, ms) {
        let expires = "";
        if(isNaN(ms) || typeof ms==="string"){
            try{
                ms = parseInt(ms);
            }
            catch(err){ms = 0;}
        }

        if(ms) {
            const date = new Date(ms);
            date.setTime(ms);
            expires = "; expires=" + date.toGMTString();
        }
        const cookieval = name + "=" + (value || "") + expires + "; path=/";
        document.cookie = cookieval;
    }

    static getCookieValueByKey(key){
        const currentcookies = document.cookie;
        let cookie = currentcookies.split(';').find(item => {
           return item.trim().indexOf(key + '=') == 0
        });
        if(cookie){
            cookie = cookie.replace(key + '=','')
            cookie = cookie.trim();
            return cookie; 
        }
        return null;  
    }

    static deleteCookie(name){
        document.cookie = name+'=; Max-Age=-99999999;path=/'; 
    }

}