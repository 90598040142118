import React from 'react';
import mapStateToProps from '../../../datastore/mapper.js';
import mapDispatchToProps from '../../../datastore/dispatcher.js';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import ItemVersionView from './item_version_view.js';
import {Dropdown} from 'semantic-ui-react';

export class ItemVersionCompare extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            items: [(props.defaultItem)||null, (props.secondDefaultItem)||null],
            versionOptions: null,
        }
    }

    async componentDidMount(){
        try{
            const itemHistory = await this.getHistory(this.props.defaultItem.id)
            if(itemHistory && itemHistory.length){
                const versionOptions = itemHistory.map((version, index) =>{
                    return {
                        value: version.id,
                        key: version.id,
                        text: `${new Date(version.modifieddate).toDateString()} ${(index == 0) ? "(Current Version)":""}`
                    }
                });
                this.setState({versionOptions});
            };
        } catch(e) {
            console.error("Couldn't get version options.", e)
        }
    }

    async getHistory(id){
        const response = await this.props.getItemHistory(id);
        if(response.success){
            return response.dataset
        }
    }

    async getItemVersion(id){
        try{
            const item = await this.props.getItemVersion(id);
            return item;
        }catch {
            console.error("Could not get version")
        }
    }

    async selectVersion(id, arrayIndex){
        try{
            const item = await this.getItemVersion(id);
            const newItems = {...this.state.items};
            newItems[arrayIndex] = item.dataset;
            this.setState({items: newItems})
            
        } catch(e) {
            console.error(e)
        }
    }

    checkForKeyDifferences(items){
        if(items[0] && items[1]){
            let keyDifferences = []

            if(items[0].title !== items[1].title){
                keyDifferences.push(
                    <React.Fragment key={`difference-title-${items[0].title}-${items[1].title}`}>
                        <h4>The titles are different:</h4>
                        <div className="differenceContent">
                            <p><strong>{`${items[0].title}`}</strong> and <strong>{`${items[1].title}`}</strong></p>
                        </div>
                    </React.Fragment>
                )
            }

            if(items[0].language !== items[1].language){
                keyDifferences.push(
                    <React.Fragment key={`difference-language-${items[0].language}-${items[1].language}`}>
                        <h4>The languages are different:</h4>
                        <div className="differenceContent">
                            <p><strong>{`${items[0].language}`}</strong> and <strong>{`${items[1].language}`}</strong></p>
                        </div>
                    </React.Fragment>
                )
            }

            if(items[0].proficiency.id !== items[1].proficiency.id){
                keyDifferences.push(
                    <React.Fragment key={`difference-proficiency-${items[0].proficiency.id}-${items[1].proficiency.id}`}>
                        <h4>The proficiency is different:</h4>
                        <div className="differenceContent">
                            <p><strong>{`${items[0].proficiency.label}`}</strong> and <strong>{`${items[1].proficiency.label}`}</strong></p>
                        </div>
                    </React.Fragment>
                )
            }

            if((items[0].timer.enabled !== items[1].timer.enabled) || (items[0].timer.minutes !== items[1].timer.minutes)){
                keyDifferences.push(
                    <React.Fragment key={`difference-timer-${items[0].timer.enabled}-${items[1].timer.enabled}`}>
                        <h4>The Timer is different:</h4>
                        <div className="differenceContent">
                            {(items[0].timer.enabled !== items[1].timer.enabled) &&
                                <p>Timer Enabled: <strong>{`${items[0].proficiency.label}`}</strong> and <strong>{`${items[1].proficiency.label}`}</strong></p>
                            }
                            {(items[0].timer.minutes !== items[1].timer.minutes) &&
                                <p>Time In Minutes: <strong>{`${items[0].proficiency.label}`}</strong> and <strong>{`${items[1].proficiency.label}`}</strong></p>
                            }
                        </div>
                    </React.Fragment>
                )
            }
            
            items && items[0].features && items[0].features.map(feature =>{
                const otherFeature = items[1].features.find(feature2 => feature2.id == feature.id);
                if(otherFeature){
                    if(feature.type !== otherFeature.type){
                        keyDifferences.push(
                            <React.Fragment key={`difference-feature-${feature.id}`}>
                                <h4>Different Feature Type:</h4>
                                <div className="differenceContent">
                                    <p><strong>Feature ID:</strong> {feature.id}</p>
                                    <p><strong>{feature.type}</strong> and <strong>{otherFeature.type}</strong></p>
                                </div>
                            </React.Fragment>
                        )
                    }
                    
                    if(feature.status !== otherFeature.status){
                        keyDifferences.push(
                            <React.Fragment key={`difference-feature-content-${feature.content.status}`}>
                                <h4>Different Feature Status:</h4>
                                <div className="differenceContent">
                                    <p>Feature ID: {feature.id}</p>
                                    <p><strong>{feature.content.status}</strong> and <strong>{feature.content.status}</strong></p>
                                </div>
                            </React.Fragment>
                        )
                    }

                    if(feature.content.html !== otherFeature.content.html){
                        keyDifferences.push(
                            <React.Fragment key={`difference-feature-${feature.id}-compare-manually`}>
                                <h4>Different Feature Content:</h4>
                                <div className="differenceContent">
                                    <p>Feature ID: {feature.id}</p>
                                    <p>Compare manually in the features section</p>
                                </div>
                            </React.Fragment>
                        )
                    }

                }
            });

            if(items && items.features && items[0].features.length !== items[0].features.length){
                keyDifferences.push(
                    <React.Fragment key={`difference-feature-compare-manually`}>
                        <h4>Versions do not have the same number of features</h4>
                        <div className="differenceContent">
                            <p>Compare manually in the features section</p>
                        </div>
                    </React.Fragment>
                )

            }

            items[0].tags.map(tag =>{
                const otherTag = items[1].features ? items[1].features.find(tag2 => tag2.id == tag.id) : null;
                if(otherTag){
                    if(tag.label !== otherTag.label){
                        keyDifferences.push(
                            <React.Fragment key={`difference-tag-${tag.id}`}>
                                <h4>Different Tag Label:</h4>
                                <div className="differenceContent">
                                    <p>Tag ID: {tag.id}</p>
                                    <p><strong>{tag.label}</strong> and <strong>{tag.label}</strong></p>
                                </div>
                            </React.Fragment>
                        )
                    }
                }
            });

            if(items[0].tags.length !== items[0].tags.length){
                keyDifferences.push(
                    <React.Fragment key={`difference-tags-compare-manually`}>
                        <h4>Versions do not have the same number of tags</h4>
                        <div className="differenceContent">
                            <p>Compare manually in the tags section</p>
                        </div>
                    </React.Fragment>
                )
            }

            return keyDifferences;
        }

        return []
    }

    render(){
        const {items, versionOptions} = this.state;
        const keyDifferences = this.checkForKeyDifferences(items);
        const defaultValues = [
            (items[0] && versionOptions) ? versionOptions.find(option => option.value == items[0].versionid).value : null,
            (items[1] && versionOptions) ? versionOptions.find(option => option.value == items[1].versionid).value : null,
        ]

        return (
            <>
                <div className="historyColumnContainer">
                    <div className="historyColumn">                   
                        {versionOptions &&
                            <>
                                <Dropdown 
                                    placeholder="Select a Version"    
                                    fluid
                                    search
                                    selection
                                    options={versionOptions}
                                    onChange={(event, data)=> {this.selectVersion(data.value, 0)}}
                                    defaultValue={defaultValues[0]}
                                />
                                {items[0] && items[1] && items[0].modified && items[1].modified &&
                                    <span className="relativeAge">
                                        {(new Date(items[0].modified.date)).getTime() < (new Date(items[1].modified.date)).getTime() ? "Older": "Newer"}
                                    </span>
                                }
                            </>
                        }
                    </div>
                    <div className="historyColumn">
                        {versionOptions &&
                            <>
                            <Dropdown 
                                placeholder="Select a Version"    
                                fluid
                                search
                                selection
                                options={versionOptions}
                                onChange={(event, data)=> {this.selectVersion(data.value, 1)}}
                                defaultValue={defaultValues[1]}
                            />
                            {items[0] && items[1] && items[0].modified && items[1].modified &&
                                <span className="relativeAge">
                                    {(new Date(items[0].modified.date)).getTime() > (new Date(items[1].modified.date)).getTime() ? "Older": "Newer"}
                                </span>
                            }
                            </>
                        }
                    </div>
                </div>
                <details open>
                    <summary>Key Differences Report</summary>
                    <div className="differenceReport">
                        {keyDifferences.length > 0 ?
                            keyDifferences
                            :
                            <p>No differences to report</p>
                        }
                    </div>
                </details>
                <div className="historyColumnContainer">
                    <div className="historyColumn">
                        {items[0] &&
                            <ItemVersionView item={items[0]}/>
                        }
                    </div>
                    <div className="historyColumn">
                        {items[1] &&
                            <ItemVersionView item={items[1]}/>
                        }
                    </div>
                </div>
            </>
        );       
    }
}

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(ItemVersionCompare));