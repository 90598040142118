import React from 'react';

import mapStateToProps from '../../datastore/mapper.js';
import mapDispatchToProps from '../../datastore/dispatcher.js';

import {connect} from 'react-redux';

import {withRouter} from 'react-router-dom';

import { Segment, Icon, Message, Grid, Item, Checkbox } from 'semantic-ui-react';
import autoBind from 'react-autobind';

export class AudioRecorder extends React.Component{
    constructor(props){
        super();
        autoBind(this);
    }

    render(){

        const {audioRecorderFeature} = this.props;

        return(
            <React.Fragment>
                <Message attached='top'>
                    <Grid columns='equal'>
                        <Grid.Column>
                            <div className="featureid_label">Feature ID:</div>
                            <div className="feature_id">{audioRecorderFeature.id}</div>
                        </Grid.Column>
                        <Grid.Column>
                            <span>Allow Student Input: </span>
                            <Checkbox
                                id={`answerable-${audioRecorderFeature.id}`}
                                toggle={true}
                                onChange={()=>this.props.updateFeatureAllowInput(audioRecorderFeature.id, !audioRecorderFeature.allowUserInput)}
                                checked={audioRecorderFeature.allowUserInput}
                            />
                        </Grid.Column>
                    </Grid>
                </Message>
                <Segment attached>
                    <Item>
                        <Item.Content>
                            <Item.Header>
                                <Icon name='microphone' size='large' />
                                Audio Recorder
                            </Item.Header>
                            <Item.Description>
                                This audio recorder will be available to students
                            </Item.Description>
                        </Item.Content>
                    </Item>
                </Segment>
            </React.Fragment>
        )
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(AudioRecorder);