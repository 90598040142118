import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';

import AppReducer from './reducers/appreducer.js';      //need .js extensions

const storelogger = (store) => (next) => (action) => {
  console.log("Logged Action: ",action);
  next(action);
}

const createAppStore = (__PRELOADED_STATE__) => {
  return createStore(combineReducers({AppReducer}), __PRELOADED_STATE__ || {}, applyMiddleware(/*storelogger, */thunk));
}

export default createAppStore;

//export const appstore = createStore(combineReducers({AppReducer}), {}, applyMiddleware(/*storelogger, */thunk));