import React from 'react';

import mapStateToProps from '../../datastore/mapper.js';
import mapDispatchToProps from '../../datastore/dispatcher.js';

import {connect} from 'react-redux';

import {withRouter} from 'react-router-dom';

import AppWrapper from '../app_wrapper/index.js'

import ListUser from './list_user.js';
import InviteUser from './invite_user.js';
import RoleManager from './role_manager.js';

export class UserManagement extends React.Component{
    constructor(props){
        super();
    }

    componentDidMount(){
        this.props.getRoles();
    }

    userManagementType(_type){
        switch(_type){
            case "list":
                return <ListUser />;
            case "invite":
                return <InviteUser />;
            case "roles":
                return <RoleManager />;
        }
    }

    parsedURLSubtitle(urlpath){
        const parsedURL = urlpath.split("/");
        return parsedURL[2];
    }

    render(){

        const {location:{pathname}} = this.props;

        const parsedpath = this.parsedURLSubtitle(pathname);
        const UserManagementComponent = this.userManagementType(parsedpath);

        return(
            <AppWrapper title={'User Management'} subtitle={parsedpath}>
                <div className="usermanagement">
                    { UserManagementComponent }
                </div>
            </AppWrapper>
        )
    }
}

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(UserManagement));