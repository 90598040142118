import React from 'react';

import { Dropdown, Table, Popup, Icon, Input, Checkbox } from 'semantic-ui-react';

import autoBind from 'react-autobind';

import mapStateToProps from '../../datastore/mapper.js';
import mapDispatchToProps from '../../datastore/dispatcher.js';

import {connect} from 'react-redux';

export class TimerManager extends React.Component{
    constructor(props){
        super();
        this.state={
            timers: [],
            isloading: true
        };
        autoBind(this);
    }

    componentDidMount(){
        this.checkMetaDataAvailability();
    }

    async checkMetaDataAvailability(){
        const {appProps:{languages, proficiencies, skills}} = this.props;

        try{
            if(!languages || !languages.length) await this.props.getLanguages();
            if(!proficiencies || !proficiencies.length) await this.props.getProficiency();
            if(!skills || !skills.length) await this.props.getSkills();

            this.setState({
                timers: this.props.timers ? JSON.parse(JSON.stringify(this.props.timers)) : [],
                isloading: false
            });
        }
        catch(err){
            console.log(err);
        }
    }

    updateTimers(){
        this.props.updateTimers(this.state.timers);
    }

    addTimer(){
        const currenttimers = JSON.parse(JSON.stringify(this.state.timers));
        currenttimers.push({
            language: null,
            proficiency: null,
            skill: null,
            seconds: 60,
            allitems: false
        });
        this.setState({
            timers: currenttimers
        }, ()=>{
            this.props.updateTimers(this.state.timers);
        });
    }

    deleteTimer(timer_index){
        this.setState((prevState)=>({
            timers: prevState.timers.filter((_,index)=>index!==timer_index)
        }), ()=>{
            this.props.updateTimers(this.state.timers);
        });
    }

    updateTimerFeature(e, data, _index){
        const currenttimers = JSON.parse(JSON.stringify(this.state.timers));
        currenttimers[_index][data._for] = data.value;
        this.setState({
            timers: currenttimers
        }, ()=>{
            this.props.updateTimers(this.state.timers);
        });
    }

    updateTimerTime(e, _index){
        const selectorid_minutes = `#event_timer_minutes_${_index}`;
        const selectorid_seconds = `#event_timer_seconds_${_index}`;

        const minutesinp = document.querySelector(selectorid_minutes);
        const secondsinp = document.querySelector(selectorid_seconds);

        const totalsec = (parseInt(minutesinp.value) * 60) + (secondsinp.value ? parseInt(secondsinp.value) : 0);
        
        const currenttimers = JSON.parse(JSON.stringify(this.state.timers));
        currenttimers[_index].seconds = totalsec;

        this.setState({
            timers: currenttimers
        }, ()=>{
            this.props.updateTimers(this.state.timers);
        });
    }

    toggleAllItemsFlag(e, _index){
        const currenttimers = JSON.parse(JSON.stringify(this.state.timers));
        currenttimers[_index].allitems = !currenttimers[_index].allitems;

        this.setState({
            timers: currenttimers
        }, ()=>{
            this.props.updateTimers(this.state.timers);
        });
    }

    render(){
        
        const {appProps:{languages, proficiencies, skills}} = this.props;

        return(
            <React.Fragment>
                {!this.state.timers || !this.state.timers.length ?
                    <Popup content='Add Timer' trigger={
                        <Icon link name="hourglass end" color="green" size="big" onClick={this.addTimer}/>
                    }/>
                :
                    <Table celled>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>
                                    <Popup content='Add Timer' trigger={
                                        <Icon link name="hourglass end" color="green" size="big" onClick={this.addTimer}/>
                                    }/>
                                </Table.HeaderCell>
                                <Table.HeaderCell>Features</Table.HeaderCell>
                                <Table.HeaderCell>Time</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        {this.state.timers && this.state.timers.length && !this.state.isloading?
                            <Table.Body>
                                {this.state.timers.map((timerobj, timer_index)=>{
                                    return (
                                        <Table.Row key={`event-timers-${timer_index}`}>
                                            <Table.Cell>
                                                <Popup content='Delete Timer' trigger={
                                                    <Icon onClick={()=>this.deleteTimer(timer_index)} link name="minus circle" color="red" size="big"/>
                                                }/>
                                            </Table.Cell>
                                            <Table.Cell>
                                                {proficiencies && proficiencies.length &&
                                                    <Dropdown
                                                        value={timerobj.proficiency}
                                                        _for='proficiency'
                                                        onChange={(e, data)=>this.updateTimerFeature(e, data, timer_index)}
                                                        fluid
                                                        floating
                                                        search
                                                        selection
                                                        clearable
                                                        placeholder='Proficiency'
                                                        options={proficiencies.map(prof=>{
                                                            return{
                                                                key:prof.difficultydesignation,
                                                                text:(prof.label[0].toUpperCase() + prof.label.slice(1)),
                                                                value:prof.id
                                                            }
                                                        })}
                                                    />
                                                }
                                                {languages && languages.length &&
                                                    <Dropdown
                                                        value={timerobj.language}
                                                        _for='language'
                                                        onChange={(e, data)=>this.updateTimerFeature(e, data, timer_index)}
                                                        fluid
                                                        floating
                                                        search
                                                        selection
                                                        clearable
                                                        placeholder='Language'
                                                        options={languages.map(lang=>{
                                                            return {
                                                                key:lang.shortname,
                                                                text:(lang.name[0].toUpperCase() + lang.name.slice(1)),
                                                                value:lang.id
                                                            }
                                                        })}
                                                    />
                                                }
                                                {skills && skills.length &&
                                                    <Dropdown
                                                        value={timerobj.skill}
                                                        _for='skill'
                                                        onChange={(e, data)=>this.updateTimerFeature(e, data, timer_index)}
                                                        fluid
                                                        floating
                                                        search
                                                        selection
                                                        clearable
                                                        placeholder='Skill'
                                                        options={skills.map(skill=>{
                                                            return{
                                                                key:skill.id,
                                                                text:(skill.name[0].toUpperCase() + skill.name.slice(1)),
                                                                value:skill.id
                                                            }
                                                        })}
                                                    />
                                                }
                                            </Table.Cell>
                                            <Table.Cell collapsing>
                                                <div>
                                                    <Popup content='This toggle when enabled will affect all items in this test event, or affect only items that have timer enabled when disabled' trigger={
                                                        <Checkbox label="All Items" toggle defaultChecked={timerobj.allitems} onChange={(e)=>this.toggleAllItemsFlag(e, timer_index)}/>
                                                    }/>
                                                </div>
                                                <div><Input value={timerobj.seconds ? Math.floor(timerobj.seconds / 60) : ''} placeholder='Minutes' size='mini' onChange={(e)=>this.updateTimerTime(e, timer_index)}><input id={`event_timer_minutes_${timer_index}`} type="number"/></Input></div>
                                                <div><Input value={timerobj.seconds ? Math.round(timerobj.seconds % 60) : ''} placeholder='Seconds' size='mini' onChange={(e)=>this.updateTimerTime(e, timer_index)}><input id={`event_timer_seconds_${timer_index}`} type="number" max="59"/></Input></div>
                                            </Table.Cell>
                                        </Table.Row>
                                    )
                                })}
                            </Table.Body>
                        :null}
                    </Table>
                }
            </React.Fragment>
        )
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(TimerManager);