export default class DOCAPIUtility{
    constructor(){
        this.apiKey = null;
        this.bearertoken = null;
    }

    static setAPIKey(apikey){
        this.apiKey = apikey;
    }

    static setBearerToken(token){
        this.bearertoken = token;
    }
    static removeBearerToken(){
        this.bearertoken = null;
    }

    static objToQueryString(obj){
        let queryString = '';
        try{
            if(obj && Object.keys(obj).length){
                queryString = '?';
                Object.keys(obj).forEach(key=>{
                    if(obj[key]){
                        queryString += (queryString!=='?' ? '&' : '') + `${key}=${obj[key]}`
                    }
                })
            }
        }catch(err){
            console.log(err);
        }
        return queryString;
    }

    static makeRequest(method,url,data){
        return new Promise((resolve, reject)=>{
            const xhr = new XMLHttpRequest();
            xhr.open(method,url,true);
            xhr.onload = ()=>{
                if ( (xhr.status >= 200 && xhr.status < 300) && xhr.readyState == 4){
                    resolve(xhr.responseText!=undefined && xhr.responseText!=null && xhr.responseText!="" && xhr.responseText.length>0?xhr.responseText:"Successfully submitted");
                } else {
                    reject({
                        status: xhr.status,
                        statusText: xhr.statusText
                    });
                }
                //console.log(this.xhr.status, "---"+this.xhr.responseText+"---", typeof this.xhr.responseText, this.xhr.responseText.length);
            };
            xhr.onerror = ()=>{
                console.log(xhr.status, xhr.statusText, xhr.response);
                reject({
                    status: xhr.status,
                    statusText: xhr.statusText
                });
                //window.location.href = "/";
            };
            if(this.apiKey){
                xhr.setRequestHeader('apikey', this.apiKey);
            }
            if(this.bearertoken){
                xhr.setRequestHeader('Authorization','Bearer '+this.bearertoken);
            }
            if((method=="PUT" || method=="POST") && data){
                try{
                    if(!data.has("mediafile")){
                        xhr.setRequestHeader('Content-Type', 'application/json');
                    }
                }catch(err){
                    xhr.setRequestHeader('Content-Type', 'application/json');
                }
                xhr.send(data);
            }
            else{
                xhr.send();
            }
        });
    }

}