import "regenerator-runtime/runtime";

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import App from './app.js';

import createAppStore from './datastore/store.js';

import 'jointjs/dist/joint.core.css';
import 'semantic-ui-css/semantic.min.css';
import './assets/importer.scss';

ReactDOM.hydrate(
    <Provider store={createAppStore(window.__PRELOADED_STATE__)}>
        <App serverload={false}/>
    </Provider>
    ,
    document.getElementById('app-root')
);
