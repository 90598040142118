import React from 'react';

import mapStateToProps from '../../datastore/mapper.js';
import mapDispatchToProps from '../../datastore/dispatcher.js';

import {withRouter} from 'react-router-dom';

import {connect} from 'react-redux';
import { Message, Segment } from 'semantic-ui-react';

import AppWrapper from '../app_wrapper/index.js'

export class __404__ extends React.Component{
    constructor(props){
        super();
    }

    render(){
        return(
            <React.Fragment>
            {this.props.appProps.user ?
                <AppWrapper title={'404'} subtitle={null}>
                    <div className="__404__">
                        Page Does Not Exists
                    </div>
                </AppWrapper>
                :
                <Segment>
                    <Message negative>
                        <Message.Header>404</Message.Header>
                        <p>Page Does Not Exists</p>
                    </Message>
                </Segment>
            }
            </React.Fragment>
        )
    }
}

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(__404__));