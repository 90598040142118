import React from 'react';
import mapStateToProps from '../../../datastore/mapper.js';
import mapDispatchToProps from '../../../datastore/dispatcher.js';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import AppWrapper from '../../app_wrapper/index.js';
import {Tab, Icon} from 'semantic-ui-react';
import ItemVersionList from './item_version_list';

import ItemVersionCompare from './item_version_compare';

export class ItemHistory extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            item: null,
            raw: null
        }
    }

    componentDidMount(){
        this.getItem();
    }

    async getItem(){
        const item = await this.props.getItems({
            pattern: null,
            proficiency: null,
            language: null,
            id: this.props.match.params.uuid,
            expandedData: true,
        })

        this.setState({item: item.dataset})
    }

    render(){
        const {item} = this.state;
        
        const title = (
            <>
                <Icon className="backButton" name='arrow left' onClick={()=>{window.history.back()}}/>
                <span>Item History</span>
            </>
        );

        const panes = [
            {
                menuItem: 'Versions',
                render: () =>
                    <Tab.Pane attached='top'>
                        <ItemVersionList item={this.state.item}/>
                    </Tab.Pane>
            },
            {
                menuItem: 'Compare Versions',
                render: () =>
                    <Tab.Pane attached='top'>
                        <ItemVersionCompare defaultItem={this.state.item[0]}/>
                    </Tab.Pane>
            }
        ];

        return(
            <AppWrapper title={title}>
                <div className="historyContainer">
                    {item &&
                        <Tab panes={panes} defaultActiveIndex={0} />
                    }
                </div>
            </AppWrapper>
        )
    }
}

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(ItemHistory));