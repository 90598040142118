import React from 'react';
import mapStateToProps from '../../datastore/mapper.js';
import mapDispatchToProps from '../../datastore/dispatcher.js';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import { Grid, Input, Button, Divider, List, Dimmer, Loader, Segment, Dropdown, Popup, Header, Icon, Modal, Table, Confirm } from 'semantic-ui-react';
import autoBind from 'react-autobind';
import ItemHistoryButton from './item_history/item_history_button.js'

import METADATA from '../../utilities/metadata.json';

export class ListItems extends React.Component{
    constructor(props){
        super();
        this.state={
            currentItemSettings:{
                pattern: null,
                proficiency: null,
                language: null,
                skill: null,
                id: null
            },
            deleteItem:{
                showConfirmation: false,
                itemInfo:null
            },
            sortedby: null,
            loadingItems: false
        };
        autoBind(this);
    }

    componentDidMount(){
        this.checkItemMetaDataAvailability();
    }

    async checkItemMetaDataAvailability(){
        const {appProps:{languages, proficiencies, skills, status}} = this.props;

        try{
            if(!languages || !languages.length) await this.props.getLanguages();
            if(!proficiencies || !proficiencies.length) await this.props.getProficiency();
            if(!skills || !skills.length) await this.props.getSkills();
            if(!status.items || !!status.items.length) await this.props.getItemStatuses();
        }
        catch(err){
            console.log(err);
        }
    }

    updateSearchPattern(e, {value}){
        this.setState({currentItemSettings:{pattern: value && value.length ? value : ''}});
    }

    updateItemId(e, {value}){
        this.setState({currentItemSettings:{id: value && value.length ? value : ''}});
    }

    updateLanguageSelection(event, {value}){
        this.setState((prevState)=>({
            currentItemSettings:{
                ...prevState.currentItemSettings,
                language:!value.length || value===""?null:value
            }
        }));
    }

    updateProficiencySelection(event, {value}){
        this.setState((prevState)=>({
            currentItemSettings:{
                ...prevState.currentItemSettings,
                proficiency:!value.length || value===""?null:value
            }
        }));
    }

    updateSkillSelection(event, {value}){
        this.setState((prevState)=>({
            currentItemSettings:{
                ...prevState.currentItemSettings,
                skill:!value.length || value===""?null:value
            }
        }));
    }

    formatedDate(dateStr) {
        let theDate = new Date(dateStr);
        return (theDate.getMonth() + 1) + '/' + theDate.getDate() + '/' + theDate.getFullYear();
    }

    editItem(item){
        this.props.history.push(`/items/edit?id=${item.id}`);
    }
    cloneItem(item){
        this.props.history.push(`/items/clone?id=${item.id}`);
    }

    async getItems(all){
        this.setState({loadingItems:true});
        try{
            if(typeof all==='boolean' && all){
                const allItems = await this.props.getItems();
            }
            else{
                const searchedItem = await this.props.getItems(this.state.currentItemSettings);
            }
        }catch(err){console.log(err);}
        this.setState({loadingItems:false});
    }

    toggleDeleteConfirmation(itemID){
        const {appProps:{items}} = this.props;

        if(typeof itemID!=='boolean' && itemID && items && items.length){
            this.setState({
                deleteItem:{
                    showConfirmation: true,
                    itemInfo: items.filter(item=>item.id===itemID).length && items.filter(item=>item.id===itemID)[0]
                }
            });
        }
        else{
            this.setState({
                deleteItem:{
                    showConfirmation: false,
                    itemInfo: null
                }
            });
        }
    }

    sortItemBy(propname){
        var newsortedby;
        if(propname && (!this.state.sortedby || (this.state.sortedby && this.state.sortedby.property!==propname))){
            newsortedby = {
                ascending: true,
                property: propname
            }
        }
        else if(propname && this.state.sortedby.property===propname){
            newsortedby = {
                ascending: !this.state.sortedby.ascending,
                property: propname
            }
        }
        this.setState({
            sortedby: newsortedby
        });
    }
    

    render(){
        const {appProps:{items, languages, proficiencies, skills, status}} = this.props;

        return(
            <React.Fragment>
                <div className="listtags">

                    <Grid columns='equal'>
                        <Grid.Row>
                            <Grid.Column>
                                <Input fluid placeholder='Search Item By Title Or Tags' onChange={this.updateSearchPattern} />
                            </Grid.Column>
                            <Grid.Column>
                                <Input fluid placeholder='Search Item By Id' onChange={this.updateItemId} />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column>
                                <Dropdown
                                    value={this.state.currentItemSettings.proficiency}
                                    onChange={this.updateProficiencySelection}
                                    fluid
                                    floating
                                    search
                                    selection
                                    clearable
                                    placeholder='Proficiency'
                                    options={proficiencies && proficiencies.map(prof=>{
                                        return{
                                            key:prof.difficultydesignation,
                                            text:(prof.label[0].toUpperCase() + prof.label.slice(1)),
                                            value:prof.id
                                        }
                                    })}
                                />
                            </Grid.Column>
                            <Grid.Column>
                                <Dropdown
                                    value={this.state.currentItemSettings.language}
                                    onChange={this.updateLanguageSelection}
                                    fluid
                                    floating
                                    search
                                    selection
                                    clearable
                                    placeholder='Language'
                                    options={languages && languages.map(lang=>{
                                        return {
                                            key:lang.shortname,
                                            text:(lang.name[0].toUpperCase() + lang.name.slice(1)),
                                            value:lang.id
                                        }
                                    })}
                                />
                            </Grid.Column>
                            <Grid.Column>
                                <Dropdown
                                    value={this.state.currentItemSettings.skill}
                                    onChange={this.updateSkillSelection}
                                    fluid
                                    floating
                                    search
                                    selection
                                    clearable
                                    placeholder='Skill'
                                    options={skills && skills.map(skill=>{
                                        return{
                                            key:skill.id,
                                            text:(skill.name[0].toUpperCase() + skill.name.slice(1)),
                                            value:skill.id
                                        }
                                    })}
                                />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column>
                                {!this.state.loadingItems?
                                    <Button onClick={()=>this.getItems()}><Icon name='search'/> Search</Button>
                                :
                                    <Button loading>Loading</Button>
                                }
                            </Grid.Column>
                            <Grid.Column>
                                <Button floated="right" onClick={()=>{this.props.history.push(`/items/create`)}} color='green'>Create</Button>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>

                    <Divider hidden />
                    {items && items.length &&
                        <Segment raised>
                            <Table compact celled definition>
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell />
                                        {Object.keys(METADATA.MAPPEDITEMTABLEHEADERS).map((header, _index)=>{
                                            return (
                                                <Table.HeaderCell key={`itemlist-headers-${_index}`}>
                                                    <div className="item_table_headers">
                                                        {METADATA.MAPPEDITEMTABLEHEADERS[header]}
                                                        {_index===Object.keys(METADATA.MAPPEDITEMTABLEHEADERS).length - 1 ?
                                                        <React.Fragment>
                                                            <br/>(MM/DD/YYYY)
                                                        </React.Fragment>
                                                        :null}
                                                        <Popup content={`Sort Items By ${METADATA.MAPPEDITEMTABLEHEADERS[header]}`} trigger={
                                                            <Icon
                                                                name={
                                                                    !this.state.sortedby || (this.state.sortedby && this.state.sortedby.property!==header)?
                                                                    "sort"
                                                                    :
                                                                    (
                                                                        this.state.sortedby.property===header && this.state.sortedby.ascending ?
                                                                        "sort down"
                                                                        :
                                                                        "sort up"
                                                                    )
                                                                }
                                                                link
                                                                onClick={()=>this.sortItemBy(header)}
                                                            />
                                                        }/>
                                                    </div>
                                                </Table.HeaderCell>
                                            )
                                        })}
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                    {items
                                    .filter(item=>{
                                        var filteritem = true;
                                        if(this.state.currentItemSettings.proficiency && this.state.currentItemSettings.proficiency!==item.proficiency){
                                            filteritem = false;
                                        }
                                        if(this.state.currentItemSettings.language && this.state.currentItemSettings.language!==item.language){
                                            filteritem = false;
                                        }
                                        if(this.state.currentItemSettings.skill && this.state.currentItemSettings.skill!==item.skill){
                                            filteritem = false;
                                        }
                                        return filteritem;
                                    })
                                    .sort((item_a, item_b)=>{
                                        if(!this.state.sortedby){
                                            return 0;
                                        }
                                        else{
                                            if(this.state.sortedby.ascending && item_a[this.state.sortedby.property]){
                                                return item_a[this.state.sortedby.property].localeCompare(item_b[this.state.sortedby.property]);
                                            }
                                            else if(item_a[this.state.sortedby.property]){
                                                return -1 * item_a[this.state.sortedby.property].localeCompare(item_b[this.state.sortedby.property]);
                                            }
                                        }
                                    })
                                    .map(item=>{
                                        return (
                                            <Table.Row key={`item-${item.id}`}>
                                                <Table.Cell collapsing>
                                                    <Popup content='Archive Item' trigger={
                                                        <Button circular icon='trash' color='red' size='tiny' onClick={()=>this.toggleDeleteConfirmation(item.id)}></Button>
                                                    }/>
                                                    <Popup content='Edit Item' trigger={
                                                        <Button circular icon='pencil' color='yellow' size='tiny' onClick={()=>this.editItem(item)}></Button>
                                                    }/>
                                                    <ItemHistoryButton itemId={item.id}/>
                                                    <Popup content='Clone Item' trigger={
                                                        <Button circular icon='clone outline' color='blue' size='tiny' onClick={()=>this.cloneItem(item)}></Button>
                                                    }/>
                                                </Table.Cell>
                                                <Table.Cell>
                                                    <span>
                                                        {item.title}
                                                        &nbsp;
                                                        <Popup size="mini" content={`Status: ${status.items && status.items.find(indivstatus=>indivstatus.id===item.status) && status.items.find(indivstatus=>indivstatus.id===item.status).status || 'NA'}`} trigger={
                                                            <Icon size="small" color="blue" name='exclamation circle' />
                                                        }/>
                                                        {item.assigned &&
                                                            <Popup size="mini" content={`Assigned To You`} trigger={
                                                                <Icon size="small" color="orange" name="user" />
                                                            }/>
                                                        }
                                                        {item.cloned_from &&
                                                            <Popup size="mini" content={`This Item Was Cloned From Another Item: ${item.cloned_from}`} trigger={
                                                                <Icon size='small' color="olive" name="clone" />
                                                            }/>
                                                        }
                                                    </span>
                                                </Table.Cell>
                                                <Table.Cell>
                                                    <span className="proficiency_label">{
                                                        proficiencies.filter(prof=>prof.id===item.proficiency).length ? 
                                                        proficiencies.filter(prof=>prof.id===item.proficiency)[0].label
                                                        :
                                                        item.proficiency
                                                    }</span>
                                                </Table.Cell>
                                                <Table.Cell>
                                                    <span className="language_label">{
                                                        languages.filter(lang=>lang.id===item.language).length ?
                                                        languages.filter(lang=>lang.id===item.language)[0].name
                                                        :
                                                        item.language
                                                    }</span>
                                                </Table.Cell>
                                                <Table.Cell>
                                                    <span className="skill_label">{
                                                        skills.filter(skill=>skill.id===item.skill).length ?
                                                        skills.filter(skill=>skill.id===item.skill)[0].name
                                                        :
                                                        item.skill
                                                    }</span>
                                                </Table.Cell>
                                                <Table.Cell><span>{item.modifiedby.name}</span></Table.Cell>
                                                <Table.Cell><span>{this.formatedDate(item.modifieddate)}</span></Table.Cell>
                                            </Table.Row>
                                        )
                                    })}
                                </Table.Body>
                            </Table>
                        </Segment>
                        || null
                    }
                </div>
                
                <Confirm
                    open={this.state.deleteItem.showConfirmation}
                    header="Delete Item"
                    content={`Are You Sure You Want To Delete Item: ${this.state.deleteItem.itemInfo ? this.state.deleteItem.itemInfo.id + ", "+this.state.deleteItem.itemInfo.title : 'Item Info Not Found'}?`}
                    cancelButton='No'
                    confirmButton="Yes"
                    onCancel={()=>this.toggleDeleteConfirmation(false)}
                    onConfirm={()=>{this.props.archiveItem(this.state.deleteItem.itemInfo);this.toggleDeleteConfirmation(false)}}
                />

            </React.Fragment>
        )
    }
}

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(ListItems));