import React from 'react';

import mapStateToProps from '../../datastore/mapper.js';
import mapDispatchToProps from '../../datastore/dispatcher.js';

import {connect} from 'react-redux';

import {withRouter, Link} from 'react-router-dom';

import { Grid, Header, Segment, Menu, Dropdown, Image, Popup, Button } from 'semantic-ui-react';

import autoBind from 'react-autobind';

import LeftMenu from './leftmenu.js';

import APPENV from '../../../env.json';

export class AppWrapper extends React.Component{
    constructor(props){
        super();
        this.state={showScrollButton:false, maxScroll:0, isAutoScrolling:false, error:null}
        autoBind(this);
    }

    componentDidMount(){
        document.addEventListener('wheel', this.stopScrollOnWheelMove);
        document.addEventListener('scroll', this.checkAndShowScroll);
    }
    componentWillUnmount(){
        document.removeEventListener('wheel', this.stopScrollOnWheelMove);
        document.removeEventListener('scroll', this.checkAndShowScroll);
    }
    checkAndShowScroll(){
        let showscroll = false;
        let maxscroll = 0;
        if(document.body.scrollTop > 20 || document.documentElement.scrollTop > 20){
            showscroll = !this.state.isAutoScrolling && true;
            maxscroll = document.body.scrollTop || document.documentElement.scrollTop;
        }
        this.setState({showScrollButton:showscroll, maxScroll:maxscroll});
    }
    stopScrollOnWheelMove(){
        if(this.state.isAutoScrolling){
            this.setState({isAutoScrolling:false});
            if(this.scrolltopinterval){clearInterval(this.scrolltopinterval)}
        }
    }

    easeInCubic(t){ return t*t*t; }

    scrollToTop(){
        this.setState({isAutoScrolling:true});
        this.scrolltopinterval = setInterval(()=>{
            const currentScrollNormalized = (this.state.maxScroll - 5)/this.state.maxScroll;
            const easedPercentNormalized = this.easeInCubic(currentScrollNormalized);
            const easedVal = Math.floor(easedPercentNormalized * this.state.maxScroll);
            if(easedVal > 0){
                document.body.scrollTop = easedVal;
                document.documentElement.scrollTop = easedVal;
                this.setState({showScrollButton:false, maxScroll:easedVal});
            }
            else if(easedVal <= 0 || isNaN(easedVal)){
                this.setState({showScrollButton:false, maxScroll:0, isAutoScrolling:false});
                clearInterval(this.scrolltopinterval);
            }
        }, 5);
    }

    async setDefaultOrganization(orgid){
        try{
            const defaultorgset = await this.props.setDefaultOrganization(orgid);
            await this.props.getLoggedInUserInfo();
        }catch(err){
            console.log(err);
            this.setState({
                error: {
                    type: "BackEnd Error",
                    message: `Could not set default organization`
                }
            });
        }
    }

    render(){
        const {appProps:{user}, logoutUser} = this.props;
        
        const defaultuserorg = user && user.organizations.length ? user.organizations.find(org=>org.isDefault) : {};

        const _fontcolor = defaultuserorg.theme && defaultuserorg.theme.color ? defaultuserorg.theme.color : '#1d1d1d';
        const _bgcolor = defaultuserorg.theme && defaultuserorg.theme.bgcolor ? defaultuserorg.theme.bgcolor : '#ffffff';

        return(
            <React.Fragment>
                <Menu style={{backgroundColor: _bgcolor}} inverted >
                    <Menu.Item>
                        <Image size='mini' src={`${APPENV.APIEndpoint}/downloads/organizations/logos/${defaultuserorg.logo || ''}`} />
                    </Menu.Item>
                    <Menu.Item style={{color: _fontcolor}} header>Proficiency Placement</Menu.Item>
                    <Menu.Item>
                        <Dropdown style={{color: _fontcolor}} text={defaultuserorg.name || ''}>
                            <Dropdown.Menu style={{backgroundColor: _bgcolor}} inverted="true">
                                {user ? user.organizations.map(org=>{
                                    return <Dropdown.Item key={`organization-${org.organization_id}`} onClick={()=>this.setDefaultOrganization(org.organization_id)}><span style={{color: _fontcolor}}>{org.name}</span></Dropdown.Item>
                                }):null}
                            </Dropdown.Menu>
                        </Dropdown>
                    </Menu.Item>
                    <Menu.Menu position='right'>
                        <Dropdown style={{color: _fontcolor}} item icon='user' text={user ? user.lastname+", "+user.firstname : 'NA'}>
                            <Dropdown.Menu style={{backgroundColor: _bgcolor}} inverted="true">
                                <Dropdown.Item key={'menu-item-settings'}><Link style={{color: _fontcolor}} to="/account_settings">Account Settings</Link></Dropdown.Item>
                                <Dropdown.Divider />
                                <Dropdown.Item key={'menu-item-apidocs'} inverted="true" onClick={()=>{this.props.history.push("/docs")}}><span style={{color: _fontcolor}}>API Docs</span></Dropdown.Item>
                                <Dropdown.Divider />
                                <Dropdown.Item key={'menu-item-logout'} inverted="true" onClick={()=>logoutUser("/")}><span style={{color: _fontcolor}}>Logout</span></Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </Menu.Menu>
                </Menu>
                <Grid columns={2} attached="true">
                    <Grid.Row>
                        <Grid.Column width={3}>
                            <LeftMenu />
                        </Grid.Column>
                        <Grid.Column width={12}>
                            <Header as='h2' attached='top' style={{backgroundColor: _bgcolor, color: _fontcolor}} inverted>
                                <Grid>
                                    <Grid.Row>
                                        <Grid.Column width={12}>{this.props.title}</Grid.Column>
                                        <Grid.Column width={4}><span className="subtitle">{this.props.subtitle}</span></Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </Header>
                            <Segment attached>
                                {this.props.children}
                            </Segment>
                        </Grid.Column>
                        <Grid.Column width={2}>
                            {this.state.showScrollButton?
                                <div className="atthebottom">
                                    <Popup content='Go Back To Top' trigger={
                                        <Button onClick={this.scrollToTop} icon='arrow up'/>
                                    }/>
                                </div>
                            :null}
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </React.Fragment>
        )
    }
}

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(AppWrapper));