import React from 'react';
import mapStateToProps from '../../datastore/mapper.js';
import mapDispatchToProps from '../../datastore/dispatcher.js';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import AppWrapper from '../app_wrapper/index.js'
import PasswordReset from '../password_reset';
import {Message} from 'semantic-ui-react';
import autoBind from 'react-autobind';

export class AccountSettings extends React.Component{
    constructor(props){
        super();
        this.state = {
            userProfile: null,
            error: ""
        }
        autoBind(this);
    }

    componentDidMount(){
        this.getUserProfile();
    }

    async getUserProfile(){
        try{
            const result = await this.props.getUserProfile();
            const parsedResult = JSON.parse(result);
            if(parsedResult.success){
                this.setState({userProfile: parsedResult.dataset});
            }
        }catch(error){
            console.log(error);
            this.setState({error: "Could not get user profile"});
        }
    }

    render(){
        const {userProfile, error} = this.state;
        return(
            <AppWrapper title={'Account Settings'}>
                <section className="accountSettingsContainer">
                    {userProfile == null && error == "" &&
                        <div className="ui segment">
                            <div className="ui active dimmer">
                                <div className="ui loader"></div>
                            </div>
                        </div>
                    }
                    {error !== "" &&
                        <Message
                            error
                            header='An Error Occured'
                            content='Could not get Account Data'
                        />
                    }
                    {error == "" && userProfile !== null &&
                        <div className="resetContainer">
                            <h3>Name:</h3>
                            <span>{userProfile.firstname} {userProfile.lastname}</span>
                            <h3>Email:</h3>
                            <span>{userProfile.email}</span>
                            <h3>New Password:</h3>
                            <PasswordReset email={userProfile.email} noLogout={true} showHeader={false}/>
                        </div>
                    }
                </section>
            </AppWrapper>
        )
    }
}

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(AccountSettings));