import React from 'react';

import mapStateToProps from '../../datastore/mapper.js';
import mapDispatchToProps from '../../datastore/dispatcher.js';

import {connect} from 'react-redux';

import autoBind from 'react-autobind';

import { Item, Icon } from "semantic-ui-react";

import TextEditor from './text_editor.js';
import OptionSelector from './option_selector.js';
import AudioRecorder from './audio_recorder.js';
import MediaSelector from './media_selector.js';

export class FeaturesManager extends React.Component{
    constructor(props){
        super();
        autoBind(this);
    }

    render(){
        const {itemFeature} = this.props

        return(
            <div className="itemfeaturescontainer">

                {itemFeature && itemFeature.type==="texteditor" &&
                    <TextEditor
                        textEditorFeature={itemFeature}
                        updateTextEditorFeatureType={this.props.updateTextEditorFeatureType}
                        getTextEditorFeatureHTML={this.props.getTextEditorFeatureHTML}
                        setTextEditorFeatureHTML={this.props.setTextEditorFeatureHTML}
                        updateFeatureAllowInput={this.props.updateFeatureAllowInput}
                    />
                }

                {itemFeature && itemFeature.type==="optionselector" &&
                    <OptionSelector
                        optionSelectorFeature={itemFeature}
                        updateOptionSelectorFeature={this.props.updateOptionSelectorFeature}
                        updateFeatureAllowInput={this.props.updateFeatureAllowInput}
                    />
                }

                {itemFeature && itemFeature.type==="audiorecorder" &&
                    <AudioRecorder
                        audioRecorderFeature={itemFeature}
                        updateFeatureAllowInput={this.props.updateFeatureAllowInput}
                    />
                }

                {itemFeature && itemFeature.type==="media" &&
                    <MediaSelector
                        mediaFeature={itemFeature}
                        updateMediaFeature={this.props.updateMediaFeature}
                    />
                }

            </div>
        )
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(FeaturesManager);