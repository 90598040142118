import React from 'react';

import autoBind from 'react-autobind';

import mapStateToProps from '../../datastore/mapper.js';
import mapDispatchToProps from '../../datastore/dispatcher.js';

import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

import { Segment, Message, Input, Grid, Divider, Button, Popup, Checkbox, Table, Modal, Loader, Dimmer, Icon, Confirm, Label, Dropdown, Header, Rating } from 'semantic-ui-react';

import BBRTE from '../../assets/lib/bitbuildrte.dev.js';
import BBRTEIconList from '../../utilities/RTEIcons.json';

import Diagrammer from '../diagrammer/';
import RatingsManager from './ratings_manager.js';
import HashManager from './hash_manager.js';
import TimerManager from './timer_manager.js';

import GeneralUtility from '../../utilities/generalUtility.js';

import DiagrammerTemplate_BACKUP from './templatebackup.json';
import METADATA from '../../utilities/metadata.json';

export class EditEvent extends React.Component{
    constructor(props){
        super();
        this.state={
            id: null,
            title: null,
            proctored: false,
            showResults: false,
            deadline: null,
            organization_ids: [],
            isEditing: false,
            cloned_from: null,
            notesHTML: null,
            template: false,
            practice: false,
            practice_type: null,
            startingTemplate: false,
            showNotesContainer: false,
            contextmenuprop:{
                show: false,
                type: null,
                coordinates: null,
                id: null
            },
            graph:{
                conditionals: [],
                links: [],
                groups: [],
                metaInfo: {
                    coordinates: {
                        x: 0,
                        y: 0
                    },
                    zoom: 0.5,
                    ratings: []
                },
                timers: []
            },
            selectedNode: {
                id: null,
                type: null
            },
            minimizediagrammer: true,
            showSaveConfirmation:false,
            error: {
                type: null,
                message: null
            },
            awatingresponse: false,
            isAdaptive: false,
            tempconditiongroup: null,
            generatingtemplate: false,
            collectionssortedby: null,
            minimizedrating: true,
            hashes: []
        };

        this.notesTextEditor = null;

        this.DIAGRAMMERTEMPLATE = DiagrammerTemplate_BACKUP;
        this.RATINGSTEMPLATE = JSON.parse(JSON.stringify(DiagrammerTemplate_BACKUP.ratings));

        autoBind(this);
    }

    componentDidMount(){
        this.getTemplates();
    }
    componentWillUnmount(){
        if(this.diagObj){
            this.diagObj.destroy();
            this.diagObj = null;
        }
        document.getElementById("contextmenu-container").removeEventListener('click', this.contextMenuClickEventHandler);
    }

    async getTemplates(){
        try{
            //throw new Error("Test Error");
            if(!this.props.appProps.eventtemplate){
                const EVENTTEMPLATE = await this.props.getEventTemplate();
                if(EVENTTEMPLATE.success && EVENTTEMPLATE.dataset){
                    this.DIAGRAMMERTEMPLATE = JSON.parse(JSON.stringify(EVENTTEMPLATE.dataset));
                    this.RATINGSTEMPLATE = JSON.parse(JSON.stringify(EVENTTEMPLATE.dataset.ratings));
                }
            }
            else{
                this.DIAGRAMMERTEMPLATE = JSON.parse(JSON.stringify(this.props.appProps.eventtemplate));
                this.RATINGSTEMPLATE = JSON.parse(JSON.stringify(this.props.appProps.eventtemplate.ratings));
            }
        }
        catch(err){}
        this.prepComponent();
    }

    prepComponent(){
        this.prepMinDate();

        document.getElementById("contextmenu-container").addEventListener('click', this.contextMenuClickEventHandler);

        this.diagObj = new Diagrammer({
            container: 'graph_paper',
            graph: this.state.graph,
            toggleContextMenu: this.toggleContextMenu,
            updateGraphMetaInfo: this.updateGraphMetaInfo,
            setSelectedNode: this.setSelectedNode,
            translateNode: this.translateNode,
            addLink: this.addLink
        });
        this.diagObj.initDiagrammer();

        this.getMetadata();
        this.parseQueryParam();
    }

    prepMinDate(){
        const currentDate = (new Date()).toISOString();
        const parsedCurrentDate  = currentDate.split(":");
        this.minDate = `${parsedCurrentDate[0]}:${parsedCurrentDate[1]}`;
    }

    parseQueryParam(){
        const {location:{search}} = this.props;
        try{
            const parsedQueryParam = search.split("?");
            if(parsedQueryParam && parsedQueryParam.length === 2){
                const testeventId = parsedQueryParam[1].split('id=')[1];
                if (testeventId) {
                    this.prepCurrentTestEvent(testeventId);
                }
            }
            else{
                this.prepCurrentTestEvent();
            }
        }catch(err){
            this.prepCurrentTestEvent();
        }
    }

    async prepCurrentTestEvent(testeventID){
        const {appProps:{testevents}, clone} = this.props;

        const _uuid4 = GeneralUtility.generateUUIDV4();

        if(testeventID){
            try{
                if(testevents && testevents.filter(_event=>_event.id===testeventID).length){
                    const currentlyEditingTestEvent = JSON.parse(JSON.stringify(testevents.filter(_event=>_event.id===testeventID)[0]));
                    this.prepGraph(currentlyEditingTestEvent);
                }
                else{
                    const eventDetail = await this.props.getEvents({id: testeventID});
                    if(eventDetail.dataset.length && eventDetail.dataset.filter(_event=>_event.id===testeventID).length){
                        const currentlyEditingTestEvent = JSON.parse(JSON.stringify(eventDetail.dataset.filter(_event=>_event.id===testeventID)[0]));
                        this.prepGraph(currentlyEditingTestEvent);
                    }
                }
            }catch(err){
                //console.log(err);
                this.setState({
                    id: _uuid4,
                    isEditing: false
                });
            }
        }
        else{
            this.setState({
                id: _uuid4,
                isEditing: false
            }, ()=>{
                if(this.state.showNotesContainer){
                    this.notesTextEditor = new BBRTE({id: 'event-notes', container: `notes-texteditor`, icons: BBRTEIconList, content: ''});
                }
                this.generateTemplate();
            });
        }
    }

    prepGraph(newGraphInfo){
        const {clone} = this.props;
        const _uuid4 = GeneralUtility.generateUUIDV4();

        this.setState({
            id: clone ? _uuid4 : newGraphInfo.id,
            title: newGraphInfo.title,
            proctored: newGraphInfo.proctored,
            showResults: newGraphInfo.showresults,
            deadline: newGraphInfo.deadline,
            organization_ids: newGraphInfo.organization_ids,
            graph: newGraphInfo.content,
            isEditing: !clone && newGraphInfo.id ? true : false,
            cloned_from: clone ? newGraphInfo.id : null,
            hashes: !clone && Array.isArray(newGraphInfo.hashes) ? newGraphInfo.hashes : [],
            notesHTML: newGraphInfo.notesHTML,
            template: newGraphInfo.template ? true : false,
            practice: newGraphInfo.practice ? true : false,
            practice_type: newGraphInfo.practice_type,
            startingTemplate: newGraphInfo.template,
            contextmenuprop:{
                show: false,
                type: null,
                coordinates: null,
                id: null
            },
            selectedNode: {
                id: null,
                type: null
            },
            showSaveConfirmation:false,
            error: {
                type: null,
                message: null
            }
        },()=>{
            if(this.state.showNotesContainer){
                this.notesTextEditor = new BBRTE({id: 'event-notes', container: `notes-texteditor`, icons: BBRTEIconList, content: newGraphInfo.notesHTML});
            }
            this.prepDiagrammer();
        });
    }
    async prepDiagrammer(){
        try{

            for(var groupcount=0; groupcount<this.state.graph.groups.length; groupcount++){
                for(var testletcount=0; testletcount<this.state.graph.groups[groupcount].testlets.length; testletcount++){
                    await this.props.getCollections({id: this.state.graph.groups[groupcount].testlets[testletcount].id});
                }
            }

            this.diagObj.updateMetaInfo(this.state.graph.metaInfo);
            this.state.graph.groups.forEach(group=>{
                this.diagObj.addNode(group, true);
                this.diagObj.updateNode(group);
            });
            this.state.graph.conditionals.forEach(conditional=>{
                this.diagObj.addNode(conditional, true);
                this.diagObj.updateNode(conditional);
            });
            this.state.graph.links.forEach(link=>{
                this.diagObj.addLink(link);
            });
            this.diagObj.updateContextOffset(this.state.hashes.length, this.state.graph.timers.length);
            this.checkTestAdaptiveness();
        }
        catch(err){}
    }

    //This is a nightmare. There must be a more optimized/efficient way to do this?
    async checkTestAdaptiveness(){
        try{
            var isAdaptive = true;

            const allGroups = this.state.graph.groups;
            for(var groupcount=0; groupcount<allGroups.length; groupcount++){
                const group_testlets = allGroups[groupcount].testlets;

                if(group_testlets && group_testlets.length){
                    for(var testletcount=0; testletcount<group_testlets.length; testletcount++){
                        const _collection = this.props.appProps.collections.filter(col=>col.id===group_testlets[testletcount].id);
                        if(_collection && _collection.length){
                            const collectionitems = _collection[0].items;

                            if(collectionitems && collectionitems.length){
                                const unavailableitems_instore = collectionitems.filter(itemid=>!this.props.appProps.items || (this.props.appProps.items && !this.props.appProps.items.filter(_item=>_item.id===itemid).length));

                                if(unavailableitems_instore.length){
                                    for(var uacount=0; uacount<unavailableitems_instore.length; uacount++){
                                        await this.props.getItems({id: unavailableitems_instore[uacount]});
                                    }
                                }
                                const filteredItems = this.props.appProps.items.filter(item=>collectionitems.includes(item.id));
                                filteredItems.forEach(item=>{
                                    const filteredFeature = item.features && item.features.length && item.features.filter(feature=>feature.type==="optionselector" && feature.allowUserInput);
                                    if(!filteredFeature || !filteredFeature.length){
                                        isAdaptive = false;
                                    }
                                });
                            }
                        }
                        if(!isAdaptive) break;
                    }
                    if(!isAdaptive) break;
                }
            }

            this.setState((prevState)=>({
                isAdaptive,
                showResults: !isAdaptive && prevState.showResults ? false : prevState.showResults
            }));
        }catch(err){
            console.log(err);
        }
    }

    async getCollectionsFromSearch(){
        try{
            const eventsearchinputdom = document.getElementById("events-searchtestlet-input");
            const searchInputVal = eventsearchinputdom.value;
            if(searchInputVal){
                await this.props.getCollections({searchpattern: searchInputVal});
            }
            else{
                await this.props.getCollections();
            }
        }catch(err){}
    }
    async getMetadata(){
        const {appProps:{languages, proficiencies, skills, status}} = this.props;

        try{
            if(!languages || !languages.length) await this.props.getLanguages();
            if(!proficiencies || !proficiencies.length) await this.props.getProficiency();
            if(!skills || !skills.length) await this.props.getSkills();
            if(!status.testevents || !!status.testevents.length) await this.props.getEventStatuses();
        }
        catch(err){
            console.log(err);
        }
    }

    toggleContextMenu(nodeprops, _coordinates){
        if(nodeprops){
            this.setState({
                contextmenuprop:{
                    show: nodeprops.type ? true : false,
                    type: nodeprops.type ? nodeprops.type : null,
                    coordinates: nodeprops.type && _coordinates ? _coordinates: null,
                    id: nodeprops.type && nodeprops.id ? nodeprops.id : null
                }
            }, ()=>{
                if(nodeprops.id)
                    this.setSelectedNode(nodeprops.id);
            });
        }
        else{
            this.setState({
                contextmenuprop:{
                    show: false,
                    type: null,
                    coordinates: null,
                    id: null
                }
            });
        }
    }

    updateGraphMetaInfo(newMetaInfo, callback){
        this.setState((prevState)=>({
            graph: {
                ...prevState.graph,
                metaInfo: {
                    ...prevState.graph.metaInfo,
                    ...newMetaInfo
                }
            }
        }), ()=>{
            this.diagObj.updateMetaInfo(this.state.graph.metaInfo);
            if(callback) callback();
        });
    }
    translateNode(nodeid, newcoordinates){
        const currentGraph = this.state.graph;
        if(nodeid){
            const filteredGroups = this.state.graph.groups.filter(_group=>_group.id===nodeid);
            const filteredConditionals = this.state.graph.conditionals.filter(_conditional=>_conditional.id===nodeid);
            const isGroup = filteredGroups && filteredGroups.length>0;
            const isConditional = filteredConditionals && filteredConditionals.length>0;
            if(isGroup){
                currentGraph.groups.forEach(_group=>{
                    if(_group.id===nodeid)
                        _group.coordinates = newcoordinates;
                });
            }
            else if(isConditional){
                currentGraph.conditionals.forEach(_conditional=>{
                    if(_conditional.id===nodeid)
                        _conditional.coordinates = newcoordinates;
                });
            }
            this.setState({
                graph: currentGraph
            });
        }
    }

    setSelectedNode(nodeid){
        let _type = null;
        if(nodeid){
            const filteredGroups = this.state.graph.groups.filter(_group=>_group.id===nodeid);
            const filteredConditionals = this.state.graph.conditionals.filter(_conditional=>_conditional.id===nodeid);
            const isGroup = filteredGroups && filteredGroups.length>0;
            const isConditional = filteredConditionals && filteredConditionals.length>0;
            _type = isGroup ? 'group' : (isConditional ? 'conditional' : null);
        }
        this.setState({
            selectedNode: {
                id: nodeid && _type ? nodeid : null,
                type: _type
            }
        });
    }

    startLinkingNodes(){
        this.diagObj.startLinking(this.state.contextmenuprop.id, this.state.contextmenuprop.coordinates);
        this.toggleContextMenu();
    }
    stopLinkingNodes(){
        this.diagObj.stopLinking();
        this.toggleContextMenu(false);
    }

    prepToggleMenu(){
        if(this.state.contextmenuprop.type==="group"){
            return(
                <div
                    className="diagrammer_contextmenu"
                    style={{top:`${this.state.contextmenuprop.coordinates.y}px`, left:`${this.state.contextmenuprop.coordinates.x}px`}}
                    onContextMenu={(event)=>{event.preventDefault();}}
                >
                    <div className="diagrammer_contextmenu-item" data-customevent onClick={this.removeAllTestlets}>Empty Group</div>
                    <div className="diagrammer_contextmenu-item" data-customevent onClick={this.startLinkingNodes}>Create Link</div>
                    <div className="diagrammer_contextmenu-item" data-customevent onClick={this.removeAllLinks}>Clear All Links</div>
                    <div className="diagrammer_contextmenu-item" data-customevent onClick={this.deleteNode}>Delete Node</div>
                </div>
            );
        }
        else if(this.state.contextmenuprop.type==="conditional"){
            return(
                <div
                    className="diagrammer_contextmenu"
                    style={{top:`${this.state.contextmenuprop.coordinates.y}px`, left:`${this.state.contextmenuprop.coordinates.x}px`}}
                    onContextMenu={(event)=>{event.preventDefault();}}
                >
                    <div className="diagrammer_contextmenu-item" data-customevent onClick={this.resetConditional}>Reset Condition</div>
                    <div className="diagrammer_contextmenu-item" data-customevent onClick={this.startLinkingNodes}>Create Link</div>
                    <div className="diagrammer_contextmenu-item" data-customevent onClick={this.removeAllLinks}>Clear All Links</div>
                    <div className="diagrammer_contextmenu-item" data-customevent onClick={this.deleteNode}>Delete Node</div>
                </div>
            );
        }
        else if(this.state.contextmenuprop.type==="link"){
            return(
                <div
                    className="diagrammer_contextmenu"
                    style={{top:`${this.state.contextmenuprop.coordinates.y}px`, left:`${this.state.contextmenuprop.coordinates.x}px`}}
                    onContextMenu={(event)=>{event.preventDefault();}}
                >
                    <div className="diagrammer_contextmenu-item" data-customevent onClick={this.removeLink}>Delete Link</div>
                </div>
            );
        }
        else{
            return (
                <div
                    className="diagrammer_contextmenu"
                    style={{top:`${this.state.contextmenuprop.coordinates.y}px`, left:`${this.state.contextmenuprop.coordinates.x}px`}}
                    onContextMenu={(event)=>{event.preventDefault();}}
                >
                    <div className="diagrammer_contextmenu-item">Create Testlet Group Node</div>
                    <div className="diagrammer_contextmenu-item">Create Conditional Node</div>
                </div>
            )
        }
    }

    contextMenuClickEventHandler(event){
        if(event.target.classList.value === "diagrammer_contextmenu-item" && (!event.target.dataset || event.target.dataset.customevent!=="true")){
            event.preventDefault();

            const clickedButtonHTML = event.target.innerHTML;
            switch(clickedButtonHTML){
                case "Create Testlet Group Node":
                    this.addGroupNode();
                    break;
                case "Create Conditional Node":
                    this.addConditionalNode();
                    break;
                default:
                    this.toggleContextMenu();
                    break;
            }
        }
        else if(event.target && (!event.target.dataset || event.target.dataset.customevent!=="true")){
            this.toggleContextMenu();
        }
    }

    addGroupNode(nodeprops){
        const nodeid = nodeprops && nodeprops.id ? nodeprops.id : GeneralUtility.generateUUIDV4();
        const currentgroupnodes = this.state.graph.groups;
        const groupssortedbytitle = currentgroupnodes.sort((a, b)=>{
            const split_title_a = a.title.split("Testlet Group ")[1];
            const split_title_b = b.title.split("Testlet Group ")[1];
            return parseInt(split_title_a) > parseInt(split_title_b) ? -1 : 1
        });
        const newtitlecount = groupssortedbytitle && groupssortedbytitle.length ? parseInt(groupssortedbytitle[0].title.split("Testlet Group ")[1]) : 0
        const newGroupNode = {
            id: nodeid,
            type: "group",
            coordinates: nodeprops && nodeprops.coordinates ? nodeprops.coordinates : {
                x: this.state.contextmenuprop.coordinates.x,
                y: this.state.contextmenuprop.coordinates.y
            },
            title: nodeprops && nodeprops.title ? nodeprops.title : `Testlet Group ${newtitlecount + 1}`,
            testlets: [],
            set: nodeprops && nodeprops.set ? nodeprops.set : null
        };
        currentgroupnodes.push(newGroupNode);
        this.setState((prevState)=>({
            graph: {
                ...prevState.graph,
                groups: currentgroupnodes
            },
            contextmenuprop:{
                show: false,
                type: null,
                coordinates: null,
                id: null
            }
        }), ()=>{
            this.diagObj.addNode(newGroupNode);
            this.setState({
                selectedNode: {
                    id: nodeid,
                    type: "group"
                }
            });
        });
    }
    addConditionalNode(nodeprops){
        const nodeid = nodeprops && nodeprops.id ? nodeprops.id : GeneralUtility.generateUUIDV4();
        const currentconditionalnodes = this.state.graph.conditionals;
        const conditionalsortedbytitle = currentconditionalnodes.sort((a, b)=>{
            const split_title_a = a.title.split("Condition ")[1];
            const split_title_b = b.title.split("Condition ")[1];
            return parseInt(split_title_a) > parseInt(split_title_b) ? -1 : 1
        });
        const newtitlecount = conditionalsortedbytitle && conditionalsortedbytitle.length ? parseInt(conditionalsortedbytitle[0].title.split("Condition ")[1]) : 0
        const newConditionalNode = {
            id: nodeid,
            type: "conditional",
            coordinates: nodeprops && nodeprops.coordinates ? nodeprops.coordinates : {
                x: this.state.contextmenuprop.coordinates.x,
                y: this.state.contextmenuprop.coordinates.y
            },
            title: nodeprops && nodeprops.title ? nodeprops.title : `Condition ${newtitlecount + 1}`,
            condition: nodeprops && nodeprops.condition ? nodeprops.condition : {
                starttest: false,
                endtest: false,
                groupscores: []
            }
        };
        currentconditionalnodes.push(newConditionalNode);
        this.setState((prevState)=>({
            graph: {
                ...prevState.graph,
                conditionals: currentconditionalnodes
            },
            contextmenuprop:{
                show: false,
                type: null,
                coordinates: null,
                id: null
            }
        }), ()=>{
            this.diagObj.addNode(newConditionalNode, null, this.state.graph.groups);
            this.setState({
                selectedNode: {
                    id: nodeid,
                    type: "conditional"
                }
            });
        });
    }
    deleteNode(event){
        event.preventDefault();

        this.removeAllLinks(null, this.state.contextmenuprop.id);
        if(this.state.contextmenuprop.type==="group"){
            const filteredGroupNodes = this.state.graph.groups.filter(_group=>_group.id!==this.state.contextmenuprop.id);

            const conditionalgroups = [];
            const currentConditionals = this.state.graph.conditionals;
            currentConditionals.forEach(_conditional=>{
                const newgroupscores = _conditional.condition.groupscores.filter(groupscore=>{
                    return groupscore.groupid!==this.state.contextmenuprop.id
                });
                conditionalgroups.concat(this.state.graph.groups.filter(group=>!!newgroupscores.find(gs=>gs.groupid===group.id)));
                _conditional.condition.groupscores = newgroupscores;

                this.diagObj.updateNode(_conditional);
            });

            this.setState((prevState)=>({
                graph: {
                    ...prevState.graph,
                    groups: filteredGroupNodes,
                    conditionals: currentConditionals
                }
            }), ()=>{
                this.diagObj.removeNode(this.state.contextmenuprop.id);
                this.toggleContextMenu();
            });
        }
        else if(this.state.contextmenuprop.type==="conditional"){
            const filteredConditionalNodes = this.state.graph.conditionals.filter(_conditional=>_conditional.id!==this.state.contextmenuprop.id);
            this.setState((prevState)=>({
                graph: {
                    ...prevState.graph,
                    conditionals: filteredConditionalNodes
                }
            }), ()=>{
                this.diagObj.removeNode(this.state.contextmenuprop.id);
                this.toggleContextMenu();
            });
        }
    }

    addLink(linkprops){
        const linkid = linkprops.id ? linkprops.id : GeneralUtility.generateUUIDV4();
        const currentlinks = this.state.graph.links;
        const newLink = {
            id: linkid,
            from: linkprops.from,
            to: linkprops.to
        }
        currentlinks.push(newLink);
        this.setState((prevState)=>({
            graph: {
                ...prevState.graph,
                links: currentlinks
            }
        }));
        return linkid;
    }
    removeLink(event){
        event.preventDefault();

        const currentLinks = this.state.graph.links.filter(_link=>_link.id!==this.state.contextmenuprop.id);
        this.setState((prevState)=>({
            graph: {
                ...prevState.graph,
                links: currentLinks
            }
        }), ()=>{
            this.diagObj.removeLink(this.state.contextmenuprop.id);
            this.toggleContextMenu();
        });
    }
    removeAllLinks(event, id){
        if(event) event.preventDefault();

        const _idToDelete = id || this.state.contextmenuprop.id;

        const linksToDelete = this.state.graph.links.filter(_link=>_link.from===_idToDelete || _link.to===_idToDelete);
        const newLinks = this.state.graph.links.filter(_link=>_link.from!==_idToDelete && _link.to!==_idToDelete);
        this.setState((prevState)=>({
            graph: {
                ...prevState.graph,
                links: newLinks
            }
        }), ()=>{
            this.diagObj.removeAllLinks(linksToDelete.map(ltd=>ltd.id));
            this.toggleContextMenu();
        });
    }

    resetConditional(event){
        if(event) event.preventDefault();

        const currentConditionals = this.state.graph.conditionals;
        currentConditionals.forEach(_conditional=>{
            if(_conditional.id===this.state.contextmenuprop.id){
                _conditional.condition = {
                    starttest: false,
                    endtest: false,
                    groupscores: []
                }
            }
        });
        this.setState((prevState)=>({
            graph: {
                ...prevState.graph,
                conditionals: currentConditionals
            }
        }), ()=>{
            this.diagObj.updateNode(this.state.graph.conditionals.filter(_conditional=>_conditional.id===this.state.contextmenuprop.id)[0]);
        });
    }

    updateConditionalGroupScoreSelection(event, data){
        this.setState({tempconditiongroup: data.value});
    }
    addGroupScoresToConditionalNode(){
        const currentConditionals = this.state.graph.conditionals;
        currentConditionals.forEach(_conditional=>{
            if(_conditional.id===this.state.selectedNode.id){
                const currentgroupscores = _conditional.condition.groupscores;
                currentgroupscores.push({
                    id: GeneralUtility.generateUUIDV4(),
                    groupid: this.state.tempconditiongroup,
                    na: false,
                    min: 0,
                    max: 0,
                    cumulative: false,
                    timerthreshold: 0
                });
                _conditional.condition = {
                    ..._conditional.condition,
                    groupscores: currentgroupscores
                };
            }
        });

        this.setState((prevState)=>({
            graph: {
                ...prevState.graph,
                conditionals: currentConditionals
            },
            tempconditiongroup: null
        }), ()=>{
            this.diagObj.updateNode(this.state.graph.conditionals.filter(_conditional=>_conditional.id===this.state.selectedNode.id)[0]);
        });
    }
    removeGroupScoresFromConditionalNode(groupscoreid){
        const currentConditionals = this.state.graph.conditionals;
        currentConditionals.forEach(_conditional=>{
            if(_conditional.id===this.state.selectedNode.id){
                const filteredgroupscores = _conditional.condition.groupscores.filter(groupscore=>groupscore.id!==groupscoreid);
                _conditional.condition = {
                    ..._conditional.condition,
                    groupscores: filteredgroupscores
                };
            }
        });

        this.setState((prevState)=>({
            graph: {
                ...prevState.graph,
                conditionals: currentConditionals
            }
        }), ()=>{
            this.diagObj.updateNode(this.state.graph.conditionals.filter(_conditional=>_conditional.id===this.state.selectedNode.id)[0]);
        });
    }
    updateConditional(event, data){
        if(event) event.preventDefault();

        const inputval = event.target.value;
        const forprop = event.target.getAttribute("forprop");

        const scoreconditionid = data && data.type==="checkbox" ? data.scoreconditionid : event.target.getAttribute("scoreconditionid");

        const ismin = forprop==="min";
        const ismax = forprop==="max";
        const istimerthreshold = forprop==="timerthreshold";

        const isnacumulative = data && data.type==="checkbox" && data.name==="nacheck";
        const ischeckcumulative = data && data.type==="checkbox" && data.name==="cumulativecheck";
        const isstart = data && data.type==="checkbox" && data.name==="starttest";
        const isend = data && data.type==="checkbox" && data.name==="endtest";

        const currentConditionals = this.state.graph.conditionals;
        currentConditionals.forEach(_conditional=>{
            if(_conditional.id===this.state.selectedNode.id){
                if(isstart){
                    _conditional.condition.starttest = data.checked;
                    _conditional.condition.endtest = data.checked ? false : _conditional.condition.endtest;
                }
                else if(isend){
                    _conditional.condition.endtest = data.checked;
                    _conditional.condition.starttest = data.checked ? false : _conditional.condition.starttest;
                }

                if(scoreconditionid){
                    _conditional.condition.groupscores.forEach(groupscore=>{
                        if(groupscore.id===scoreconditionid && ismin){
                            groupscore.min = parseInt(inputval);
                            groupscore.max = groupscore.max < parseInt(inputval) ? parseInt(inputval) : groupscore.max
                        }
                        else if(groupscore.id===scoreconditionid && ismax){
                            groupscore.max = parseInt(inputval);
                            groupscore.min = groupscore.min > parseInt(inputval) ? parseInt(inputval) : groupscore.min
                        }
                        else if(groupscore.id===scoreconditionid && istimerthreshold){
                            groupscore.timerthreshold = parseInt(inputval);
                        }
                        else if(groupscore.id===scoreconditionid && ischeckcumulative){
                            groupscore.cumulative = data.checked;
                        }
                        else if(groupscore.id===scoreconditionid && isnacumulative){
                            groupscore.na = data.checked;
                            //This may not be a good user interaction logic
                            if(data.checked){
                                groupscore.min = 0;
                                groupscore.max = 0;
                                groupscore.timerthreshold = 0;
                                groupscore.cumulative = false;
                            }
                        }
                    });
                }
            }
        });

        this.setState((prevState)=>({
            graph: {
                ...prevState.graph,
                conditionals: currentConditionals
            }
        }), ()=>{
            this.diagObj.updateNode(this.state.graph.conditionals.filter(_conditional=>_conditional.id===this.state.selectedNode.id)[0]);
        });
    }

    updateGroupSetName(event, data){
        event.preventDefault();
        const setname = data.value;

        const currentGroups = this.state.graph.groups;
        currentGroups.forEach(_group=>{
            if(_group.id===this.state.selectedNode.id){
                _group.set = !setname.length ? null : setname
            }
        });
        this.setState((prevState)=>({
            graph: {
                ...prevState.graph,
                groups: currentGroups
            }
        }), ()=>{
            this.diagObj.updateNode(this.state.graph.groups.filter(_group=>_group.id===this.state.selectedNode.id)[0]);
        });
    }
    addTestletToGroup(testletid){
        const {appProps:{collections}} = this.props;

        const currentGroups = this.state.graph.groups;
        currentGroups.forEach(_group=>{
            if(_group.id===this.state.selectedNode.id && !_group.testlets.filter(_testlet=>_testlet.id===testletid).length && collections.filter(_testlet=>_testlet.id===testletid).length){
                _group.testlets.push(
                    {
                        id: collections.filter(_testlet=>_testlet.id===testletid)[0].id,
                        title: collections.filter(_testlet=>_testlet.id===testletid)[0].title
                    }
                );
            }
        });
        this.setState((prevState)=>({
            graph: {
                ...prevState.graph,
                groups: currentGroups
            }
        }), ()=>{
            this.diagObj.updateNode(this.state.graph.groups.filter(_group=>_group.id===this.state.selectedNode.id)[0]);
            this.checkTestAdaptiveness();
        });
    }
    removeTestletFromGroup(testletid){
        const currentGroups = this.state.graph.groups;
        currentGroups.forEach(_group=>{
            if(_group.id===this.state.selectedNode.id && _group.testlets.filter(_testlet=>_testlet.id===testletid).length){
                const indexToSplice = _group.testlets.findIndex(_testlet=>_testlet.id===testletid);
                _group.testlets.splice(indexToSplice, 1);
            }
        });

        this.setState((prevState)=>({
            graph: {
                ...prevState.graph,
                groups: currentGroups
            }
        }), ()=>{
            this.diagObj.updateNode(this.state.graph.groups.filter(_group=>_group.id===this.state.selectedNode.id)[0]);
        });
    }
    removeAllTestlets(){
        const currentGroups = this.state.graph.groups;
        currentGroups.forEach(_group=>{
            if(_group.id===this.state.selectedNode.id){
                _group.testlets = [];
            }
        });
        this.setState((prevState)=>({
            graph: {
                ...prevState.graph,
                groups: currentGroups
            }
        }), ()=>{
            this.diagObj.updateNode(this.state.graph.groups.filter(_group=>_group.id===this.state.selectedNode.id)[0]);
        });
    }

    toggleProctoring(){
        this.setState((prevState)=>({
            proctored: !prevState.proctored
        }));
    }

    toggleTemplate(){
        this.setState((prevState)=>({
            template: !prevState.template
        }));
    }

    togglePractice(){
        this.setState((prevState)=>({
            practice: !prevState.practice,
            hashes: prevState.practice ? [{
                id: GeneralUtility.generateUUIDV4(),
                event_id: this.state.id,
                hash:  GeneralUtility.randomString(21),
                status: null,
                max_uses: null,
                isDebug: false,
                organization_id: null,
            }] : prevState.hashes
        }));
    }

    updatePracticeType(e, {value}){
        this.setState({practice_type: value});
    }

    toggleShowResults(){
        this.setState((prevState)=>({
            showResults: !prevState.showResults
        }));
    }

    updateDeadline(event){
        if(!event){
            this.setState((prevState)=>({
                deadline: (!prevState.deadline ? this.minDate : null)
            }));
        }
        else{
            const newDeadline = event.target.value;
            this.setState({
                deadline: newDeadline
            });
        }
        
    }

    toggleSaveConfirmation(toggleState){
        if(toggleState && this.state.isEditing && this.state.template && this.state.startingTemplate){
            this.setState({
                error: {
                    type: 1,
                    message: "This Test Event is a template and can not be edited"
                },
                showSaveConfirmation: false,
                awatingresponse: false
            });
        }
        else if(toggleState && this.state.title){
            this.setState({showSaveConfirmation: toggleState});
        }
        else if(toggleState && !this.state.title){
            this.setState({
                error: {
                    type: 1,
                    message: "Test events must have a title"
                },
                showSaveConfirmation: false,
                awatingresponse: false
            });
        }
        else if(!toggleState){
            this.setState({
                error: {
                    type: null,
                    message: null
                },
                showSaveConfirmation: false,
                awatingresponse: false
            })
        }
    }

    setNotesHTML(event){
        const newcontenthtml = event.target.innerHTML;
        this.setState({notesHTML: newcontenthtml});
    }

    showNotes(){
        this.setState((prevState)=>({showNotesContainer: !prevState.showNotesContainer}),
            ()=>{
                if(this.state.showNotesContainer){
                    if(this.notesTextEditor){
                        this.notesTextEditor.destroy();
                    }
                    this.notesTextEditor = new BBRTE({id: 'event-notes', container: `notes-texteditor`, icons: BBRTEIconList, content: this.state.notesHTML});
                }
            });
    }

    updateEventTitle(event){
        const newTitle = event.target.value;
        this.setState({title: newTitle});
    }

    toggleDiagrammer(togglestate){
        this.setState((prevState)=>({
            minimizediagrammer: typeof togglestate==="boolean" ? togglestate : !prevState.minimizediagrammer
        }), ()=>{
            if(!this.state.minimizediagrammer){
                this.toggleRating(true);
            }
            this.stopLinkingNodes();
            this.setSelectedNode(null);
            this.toggleContextMenu(null);
            this.diagObj.deselectAllNodes();
        });
    }

    toggleRating(togglestate){
        this.setState((prevState)=>({
            minimizedrating: typeof togglestate==="boolean" ? togglestate : !prevState.minimizedrating
        }), ()=>{
            if(!this.state.minimizedrating){
                this.toggleDiagrammer(true);
            }
        });
    }

    clearEventGraph(){
        this.setState((prevState)=>({
            graph:{
                ...prevState.graph,
                conditionals: [],
                links: [],
                groups: []
            }
        }), ()=>{
            this.stopLinkingNodes();
            this.setSelectedNode(null);
            this.toggleContextMenu(null);
            this.diagObj.deselectAllNodes();
            this.diagObj.deleteAllNodes();
            this.clearRatings();
        });
    }

    clearRatings(){
        this.updateGraphMetaInfo({
            ratings: []
        });
    }

    sortCollectionsBy(propname){
        var newsortedby;
        if(propname && (!this.state.collectionssortedby || (this.state.collectionssortedby && this.state.collectionssortedby.property!==propname))){
            newsortedby = {
                ascending: true,
                property: propname
            }
        }
        else if(propname && this.state.collectionssortedby.property===propname){
            newsortedby = {
                ascending: !this.state.collectionssortedby.ascending,
                property: propname
            }
        }
        this.setState({
            collectionssortedby: newsortedby
        });
    }

    deselectNode(event){
        const is_segment = event && event.target && event.target.classList && event.target.classList.contains("segment");

        if(is_segment && this.state.selectedNode && this.state.selectedNode.id){
            this.setSelectedNode(null);

            setTimeout(()=>{
                this.diagObj.deselectAllNodes();
            });
        }
    }

    generateRatings(template_id_relations){
        this.template_id_relations = template_id_relations instanceof Array ? template_id_relations : this.state.graph?.groups.map(g =>{
            const generatedID = GeneralUtility.generateUUIDV4();
            return {
                templateid: g.id,
                generatedid: generatedID
            };
        });

        const ratingtemplate = JSON.parse(JSON.stringify(this.RATINGSTEMPLATE));

        const preppedratings = ratingtemplate.map(rating=>{
            const selectedtemplateratinggroup = this.template_id_relations.find(idprop=>idprop.templateid===rating.group);
            rating.id = GeneralUtility.generateUUIDV4();
            rating.group = selectedtemplateratinggroup ? selectedtemplateratinggroup.generatedid : rating.group;
            rating.scores = rating.scores.map(score=>{
                const selectedtemplatescoregroup = this.template_id_relations.find(idprop=>idprop.templateid===score.group);
                score.id = GeneralUtility.generateUUIDV4();
                score.group = selectedtemplatescoregroup ? selectedtemplatescoregroup.generatedid : score.group;
                return score;
            })
            return rating;
        });
        
        this.updateGraphMetaInfo({ratings: preppedratings.slice()});
    }

    generateTemplate(){

        const diagrammerTemplateJSON = JSON.parse(JSON.stringify({...this.DIAGRAMMERTEMPLATE}));

        const templategroups = new Array(...diagrammerTemplateJSON.groups);
        const templateconditionals = new Array(...diagrammerTemplateJSON.conditionals);
        const templatelinks = new Array(...diagrammerTemplateJSON.links);

        const template_id_relations = new Array();
        
        const templatemetainfo = {...diagrammerTemplateJSON.metaInfo};

        this.setState({
            generatingtemplate: true
        }, ()=>{
            this.updateGraphMetaInfo({
                coordinates: {
                    x: 0,
                    y: 0
                },
                zoom: 1.5,
                ratings: []
            }, ()=>{
                templategroups.forEach(group=>{
                    const generatedID = GeneralUtility.generateUUIDV4();
                    template_id_relations.push({
                        templateid: group.id,
                        generatedid: generatedID
                    })
                    const group_props = {
                        id: generatedID,
                        coordinates: {
                            x: Math.floor(group.coordinates.x * 1.5),
                            y: Math.floor(group.coordinates.y * 1.5)
                        },
                        title: group.title,
                        set: group.set
                    }
                    this.addGroupNode(group_props);
                });

                templateconditionals.forEach(conditional=>{
                    const generatedID = GeneralUtility.generateUUIDV4();
                    template_id_relations.push({
                        templateid: conditional.id,
                        generatedid: generatedID
                    });

                    conditional.condition.groupscores.forEach(groupscore=>{
                        groupscore.id = GeneralUtility.generateUUIDV4();
                        const generatedID = template_id_relations.find(idprop=>idprop.templateid===groupscore.groupid).generatedid;
                        groupscore.groupid = generatedID;
                    });

                    const conditional_props = {
                        id: generatedID,
                        coordinates: {
                            x: Math.floor(conditional.coordinates.x * 1.5),
                            y: Math.floor(conditional.coordinates.y * 1.5)
                        },
                        title: conditional.title,
                        condition: conditional.condition
                    }
                    this.addConditionalNode(conditional_props);
                });

                templatelinks.forEach(link=>{
                    const generatedfrom_id = template_id_relations.find(idprop=>idprop.templateid===link.from).generatedid;
                    const generatedto_id = template_id_relations.find(idprop=>idprop.templateid===link.to).generatedid;
                    const linkprops = {
                        id: GeneralUtility.generateUUIDV4(),
                        from: generatedfrom_id,
                        to: generatedto_id
                    };
                    this.addLink(linkprops);
                });

                this.generateRatings(template_id_relations);

                this.setSelectedNode(null);

                setTimeout(()=>{
                    this.state.graph.groups.forEach(group=>{
                        this.diagObj.updateNode(group);
                    });
                    this.state.graph.conditionals.forEach(conditional=>{
                        this.diagObj.updateNode(conditional);
                    });
                    this.state.graph.links.forEach(link=>{
                        this.diagObj.addLink(link);
                    });
                    this.diagObj.deselectAllNodes();

                    this.updateGraphMetaInfo(templatemetainfo, ()=>{
                        this.setState({generatingtemplate: false});
                    });
                }, 250);
            });
        });

        //this.checkTestAdaptiveness();
        
    }

    updateOrganizationAssignments(e, data){
        const {appProps:{user:{organization}}} = this.props;
        
        this.setState({
            organization_ids: data.value.find(val=>val===organization.organization_id) ? data.value : [organization.organization_id, ...data.value]
        });
    }

    updateHashes(updatedhashset){
        this.setState({
            hashes: updatedhashset
        }, ()=>{this.diagObj.updateContextOffset(this.state.hashes.length, this.state.graph.timers.length);});
    }

    updateTimers(updatedtimers){
        this.setState((prevState)=>({
            graph: {
                ...prevState.graph,
                timers: updatedtimers
            }
        }), ()=>{
            this.diagObj.updateContextOffset(this.state.hashes.length, this.state.graph.timers.length);
        });
    }

    async saveEventGraph(){
        // console.log(
        //     JSON.stringify(
        //         {
        //             id: this.state.id,
        //             title: this.state.title,
        //             showResults: this.state.showResults,
        //             proctored: this.state.proctored,
        //             deadline: this.state.deadline,
        //             organization_ids: this.state.organization_ids,
        //             content: this.state.graph,
        //             cloned_from: this.state.cloned_from,
        //             hashes: this.state.hashes
        //         },
        //         null,
        //         4
        //     )
        // );

        // this.setState({
        //     awatingresponse: true,
        //     showSaveConfirmation: false
        // });
        const {appProps:{skills}} = this.props;

        try{
            if(this.state.practice){
                const skill = this.state.practice_type && this.state.practice_type.length ? skills.find(s => s.id == this.state.practice_type) : null;
                this.state.hashes = [{
                    id: GeneralUtility.generateUUIDV4(),
                    event_id: this.state.id,
                    hash:  skill ? "practice_" + skill.name : "practice",
                    status: null,
                    max_uses: null,
                    isDebug: true,
                    organization_id: null
                }]
            }

            if(this.state.isEditing){
                await this.props.updateEvent({
                    id: this.state.id,
                    title: this.state.title,
                    showResults: this.state.showResults,
                    proctored: this.state.proctored,
                    deadline: this.state.deadline,
                    organization_ids: this.state.organization_ids,
                    content: this.state.graph,
                    cloned_from: this.state.cloned_from,
                    hashes: this.state.hashes,
                    notesHTML: this.state.notesHTML,
                    template: this.state.template,
                    practice: this.state.practice,
                    practice_type: this.state.practice_type
                });
            }
            else{
                await this.props.createEvent({
                    id: this.state.id,
                    title: this.state.title,
                    showResults: this.state.showResults,
                    proctored: this.state.proctored,
                    deadline: this.state.deadline,
                    organization_ids: this.state.organization_ids,
                    content: this.state.graph,
                    cloned_from: this.state.cloned_from,
                    hashes: this.state.hashes,
                    notesHTML: this.state.notesHTML,
                    template: this.state.template,
                    practice: this.state.practice,
                    practice_type: this.state.practice_type
                });
            }
            this.props.history.push("/events");
        }catch(err){
            console.log(err);
            this.setState({
                error: {
                    type: "Backend Error",
                    message: `Could not ${this.state.isEditing? 'update' : 'create'} this test event.`
                },
                awatingresponse: false,
                showConfirmation: false
            });
        }
    }


    render(){

        const {appProps:{collections, proficiencies, languages, skills, user:{organizations}}} = this.props;

        return(
            <React.Fragment>
                <Message info size="mini">
                    <Message.Header>
                        <span>Event Id</span>
                        <span className="id-message-span">{this.state.id}</span>
                    </Message.Header>
                </Message>
                <Segment inverted>
                    {!this.state.awatingresponse?
                        <React.Fragment>
                            <Button color='red' onClick={()=>this.props.history.push("/events")}>Cancel</Button>
                            <Button color="green" onClick={()=>this.toggleSaveConfirmation(true)}>{this.state.isEditing?'Update':'Create'}</Button>
                        </React.Fragment>
                    :null}
                </Segment>
                {this.state.error && this.state.error.type &&
                    <Message negative>
                        <p>{this.state.error.message}</p>
                    </Message>
                || null}
                <Segment>
                    <Grid columns='equal'>
                        <Grid.Row>
                            <Grid.Column>
                                <Input label='Event Title' fluid placeholder="Event Title" value={this.state.title || ''} onChange={this.updateEventTitle}/>
                            </Grid.Column>
                            <Grid.Column>
                                <Checkbox
                                    toggle
                                    label='Enable Proctoring'
                                    onChange={this.toggleProctoring}
                                    checked={this.state.proctored}
                                />
                            </Grid.Column>
                            {this.state.isAdaptive &&
                                <Grid.Column>
                                    <Checkbox
                                        toggle
                                        label='Show Result At The End of The Test'
                                        onChange={this.toggleShowResults}
                                        checked={this.state.showResults}
                                    />
                                </Grid.Column>
                            }
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width={3}>
                                <Checkbox
                                    toggle
                                    label='Template'
                                    onChange={this.toggleTemplate}
                                    checked={this.state.template}
                                />
                            </Grid.Column>
                            <Grid.Column width={3}>
                                <Checkbox
                                    toggle
                                    label='Enable Deadline'
                                    onChange={()=>this.updateDeadline()}
                                    checked={(this.state.deadline && this.state.deadline.length>0) || false}
                                />
                            </Grid.Column>
                            <Grid.Column>
                                <label style={{marginRight: "10px"}} htmlFor="enddate">Event Deadline</label>
                                <Input>
                                    <input
                                        type="datetime-local"
                                        id="enddate"
                                        name="event-end-date"
                                        defaultValue={this.state.deadline}
                                        min={this.minDate}
                                        onChange={this.updateDeadline}
                                    />
                                </Input>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width={3}>
                                <Checkbox
                                    toggle
                                    label='Practice Test'
                                    onChange={this.togglePractice}
                                    checked={this.state.practice}
                                />
                            </Grid.Column>
                            {this.state.practice && skills && skills.length ? 
                                <Grid.Column>
                                    <label style={{marginRight: "10px"}}  htmlFor="practicetype">Practice Skill</label>
                                    <Dropdown
                                        id="practicetype"
                                        onChange={this.updatePracticeType}
                                        placeholder='Practice Type'
                                        selection
                                        value={this.state.practice_type}
                                        options={skills
                                            .map(s=>{
                                                return {
                                                    key: s.id,
                                                    value: s.id,
                                                    text: s.name
                                                }
                                            })
                                        }
                                    />
                                </Grid.Column> 
                            : null}
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column>
                                <label style={{marginRight: "10px"}} htmlFor="organizations">Organizations Access</label>
                                <Popup size="mini" content="You cannot remove your own organization." trigger={
                                    <Icon size="small" color="blue" name="exclamation circle" />
                                }/>
                                <Dropdown
                                    id="organizations"
                                    onChange={this.updateOrganizationAssignments}
                                    placeholder='Organizations'
                                    fluid
                                    multiple
                                    search
                                    selection
                                    value={this.state.organization_ids}
                                    options={organizations
                                        .map(org=>{
                                            return {
                                                key: org.organization_id,
                                                value: org.organization_id,
                                                text: org.name
                                            }
                                        })
                                    }
                                />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                    <Divider />
                    {this.state.id?<TimerManager updateTimers={this.updateTimers} timers={this.state.graph.timers}/>:null}
                    <Divider />
                    {this.state.id && ! this.state.practice?
                    <HashManager 
                        eventid={this.state.id} 
                        updateHashes={this.updateHashes} 
                        hashes={this.state.hashes} 
                        organizations={organizations}
                        organization_ids={this.state.organization_ids}
                    />:null}
                </Segment>
                <Message attached="top">
                    <Message.Header>Event Notes</Message.Header>
                    <Popup content={this.state.showNotesContainer?'Minimize Notes':'Maximize Notes'} trigger={
                        <Label size='small' attached="top right" as='a' color='blue' onClick={this.showNotes}><Icon name={this.state.showNotesContainer?'window minimize':'window maximize outline'}/></Label>
                    }/>
                </Message>
                {this.state.showNotesContainer ?
                    <React.Fragment>
                        <div
                            id={`notes-texteditor`}
                            className={''}
                            onInput={this.setNotesHTML}
                        ></div>
                    </React.Fragment>
                :null}
                <Message attached="top">
                    <Message.Header>Rating Labels Manager</Message.Header>
                    {!this.state.minimizedrating && this.state.graph.metaInfo?.ratings?.length && <Button color="orange" onClick={this.clearRatings}>Clear Ratings</Button> || null}
                    {!this.state.minimizedrating && !this.state.graph.metaInfo.ratings.length && this.state.graph?.groups?.length && <Button color="blue" onClick={this.generateRatings}>Generate Ratings</Button> || null}
                    <Popup content={this.state.minimizedrating?'Maximize Ratings Manager':'Minimize Ratings Manager'} trigger={
                        <Label size='small' attached="top right" as='a' color='blue' onClick={this.toggleRating}><Icon name={this.state.minimizedrating?'window maximize outline':'window minimize'}/></Label>
                    }/>
                </Message>
                {!this.state.minimizedrating && !this.state.generatingtemplate ?
                    <RatingsManager
                        ratings={this.state.graph.metaInfo.ratings}
                        groups={this.state.graph.groups
                        .map(group=>{return {id: group.id, title: group.title}})}
                        updateGraphMetaInfo={this.updateGraphMetaInfo}
                    />
                :null}

                <Message attached="top" color='brown'>
                    <Grid>
                        <Grid.Row>
                            <Grid.Column width={7}>
                                <Header as='h4'>Event Designer</Header>
                                <p>You can design event scoring logic using these tool.</p>
                            </Grid.Column>
                            {!this.state.minimizediagrammer ?
                                <Grid.Column width={7}>
                                    {!this.state.graph.conditionals.length &&
                                    !this.state.graph.groups.length &&
                                    !this.state.graph.links.length ?
                                        <Button color="blue" onClick={this.generateTemplate}>Generate Template</Button>
                                    :null}
                                    {this.state.graph.conditionals.length || this.state.graph.groups.length ?
                                        <Button color="orange" onClick={this.clearEventGraph}>Clear Graph</Button>
                                    :null}
                                </Grid.Column>
                            :null}
                        </Grid.Row>
                    </Grid>
                    <Popup content={this.state.minimizediagrammer?'Maximize Event Designer':'Minimize Event Designer'} trigger={
                        <Label size='small' attached="top right" as='a' color='blue' onClick={this.toggleDiagrammer}><Icon name={this.state.minimizediagrammer?'window maximize outline':'window minimize'}/></Label>
                    }/>
                </Message>
                <Segment raised attached>
                    <div className={`diagrammer${this.state.minimizediagrammer || this.state.generatingtemplate ? ' hidden' : ''}`} onContextMenu={(event)=>{event.preventDefault();return false;}}>
                        <div className="diagrammer-info">
                            <div>
                                {`Coordinates: { x: ${this.state.graph.metaInfo.coordinates.x.toFixed(2)}, y: ${this.state.graph.metaInfo.coordinates.y.toFixed(2)} }`}
                            </div>
                            <div>Zoom: {Math.floor(100 * this.state.graph.metaInfo.zoom) + 50}%</div>
                        </div>
                        <div id="graph_paper"></div>
                    </div>
                    {this.state.generatingtemplate?
                        <React.Fragment>
                            <Divider hidden/>
                            <Divider hidden/>
                            <Dimmer active>
                                <Loader>Generating Test Event Template</Loader>
                            </Dimmer>
                        </React.Fragment>
                    :null}
                </Segment>
                
                <Divider hidden/>

                {this.state.minimizediagrammer && this.state.graph.groups.length ?
                    <React.Fragment>
                        <Message attached="top" color='brown'>
                            <Message.Header>Testlet Selector</Message.Header>
                            <p>Please select testlets for available groups</p>
                        </Message>
                        <Segment raised attached onClick={this.deselectNode}>
                            {//This is very very expensive, but I'm le tired
                            this.state.graph.groups
                            .sort((group_a, group_b)=>{
                                const grouptitle_a = group_a.title && group_a.title.includes("Testlet Group ") ? group_a.title : null;
                                const grouptitle_b = group_b.title && group_b.title.includes("Testlet Group ") ? group_b.title : null;
                                if(grouptitle_a && grouptitle_b){
                                    try{
                                        const group_a_index = parseInt(grouptitle_a.split("Testlet Group")[1]);
                                        const group_b_index = parseInt(grouptitle_b.split("Testlet Group")[1]);
                                        return group_a_index > group_b_index ? 1 : -1;
                                    }
                                    catch(err){}
                                }
                                return 0;
                            })
                            .map(group=>{
                                const availablealphabets = ["c","a","b"];
                                let seriesname = group.title;
                                if(seriesname.includes("Testlet Group ")){
                                    const testletseriesset = seriesname.split("Testlet Group ");
                                    if(testletseriesset.length>1){
                                        const testletseriesset_num = testletseriesset[1];
                                        try{
                                            const seriessetnum = parseInt(testletseriesset_num);
                                            const profnum = parseInt(group.set);
                                            if(profnum >= 1 && profnum <= 3 && seriessetnum >= 1 && seriessetnum <= 9){
                                                const alphabettouse_index = (seriessetnum % 3);
                                                var profalphabet = 'NA';
                                                if(alphabettouse_index < availablealphabets.length){
                                                    profalphabet = availablealphabets[alphabettouse_index];
                                                }
                                                seriesname = `Testlet Group Series ${profnum}${profalphabet}`
                                            }
                                        }catch(err){}
                                    }
                                }
                                return(
                                    <Button
                                        onClick={()=>this.setSelectedNode(group.id)}
                                        icon
                                        labelPosition='left'
                                        basic={this.state.selectedNode.type==="group" && this.state.selectedNode.id===group.id ? false : true}
                                        color='blue'
                                        key={`testlet-selector-button-${group.id}`}
                                    >
                                        <Icon name={this.state.selectedNode.type==="group" && this.state.selectedNode.id===group.id ? 'dot circle' : 'dot circle outline'} />
                                        {seriesname}
                                    </Button>
                                )
                            })}
                        </Segment>
                    </React.Fragment>
                :null}

                <Divider hidden/>
                
                {this.state.selectedNode.id ?
                    <div id="node-settings">
                        <Message info attached='top'>
                            <Message.Header>Edit Selected {this.state.selectedNode.type==="group" ? 'Group' : 'Conditional'} Node</Message.Header>
                        </Message>
                        <Segment attached>
                            <Divider hidden/>
                            {this.state.selectedNode.type==="group" ?
                                <Grid relaxed>
                                    <Grid.Row>
                                        <Grid.Column width={3}>
                                            <Popup content='Sets are used for score accumulation' trigger={
                                                <Icon name="info circle" color="blue"/>
                                            }/>
                                            Testlet Group Set
                                        </Grid.Column>
                                        <Grid.Column width={12}>
                                            <Dropdown
                                                options={
                                                    [...new Set(
                                                        this.state.graph.groups
                                                        .filter(group=>group.set)
                                                        .map(group=>group.set)
                                                    )]
                                                    .map(setname=>{
                                                        return {
                                                            key: setname,
                                                            value: setname,
                                                            text: setname
                                                        }
                                                    })
                                                }
                                                placeholder='Select or Create New Set'
                                                search
                                                selection
                                                allowAdditions
                                                clearable
                                                value={
                                                    this.state.graph.groups
                                                    .find(groupnode=>groupnode.id===this.state.selectedNode.id) ?
                                                    this.state.graph.groups
                                                    .find(groupnode=>groupnode.id===this.state.selectedNode.id).set
                                                    :null
                                                }
                                                onAddItem={this.updateGroupSetName}
                                                onChange={this.updateGroupSetName}
                                            />
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column width={10}>
                                            <Input fluid placeholder="Search Testlet"><input id="events-searchtestlet-input" /></Input>
                                        </Grid.Column>
                                        <Grid.Column width={4}>
                                            <Button color='green' onClick={this.getCollectionsFromSearch}>Search Testlet</Button>
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column>
                                            <Table compact celled definition selectable>
                                                <Table.Header>
                                                    <Table.Row>
                                                        <Table.HeaderCell />
                                                        {Object.keys(METADATA.MAPPEDEVTCOLTABLEHEADERS).map((header, _index)=>{
                                                            return (
                                                                <Table.HeaderCell key={`collectionlist-headers-${_index}`} onClick={()=>this.sortCollectionsBy(header)}>
                                                                    <div className="collection_table_headers">
                                                                        {METADATA.MAPPEDEVTCOLTABLEHEADERS[header]}
                                                                        <Popup content={`Sort Testlets By ${METADATA.MAPPEDEVTCOLTABLEHEADERS[header]}`} trigger={
                                                                            <Icon
                                                                                name={
                                                                                    !this.state.collectionssortedby || (this.state.collectionssortedby && this.state.collectionssortedby.property!==header)?
                                                                                    "sort"
                                                                                    :
                                                                                    (
                                                                                        this.state.collectionssortedby.property===header && this.state.collectionssortedby.ascending ?
                                                                                        "sort down"
                                                                                        :
                                                                                        "sort up"
                                                                                    )
                                                                                }
                                                                                link
                                                                            />
                                                                        }/>
                                                                    </div>
                                                                </Table.HeaderCell>
                                                            )
                                                        })}
                                                    </Table.Row>
                                                </Table.Header>
                                                <Table.Body>
                                                    {collections && collections
                                                    .sort((col_a, col_b)=>{
                                                        if(!this.state.collectionssortedby){
                                                            return 0;
                                                        }
                                                        else if(this.state.collectionssortedby && this.state.collectionssortedby.property!=="timer" && this.state.collectionssortedby.property!=="itemcount"){
                                                            if(this.state.collectionssortedby.ascending && col_a[this.state.collectionssortedby.property]){
                                                                return col_a[this.state.collectionssortedby.property].localeCompare(col_b[this.state.collectionssortedby.property]);
                                                            }
                                                            else if(col_a[this.state.collectionssortedby.property]){
                                                                return -1 * col_a[this.state.collectionssortedby.property].localeCompare(col_b[this.state.collectionssortedby.property]);
                                                            }
                                                        }
                                                        else if(this.state.collectionssortedby && this.state.collectionssortedby.property==="timer"){
                                                            // Need to do some timer check and parse it into a uniz timestamp and do integer comparison
                                                            return 0;
                                                        }
                                                        else if(this.state.collectionssortedby && this.state.collectionssortedby.property==="itemcount"){
                                                            // Need to get itemcount for both a and b and do comparison
                                                            return 0;
                                                        }
                                                    })
                                                    .map((collection,col_idx)=>{
                                                        const selectedGroup = this.state.graph.groups.length && this.state.graph.groups.filter(_group=>_group.id===this.state.selectedNode.id);
                                                        const testletInGroup = selectedGroup && selectedGroup.length && selectedGroup[0].testlets.filter(_grouptestlet=>_grouptestlet.id===collection.id).length>0;
                                                        var colbtnset = {
                                                            icon: 'circle outline',
                                                            color: 'blue'
                                                        }
                                                        if(testletInGroup){
                                                            colbtnset.icon='dot circle',
                                                            colbtnset.color='green'
                                                        }
                                                        else if(!testletInGroup){
                                                            colbtnset.icon='dot circle outline',
                                                            colbtnset.color='brown'
                                                        }
                                                        
                                                        return (
                                                            <Table.Row key={`collection-${collection.id}`}>
                                                                <Table.Cell collapsing>
                                                                    <Popup content='Add Testlet To Group' trigger={
                                                                        <Icon
                                                                            link={!selectedGroup}
                                                                            name={colbtnset.icon}
                                                                            color={colbtnset.color}
                                                                            size="big"
                                                                            onClick={!testletInGroup ? ()=>this.addTestletToGroup(collection.id) : ()=>this.removeTestletFromGroup(collection.id)}
                                                                        />
                                                                    }/>
                                                                </Table.Cell>
                                                                <Table.Cell><span>{collection.title}</span></Table.Cell>
                                                                <Table.Cell>
                                                                    <span className="metadata_label">{
                                                                        proficiencies &&
                                                                        proficiencies.filter(prof=>prof.id===collection.proficiency)[0].label
                                                                    }</span>
                                                                </Table.Cell>
                                                                <Table.Cell>
                                                                    <span className="metadata_label">{
                                                                        languages &&
                                                                        languages.filter(lang=>lang.id===collection.language)[0].name
                                                                    }</span>
                                                                </Table.Cell>
                                                                <Table.Cell>
                                                                    <span className="metadata_label">{
                                                                        skills &&
                                                                        skills.filter(skill=>skill.id===collection.skill)[0].name
                                                                    }</span>
                                                                </Table.Cell>
                                                                <Table.Cell><span>Not Available</span></Table.Cell>
                                                                <Table.Cell><span>{collection.items.length}</span></Table.Cell>
                                                            </Table.Row>
                                                        )
                                                    })}
                                                </Table.Body>
                                            </Table>
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            :
                                <Grid celled>
                                    <Grid.Row>
                                        <Grid.Column width={6}>
                                            <Popup content='This flag makes linked testlet group, first to be served in test app' trigger={
                                                <Icon name='exclamation circle' color="blue"/>
                                            }/>
                                            Start Test
                                        </Grid.Column>
                                        <Grid.Column width={10}>
                                            <Checkbox
                                                toggle
                                                name="starttest"
                                                checked={
                                                    (
                                                        this.state.graph.conditionals.length>0 &&
                                                        this.state.graph.conditionals.filter(cond=>cond.id===this.state.selectedNode.id).length>0 &&
                                                        this.state.graph.conditionals.filter(cond=>cond.id===this.state.selectedNode.id)[0].condition.starttest
                                                    )
                                                }
                                                onChange={this.updateConditional}
                                            />
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column width={6}>
                                            <Popup content='This flag makes linked testlet group, final to be served in test app.' trigger={
                                                <Icon name='exclamation circle' color="blue"/>
                                            }/>
                                            End Test
                                        </Grid.Column>
                                        <Grid.Column width={10}>
                                            <Checkbox
                                                toggle
                                                name="endtest"
                                                checked={
                                                    (
                                                        this.state.graph.conditionals.length>0 &&
                                                        this.state.graph.conditionals.filter(cond=>cond.id===this.state.selectedNode.id).length>0 &&
                                                        this.state.graph.conditionals.filter(cond=>cond.id===this.state.selectedNode.id)[0].condition.endtest
                                                    )
                                                }
                                                onChange={this.updateConditional}
                                            />
                                        </Grid.Column>
                                    </Grid.Row>
                                    {
                                        this.state.graph.conditionals.length>0 &&
                                        this.state.graph.conditionals.filter(cond=>cond.id===this.state.selectedNode.id).length>0 &&
                                        !this.state.graph.conditionals.filter(cond=>cond.id===this.state.selectedNode.id)[0].condition.endtest &&
                                        !this.state.graph.conditionals.filter(cond=>cond.id===this.state.selectedNode.id)[0].condition.starttest
                                        
                                    ?
                                            <React.Fragment>

                                                {this.state.graph.groups.length>0 ?
                                                    <Grid.Row>
                                                        <Grid.Column width={6}>Add Group Score Criteria:</Grid.Column>
                                                        <Grid.Column width={8}>
                                                            <Dropdown
                                                                value={this.state.tempconditiongroup}
                                                                onChange={this.updateConditionalGroupScoreSelection}
                                                                fluid
                                                                floating
                                                                search
                                                                selection
                                                                placeholder='Group'
                                                                options={this.state.graph.groups.map(group=>{
                                                                    return {
                                                                        key: group.id,
                                                                        value: group.id,
                                                                        text: group.title
                                                                    }
                                                                })}
                                                            />
                                                        </Grid.Column>
                                                        <Grid.Column width={2}>
                                                            <Popup content='Add New Group Score Criteria Section' trigger={
                                                                <Icon onClick={this.addGroupScoresToConditionalNode} link size='large' name='plus circle' color="green"/>
                                                            }/>
                                                        </Grid.Column>
                                                    </Grid.Row>
                                                :null}

                                                {this.state.graph.conditionals.filter(cond=>cond.id===this.state.selectedNode.id)[0].condition.groupscores &&
                                                this.state.graph.conditionals.filter(cond=>cond.id===this.state.selectedNode.id)[0].condition.groupscores.length ?
                                                this.state.graph.conditionals.filter(cond=>cond.id===this.state.selectedNode.id)[0].condition.groupscores.map(groupscore=>{
                                                    return (
                                                        <React.Fragment key={`conditional-node-${this.state.graph.conditionals.find(cond=>cond.id===this.state.selectedNode.id).id}-criteria-${groupscore.id}-${groupscore.groupid}`}>
                                                            <Grid.Row>
                                                                <Grid.Column width={10}>
                                                                    {this.state.graph.groups.find(group=>group.id===groupscore.groupid).title} Conditional Criteria
                                                                </Grid.Column>
                                                                <Grid.Column width={6}>
                                                                    <Popup content='Delete Group Score Criteria Section' trigger={
                                                                        <Icon onClick={()=>this.removeGroupScoresFromConditionalNode(groupscore.id)} link size='large' name='trash' color="red"/>
                                                                    }/>
                                                                </Grid.Column>
                                                            </Grid.Row>

                                                            <Grid.Row>
                                                                <Grid.Column width={6}>
                                                                    <Popup content='When toggled, it will check whether or not this group has been done atleast once.' trigger={
                                                                        <Icon name='exclamation circle' color="blue"/>
                                                                    }/>
                                                                    Not Done Yet
                                                                </Grid.Column>
                                                                <Grid.Column width={10}>
                                                                    <Checkbox
                                                                        toggle
                                                                        name="nacheck"
                                                                        scoreconditionid={groupscore.id}
                                                                        checked={groupscore.na}
                                                                        onChange={this.updateConditional}
                                                                    />
                                                                </Grid.Column>
                                                            </Grid.Row>

                                                            {!groupscore.na ?
                                                                <React.Fragment>
                                                                    <Grid.Row>
                                                                        <Grid.Column width={6}>
                                                                            <Popup content='Minimum score threshold for current Testlet group.' trigger={
                                                                                <Icon name='exclamation circle' color="blue"/>
                                                                            }/>
                                                                            Minimum Correct Answer
                                                                        </Grid.Column>
                                                                        <Grid.Column width={10}>
                                                                            <Input placeholder='0'>
                                                                                <input
                                                                                    type="number"
                                                                                    min="0"
                                                                                    max="15"
                                                                                    value={groupscore.min || '0'}
                                                                                    onChange={this.updateConditional}
                                                                                    forprop="min"
                                                                                    scoreconditionid={groupscore.id}
                                                                                />
                                                                            </Input>
                                                                        </Grid.Column>
                                                                    </Grid.Row>
                                                                    <Grid.Row>
                                                                        <Grid.Column width={6}>
                                                                            <Popup content='Maximum score threshold for current Testlet group.' trigger={
                                                                                <Icon name='exclamation circle' color="blue"/>
                                                                            }/>
                                                                            Maximum Correct Answer
                                                                        </Grid.Column>
                                                                        <Grid.Column width={10}>
                                                                            <Input placeholder='0'>
                                                                                <input
                                                                                    type="number"
                                                                                    min="0"
                                                                                    max="15"
                                                                                    value={groupscore.max || '0'}
                                                                                    onChange={this.updateConditional}
                                                                                    forprop="max"
                                                                                    scoreconditionid={groupscore.id}
                                                                                />
                                                                            </Input>
                                                                        </Grid.Column>
                                                                    </Grid.Row>
                                                                    <Grid.Row>
                                                                        <Grid.Column width={6}>
                                                                            <Popup content='When toggled scoring will be cumulative.' trigger={
                                                                                <Icon name='exclamation circle' color="blue"/>
                                                                            }/>
                                                                            Check Cumulative Score
                                                                        </Grid.Column>
                                                                        <Grid.Column width={10}>
                                                                            <Checkbox
                                                                                toggle
                                                                                name="cumulativecheck"
                                                                                scoreconditionid={groupscore.id}
                                                                                checked={groupscore.cumulative}
                                                                                onChange={this.updateConditional}
                                                                            />
                                                                        </Grid.Column>
                                                                    </Grid.Row>
                                                                    <Grid.Row>
                                                                        <Grid.Column width={6}>
                                                                            <Popup content='Timer threshold in minutes' trigger={
                                                                                <Icon name='exclamation circle' color="blue"/>
                                                                            }/>
                                                                            Timer Threshold [In Minutes]
                                                                        </Grid.Column>
                                                                        <Grid.Column width={10}>
                                                                            <Input placeholder='0'>
                                                                                <input
                                                                                    type="number"
                                                                                    min="0"
                                                                                    value={groupscore.timerthreshold || '0'}
                                                                                    onChange={this.updateConditional}
                                                                                    forprop="timerthreshold"
                                                                                    scoreconditionid={groupscore.id}
                                                                                />
                                                                            </Input>
                                                                        </Grid.Column>
                                                                    </Grid.Row>
                                                                </React.Fragment>
                                                            :null}

                                                        </React.Fragment>
                                                    )
                                                })
                                                :null}

                                            </React.Fragment>
                                    :''}
                                </Grid>
                            }
                        </Segment>
                    </div>
                :null}

                <Modal
                    basic
                    open={this.state.awatingresponse}
                    size='small'
                >
                    <Loader>Generating Test Event Zip File</Loader>
                </Modal>

                <Confirm
                    open={this.state.showSaveConfirmation}
                    header={`${this.state.isEditing ? 'Update' : 'Create'} Event`}
                    content={`Are You Sure You Want To ${this.state.isEditing ? 'Update' : 'Create'} This Event ?`}
                    cancelButton='No'
                    confirmButton="Yes"
                    onCancel={()=>this.toggleSaveConfirmation(false)}
                    onConfirm={this.saveEventGraph}
                />

                <div id="contextmenu-container">
                    {this.state.contextmenuprop.show &&
                        this.prepToggleMenu()
                    || null}
                </div>
            </React.Fragment>






        )
    }
}

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(EditEvent));