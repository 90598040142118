import React from 'react';

import mapStateToProps from '../../datastore/mapper.js';
import mapDispatchToProps from '../../datastore/dispatcher.js';

import {connect} from 'react-redux';

import {withRouter} from 'react-router-dom';

import autoBind from 'react-autobind';

import {Tab, Segment, Grid, Header, Icon, Message, Form, TextArea, Divider, Button, Table} from 'semantic-ui-react';

import AppWrapper from '../app_wrapper/index.js'

import MLTester from './mltester.js';
import Scores from './scores.js';
import ItemsReport from './items_report.js';
import TestletsReport from './testlets_report.js';

const ReportTabs = ["Tests", "Testlets", "Items", "MLTest"];

const COMPONENTSTORENDER = [<Scores />, <TestletsReport />, <ItemsReport />, <MLTester/>];

export class Reports extends React.Component{
    constructor(props){
        super();
        this.state = {
            tabIndex: 0
        };

        autoBind(this);
    }

    switchActiveReportsTab(e, {activeIndex}){
        this.setState({tabIndex: activeIndex});
    }

    getTabbedComponent(index){
        return COMPONENTSTORENDER[index];
    }

    render(){
        return(
            <AppWrapper title={'Reports'} subtitle={null}>
                <div className="reports">
                    <Tab
                        panes={ReportTabs.map(_tab=>{return {menuItem:_tab, render:()=>null}})}
                        activeIndex={this.state.tabIndex}
                        onTabChange={this.switchActiveReportsTab}
                        menu={{attached:'top'}}
                    />
                    {this.getTabbedComponent(this.state.tabIndex)}
                </div>
            </AppWrapper>
        )
    }
}

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(Reports));