import React from 'react';

import mapStateToProps from '../../datastore/mapper.js';
import mapDispatchToProps from '../../datastore/dispatcher.js';

import {connect} from 'react-redux';

import {withRouter} from 'react-router-dom';

import AppWrapper from '../app_wrapper/index.js'

import ListTags from './list_tags.js';
import EditTag from './edit_tag.js';

export class TagsManagement extends React.Component{
    constructor(props){
        super();
    }

    tagsManagementType(_type){
        switch(_type){
            case "create":
            case "edit":
                return <EditTag />;
            default:
                return <ListTags />;
        }
    }

    parsedURLSubtitle(urlpath){
        const parsedURL = urlpath.split("/");
        if(parsedURL[2]==="edit" || parsedURL[2]==="create")
            return parsedURL[2];
        else
            return "List";
    }


    render(){

        const {location:{pathname}} = this.props;

        const parsedpath = this.parsedURLSubtitle(pathname);
        const TagsManagementComponent = this.tagsManagementType(parsedpath);

        return(
            <AppWrapper title={'Tags Management'} subtitle={parsedpath}>
                <div className="tagsmanagement">
                    {TagsManagementComponent}
                </div>
            </AppWrapper>
        )
    }
}

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(TagsManagement));