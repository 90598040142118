import React from 'react';

import mapStateToProps from '../../datastore/mapper.js';
import mapDispatchToProps from '../../datastore/dispatcher.js';

import {connect} from 'react-redux';

import {withRouter} from 'react-router-dom';

import { Grid, Input, Button, Divider, List, Segment, Dropdown, Popup, Modal, Header, Icon, Confirm } from 'semantic-ui-react';

import autoBind from 'react-autobind';

import APPENV from '../../../env.json';
import METADATA from '../../utilities/metadata.json';

export class ListMedia extends React.Component{
    constructor(props){
        super();
        this.state={
            selectedMediaType: "all",
            searchPattern: null,
            titleEditing: {
                status: false,
                index: -1
            },
            archiveMedia: {
                title: null,
                id: null
            }
        }

        autoBind(this);
    }

    componentDidMount(){
        const {appProps:{media:{schema}}} = this.props;

        if(!schema) this.props.getMediaSchema();
    }

    prepMediaTypeSet(sendFullSchema){
        const {appProps:{media:{schema}}} = this.props;

        if(sendFullSchema===true && schema && schema.length){
            return schema;
        }
        else if(schema && schema.length){
            const schemadinstinctTypes = [...new Set(schema.map(mediaschema=>mediaschema.type))];
            const preppeddropdownSchema = schemadinstinctTypes.map(mediatype=>{return {key:mediatype, text:(mediatype[0].toUpperCase() + mediatype.slice(1)), value:mediatype}});
            preppeddropdownSchema.push({
                key: "all",
                text: "All",
                value: "all"
            });
            return preppeddropdownSchema;
        }
        else{
            return [];
        }
    }

    updateMediaTypeSelection(event, {value}){
        this.setState({selectedMediaType:value});
    }
    updateSearchPattern(evt){
        const inputVal = evt.target.value;
        this.setState({
            searchPattern: inputVal
        });
    }

    async getSearchedMediaAssets(){
        try{
            const searchPattern = this.state.searchPattern;
            const mediatype = this.state.selectedMediaType==="all"?null:this.state.selectedMediaType;

            const allSearchedMediaAssets = await this.props.getMediaAssets(searchPattern, null, mediatype);

        }catch(err){if(APPENV.isDev) throw new Error("could not fetch media file of given type")}
    }

    getMediaDOM(mediatype, filename){
        if(mediatype==="video"){
            return (
                <video autoPlay muted loop className="thumb_content">
                    <source src={`${APPENV.APIEndpoint}/downloads/media/${filename}#t=1,2`}/>
                    <p>Your browser doesn't support HTML5 video. Here is a <a href={`${APPENV.APIEndpoint}/downloads/media/${filename}`}>link to the video</a> instead.</p> 
                </video>
            );
        }
        else if(mediatype==="audio"){
            return (
                <audio
                    controls
                    src={`${APPENV.APIEndpoint}/downloads/media/${filename}#t=1,5`}
                    controlsList="nodownload"
                >
                    Your browser does not support the
                    <code>audio</code> element.
                </audio>
            )
        }
        else if(mediatype==="image"){
            return (
                <img className="thumb_content" src={`${APPENV.APIEndpoint}/downloads/media/${filename}`} />
            )
        }
    }

    toggleMediaTitleEdit(_asset){
        this.setState({
            titleEditing: {
                status: _asset ? true : false,
                asset: _asset
            }
        });
    }

    toggleDeleteConfirmation(mediaObj){
        this.setState({
            archiveMedia: mediaObj ? mediaObj : {title: null, id: null}
        });
    }

    updateMediaTitle(event){
        const newtitle = event.target.value;
        this.setState((prevState)=>({
            titleEditing: {
                ...prevState.titleEditing,
                asset: {
                    ...prevState.titleEditing.asset,
                    title: newtitle || 'No Title'
                }
            }
        }));
    }

    async saveEditChanges(){
        try{
            const assettoupdate = this.state.titleEditing.asset;
            const mediatitleUpdated = await this.props.updateMedia({...assettoupdate});
            this.toggleMediaTitleEdit(null);
        }catch(err){
            console.log(err);
        }
    }

    render(){

        const {appProps:{media:{schema, allAssets}}} = this.props;

        return(
            <React.Fragment>
                <div className="listmedia">
                    <Grid>
                        <Grid.Row>
                            {schema && schema.length>0 &&
                                <Grid.Column width={10}>
                                    <Input placeholder='Search by media title' action>
                                        <input size="40" onChange={this.updateSearchPattern} value={this.state.searchPattern || ''}/>
                                        <Dropdown
                                            onChange={this.updateMediaTypeSelection}
                                            floating
                                            search
                                            selection
                                            options={this.prepMediaTypeSet()}
                                            defaultValue={this.state.selectedMediaType}
                                        />
                                        <Button content='Search' icon='search' labelPosition='left' color="green" onClick={()=>this.getSearchedMediaAssets()} />
                                    </Input>
                                </Grid.Column>
                            }
                            <Grid.Column width={3}>
                                <div>
                                    <Button color='green' onClick={()=>{this.props.history.push("/media/upload");}}>Upload</Button>
                                </div>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                    <Divider hidden />
                    {allAssets && allAssets.length && 
                        allAssets
                        .filter(asset=>{
                            if(schema && schema.length && this.state.selectedMediaType!=="all"){
                                const filteredSchema = schema.filter(schemads=>schemads.mime===asset.mimeType);
                                return filteredSchema.length && filteredSchema[0].type===this.state.selectedMediaType;
                            }
                            return this.state.selectedMediaType==="all";
                        }).length
                    ?
                        <Segment raised>
                            <List divided verticalAlign='middle'>
                                {allAssets
                                .filter(asset=>{
                                    if(schema && schema.length && this.state.selectedMediaType!=="all"){
                                        const filteredSchema = schema.filter(schemads=>schemads.mime===asset.mimeType);
                                        return filteredSchema.length && filteredSchema[0].type===this.state.selectedMediaType;
                                    }
                                    return this.state.selectedMediaType==="all";
                                })
                                .map((asset, assetindex)=>{
                                    
                                    return (
                                        <List.Item key={`media-${asset.id}`}>
                                            <List.Content floated='right'>
                                                {this.state.titleEditing.status && this.state.titleEditing.asset && this.state.titleEditing.asset.id===asset.id ?
                                                    <React.Fragment>
                                                        <Popup content='Cancel Change' trigger={
                                                            <Button onClick={()=>this.toggleMediaTitleEdit(null)} circular icon='close' color='red' size='tiny'></Button>
                                                        }/>
                                                        <Popup content='Save Change' trigger={
                                                            <Button onClick={this.saveEditChanges} circular icon='check' color='green' size='tiny'></Button>
                                                        }/>
                                                    </React.Fragment>
                                                :
                                                    <React.Fragment>
                                                        <Popup content='Archive Media File' trigger={
                                                            <Button onClick={()=>this.toggleDeleteConfirmation({id:asset.id, title:asset.title})} circular icon='trash' color='red' size='tiny'></Button>
                                                        }/>
                                                        <Popup content='Edit Title' trigger={
                                                            <Button onClick={()=>this.toggleMediaTitleEdit({id:asset.id, title:asset.title})} circular icon='pencil' color='yellow' size='tiny'></Button>
                                                        }/>
                                                    </React.Fragment>
                                                }
                                            </List.Content>
                                            <List.Content>
                                                <List.Header>
                                                    <Popup content={METADATA.MEDIA_MIME_ICONS[asset.mimeType] ? METADATA.MEDIA_MIME_ICONS[asset.mimeType].tooltip : METADATA.MEDIA_MIME_ICONS.placeholder.tooltip} trigger={
                                                        <List.Icon name={METADATA.MEDIA_MIME_ICONS[asset.mimeType] ? METADATA.MEDIA_MIME_ICONS[asset.mimeType].icon : METADATA.MEDIA_MIME_ICONS.placeholder.icon} />
                                                    } />
                                                    {this.state.titleEditing.status && this.state.titleEditing.asset && this.state.titleEditing.asset.id===asset.id ?
                                                        <Input>
                                                            <input id="mediaTitleChange" onChange={this.updateMediaTitle} defaultValue={asset.title}/>
                                                        </Input>
                                                    :
                                                        <span>{asset.title}</span>
                                                    }
                                                </List.Header>
                                                <small className="asset-filename">{asset.fileName}</small>
                                                <div className="asset-container">{this.getMediaDOM(asset.mimeType.split("/")[0], asset.fileName)}</div>
                                            </List.Content>
                                        </List.Item>
                                    )
                                })}
                            </List>
                        </Segment>
                    :null}
                </div>

                <Confirm
                    open={!!this.state.archiveMedia.title && !!this.state.archiveMedia.id}
                    header="Delete Media Asset"
                    content={`Are You Sure You Want To Delete This Media Asset: ${this.state.archiveMedia ? this.state.archiveMedia.title + " [" + this.state.archiveMedia.id + "]" : 'Media Asset Info Not Found'}?`}
                    cancelButton='No'
                    confirmButton="Yes"
                    onCancel={()=>this.toggleDeleteConfirmation(false)}
                    onConfirm={()=>{this.props.archiveMedia(this.state.archiveMedia.id);this.toggleDeleteConfirmation(false)}}
                />
                
            </React.Fragment>
        )
    }
}

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(ListMedia));