import React from 'react';

import mapStateToProps from '../../datastore/mapper.js';
import mapDispatchToProps from '../../datastore/dispatcher.js';

import {connect} from 'react-redux';

import { Segment, Icon, Popup, Grid, Table, Input, Label, Checkbox, Divider, Button, List, Header, Dropdown } from 'semantic-ui-react';
import autoBind from 'react-autobind';

import METADATA from '../../utilities/metadata.json';

export class ItemsManager extends React.Component{
    constructor(props){
        super();
        this.state={
            searchReady: false,
            preppedLanguages: [],
            preppedProficiencies: [],
            preppedSkills: [],
            search: {
                pattern: null,
                id: null,
                proficiency: null,
                skill: null,
                language: null
            },
            loadingItems: false,
            showItemAddTool: true,
            listnarrowpattern: null,
            newsortedby: null
        }

        autoBind(this);
    }

    componentDidMount(){
        if(
            this.props.appProps.languages &&
            this.props.appProps.languages.length &&
            this.props.appProps.proficiencies &&
            this.props.appProps.proficiencies.length &&
            this.props.appProps.skills &&
            this.props.appProps.skills.length &&
            this.props.appProps.status.items &&
            this.props.appProps.status.items.length &&
            !this.state.searchReady
        ){
            this.prepItemMetaData();
        }
        else{
            this.checkItemMetaDataAvailability();
        }
    }

    async checkItemMetaDataAvailability(){
        const {appProps:{languages, proficiencies, skills, status}} = this.props;

        try{
            if(!languages || !languages.length) await this.props.getLanguages();
            if(!proficiencies || !proficiencies.length) await this.props.getProficiency();
            if(!skills || !skills.length) await this.props.getSkills();
            if(!status.items || !!status.items.length) await this.props.getItemStatuses();
            this.prepItemMetaData();
        }
        catch(err){
            console.log(err);
        }
    }

    prepItemMetaData(){
        const {appProps:{languages, proficiencies, skills}} = this.props;

        let preppedLang = [], preppedProf = [], preppedSkill = [];
        if(languages && languages.length){
            preppedLang = languages.map(lang=>{
                return {
                    key:lang.shortname,
                    text:(lang.name[0].toUpperCase() + lang.name.slice(1)),
                    value:lang.id
                }
            });
        }
        if(proficiencies && proficiencies.length){
            preppedProf = proficiencies.map(prof=>{
                return{
                    key:prof.difficultydesignation,
                    text:(prof.label[0].toUpperCase() + prof.label.slice(1)),
                    value:prof.id
                }
            })
        }
        if(skills && skills.length){
            preppedSkill = skills.map(skill=>{
                return {
                    key:skill.id,
                    text:(skill.name[0].toUpperCase() + skill.name.slice(1)),
                    value:skill.id
                }
            });
        }

        if(preppedLang.length && preppedProf.length && preppedSkill.length){
            this.setState({
                searchReady:true,
                preppedLanguages:preppedLang,
                preppedProficiencies:preppedProf,
                preppedSkills:preppedSkill
            });
        }
    }

    async searchItems(){
        this.setState({loadingItems:true});
        try{
            const allItems = await this.props.getItems(JSON.parse(JSON.stringify(this.state.search)));
        }
        catch(err){}
        this.setState({loadingItems:false});
    }

    updateItemSearchPattern(event){
        const inputval = event.target.value;
        this.setState((prevState)=>(
            {
                search: {
                    ...prevState.search,
                    pattern: inputval
                }
            }
        ));
    }
    updateItemSearchID(event){
        const inputval = event.target.value;
        this.setState((prevState)=>(
            {
                search: {
                    ...prevState.search,
                    id: inputval
                }
            }
        ));
    }
    updateItemSearchMeta(event, data){
        const currentsearchstate = this.state.search;
        currentsearchstate[`${data._for}`] = data.value;
        this.setState({search: currentsearchstate});
    }

    toggleItemAddTool(){
        this.setState((prevState)=>({showItemAddTool: !prevState.showItemAddTool}));
    }

    async getUnavailableItemByID(_id){
        try{
            const newItems = await this.props.getItems({id: _id});
        }
        catch(err){}
    }

    updateNarrowPattern(event){
        const inputval = !event ? null : event.target.value;
        this.setState({listnarrowpattern: inputval});
    }

    formatedDate(dateStr) {
        let theDate = new Date(dateStr);
        return (theDate.getMonth() + 1) + '/' + theDate.getDate() + '/' + theDate.getFullYear();
    }

    sortItemBy(propname){
        var newsortedby;
        if(propname && (!this.state.sortedby || (this.state.sortedby && this.state.sortedby.property!==propname))){
            newsortedby = {
                ascending: true,
                property: propname
            }
        }
        else if(propname && this.state.sortedby.property===propname){
            newsortedby = {
                ascending: !this.state.sortedby.ascending,
                property: propname
            }
        }
        this.setState({
            sortedby: newsortedby
        });
    }

    render(){

        const { appProps: {items, languages, proficiencies, skills}, currentCollection } = this.props;

        let filteredItems = [];
        if(items && items.length){
            filteredItems = items.filter((_item, _itemindex)=>{
                const isItemAvailable = true;//_item.status==="unpublished";
                var itemMatchSearch = this.state.listnarrowpattern ? false : true;
                if(!itemMatchSearch){
                    _item.title.split(/[ ,]+/).forEach(titlefrag=>{
                        if(titlefrag.match(new RegExp((this.state.listnarrowpattern || ''),"i"))){
                            itemMatchSearch = true;
                        }
                    });
                }
                const itemInCollection = currentCollection && currentCollection.items.filter(_colitem=>_colitem===_item.id).length;
                return (isItemAvailable && itemMatchSearch) || itemInCollection;
            });
        }

        return(
            <Segment raised>
                <Popup content={this.state.showItemAddTool?'Minimize Toolset':'Maximize Toolset'} trigger={
                    <Label size='small' attached="top right" as='a' onClick={this.toggleItemAddTool}><Icon name={this.state.showItemAddTool?'window minimize':'window maximize outline'}/></Label>
                }/>
                {this.state.showItemAddTool &&
                    <React.Fragment>
                        <Grid columns='equal'>
                            <Grid.Row><Grid.Column><span>Search Items: </span></Grid.Column></Grid.Row>
                            <Grid.Row>
                                <Grid.Column>
                                    <Input
                                        onChange={this.updateItemSearchPattern}
                                        placeholder='Item Title'
                                        value={this.state.search.pattern || ''}
                                        fluid
                                    />
                                </Grid.Column>
                                <Grid.Column>
                                    <Input
                                        onChange={this.updateItemSearchID}
                                        placeholder='Item ID'
                                        value={this.state.search.id || ''}
                                        fluid
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column>
                                    <Dropdown
                                        _for="proficiency"
                                        value={this.state.search.proficiency}
                                        onChange={this.updateItemSearchMeta}
                                        fluid
                                        floating
                                        search
                                        selection
                                        clearable
                                        placeholder='Proficiency'
                                        options={this.state.preppedProficiencies}
                                    />
                                </Grid.Column>
                                <Grid.Column>
                                    <Dropdown
                                        _for="language"
                                        value={this.state.search.language}
                                        onChange={this.updateItemSearchMeta}
                                        fluid
                                        floating
                                        search
                                        selection
                                        clearable
                                        placeholder='Language'
                                        options={this.state.preppedLanguages}
                                    />
                                </Grid.Column>
                                <Grid.Column>
                                    <Dropdown
                                        _for="skill"
                                        value={this.state.search.skill}
                                        onChange={this.updateItemSearchMeta}
                                        fluid
                                        floating
                                        search
                                        selection
                                        clearable
                                        placeholder='Skill'
                                        options={this.state.preppedSkills}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column>
                                    {!this.state.loadingItems?
                                        <Button onClick={this.searchItems} color="green"><Icon name='search'/> Search</Button>
                                    :
                                        <Button loading>Loading</Button>
                                    }
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                        <Divider></Divider>
                        {filteredItems && filteredItems.length &&
                            <Table compact celled definition>
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell />
                                        {Object.keys(METADATA.MAPPEDITEMTABLEHEADERS).map((header, _index)=>{
                                            return (
                                                <Table.HeaderCell key={`itemlist-headers-${_index}`}>
                                                    <div className="item_table_headers">
                                                        {METADATA.MAPPEDITEMTABLEHEADERS[header]}
                                                        {_index===Object.keys(METADATA.MAPPEDITEMTABLEHEADERS).length - 1 ?
                                                        <React.Fragment>
                                                            <br/>(MM/DD/YYYY)
                                                        </React.Fragment>
                                                        :null}
                                                        <Popup content={`Sort Items By ${METADATA.MAPPEDITEMTABLEHEADERS[header]}`} trigger={
                                                            <Icon
                                                                name={
                                                                    !this.state.sortedby || (this.state.sortedby && this.state.sortedby.property!==header)?
                                                                    "sort"
                                                                    :
                                                                    (
                                                                        this.state.sortedby.property===header && this.state.sortedby.ascending ?
                                                                        "sort down"
                                                                        :
                                                                        "sort up"
                                                                    )
                                                                }
                                                                link
                                                                onClick={()=>this.sortItemBy(header)}
                                                            />
                                                        }/>
                                                    </div>
                                                </Table.HeaderCell>
                                            )
                                        })}
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                    {filteredItems
                                    .filter(item=>{
                                        var filteritem = true;
                                        if(this.state.search.proficiency && this.state.search.proficiency!==item.proficiency){
                                            filteritem = false;
                                        }
                                        if(this.state.search.language && this.state.search.language!==item.language){
                                            filteritem = false;
                                        }
                                        if(this.state.search.skill && this.state.search.skill!==item.skill){
                                            filteritem = false;
                                        }
                                        return filteritem;
                                    })
                                    .sort((item_a, item_b)=>{
                                        const itemincollection = currentCollection && currentCollection.items.filter(_colitem=>_colitem===item_a.id).length>0;
                                        if(itemincollection){
                                            return -1;
                                        }
                                        else if(!this.state.sortedby){
                                            return 0;
                                        }
                                        else{
                                            if(this.state.sortedby.ascending && item_a[this.state.sortedby.property]){
                                                return item_a[this.state.sortedby.property].localeCompare(item_b[this.state.sortedby.property]);
                                            }
                                            else if(item_a[this.state.sortedby.property]){
                                                return -1 * item_a[this.state.sortedby.property].localeCompare(item_b[this.state.sortedby.property]);
                                            }
                                        }
                                    })
                                    .map((_item, item_index)=>{
                                        const itemincollection = currentCollection && currentCollection.items.filter(_colitem=>_colitem===_item.id).length>0;
                                        return (
                                            <Table.Row key={`item-${_item.id}`}>
                                                <Table.Cell collapsing>
                                                    {itemincollection ?
                                                        <Popup content='Remove Item From Collection' trigger={
                                                            <Icon onClick={()=>this.props.removeItemFromCollection(_item.id)} link name='dot circle' color="green" size="big"/>
                                                        }/>
                                                    :
                                                        <Popup content='Add Item To Collection' trigger={
                                                            <Icon onClick={()=>this.props.addItemToCollection(_item.id)} link name='circle outline' color="blue" size="big"/>
                                                        }/>
                                                    }
                                                </Table.Cell>
                                                <Table.Cell>
                                                    <span>{_item.title}</span><br/>
                                                    <span><small>{_item.id}</small></span>
                                                </Table.Cell>
                                                <Table.Cell>
                                                    <span className="proficiency_label">{
                                                        proficiencies &&
                                                        proficiencies.filter(prof=>prof.id===_item.proficiency).length ? 
                                                        proficiencies.filter(prof=>prof.id===_item.proficiency)[0].label
                                                        :
                                                        _item.proficiency
                                                    }</span>
                                                </Table.Cell>
                                                <Table.Cell>
                                                    <span className="language_label">{
                                                        languages &&
                                                        languages.filter(lang=>lang.id===_item.language).length ?
                                                        languages.filter(lang=>lang.id===_item.language)[0].name
                                                        :
                                                        _item.language
                                                    }</span>
                                                </Table.Cell>
                                                <Table.Cell>
                                                    <span className="skill_label">{
                                                        skills &&
                                                        skills.filter(skill=>skill.id===_item.skill).length ?
                                                        skills.filter(skill=>skill.id===_item.skill)[0].name
                                                        :
                                                        _item.skill
                                                    }</span>
                                                </Table.Cell>
                                                <Table.Cell><span>{_item.modifiedby.name}</span></Table.Cell>
                                                <Table.Cell><span>{this.formatedDate(_item.modifieddate)}</span></Table.Cell>
                                            </Table.Row>
                                        )
                                    })}
                                </Table.Body>
                            </Table>
                        || null}
                    </React.Fragment>
                || <Label size='small' attached="top left">Item Selector</Label>}
            </Segment>
        )
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(ItemsManager);