import React from 'react';

import mapStateToProps from '../../datastore/mapper.js';
import mapDispatchToProps from '../../datastore/dispatcher.js';

import {connect} from 'react-redux';

import {withRouter} from 'react-router-dom';

import { Segment, Button, Icon, Message, Modal, Header, Dropdown, Confirm } from 'semantic-ui-react';
import autoBind from 'react-autobind';

import GeneralUtility from '../../utilities/generalUtility.js';
import BBRTE from '../../assets/lib/bitbuildrte.dev.js';

import BBRTEIconList from '../../utilities/RTEIcons.json';

import METADATA from '../../utilities/metadata.json';
import ItemHistoryButton from './item_history/item_history_button.js'
import ItemPreview from './item_preview/item_preview.js';
import TagManager from './tag_manager.js';
import MetadataManager from './metadata_manager.js';
import FeaturesToolbar from './features_toolbar.js';
import ReviewerNotes from './reviewer_notes.js';

import Features from '../features';

export class EditItem extends React.Component{
    constructor(props){
        super();

        this.state={
            currentItem:null,
            isEditing:false,
            showPreview:false,
            showConfirmation:false,
            error:null,
            isReviewing:false,
        }

        this.featureTextEditors = [];
        this.reviewerNotesTextEditor = null;

        autoBind(this);
    }

    updateError(_error){
        this.setState({error:_error},()=>{
            if(_error && _error.type==='feature'){
                const elementToScrollTo = document.getElementById("feature-error-message");
                elementToScrollTo.scrollIntoView();
                window.scrollTo(window.scrollX, window.scrollY - 50);
            }
        });
    }

    componentDidMount(){
        this.parseQueryParam();
    }

    async parseQueryParam(){
        const {location:{search}, appProps: {status}} = this.props;
        
        try{
            if(!status.items){
                await this.props.getItemStatuses();
            }

            const parsedQueryParam = search.split("?");
            if(parsedQueryParam && parsedQueryParam.length === 2){
                const itemId = parsedQueryParam[1].split('id=')[1];
                if (itemId) {
                    this.prepCurrentItem(itemId);
                }
            }
            else{
                this.prepCurrentItem();
            }
        }catch(err){
            console.log(err);
            this.prepCurrentItem();
        }
    }

    async prepCurrentItem(parsedID){
        const {appProps:{languages, proficiencies, skills, status}, clone, review} = this.props;

        const currentVersion = 1;
        const _uuid4 = GeneralUtility.generateUUIDV4();

        const currentlyEditingItem = {
            id: _uuid4,
            title: '',
            version: currentVersion,
            language: null,//languages && languages.length ? languages[0].id : null,    //use this if we need to initialialize language selection
            proficiency: null,//proficiencies && proficiencies.length ? proficiencies[0].id : null,     //use this if we need to initialialize proficiency selection
            skill: null,
            timer:{
                enabled:false,
                seconds:0
            },
            features: null,
            ispractice: false,
            reviewerNotesHTML: null
        };
        if(parsedID){
            try{
                const itemInfo = await this.props.getItemInfo({id: parsedID});
                let isEditing, isReviewing = false;
                if(clone){
                    delete itemInfo[0].versionid;
                }
                if(!clone && parsedID && !review){
                    isEditing = true;
                }
                if(!clone && parsedID && review){
                    isReviewing = true;
                }
                if(itemInfo[0].timer.enabled && itemInfo[0].timer.minutes && itemInfo[0].timer.seconds !== itemInfo[0].timer.minutes * 60){
                    itemInfo[0].timer.seconds = itemInfo[0].timer.minutes * 60;
                }
                this.setState({
                    currentItem: {
                        ...itemInfo[0],
                        id: clone ? currentlyEditingItem.id : itemInfo[0].id,
                        cloned_from: clone ? itemInfo[0].id : null
                    },
                    isEditing,
                    isReviewing
                },
                ()=>{
                    itemInfo[0].features && itemInfo[0].features.forEach(feature=>{
                        if(feature.type==='texteditor'){
                            const newBBRTE = new BBRTE({id: feature.id, container: `feature-texteditor-${feature.id}`, icons: BBRTEIconList, content: feature.content.html});
                            this.featureTextEditors.push(newBBRTE);
                        }
                    });
                    this.reviewerNotesTextEditor = new BBRTE({id: 'reviewer-notes', container: `reviewernotes-texteditor`, icons: BBRTEIconList, content: itemInfo[0].reviewerNotesHTML});
                });
            }
            catch(err){
                console.log(err);
                this.setState({
                    currentItem: currentlyEditingItem,
                    isEditing: false
                });
            }
        }
        else{
            this.setState({
                currentItem: currentlyEditingItem,
                isEditing: false
            },
            ()=>{
                this.reviewerNotesTextEditor = new BBRTE({id: 'reviewer-notes', container: `reviewernotes-texteditor`, icons: BBRTEIconList, content: currentlyEditingItem.reviewerNotesHTML});
            });
        }
    }

    updateItemMetaData(metadata){
        this.setState((prevState)=>({
            currentItem:{
                ...prevState.currentItem,
                ...metadata
            }
        }));
    }

    addFeatureToItem(newFeature){
        this.setState((prevState)=>({
            currentItem: {
                ...prevState.currentItem,
                features: prevState.currentItem.features && prevState.currentItem.features.length ?
                [
                    ...prevState.currentItem.features,
                    newFeature
                ]
                :
                [newFeature]
            },
            error:null
        }), ()=>{
            if(newFeature.type==='texteditor'){
                const newBBRTE = new BBRTE({id: newFeature.id, container: `feature-texteditor-${newFeature.id}`, icons: BBRTEIconList});
                this.featureTextEditors.push(newBBRTE);
            }
        });
    }

    removeFeatureFromItem(featureid){
        const currentFeature = this.state.currentItem.features.find(feature=>feature.id===featureid);
        const featureindex = this.state.currentItem.features.findIndex(feature=>feature.id===featureid);

        const shiftedrte = [];
        if(
            this.state.currentItem.features &&
            this.state.currentItem.features.filter((feat, f_index)=>f_index > featureindex && feat.type==="texteditor")
        ){
            const filtered_features = this.state.currentItem.features.filter((feat, f_index)=>f_index > featureindex && feat.type==="texteditor");
            filtered_features.forEach((rtefeat, _index)=>{
                const rtetoindex = _index===0 ? this.featureTextEditors.findIndex(_bbrte=>_bbrte.getOptions().id===featureid) : rtefromindex - 1;
                const rtecontent = this.featureTextEditors.filter(_bbrte=>_bbrte.getOptions().id===rtefeat.id)[0].getHTML();
                shiftedrte.push({
                    id: rtefeat.id,
                    toindex: rtetoindex,
                    content: rtecontent,
                    refresh: currentFeature.length && currentFeature.type!=="texteditor"
                });
            });
        }



        this.setState((prevState)=>({
            currentItem: {
                ...prevState.currentItem,
                features: prevState.currentItem.features.filter(feature=>feature.id!==featureid)
            }
        }), ()=>{
            if(shiftedrte.length){
                shiftedrte.forEach((rteobj, _index)=>{
                    if(rteobj.refresh){
                        const indextodelete = this.featureTextEditors.findIndex(_bbrte=>_bbrte.getOptions().id===rteobj.id);
                        this.featureTextEditors.splice(indextodelete, 1);
                    }
                    else{
                        this.featureTextEditors[rteobj.toindex].setOptions({id: rteobj.id, content: rteobj.content});
                        this.featureTextEditors[rteobj.toindex].setHTML(rteobj.content);
                        if(_index===shiftedrte.length - 1){
                            this.featureTextEditors[this.featureTextEditors.length - 1].destroy();
                            this.featureTextEditors.splice(this.featureTextEditors.length - 1, 1);
                        }
                    }
                });
                // This is awfully weird way of doing refresh
                shiftedrte.forEach((rteobj, _index)=>{
                    if(rteobj.refresh){
                        const newBBRTE = new BBRTE({id: rteobj.id, content: rteobj.content, container: `feature-texteditor-${rteobj.id}`, icons: BBRTEIconList});
                        this.featureTextEditors.push(newBBRTE);
                    }
                });
            }
        });
    }

    updateFeatures(featureSet, detail){

        const newRTESets = {
            fromid: null,
            fromindex: null,
            fromcontent: null,
            toid: null,
            toindex: null,
            tocontent: null
        };

        /*** This entire logic is problematic. Has some bugs related to position change of feature when coming back from preview. Need to fix it in the future ***/
        if(detail && detail.type==="texteditor"){
            const fromid = this.state.currentItem.features[detail.fromindex].id;
            const fromindex = this.featureTextEditors.findIndex(_bbrte=>_bbrte.getOptions().id===fromid);
            const fromcontent = this.featureTextEditors.find(_bbrte=>_bbrte.getOptions().id===fromid) && this.featureTextEditors.find(_bbrte=>_bbrte.getOptions().id===fromid).getHTML();
            newRTESets.fromid = fromid;
            newRTESets.fromindex = fromindex;
            newRTESets.fromcontent = fromcontent;
        }
        if(detail && this.state.currentItem.features[detail.toindex].type==="texteditor"){
            const toid = this.state.currentItem.features[detail.toindex].id;
            const toindex = this.featureTextEditors.findIndex(_bbrte=>_bbrte.getOptions().id===toid);
            const tocontent = this.featureTextEditors.find(_bbrte=>_bbrte.getOptions().id===toid) && this.featureTextEditors.find(_bbrte=>_bbrte.getOptions().id===toid).getHTML();
            newRTESets.toid = toid;
            newRTESets.toindex = toindex;
            newRTESets.tocontent = tocontent;
        }

        this.setState((prevState)=>({
            currentItem: {
                ...prevState.currentItem,
                features: featureSet
            }
        }), ()=>{
            if(newRTESets.fromid && newRTESets.toid){
                this.featureTextEditors.forEach(rte=>{
                    if(rte.getOptions().id===newRTESets.toid){
                        rte.setOptions({id: newRTESets.fromid, content: newRTESets.fromcontent});
                        rte.setHTML(newRTESets.fromcontent);
                    }
                    else if(rte.getOptions().id===newRTESets.fromid){
                        rte.setOptions({id: newRTESets.toid, content: newRTESets.tocontent});
                        rte.setHTML(newRTESets.tocontent);
                    }
                });
            }
            else if(newRTESets.fromid){
                this.featureTextEditors.splice(newRTESets.fromindex, 1);

                const newBBRTE = new BBRTE({id: newRTESets.fromid, content: newRTESets.fromcontent, container: `feature-texteditor-${newRTESets.fromid}`, icons: BBRTEIconList});
                this.featureTextEditors.push(newBBRTE);
            }
            else if(newRTESets.toid){
                this.featureTextEditors.splice(newRTESets.toindex, 1);

                const newBBRTE = new BBRTE({id: newRTESets.toid, content: newRTESets.tocontent, container: `feature-texteditor-${newRTESets.toid}`, icons: BBRTEIconList});
                this.featureTextEditors.push(newBBRTE);
            }
        });
    }

    getTextEditorFeatureHTML(_id){
        const featureObjIndex = this.featureTextEditors.findIndex(_bbrte=>_bbrte.getOptions().id===_id);
        if(featureObjIndex>-1){
            return this.featureTextEditors[featureObjIndex].getHTML();
        }
        return '';
    }
    setTextEditorFeatureHTML(_id, newcontent){
        const editedCurrentItem = this.state.currentItem;
        editedCurrentItem.features.forEach(feature=>{
            if(feature.id===_id){
                feature.content.html = newcontent;
            }
        });
        // this.featureTextEditors.forEach(rte=>{
        //     if(rte.getOptions().id===_id){
        //         rte.setHTML(newcontent);
        //     }
        // });
        this.setState({currentItem: editedCurrentItem});
    }

    setReviewerNotesHTML(newcontent){
        const editedCurrentItem = this.state.currentItem;
        editedCurrentItem.reviewerNotesHTML = newcontent;
        this.setState({currentItem: editedCurrentItem});
    }

    addSelectedTag(tagObj){
        const {appProps: {tags}} = this.props;

        let _error = null;

        for(var t=0; t<tags.length; t++){
            if(this.state.currentItem.tags && this.state.currentItem.tags.length){

                const currentItemHasGlobalTag = this.state.currentItem.tags.includes(tags[t].id);
                const isParamTagObject = tags[t].id===tagObj.id;

                if(isParamTagObject && currentItemHasGlobalTag){
                    _error = {type: 'tags', message:'Cannot have duplicate tag'};
                }
                else if(isParamTagObject && !currentItemHasGlobalTag){
                    const filteredTags = tags.filter(tag=>this.state.currentItem.tags.includes(tag.id) || tag.id===tagObj.id);
                    if(filteredTags.length){
                        const uniqueFilteredByLanguage = filteredTags
                            .filter(tag => tag.language && tagObj.language && tag.language.id!==tagObj.language.id);

                        const uniqueFilteredByProficiency = filteredTags
                            .filter(tag => tag.proficiency && tagObj.proficiency && tag.proficiency.id!==tagObj.proficiency.id);

                        if(uniqueFilteredByLanguage.length){
                            _error = {type: 'tags', message:'Cannot have multiple language assigned to an item'};
                        }
                        else if(uniqueFilteredByProficiency.length){
                            _error = {type: 'tags', message:'Cannot have multiple proficiency assigned to an item'};
                        }
                    }
                }

            }
        }

        if(!_error && (this.state.currentItem.tags && !this.state.currentItem.tags.includes(tagObj.id)) || !this.state.currentItem.tags || !this.state.currentItem.tags.length){
            this.setState((prevState)=>({
                error: null,
                currentItem:{
                    ...prevState.currentItem,
                    tags: 
                        prevState.currentItem.tags && prevState.currentItem.tags.length ? 
                        [...prevState.currentItem.tags, tagObj.id]
                        :
                        [tagObj.id]
                }
            }));
        }
        else if(_error){
            this.setState({error: _error},()=>{window.scrollTo(0,0)});
        }
    }
    removeSelectedTag(tagid){
        this.setState((prevState)=>({
            currentItem:{
                ...prevState.currentItem,
                tags: prevState.currentItem.tags.filter(tag=>tag!==tagid)
            }
        }));
    }

    previewItem(){
        if(this.state.currentItem.features && this.state.currentItem.features.length && this.state.currentItem.features.filter(feature=>feature.type==='texteditor').length){
            /*** This may not be needed in the future, but needds more testing ***/
            // const currentItemChanges = this.state.currentItem;
            // for(var i=0; i<currentItemChanges.features.length; i++){
            //     if(currentItemChanges.features[i].type==='texteditor'){
            //         const textEditorContents = this.getTextEditorFeatureHTML(currentItemChanges.features[i].id);
            //         currentItemChanges.features[i].content = {
            //             ...currentItemChanges.features[i].content,
            //             html: textEditorContents
            //         };
            //     }
            // }
            // this.setState({currentItem:currentItemChanges, showPreview:true, error:null});
            /***********/
            this.setState({showPreview:true, error:null});
        }
        else if(!this.state.currentItem.features || !this.state.currentItem.features.length || !this.state.currentItem.features.filter(feature=>feature.type==='texteditor').length){
            this.setState(
                {
                    error:{
                        type:'preview', message:'There must be at least one texteditor feature in current item to enable preview'
                    }
                },
                ()=>{
                    window.scrollTo(0,0);
                }
            );
        }
    }
    showReviewerNotes(){
        this.reviewerNotesTextEditor.destroy();
        this.reviewerNotesTextEditor = new BBRTE({id: 'reviewer-notes', container: `reviewernotes-texteditor`, icons: BBRTEIconList, content: this.state.currentItem.reviewerNotesHTML});
    }

    exitPreview(){
        this.featureTextEditors.forEach(bbrte=>bbrte.destroy());
        this.featureTextEditors = [];
        this.reviewerNotesTextEditor.destroy();
        this.setState({error:null, showPreview:false},()=>{
            if(this.state.currentItem.features && this.state.currentItem.features.length){
                this.state.currentItem.features.forEach(feature => {
                    if(feature.type==="texteditor"){
                        const optionsToUse = {
                            container: `feature-texteditor-${feature.id}`,
                            icons: BBRTEIconList
                        };
                        if(feature.content && feature.content.html){
                            optionsToUse.content = feature.content.html;
                        }
                        this.featureTextEditors.push(new BBRTE(optionsToUse));
                    }
                });
            }
            this.reviewerNotesTextEditor = new BBRTE({id: 'reviewer-notes', container: `reviewernotes-texteditor`, icons: BBRTEIconList, content: this.state.currentItem.reviewerNotesHTML});
        });
    }

    prepItemCreation(){
        /** Might need this logic in the future */
        // const currentItemChanges = this.state.currentItem;
        // if(currentItemChanges.features && currentItemChanges.features.length){
        //     for(var i=0; i<currentItemChanges.features.length; i++){
        //         if(currentItemChanges.features[i].type==='texteditor'){
        //             const textEditorContents = this.getTextEditorFeatureHTML(currentItemChanges.features[i].id);
        //             console.log(textEditorContents);
        //             currentItemChanges.features[i].content = {
        //                 ...currentItemChanges.features[i].content,
        //                 html: textEditorContents
        //             };
        //         }
        //     }
        // }
        // this.setState({currentItem: currentItemChanges, showConfirmation:true, error:null});
        /******************************** */

        this.setState({showConfirmation:true, error:null});
    }
    toggleConfirmation(){
        this.setState((prevState)=>({showConfirmation: !prevState.showConfirmation}));
    }

    updateItemStatus(e, {value}){
        this.setState((prevState)=>({
            currentItem: {
                ...prevState.currentItem,
                status: value
            }
        }));
    }

    async createItem(){
        const currentItem = this.state.currentItem;
        currentItem.tags = (currentItem.tags && currentItem.tags.filter(item=>item)) || [];
        //console.log(JSON.stringify(currentItem));
        try{
            if(this.state.isEditing || this.state.isReviewing){
                const itemUpdated = await this.props.updateItem(currentItem);
            }
            else{
                const itemCreated = await this.props.createItem(currentItem);
            }
            this.setState({                
                showConfirmation: false
            });
        }catch(err){
            console.log(err);
            this.setState({
                error: {
                    type: "BackEnd Error",
                    message: `Could not ${this.state.isEditing? 'update' : 'create'} this item.`
                },
                showConfirmation: false
            });
        }
    }

    render(){
        const {appProps:{proficiencies, status}} = this.props;

        return(
            <React.Fragment>

                {this.state.currentItem ?

                    <React.Fragment>

                        <Message info size="mini">
                            <Message.Header>
                                <span>Item Id</span>
                                <span className="id-message-span">{this.state.currentItem.id}</span>
                            </Message.Header>
                        </Message>

                        <div className="createcancelpanel">
                            
                            {this.state.error && this.state.error.type!=='feature' &&
                                <Message negative>
                                    <p>{this.state.error.message}</p>
                                </Message>
                            || null}
                            <Segment inverted color='grey'>
                                <Button color='red' onClick={()=>this.state.isReviewing ? this.props.history.push("/") : this.props.history.push("/items")}>Cancel</Button>
                                <Button color='green' onClick={this.prepItemCreation}>{this.state.isEditing?'Update':this.state.isReviewing ? 'Review':'Create'}</Button>
                                
                                <Dropdown
                                    floated='right'
                                    onChange={this.updateItemStatus}
                                    search
                                    selection
                                    options={
                                        status.items
                                        .filter(status=>!(status.default && !status.can_use && !status.searchable))
                                        .map(status=>{
                                            return {
                                                key: status.id,
                                                value: status.id,
                                                text: status.status
                                            }
                                        })
                                    }
                                    value={this.state.currentItem.status || (status.items && status.items[0].value) || 'NA'}
                                />
                                
                                <Button floated='right' onClick={this.state.showPreview ? this.exitPreview : this.previewItem} color={this.state.showPreview ? 'yellow' : 'blue'} icon labelPosition='left'>
                                    <Icon name={this.state.showPreview ? 'window close' : 'eye'} />
                                    {this.state.showPreview ? 'Close Preview' : 'Preview'}
                                </Button>
                                <ItemHistoryButton itemId={this.state.currentItem.id}/>
                            </Segment>
                        </div>

                        {this.state.showPreview ? 
                            <ItemPreview
                                itemDetail={
                                    {
                                        ...this.state.currentItem,
                                        proficencyLabel: (
                                            proficiencies.filter((item)=>{
                                                return item.id == this.state.currentItem.proficiency
                                            }).length && proficiencies.filter((item)=>{
                                                return item.id == this.state.currentItem.proficiency
                                            })[0].label
                                        ) || ''
                                    }
                                }
                            />
                        :
                            <React.Fragment>

                                <div className="createitem">
                                    <TagManager
                                        error={this.state.error}
                                        currentItemTags={this.state.currentItem.tags}
                                        addSelectedTag={this.addSelectedTag}
                                        removeSelectedTag={this.removeSelectedTag}
                                    />

                                    <MetadataManager
                                        currentItemTags={this.state.currentItem.tags}
                                        currentItemMetadata={{
                                            version: this.state.currentItem.version,
                                            title: this.state.currentItem.title,
                                            language: this.state.currentItem.language,
                                            proficiency: this.state.currentItem.proficiency,
                                            skill: this.state.currentItem.skill,
                                            timer: this.state.currentItem.timer,
                                            ispractice: this.state.currentItem.ispractice,
                                            domain: this.state.currentItem.domain,
                                            difficulty: this.state.currentItem.difficulty
                                        }}
                                        updateItemMetaData={this.updateItemMetaData}
                                        error={this.state.error}
                                    />

                                    <ReviewerNotes setReviewerNotesHTML={this.setReviewerNotesHTML} showReviewerNotes={this.showReviewerNotes} />

                                    <FeaturesToolbar error={this.state.error} addFeatureToItem={this.addFeatureToItem}/>
                                </div>

                                <Features
                                    getTextEditorFeatureHTML={this.getTextEditorFeatureHTML}
                                    setTextEditorFeatureHTML={this.setTextEditorFeatureHTML}
                                    currentItemFeatures={{
                                        features: this.state.currentItem.features
                                    }}
                                    updateFeatures={this.updateFeatures}
                                    removeFeatureFromItem={this.removeFeatureFromItem}
                                    updateError={this.updateError}
                                />

                            </React.Fragment>
                        }
                        
                    </React.Fragment>

                :
                    <Segment loading></Segment>
                }

                <Confirm
                    open={this.state.showConfirmation}
                    header={`${this.state.isEditing ? 'Update' : this.state.isReviewing ? 'Review':'Create'} Item`}
                    content={`Are You Sure You Want To ${this.state.isEditing ? 'Update' : this.state.isReviewing ? 'Review':'Create'} This Item ?`}
                    cancelButton='No'
                    confirmButton="Yes"
                    onCancel={()=>this.toggleConfirmation(false)}
                    onConfirm={this.createItem}
                />

            </React.Fragment>
        )
    }
}

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(EditItem));