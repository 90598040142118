import React from 'react';

import mapStateToProps from '../../datastore/mapper.js';
import mapDispatchToProps from '../../datastore/dispatcher.js';

import {connect} from 'react-redux';

import {withRouter} from 'react-router-dom';

import { Grid, Input, Button, Divider, Table, Segment, Dropdown, Popup, Modal, Header, Image, Confirm } from 'semantic-ui-react';

import autoBind from 'react-autobind';

import APPENV from '../../../env.json';
import METADATA from '../../utilities/metadata.json';

export class ListOrganizations extends React.Component{
    constructor(props){
        super();
        this.state={
            search: {
                pattern: null,
                id: null
            },
            error:{
                type: null,
                message: null
            },
            deleteorg: {
                showConfirmation: false,
                id: null,
                name: null
            }
        }

        autoBind(this);
    }

    componentDidMount(){
        if(this.props.appProps.organizations && this.props.appProps.organizations.length){
            this.refreshOrgList();
        }
        this.getOrganizationStatuses();
    }

    async refreshOrgList(){
        for(var i=0; i<this.props.appProps.organizations.length; i++){
            await this.props.getOrganizations({id: this.props.appProps.organizations[i].id});
        }
    }

    async getOrganizationStatuses(){
        try{
            const allorgstatuses = await this.props.getOrganizationStatuses();
        }
        catch(err){}
    }

    updateSearchPattern(e, data){
        this.setState((prevState)=>({
            search:{
                pattern: data.inputtype==="searchnameinp" ? data.value : prevState.search.pattern,
                id: data.inputtype==="searchidinp" ? data.value : prevState.search.id
            }
        }));
    }

    async searchOrganizations(){
        try{
            const allorgs = await this.props.getOrganizations(this.state.search);
            if(allorgs.success && allorgs.dataset && allorgs.dataset.length && allorgs.dataset[0].relations.length){
                for(var relorgc=0; relorgc<allorgs.dataset[0].relations.length; relorgc++){
                    if(!this.props.appProps.organizations.find(org=>org.id===allorgs.dataset[0].relations[relorgc])){
                        await this.props.getOrganizations({id: allorgs.dataset[0].relations[relorgc]});
                    }
                }
            }
        }
        catch(err){}
    }

    toggleDeleteConfirmation(orgid){
        const {appProps:{ organizations, status }} = this.props;

        if(orgid){
            const foundorg = organizations.find(org=>org.id===orgid);

            this.setState({
                deleteorg: {
                    showConfirmation: true,
                    id: orgid,
                    name: foundorg ? foundorg.name : null
                }
            });
        }
        else{
            this.setState({
                deleteorg: {
                    showConfirmation: false,
                    id: null,
                    name: null
                }
            });
        }
    }

    editOrg(org){
        this.props.history.push(`/organizations/edit?id=${org.id}`);
    }

    render(){

        const {appProps:{ organizations, status }} = this.props;

        return(
            <React.Fragment>
                <div className="listorganizations">
                    {this.state.error.type && this.state.error.message &&
                            <Message info size="mini">
                                <Message.Header>
                                    {this.state.error.message}
                                </Message.Header>
                            </Message>
                        }
                        <Grid columns='equal'>
                            <Grid.Row>
                                <Grid.Column>
                                    <Input fluid placeholder='Search Organizations By Name' onChange={this.updateSearchPattern} inputtype="searchnameinp" />
                                </Grid.Column>
                                <Grid.Column>
                                    <Input fluid placeholder='Search Organizations By Id' onChange={this.updateSearchPattern} inputtype="searchidinp" />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column>
                                    <Button onClick={this.searchOrganizations}>Search</Button>
                                </Grid.Column>
                                <Grid.Column>
                                    <Button onClick={()=>{this.props.history.push(`/organizations/create`)}} color='green'>Create</Button>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>

                        <Divider hidden />
                        {organizations && organizations.length &&
                            <Segment raised>
                                <Table compact celled definition>
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell />
                                            <Table.HeaderCell>Logo</Table.HeaderCell>
                                            <Table.HeaderCell>Name</Table.HeaderCell>
                                            <Table.HeaderCell>Address</Table.HeaderCell>
                                            <Table.HeaderCell>Theme</Table.HeaderCell>
                                            <Table.HeaderCell>Related Organizations</Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>
                                    <Table.Body>
                                        {organizations.map((org, idx)=>{
                                            return (
                                                <Table.Row key={`orgs-${org.id}`}>
                                                    <Table.Cell collapsing>
                                                        <Popup content='Delete Organizations' trigger={
                                                            <Button circular icon='trash' color='red' size='tiny' onClick={()=>this.toggleDeleteConfirmation(org.id)}></Button>
                                                        }/>
                                                        <Popup content='Edit Organization' trigger={
                                                            <Button circular icon='pencil' color='yellow' size='tiny' onClick={()=>this.editOrg(org)}></Button>
                                                        }/>
                                                    </Table.Cell>
                                                    <Table.Cell collapsing>
                                                        <div className="logo-section"><Image size='mini' src={`${APPENV.APIEndpoint}/downloads/organizations/logos/${org.logo || ''}`} /></div>
                                                    </Table.Cell>
                                                    <Table.Cell>
                                                        <section className="orgname-section">
                                                            <div>{org.name}</div>
                                                            <div className="org_id">{org.id}</div>
                                                        </section>
                                                    </Table.Cell>
                                                    <Table.Cell>
                                                        <section className="orgname-section">
                                                            <div>{org.address}</div>
                                                        </section>
                                                    </Table.Cell>
                                                    <Table.Cell>
                                                        <section className="orgtheme-section">
                                                            <Popup content='Background Color' trigger={
                                                                <div style={{backgroundColor: org.theme.bgcolor}}></div>
                                                            }/>
                                                            <Popup content='Foreground Color' trigger={
                                                                <div style={{backgroundColor: org.theme.color}}></div>
                                                            }/>
                                                        </section>
                                                    </Table.Cell>
                                                    <Table.Cell collapsing>
                                                        <section className="orgrelation-section">
                                                            {org.relations.map(orgrel=>{
                                                                const relatedorginfo = organizations.find(_org=>_org.id===orgrel);
                                                                if(relatedorginfo){
                                                                    return (
                                                                        <Popup key={`orgs-${org.id}-rel-${relatedorginfo.id}`} content={relatedorginfo.name} trigger={
                                                                            <Image size='mini' src={`${APPENV.APIEndpoint}/downloads/organizations/logos/${relatedorginfo.logo || ''}`} />
                                                                        }/>
                                                                    );
                                                                }
                                                            }).filter(item=>item)}
                                                        </section>
                                                    </Table.Cell>
                                                </Table.Row>
                                            )
                                        })}
                                    </Table.Body>
                                </Table>
                            </Segment>
                            || null
                        }
                </div>

                <Confirm
                    open={this.state.deleteorg.showConfirmation}
                    header="Delete Item"
                    content={`Are You Sure You Want To Delete Organization: ${this.state.deleteorg.id ? this.state.deleteorg.id + ", "+this.state.deleteorg.name : 'Org Id Not Found'}?`}
                    cancelButton='No'
                    confirmButton="Yes"
                    onCancel={()=>this.toggleDeleteConfirmation(false)}
                    onConfirm={()=>{this.props.archiveOrganization(this.state.deleteorg.id);this.toggleDeleteConfirmation(false)}}
                />

            </React.Fragment>
        )
    }
}

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(ListOrganizations));