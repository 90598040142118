import React from 'react';

import mapStateToProps from '../../datastore/mapper.js';
import mapDispatchToProps from '../../datastore/dispatcher.js';

import {connect} from 'react-redux';

import {withRouter} from 'react-router-dom';

import { Container, Divider, Button, Grid, Input, Label, Icon, Message, Dropdown, Segment } from 'semantic-ui-react'

import autoBind from 'react-autobind';

class UploadMedia extends React.Component{
    constructor(props){
        super();
        this.state={
            error:null,
            success:null,
            selectedFile:{
                type:null,
                filename:null,
                title:null
            },
            titleAutoGenerator:{
                ready:false,
                proficiency:null,
                language:null
            }
        }

        this.uploadFormData = new FormData();

        autoBind(this);
    }

    componentDidMount(){
        if(!this.state.titleAutoGenerator.ready){
            this.setTitleAutogeneratorStatus();
        }

        this.getMetaData();

    }

    componentDidUpdate(){
        if(!this.state.titleAutoGenerator.ready){
            this.setTitleAutogeneratorStatus();
        }
    }

    setTitleAutogeneratorStatus(){
        const {appProps:{media:{schema}, languages, proficiencies}} = this.props;

        if(languages && languages.length && proficiencies && proficiencies.length && !this.state.titleAutoGenerator.ready){
            this.setState({
                titleAutoGenerator:{
                    ready:true,
                    proficiency:null,
                    language:null
                }
            });
        }
    }

    async getMetaData(){
        const {appProps:{media:{schema}, languages, proficiencies}} = this.props;

        if(!schema) await this.props.getMediaSchema();
        if(!languages) this.props.getLanguages();
        if(!proficiencies) this.props.getProficiency();
    }

    generateTitle(){
        const {appProps:{media:{schema}, languages, proficiencies}} = this.props;

        let mediaFileTitle = '';

        if(this.state.titleAutoGenerator.language){
            const selectedLanguage = languages.filter(language=>language.id===this.state.titleAutoGenerator.language);
            if(selectedLanguage.length){
                mediaFileTitle += selectedLanguage[0].name.substring(0, 3).toUpperCase();
            }
        }
        if(this.state.titleAutoGenerator.proficiency){
            const selectedProficiency = proficiencies.filter(proficiency=>proficiency.id===this.state.titleAutoGenerator.proficiency);
            if(selectedProficiency.length){
                mediaFileTitle += (mediaFileTitle!=='' ? '_' : '') + selectedProficiency[0].label.substring(0, 3).toUpperCase();
            }
        }

        if(mediaFileTitle.length && this.state.selectedFile.type && schema){
            const mediaFileType = schema.find(indivschema=>indivschema.mime===this.state.selectedFile.type).type.toUpperCase() || 'MEDIA';
            this.setState((prevState)=>({
                selectedFile: {
                    ...prevState.selectedFile,
                    title: `${mediaFileTitle}_${mediaFileType}_${prevState.selectedFile.filename.split(".")[0] || ''}`
                }
            }));
        }
    }

    updateAutoGeneratorOptions(event, {value}, _for){
        if(_for==="language"){
            this.setState((prevState)=>({
                titleAutoGenerator:{
                    ...prevState.titleAutoGenerator,
                    language: value
                }
            }), ()=>{this.generateTitle();});
        }
        else if(_for==="proficiency"){
            this.setState((prevState)=>({
                titleAutoGenerator:{
                    ...prevState.titleAutoGenerator,
                    proficiency: value
                }
            }), ()=>{this.generateTitle();});
        }
    }

    autoGeneratorOptions(_for){
        const {appProps:{languages, proficiencies}} = this.props;

        if(_for==="proficiency"){
            return proficiencies.map(prof=>{
                return{
                    key:prof.difficultydesignation,
                    text:(prof.label[0].toUpperCase() + prof.label.slice(1)),
                    value:prof.id
                }
            });
        }
        else if(_for==="language"){
            return languages.map(lang=>{
                return {
                    key:lang.shortname,
                    text:(lang.name[0].toUpperCase() + lang.name.slice(1)),
                    value:lang.id
                }
            });
        }
    }

    prepMediaTypeSet(sendFullSchema){
        const {appProps:{media:{schema}}} = this.props;

        if(sendFullSchema===true && schema && schema.length){
            return schema;
        }
        else if(schema && schema.length){
            const schemadinstinctTypes = [...new Set(schema.map(mediaschema=>mediaschema.type))];
            return schemadinstinctTypes.map(mediatype=>{return {key:mediatype, text:(mediatype[0].toUpperCase() + mediatype.slice(1)), value:mediatype}});
        }
        else{
            return [];
        }
    }

    prepVideoFileUpload(){
        this.setState((prevState)=>({
            selectedFile:{
                ...prevState.selectedFile,
                title: null
            }
        }));

        const uploadInputNode = document.createElement('INPUT');
        uploadInputNode.setAttribute("type", "file");
        uploadInputNode.setAttribute("accept",
            this.prepMediaTypeSet(true).map(mediaschema=>mediaschema.mime)
        );
        document.body.appendChild(uploadInputNode);
        uploadInputNode.style.display = 'none';
        uploadInputNode.click();
        uploadInputNode.addEventListener("change", (event)=>{
            const _mediafile = event.target.files[0];

            const fileType = event.target.files[0].type;
            const fileName = event.target.files[0].name;

            if(this.uploadFormData.has("mediafile")){
                this.uploadFormData.delete("mediafile");
            }
            this.uploadFormData.append("mediafile", _mediafile);
            this.setState((prevState)=>({
                selectedFile:{
                    ...prevState.selectedFile,
                    type: fileType,
                    filename: fileName,
                    title: fileName.split(".")[0]
                },
                success:null
            }), ()=>{this.generateTitle();});
            uploadInputNode.remove();
        });

    }

    updateMediaTitle(event, {value}){
        this.setState((prevState)=>({
            selectedFile:{
                ...prevState.selectedFile,
                title: value
            },
            success:null
        }));
    }

    async uploadMediaFile(){
        if(this.state.selectedFile.title && this.state.selectedFile.filename){
            try{
                
                if(this.uploadFormData.has("title")){
                    this.uploadFormData.set("title", this.state.selectedFile.title);
                }
                else{
                    this.uploadFormData.append("title", this.state.selectedFile.title);
                }

                const mediaFileuploaded =  await this.props.uploadMedia(this.uploadFormData);
                this.setState(()=>({
                    error:null,
                    success:true,
                    selectedFile:{
                        type:null,
                        filename:null,
                        title:null
                    },
                    titleAutoGenerator:{
                        ready:true,
                        proficiency:null,
                        language:null
                    }
                }));
            }catch(err){
                console.log(err)
            }
        }
        else{
            this.setState({error: {type: (!this.state.selectedFile.title ? 'title' : 'file')}})
        }
    }


    render(){

        const {appProps:{media:{schema}, languages, proficiencies}} = this.props;

        return(
            <div className="uploadmedia">
                <Container>
                    <Grid>
                        {this.state.error && this.state.error.type &&
                            <Grid.Row><Grid.Column>
                                {this.state.error.type==='title'?
                                    <Message negative>
                                        <span>Media File Must Have a Title</span>
                                    </Message>
                                :
                                    <Message negative>
                                        <span>Media File Must Be Selected</span>
                                    </Message>
                                }    
                            </Grid.Column></Grid.Row>
                        }
                        {this.state.success &&
                            <Grid.Row><Grid.Column>
                                <Message positive>
                                    <span>Media File Successfully Uploaded</span>
                                </Message> 
                            </Grid.Column></Grid.Row>
                        }
                        <Grid.Row columns='equal'>
                            <Grid.Column>
                                <Input value={this.state.selectedFile.title || ''} fluid placeholder='Media File Title' onChange={this.updateMediaTitle}/>
                            </Grid.Column>
                            <Grid.Column>
                                <Button onClick={this.prepVideoFileUpload} icon labelPosition='left'>
                                    <Icon name={this.state.selectedFile.filename ? 'upload' :'hdd'} />
                                    {this.state.selectedFile.filename || 'Browse Media File...'}
                                </Button>
                                {this.state.error && this.state.error.type==='file'?
                                    <Label pointing='up' basic color='red'>
                                        Must Select A File
                                    </Label>
                                :null}
                            </Grid.Column>
                        </Grid.Row>
                        {languages && proficiencies && this.state.titleAutoGenerator.ready && this.state.selectedFile.filename ?
                            <Container>
                                <Message attached="top" info size="tiny">
                                    <Message.Header>Change Properties Below, To Auto Generate Media Title</Message.Header>
                                    <p>Titles can still be edited after auto generation.</p>
                                    <p>Titles can be used to search for media files during item creation/editing.</p>
                                    <p>Available Properties are only for title generation and are not in any way associated with the media files themselves.</p>
                                </Message>
                                <Segment attached>
                                    <div className="title-generator-tools">
                                        <div>
                                            <Dropdown
                                                onChange={(event, data)=>this.updateAutoGeneratorOptions(event, data, "language")}
                                                floating
                                                search
                                                selection
                                                clearable
                                                placeholder="Language"
                                                options={this.autoGeneratorOptions("language")}
                                            />
                                        </div>
                                        <div>
                                            <Dropdown
                                                onChange={(event, data)=>this.updateAutoGeneratorOptions(event, data, "proficiency")}
                                                floating
                                                search
                                                selection
                                                clearable
                                                placeholder="Proficiency"
                                                options={this.autoGeneratorOptions("proficiency")}
                                            />
                                        </div>
                                    </div>
                                </Segment>
                            </Container>
                        :null}
                    </Grid>
                </Container>
                <Divider hidden />
                <Message>
                    <p>
                        <Button color='red' onClick={()=>this.props.history.push("/media")}>Cancel</Button>
                        <Button floated='right' color='green' onClick={this.uploadMediaFile}>Upload</Button>
                    </p>
                </Message>
            </div>
        )
    }
}

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(UploadMedia));