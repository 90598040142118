import React from 'react';
import mapStateToProps from '../../datastore/mapper.js';
import mapDispatchToProps from '../../datastore/dispatcher.js';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import { Grid, Input, Button, Divider, List, Dimmer, Loader, Segment, Dropdown, Popup, Header, Icon, Modal, Table, Confirm } from 'semantic-ui-react';
import autoBind from 'react-autobind';
import ItemHistoryButton from '../items_management/item_history/item_history_button.js'

import METADATA from '../../utilities/metadata.json';

export class ListItemsReviews extends React.Component{
    constructor(props){
        super();
        this.state={
            currentItemSettings:{
                pattern: null,
                proficiency: null,
                language: null,
                skill: null,
                id: null
            },
            sortedby: null,
            loadingItems: false
        };
        autoBind(this);
    }

    componentDidMount(){
        this.checkItemMetaDataAvailability();
    }

    async checkItemMetaDataAvailability(){
        const {appProps:{languages, proficiencies, skills, status}} = this.props;

        try{
            if(!languages || !languages.length) await this.props.getLanguages();
            if(!proficiencies || !proficiencies.length) await this.props.getProficiency();
            if(!skills || !skills.length) await this.props.getSkills();
            if(!status.items || !!status.items.length) await this.props.getItemStatuses();
        }
        catch(err){
            console.log(err);
        }
    }

    formatedDate(dateStr) {
        let theDate = new Date(dateStr);
        return (theDate.getMonth() + 1) + '/' + theDate.getDate() + '/' + theDate.getFullYear();
    }

    editItem(item){
        const {type} = this.props;
        if(type == 'toreview'){
            this.props.history.push(`/items/review?id=${item.id}`);
        }
        else{
            this.props.history.push(`/items/edit?id=${item.id}`);
        }
    }

    async getItems(all){
        this.setState({loadingItems:true});
        try{
            if(typeof all==='boolean' && all){
                const allItems = await this.props.getItems();
            }
            else{
                const searchedItem = await this.props.getItems(this.state.currentItemSettings);
            }
        }catch(err){console.log(err);}
        this.setState({loadingItems:false});
    }

    sortItemBy(propname){
        var newsortedby;
        if(propname && (!this.state.sortedby || (this.state.sortedby && this.state.sortedby.property!==propname))){
            newsortedby = {
                ascending: true,
                property: propname
            }
        }
        else if(propname && this.state.sortedby.property===propname){
            newsortedby = {
                ascending: !this.state.sortedby.ascending,
                property: propname
            }
        }
        this.setState({
            sortedby: newsortedby
        });
    }
    

    render(){
        const {appProps:{items, languages, proficiencies, skills, status}, type} = this.props;

        return(
            <React.Fragment>
                <div className="listtags">
                    {items && items.length &&
                        <Segment raised>
                            <Table compact celled definition>
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell width={2}>
                                            <div className="item_table_headers">{
                                                type==="pending" ? "My Items Pending Review" :
                                                type==="toreview" ? "Items To Review" : 
                                                type==="reviewcomplete" ? "My Reviewed Items" : ""
                                            }</div>
                                        </Table.HeaderCell>
                                        {Object.keys(METADATA.MAPPEDITEMREVIEWTABLEHEADERS).map((header, _index)=>{
                                            return (
                                                <Table.HeaderCell key={`itemlist-headers-${_index}`}>
                                                    <div className="item_table_headers">
                                                        {METADATA.MAPPEDITEMREVIEWTABLEHEADERS[header]}
                                                        <Popup content={`Sort Items By ${METADATA.MAPPEDITEMREVIEWTABLEHEADERS[header]}`} trigger={
                                                            <Icon
                                                                name={
                                                                    !this.state.sortedby || (this.state.sortedby && this.state.sortedby.property!==header)?
                                                                    "sort"
                                                                    :
                                                                    (
                                                                        this.state.sortedby.property===header && this.state.sortedby.ascending ?
                                                                        "sort down"
                                                                        :
                                                                        "sort up"
                                                                    )
                                                                }
                                                                link
                                                                onClick={()=>this.sortItemBy(header)}
                                                            />
                                                        }/>
                                                    </div>
                                                </Table.HeaderCell>
                                            )
                                        })}
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                    {items
                                    .filter(item=>{
                                        if(type==="pending"){
                                            return item.reviewable && item.isMine && !item.reviewed;
                                        }
                                        else if(type==="toreview"){
                                            return item.reviewable && !item.reviewed && (item.assigned || !item.isMine);
                                        }
                                        else if(type==="reviewcomplete"){
                                            return !item.reviewable && item.isMine && item.reviewed;
                                        }
                                        return false;
                                    })
                                    .sort((item_a, item_b)=>{
                                        if(!this.state.sortedby){
                                            return 0;
                                        }
                                        else{
                                            if(this.state.sortedby.ascending && item_a[this.state.sortedby.property]){
                                                return item_a[this.state.sortedby.property].localeCompare(item_b[this.state.sortedby.property]);
                                            }
                                            else if(item_a[this.state.sortedby.property]){
                                                return -1 * item_a[this.state.sortedby.property].localeCompare(item_b[this.state.sortedby.property]);
                                            }
                                        }
                                    })
                                    .map(item=>{
                                        return (
                                            <Table.Row key={`item-${item.id}`}>
                                                <Table.Cell collapsing>
                                                    <Popup content={
                                                        type==="toreview" ? 'Review Item' : "Edit Item"
                                                    } trigger={
                                                        <Button circular icon='pencil' color='yellow' size='tiny' onClick={()=>this.editItem(item)}></Button>
                                                    }/>
                                                    <ItemHistoryButton itemId={item.id}/>
                                                </Table.Cell>
                                                <Table.Cell>
                                                    <span>
                                                        {item.title}
                                                        &nbsp;
                                                        {item.assigned &&
                                                            <Popup size="mini" content={`Assigned To You`} trigger={
                                                                <Icon size="small" color="orange" name="user" />
                                                            }/>
                                                        }
                                                    </span>
                                                </Table.Cell>
                                                <Table.Cell>
                                                    <span className="proficiency_label">{
                                                        proficiencies && proficiencies.filter(prof=>prof.id===item.proficiency).length ? 
                                                        proficiencies.filter(prof=>prof.id===item.proficiency)[0].label
                                                        :
                                                        item.proficiency
                                                    }</span>
                                                </Table.Cell>
                                                <Table.Cell>
                                                    <span className="language_label">{
                                                        languages && languages.filter(lang=>lang.id===item.language).length ?
                                                        languages.filter(lang=>lang.id===item.language)[0].name
                                                        :
                                                        item.language
                                                    }</span>
                                                </Table.Cell>
                                                <Table.Cell>
                                                    <span className="skill_label">{
                                                        skills && skills.filter(skill=>skill.id===item.skill).length ?
                                                        skills.filter(skill=>skill.id===item.skill)[0].name
                                                        :
                                                        item.skill
                                                    }</span>
                                                </Table.Cell>
                                                <Table.Cell><span>{item.createdby.name}</span></Table.Cell>
                                                {/* <Table.Cell><span>{this.formatedDate(item.creationdate)}</span></Table.Cell> */}
                                            </Table.Row>
                                        )
                                    })}
                                </Table.Body>
                            </Table>
                        </Segment>
                        || null
                    }
                </div>
                
            </React.Fragment>
        )
    }
}

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(ListItemsReviews));