/***********
 * XHRRequest is a utility class to make xhr request to endpoint
 * Methods:
 * 1. XHRRequest    [@param: method,url,data]
 *      ->  method that makes xhr request
 *      ->  method: string enum["POST","PUT","GET","DELETE"]
 *      ->  url:    string
 *      ->  data:   json/string
 */

import * as APPENV from '../../env.json';
import CookieUtility from './cookieUtility.js';

export default class APIConnector{
    constructor(){
        this.bearertoken = null;
    }

    static setBearerToken(token){
        this.bearertoken = token;
    }
    static removeBearerToken(){
        this.bearertoken = null;
    }

    static objToQueryString(obj){
        let queryString = '';
        try{
            if(obj && Object.keys(obj).length){
                queryString = '?';
                Object.keys(obj).forEach(key=>{
                    if(obj[key]){
                        queryString += (queryString!=='?' ? '&' : '') + `${key}=${obj[key]}`
                    }
                })
            }
        }catch(err){
            console.log(err);
        }
        return (queryString==='?'?'/':queryString);
    }

    static makeRequest(method,url,data,isformdata){
        return new Promise((resolve, reject)=>{
            const xhr = new XMLHttpRequest();
            xhr.open(method,url,true);
            xhr.onload = ()=>{
                if ( (xhr.status >= 200 && xhr.status < 300) && xhr.readyState == 4){
                    if(xhr.getResponseHeader('JWT') && xhr.getResponseHeader('expires')){
                        const newJWT = xhr.getResponseHeader('JWT');
                        const newExpiration = xhr.getResponseHeader('expires');
                        this.bearertoken = newJWT;
                        if(window.localStorage.getItem('remember')){
                            CookieUtility.setCookie("JWT", newJWT, newExpiration);
                        }
                    }
                    
                    var responsedata = undefined;
                    if(xhr.responseText!=undefined && xhr.responseText!=null && xhr.responseText!="" && xhr.responseText.length>0){
                        responsedata = xhr.responseText;
                    }
                    else{
                        responsedata = "Successfully submitted";
                    }
                    resolve(responsedata);
                } else {
                    if(xhr.status===401 && xhr.response){
                        try{
                            const parsedResponse = JSON.parse(xhr.response);
                            if(parsedResponse && parsedResponse.error && parsedResponse.error.type===102 && parsedResponse.error.message==="JWT Invalid"){
                                window.localStorage.removeItem('remember');
                                window.localStorage.removeItem('userloggedin');
                                window.location.href = "/";
                            }
                        }catch(err){}
                    }
                    reject({
                        status: xhr.status,
                        statusText: xhr.statusText
                    });
                }
                //console.log(this.xhr.status, "---"+this.xhr.responseText+"---", typeof this.xhr.responseText, this.xhr.responseText.length);
            };
            xhr.onerror = ()=>{
                console.log("XHR Error: ", xhr.status, xhr.statusText, xhr.response);
                reject({
                    status: xhr.status,
                    statusText: xhr.statusText
                });
                //window.location.href = "/";
            };
            xhr.setRequestHeader("Cache-Control", "no-cache, no-store, must-revalidate");
            xhr.setRequestHeader('apikey', APPENV.apikey);
            if(this.bearertoken){
                xhr.setRequestHeader('Authorization','Bearer '+this.bearertoken);
            }
            if((method=="PUT" || method=="POST") && data){
                try{
                    if(!isformdata){
                        xhr.setRequestHeader('Content-Type', 'application/json');
                    }
                }catch(err){
                    xhr.setRequestHeader('Content-Type', 'application/json');
                }
                
                xhr.send(data);
            }
            else{
                xhr.send();
            }
        });
    }
    
    static async downloadScoreCSV(eventid){
        var url = !!eventid ? `${APPENV.APIEndpoint}/scores/csv?eventid=${eventid}` : `${APPENV.APIEndpoint}/scores/csv`;
        var xhr = new XMLHttpRequest();
        xhr.open("GET", url, true);
        xhr.setRequestHeader('apikey', APPENV.apikey);
        xhr.responseType = 'blob';
        xhr.onload = function (e) {
            var blob = e.currentTarget.response;
            var contentDispo = e.currentTarget.getResponseHeader('Content-Disposition');
            var fileName = contentDispo.match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/)[1];
            var a = document.createElement('a');
            a.href = window.URL.createObjectURL(blob);
            a.download = `Score_Report_${fileName.split('_')[1]}`;
            a.click();
            window.URL.revokeObjectURL(a.href)
        }
        xhr.send();
    }

    static async downloadItemsCSV(params){
        var xhr = new XMLHttpRequest();
        xhr.open("GET", `${APPENV.APIEndpoint}/scores/itemsreport/csv${params}`, true);
        xhr.setRequestHeader('apikey', APPENV.apikey);
        xhr.responseType = 'blob';
        xhr.onload = function (e) {
            var blob = e.currentTarget.response;
            var contentDispo = e.currentTarget.getResponseHeader('Content-Disposition');
            var fileName = contentDispo.match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/)[1];
            console.log(fileName);
            var a = document.createElement('a');
            a.href = window.URL.createObjectURL(blob);
            a.download = `Item_Report_${fileName.split('_')[1]}`;
            a.dispatchEvent(new MouseEvent('click'));
        }
        xhr.send();
    }

    static async downloadTestletCSV(params){
        var xhr = new XMLHttpRequest();
        xhr.open("GET", `${APPENV.APIEndpoint}/scores/testletsreport/csv${params}`, true);
        xhr.setRequestHeader('apikey', APPENV.apikey);
        xhr.responseType = 'blob';
        xhr.onload = function (e) {
            var blob = e.currentTarget.response;
            var contentDispo = e.currentTarget.getResponseHeader('Content-Disposition');
            var fileName = contentDispo.match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/)[1];
            console.log(fileName);
            var a = document.createElement('a');
            a.href = window.URL.createObjectURL(blob);
            a.download = `Testlet_Report_${fileName.split('_')[1]}`;
            a.download = fileName;
            a.dispatchEvent(new MouseEvent('click'));
        }
        xhr.send();
    }
    static async validateJWT(){
        try{
            const loggedIn = await this.makeRequest("GET", `${APPENV.APIEndpoint}/`);
            return loggedIn;
        }
        catch(err){console.log(err);throw new Error('Login Failed!');}
    }

    static async loginUser(email, password){
        const encodedpass = window.btoa(password);
        const loginobj = {
            email,
            password: encodedpass
        }
        try{
            const loggedIn = await this.makeRequest("POST", `${APPENV.APIEndpoint}/auth/login`, JSON.stringify(loginobj));
            return loggedIn;
        }
        catch(err){throw new Error('Login Failed!');}
    }

    static async getMyUserInfo(){
        try{
            const userdata = await this.makeRequest("GET", `${APPENV.APIEndpoint}/info`);
            return userdata;
        }
        catch(err){throw new Error('Could Not Get User Info');}
    }

    static async setDefaultOrganization(orgid){
        try{
            const defaultorgset = await this.makeRequest("PUT", `${APPENV.APIEndpoint}/organization/default?organizationid=${orgid}`);
            return defaultorgset;
        }
        catch(err){throw new Error('Could Not Get User Info');}
    }

    static async createUser(userData){
        try{
            const userCreated = await this.makeRequest("POST", `${APPENV.APIEndpoint}/auth/create`, JSON.stringify(userData));
            return userCreated;
        }
        catch(err){throw new Error('Could Not Create New User');}
    }

    static async updateUserRole(userData){
        try{
            const userRoleUpdated = await this.makeRequest("PUT", `${APPENV.APIEndpoint}/user/updaterole`, JSON.stringify(userData));
            return userRoleUpdated;
        }
        catch(err){throw new Error('Could Not Update User Role');}
    }

    static async updateUserPassword(email, oldPassword, newPassword, key=false){
        let pswData;
        if(!key){
            pswData = {
                email,
                oldPassword: window.btoa(oldPassword),
                newPassword: window.btoa(newPassword),
            }
        }else{
            pswData = {
                email,
                newPassword: window.btoa(newPassword),
                key
            }
        }
        try{
            const userPasswordUpdated = await this.makeRequest("PUT", `${APPENV.APIEndpoint}/auth/update`, JSON.stringify(pswData));
            return userPasswordUpdated;
        }
        catch(error){
            if(error.status == 403){
                throw new Error('Invalid Reset Authorization.');
            } else {
                throw new Error('A communication error occured, could not update user password');
            }
            
        }
    }

    static async requestPasswordReset(email){
        try{
            const result = await this.makeRequest("POST", `${APPENV.APIEndpoint}/auth/request_reset`, JSON.stringify({email}));
            return result;
        }
        catch(err){
            if(err.status == 404){
                throw new Error("Email does not exist");
            }else{
                throw new Error("A communication error occured, could not request a password reset.");
            }
        }
    }

    static async inviteUser(userData){
        try{
            const userInvited = await this.makeRequest("POST", `${APPENV.APIEndpoint}/user/invite`, JSON.stringify(userData));
            return userInvited;
        }
        catch(err){throw new Error('Could Not Invite User');}
    }

    static async getUserProfile(){
        try{
            const userProfile = await this.makeRequest("GET", `${APPENV.APIEndpoint}/user`);
            return userProfile;
        }
        catch(err){throw new Error('Could Not User Profile');}
    }

    static async cancelInvitation(invitationID){
        try{
            const invitationCancelled = await this.makeRequest("DELETE", `${APPENV.APIEndpoint}/user/cancelinvite?id=${invitationID}`);
            return invitationCancelled;
        }
        catch(err){throw new Error('Could Not Cancel User Invitation');}
    }

    static async getUserInvites(){
        try{
            const allInvites = await this.makeRequest("GET", `${APPENV.APIEndpoint}/user/invites`);
            return allInvites;
        }
        catch(err){throw new Error('Invites Could Not Be Retrieved');}
    }

    static async searchUser(roleid, searchPattern){
        let queryParam = '';
        if(roleid && searchPattern){
            queryParam = '?pattern='+searchPattern+'&roleid='+roleid;
        }
        else if(roleid && !searchPattern){
            queryParam = '?roleid='+roleid;
        }
        else if(!roleid && searchPattern){
            queryParam = '?pattern='+searchPattern;
        }
        else{
            queryParam = '?all'
        }

        try{
            const searchedUser = await this.makeRequest("GET", `${APPENV.APIEndpoint}/user/${queryParam}`);
            return searchedUser;
        }
        catch(err){throw new Error('Could Not Invite User');}
    }

    static async getAvailablePermissions(){
        try{
            const allAvailablePermissions = await this.makeRequest("GET", `${APPENV.APIEndpoint}/role/availablepermissions`);
            return allAvailablePermissions;
        }
        catch(err){throw new Error('Permissions Could Not Be Retrieved');}
    }

    static async getRoles(id){
        try{
            const allRoles = await this.makeRequest("GET", `${APPENV.APIEndpoint}/role${id?'?id='+id:''}`);
            return allRoles;
        }
        catch(err){throw new Error('Role Could Not Be Retrieved');}
    }

    static async createRole(roleObj){
        try{
            const newrole = await this.makeRequest("POST", `${APPENV.APIEndpoint}/role/create`, roleObj);
            return newrole;
        }
        catch(err){throw new Error('Role Could Not Be Created');}
    }

    static async updateRole(roleObj){
        try{
            const newrole = await this.makeRequest("PUT", `${APPENV.APIEndpoint}/role/update`, JSON.stringify(roleObj));
            return newrole;
        }
        catch(err){throw new Error('Role Could Not Be Updated');}
    }

    static async deleteRole(roleid){
        try{
            const deleterole = await this.makeRequest("DELETE", `${APPENV.APIEndpoint}/role/delete?id=${roleid}`);
            return deleterole;
        }
        catch(err){throw new Error('Role Could Not Be Deleted');}
    }

    static async deleteTest(externalid, hash){
        try {
            const deletetest = await this.makeRequest("POST", `${APPENV.APIEndpoint}/scores/resetstudent?externalid=${externalid}&hash=${hash}`);
            return deletetest;
        }
        catch(err){throw new Error('Test Could Not Be Deleted');}
    }
    static async reDateTest(externalid, hash, date) {
        const reDateTest = await this.makeRequest("POST", `${APPENV.APIEndpoint}/scores/resetstudent?externalid=${externalid}&hash=${hash}&submittedat=${date}`);
        return reDateTest;
    }

    static async getLanguages(){
        try{
            const allLanguages = await this.makeRequest("GET", `${APPENV.APIEndpoint}/items/languages`);
            return allLanguages;
        }
        catch(err){throw new Error('Languages Could Not Be Retrieved');}
    }

    static async getProficiency(){
        try{
            const allProficiency = await this.makeRequest("GET", `${APPENV.APIEndpoint}/items/proficiency`);
            return allProficiency;
        }
        catch(err){throw new Error('Proficiency Could Not Be Retrieved');}
    }

    static async getSkills(){
        try{
            const allSkills = await this.makeRequest("GET", `${APPENV.APIEndpoint}/items/skills`);
            return allSkills;
        }
        catch(err){throw new Error('Skills Could Not Be Retrieved');}
    }

    static async getTags(searchPattern, id){
        let queryString = '';
        if(searchPattern && !id) queryString += `?pattern=${searchPattern}`
        else if(!searchPattern && id) queryString += `?id=${id}`
        else if(searchPattern && id) queryString += `?id=${id}&pattern=${searchPattern}`;

        try{
            const allTags = await this.makeRequest("GET", `${APPENV.APIEndpoint}/tags${queryString}`);
            return allTags;
        }
        catch(err){throw new Error('Tags Could Not Be Retrieved');}
    }

    static async createTag(tagObj){
        try{
            const newtag = await this.makeRequest("POST", `${APPENV.APIEndpoint}/tags/create`, JSON.stringify(tagObj));
            return newtag;
        }
        catch(err){throw new Error('Tag Could Not Be Created');}
    }

    static async updateTag(tagObj){
        try{
            const updatedtag = await this.makeRequest("PUT", `${APPENV.APIEndpoint}/tags/update`, JSON.stringify(tagObj));
            return updatedtag;
        }
        catch(err){throw new Error('Tag Could Not Be Updated');}
    }

    static async deleteTag(tagid){
        try{
            const deletetag = await this.makeRequest("DELETE", `${APPENV.APIEndpoint}/tags/delete?id=${tagid}`);
            return deletetag;
        }
        catch(err){throw new Error('Role Could Not Be Deleted');}
    }

    static async createItem(itemObj){
        try{
            const newitem = await this.makeRequest("POST", `${APPENV.APIEndpoint}/items/create`, JSON.stringify(itemObj));
            return newitem;
        }
        catch(err){console.log(err);throw new Error('Item Could Not Be Created');}
    }

    static async getItemHistory(id){
        try{
            const itemHistory = await this.makeRequest("GET", `${APPENV.APIEndpoint}/items/history?id=${id}`);
            return itemHistory;
        }
        catch(err){console.log(err);throw new Error('Could not get item history');}
    }

    static async getItemVersion(id){
        try{
            const version = await this.makeRequest("GET", `${APPENV.APIEndpoint}/items/version?id=${id}&expandedData=true`);
            return version;
        }
        catch(err){console.log(err);throw new Error('Could not get item version');}
    }

    static async updateItem(itemObj){
        try{
            const updateditem = await this.makeRequest("PUT", `${APPENV.APIEndpoint}/items/update`, JSON.stringify(itemObj));
            return updateditem;
        }
        catch(err){console.log(err);throw new Error('Item Could Not Be Updated');}
    }

    static async archiveItem(itemid){
        try{
            const archiveditem = await this.makeRequest("DELETE", `${APPENV.APIEndpoint}/items/delete?id=${itemid}`);
            return archiveditem;
        }
        catch(err){console.log(err);throw new Error('Item Could Not Be Archived');}
    }

    static async getItems(itemObj){
        const queryString = this.objToQueryString(itemObj);
        try{
            const allitems = await this.makeRequest("GET", `${APPENV.APIEndpoint}/items` + queryString);
            return allitems;
        }
        catch(err){throw new Error('Item Could Not Be Retrieved');}
    }

    static async getItemStatuses(){
        try{
            const allitemstatuses = await this.makeRequest("GET", `${APPENV.APIEndpoint}/items/statuses`);
            return allitemstatuses;
        }
        catch(err){throw new Error('Item Statuses Could Not Be Retrieved');}
    }

    static async getMediaSchema(){
        try{
            const mediatypes = await this.makeRequest("GET", `${APPENV.APIEndpoint}/media/schema`);
            return mediatypes;
        }
        catch(err){throw new Error('Media Schema Could Not Be Retrieved');}
    }

    static async getMediaAssets(paramObject){
        const queryString = this.objToQueryString(paramObject);
        try{
            const allMediaAssets = await this.makeRequest("GET", `${APPENV.APIEndpoint}/media${queryString}`);
            return allMediaAssets;
        }
        catch(err){throw new Error('Media Assets Info Could Not Be Retrieved');}
    }

    static async uploadMediaAsset(formdata){
        try{
            const mediaAssetsUploaded = await this.makeRequest("POST", `${APPENV.APIEndpoint}/media/upload`, formdata, true);
            return mediaAssetsUploaded;
        }
        catch(err){throw new Error('Media Assets Could Not Be Uploaded');}
    }

    static async updateMediaAsset(mediaObj){
        try{
            const updatedmedia = await this.makeRequest("PUT", `${APPENV.APIEndpoint}/media/update`, JSON.stringify(mediaObj));
            return updatedmedia;
        }
        catch(err){console.log(err);throw new Error('Media Asset Could Not Be Updated');}
    }

    static async archiveMediaAsset(mediaid){
        try{
            const archivedmedia = await this.makeRequest("DELETE", `${APPENV.APIEndpoint}/media/delete?id=${mediaid}`);
            return archivedmedia;
        }
        catch(err){console.log(err);throw new Error('Media Asset Could Not Be Archived');}
    }

    static async getCollections(collectionObj){
        const queryString = this.objToQueryString(collectionObj);
        try{
            const newcollection = await this.makeRequest("GET", `${APPENV.APIEndpoint}/collections` + queryString);
            return newcollection;
        }
        catch(err){throw new Error('Collection Could Not Be Retrieved');}
    }

    static async getCollectionStatuses(){
        try{
            const newcollectionstatuses = await this.makeRequest("GET", `${APPENV.APIEndpoint}/collections/statuses`);
            return newcollectionstatuses;
        }
        catch(err){throw new Error('Collection Statuses Could Not Be Retrieved');}
    }

    static async createCollection(collectionObj){
        try{
            const newcollection = await this.makeRequest("POST", `${APPENV.APIEndpoint}/collections/create`, JSON.stringify(collectionObj));
            return newcollection;
        }
        catch(err){console.log(err);throw new Error('Collection Could Not Be Created');}
    }

    static async updateCollection(collectionObj){
        try{
            const updatedcollection = await this.makeRequest("PUT", `${APPENV.APIEndpoint}/collections/update`, JSON.stringify(collectionObj));
            return updatedcollection;
        }
        catch(err){console.log(err);throw new Error('Collection Could Not Be Updated');}
    }

    static async archiveCollection(collectionid){
        try{
            const archivedcollection = await this.makeRequest("DELETE", `${APPENV.APIEndpoint}/collections/delete?id=${collectionid}`);
            return archivedcollection;
        }
        catch(err){console.log(err);throw new Error('Collection Could Not Be Archived');}
    }

    static async uploadAutoGradingAudio(formdata){
        try{
            const autogradingAudioUploaded = await this.makeRequest("POST", `${APPENV.APIEndpoint}/ml/audio`, formdata);
            return autogradingAudioUploaded;
        }
        catch(err){throw new Error('Audio To Be Auto Graded Could Not Be Uploaded');}
    }

    static async checkTextAutoGrading(autoGradingTextObj){
        try{
            const textGrades = await this.makeRequest("POST", `${APPENV.APIEndpoint}/ml/text`, JSON.stringify(autoGradingTextObj));
            return textGrades;
        }
        catch(err){throw new Error('Text Could Not Be Auto Graded');}
    }

    static async checkAudioAutoGrading(tokenObj){
        const queryString = this.objToQueryString(tokenObj);
        try{
            const audioGrades = await this.makeRequest("GET", `${APPENV.APIEndpoint}/ml/audio/score` + queryString );
            return audioGrades;
        }
        catch(err){throw new Error('Could Not Retrieve Audio Grades');}
    }

    static async getEvents(eventObj){
        const queryString = this.objToQueryString(eventObj);
        try{
            const allevents = await this.makeRequest("GET", `${APPENV.APIEndpoint}/events` + queryString);
            return allevents;
        }
        catch(err){throw new Error('Event Could Not Be Retrieved');}
    }

    static async generateEventFile(eventObj){
        const queryString = this.objToQueryString(eventObj);
        try{
            const eventfile = await this.makeRequest("GET", `${APPENV.APIEndpoint}/events/generatefile` + queryString);
            return eventfile;
        }
        catch(err){throw new Error('Event File Could Not Be Retrieved');}
    }

    static async getEventStatuses(){
        try{
            const alleventstatuses = await this.makeRequest("GET", `${APPENV.APIEndpoint}/events/statuses`);
            return alleventstatuses;
        }
        catch(err){throw new Error('Event Statuses Could Not Be Retrieved');}
    }

    static async createEvent(eventObj){
        try{
            const newevent = await this.makeRequest("POST", `${APPENV.APIEndpoint}/events/create`, JSON.stringify(eventObj));
            return newevent;
        }
        catch(err){console.log(err);throw new Error('Event Could Not Be Created');}
    }

    static async updateEvent(eventObj){
        try{
            const updatedevent = await this.makeRequest("PUT", `${APPENV.APIEndpoint}/events/update`, JSON.stringify(eventObj));
            return updatedevent;
        }
        catch(err){console.log(err);throw new Error('Event Could Not Be Updated');}
    }

    static async archiveEvent(eventid){
        try{
            const archivedevent = await this.makeRequest("DELETE", `${APPENV.APIEndpoint}/events/delete?id=${eventid}`);
            return archivedevent;
        }
        catch(err){console.log(err);throw new Error('Event Could Not Be Archived');}
    }

    static async getScores(scoreObj){
        const queryString = this.objToQueryString(scoreObj);
        try{
            const allscores = await this.makeRequest("GET", `${APPENV.APIEndpoint}/reports` + queryString);
            return allscores;
        }
        catch(err){throw new Error('Scores Could Not Be Retrieved');}
    }

    static async getItemsReport(itemsObj){
        const queryString = this.objToQueryString(itemsObj);
        try{
            const allscores = await this.makeRequest("GET", `${APPENV.APIEndpoint}/reports/itemsreport` + queryString);
            return allscores;
        }
        catch(err){throw new Error('Items Could Not Be Retrieved');}
    }

    static async getTestletsReport(testletsObj){
        const queryString = this.objToQueryString(testletsObj);
        try{
            const allscores = await this.makeRequest("GET", `${APPENV.APIEndpoint}/reports/testletsreport` + queryString);
            return allscores;
        }
        catch(err){throw new Error('Items Could Not Be Retrieved');}
    }

    static async getOrganizations(searchobj){
        const queryString = this.objToQueryString(searchobj);
        try{
            const allorganizations = await this.makeRequest("GET", `${APPENV.APIEndpoint}/organization` + queryString);
            return allorganizations;
        }
        catch(err){throw new Error('Organizations Could Not Be Retrieved');}
    }

    static async getOrganizationStatuses(){
        try{
            const allorganizationstatus = await this.makeRequest("GET", `${APPENV.APIEndpoint}/organization/statuses`);
            return allorganizationstatus;
        }
        catch(err){throw new Error('Organization Statuses Could Not Be Retrieved');}
    }

    static async createOrganization(orgformdata){
        try{
            const neworganization = await this.makeRequest("POST", `${APPENV.APIEndpoint}/organization/create`, orgformdata, true);
            return neworganization;
        }
        catch(err){console.log(err);throw new Error('Organization Could Not Be Created');}
    }

    static async updateOrganization(orgformdata){
        try{
            const updatedorg = await this.makeRequest("PUT", `${APPENV.APIEndpoint}/organization/update`, orgformdata, true);
            return updatedorg;
        }
        catch(err){console.log(err);throw new Error('Organization Could Not Be Updated');}
    }

    static async archiveOrganization(orgid){
        try{
            const archivedorg = await this.makeRequest("DELETE", `${APPENV.APIEndpoint}/organization/delete?id=${orgid}`);
            return archivedorg;
        }
        catch(err){console.log(err);throw new Error('Organization Could Not Be Archived');}
    }

    static async getEventTemplate(){
        try{
            const eventtemplate = await this.makeRequest("GET", `${APPENV.APIEndpoint}/template`);
            return eventtemplate;
        }
        catch(err){throw new Error('Event Template Could Not Be Retrieved');}
    }
    static async getNextItemNumber(itemObj){
        const queryString = this.objToQueryString(itemObj);
        try{
            const itemNumber = await this.makeRequest("GET", `${APPENV.APIEndpoint}/items/itemnumber`+queryString);
            return itemNumber;
        }
        catch(err){throw new Error('Event Template Could Not Be Retrieved');}
    }
}