import React from 'react';

import * as APPENV from '../../../env.json';
import DOCAPIUtility from './docAPIUtility';

import autoBind from 'react-autobind';

export default class DocManager extends React.Component{
    constructor(props){
        super();
        this.state={
            apikey:null,
            jwt:null,
            docs:null,
            docselection:{
                api: null,
                endpoint: null,
                tab: 0,
                header: 0,
                query: 0
            }
        };
        this.servicesDropDownOptions = null
        autoBind(this);
    }

    componentDidMount(){
        this.prepDoc();
        if(Object.keys(this.props).includes("jwt") && this.props.jwt){
            this.setState({jwt: this.props.jwt});
        }
    }

    async prepDoc(){
        try{
            const docData = await DOCAPIUtility.makeRequest("GET", `${APPENV.APIEndpoint}/doc`, null);
            const parsedDoc = JSON.parse(docData);
            if(parsedDoc && parsedDoc.success){
                this.setState({docs: parsedDoc.doc});
            }
            console.log(parsedDoc.doc);
        }
        catch(err){console.log(err);}
    }

    setAPISelection(event){
        this.setState({
            docselection:{
                api: event.target.value,
                endpoint: null,
                tab: 0,
                header: 0,
                query: 0
            }
        })
    }

    setAPIEndpoint(event){
        const selectedEndpoint = event.target.value;
        this.setState((prevState)=>({
            docselection:{
                ...prevState.docselection,
                endpoint: selectedEndpoint,
                tab: 0,
                header: 0,
                query: 0
            }
        }));
    }

    setEndpointHeaderIndex(headerIndex){
        this.setState((prevState)=>({
            docselection:{
                ...prevState.docselection,
                header: headerIndex
            }
        }));
    }

    setEndpointQueryIndex(queryIndex){
        this.setState((prevState)=>({
            docselection:{
                ...prevState.docselection,
                query: queryIndex
            }
        }));
    }

    toggleTab(tabIndex){
        this.setState((prevState)=>({
            docselection:{
                ...prevState.docselection,
                tab: tabIndex
            }
        }));
    }


    render(){

        return(
            <div className="docmanager">
                {!this.props.jwt ?
                    <React.Fragment>
                        <div className="doc-access-info">
                            <div className="title">Proficiency Placement Data Services API Documentation</div>
                            <div>To test API using tools provided here, you must have an <span className="emphasize">API key</span>,</div>
                            <div>And, you must have either full <span className="emphasize">JWT</span> generated by logging into auth service,</div>
                            <div>OR, login using form provided below.</div>
                        </div>
                        <div className="doc-apikey">
                            <label htmlFor="apikeyinput">API Key: </label>
                            <input id="apikeyinput" placeholder="API Key" />
                        </div>
                        <div className="doc-login-form">
                            <form>
                                <div>
                                    <label htmlFor="loginemailinput">Email: </label>
                                    <input id="loginemailinput" placeholder="Email Address" />
                                </div>
                                <div>
                                    <label htmlFor="loginpasswordinput">Password: </label>
                                    <input id="loginpasswordinput" placeholder="Password" />
                                </div>
                                <div>
                                    <button>Login</button>
                                </div>
                            </form>

                            <div>OR</div>

                            <div>
                                <label htmlFor="jwtinput">JWT: </label>
                                <textarea id="jwtinput" name="jwt" placeholder="JWT" rows="5" cols="50"></textarea>
                            </div>

                        </div>
                    </React.Fragment>
                :
                    <div className="doc-access-info">
                        <div className="title">Proficiency Placement Data Services API Documentation</div>
                        <div>You are already logged into docs testing system,</div>
                        <div>Select a data service by clicking on the dropdown labeled <span className="emphasize">Services</span> below to view the documentation for it.</div>
                    </div>
                }

                <div className="docmanager-api-info-container">
                    <div>
                        <label htmlFor="serviceslist">Services: </label>
                        <select onChange={this.setAPISelection} id="serviceslist" defaultValue={this.state.docselection.api || 'na'} >
                            <option value="na" disabled>Select A Service</option>
                            {this.state.docs && Object.keys(this.state.docs).map((servicename, sidx)=>{
                                return (
                                    <option key={`apiservicesselectoption-${sidx}`} value={servicename}>{servicename.toUpperCase()}</option>
                                );
                            })}
                        </select>
                    </div>
                    {this.state.docselection.api &&
                        <div className="api-service-desc">
                            <div>
                                {this.state.docs[this.state.docselection.api].description}
                            </div>
                        </div>
                    }
                    <div className="api-endpoint-info-container">
                        {this.state.docselection.api &&
                            <div className="api-endpoint-radio-group">
                                <div>Endpoints</div>
                                {this.state.docs[this.state.docselection.api].endpoints.map((endpoint, epidx)=>{
                                    return (
                                        <div key={`api-${this.state.docselection.api}-endpoint-${epidx}`} >
                                            <input
                                                defaultChecked={this.state.docselection.endpoint===endpoint.url}
                                                onChange={this.setAPIEndpoint}
                                                type="radio"
                                                name="enpoint-radio"
                                                id={`api-${this.state.docselection.api}-endpoint-${epidx}-radio`} 
                                                value={epidx}
                                            />
                                            <label htmlFor={`api-${this.state.docselection.api}-endpoint-${epidx}-radio`}>{endpoint.url}</label>
                                        </div>
                                    )
                                })}
                            </div>
                        }
                        {this.state.docselection.endpoint!==null &&
                            <div className="endpoint-info-container">
                                <div>
                                    <div>Endpoint Description:</div>
                                    <div>
                                        {this.state.docs[this.state.docselection.api].endpoints[this.state.docselection.endpoint].description}
                                    </div>
                                </div>
                                <div style={{marginTop: "10px"}}>Available Methods: </div>
                                <div>{
                                    this.state.docs[this.state.docselection.api]
                                    .endpoints[this.state.docselection.endpoint]
                                    .methods
                                    .map(method=>{
                                        return <span key={`endpoint-methods-${method}`}>{method}</span>
                                    })
                                }</div>
                                <div style={{marginTop: "5px"}}>
                                    <input
                                        checked={
                                            this.state.docs[this.state.docselection.api]
                                            .endpoints[this.state.docselection.endpoint]
                                            .permissionRequired
                                        }
                                        readOnly
                                        type="checkbox"
                                        id="permissionrequiredradio"
                                    />
                                    <label stye={{marginLeft:"5px"}} htmlFor={`permissionrequiredradio`}>Permission Required</label>
                                </div>
                            </div>
                        }
                    </div>

                    {this.state.docselection.endpoint!==null &&
                        <div className="api-req-res-info">

                            <div className="doc-tab">
                                <button onClick={()=>this.toggleTab(0)} className={`doc-tablinks${this.state.docselection.tab===0 ? ' selected' : ''}`}>Request</button>
                                <button onClick={()=>this.toggleTab(1)} className={`doc-tablinks${this.state.docselection.tab===1 ? ' selected' : ''}`}>Response</button>
                            </div>

                            <div className={`doc-tabcontent${this.state.docselection.tab===0 ? ' active' : ''}`}>
                                <div className="request-content">
                                    <div style={{width: "40%"}}>
                                    {
                                        this.state.docs[this.state.docselection.api]
                                        .endpoints[this.state.docselection.endpoint]
                                        .request.headers &&

                                        <React.Fragment>
                                            <div>Headers</div>
                                            <div style={{marginTop: "5px"}}>{
                                                this.state.docs[this.state.docselection.api]
                                                .endpoints[this.state.docselection.endpoint]
                                                .request.headers.map((header, hidx)=>{
                                                    return (
                                                        <div key={`endpoint-req-header-${hidx}`}>
                                                            <input onChange={()=>this.setEndpointHeaderIndex(hidx)} checked={this.state.docselection.header===hidx} type="radio" name="req-header" id={`endpoint-req-header-${hidx}-input`} />
                                                            <label style={{marginLeft:"5px"}} htmlFor={`endpoint-req-header-${hidx}-input`}>{header.parameter}</label>
                                                        </div>
                                                    )
                                                })
                                            }</div>
                                            <div style={{backgroundColor:"#6d6d6d", color:"#fff", padding:"5px 5px", marginTop:"5px"}}><div style={{fontSize:"0.5rem"}}>Header Description:</div><div>{
                                                this.state.docs[this.state.docselection.api]
                                                .endpoints[this.state.docselection.endpoint]
                                                .request.headers[this.state.docselection.header].description
                                            }</div></div>
                                        </React.Fragment>
                                    }
                                    {
                                        this.state.docs[this.state.docselection.api]
                                        .endpoints[this.state.docselection.endpoint]
                                        .request.queries &&

                                        <React.Fragment>
                                            <div>Queries</div>
                                            <div style={{marginTop: "5px"}}>{
                                                this.state.docs[this.state.docselection.api]
                                                .endpoints[this.state.docselection.endpoint]
                                                .request.queries.map((query, qidx)=>{
                                                    return (
                                                        <div key={`endpoint-req-query-${qidx}`}>
                                                            <input onChange={()=>this.setEndpointQueryIndex(qidx)} checked={this.state.docselection.query===qidx} type="radio" name="req-query" id={`endpoint-req-query-${qidx}-input`} />
                                                            <label style={{marginLeft:"5px"}} htmlFor={`endpoint-req-query-${qidx}-input`}>{query.parameter}</label>
                                                        </div>
                                                    )
                                                })
                                            }</div>
                                            <div style={{backgroundColor:"#6d6d6d", color:"#fff", padding:"5px 5px", marginTop:"5px"}}>
                                                <div style={{fontSize:"0.5rem"}}>Query Description:</div>
                                                <div>{
                                                    this.state.docs[this.state.docselection.api]
                                                    .endpoints[this.state.docselection.endpoint]
                                                    .request.queries[this.state.docselection.query].description
                                                }</div>
                                                <div>
                                                    <input
                                                        type="radio"
                                                        readOnly
                                                        checked={
                                                            this.state.docs[this.state.docselection.api]
                                                            .endpoints[this.state.docselection.endpoint]
                                                            .request.queries[this.state.docselection.query].isRequired
                                                        }
                                                        id="query-isrequired-checkbox"
                                                    />
                                                    <label htmlFor="query-isrequired-checkbox"> Required</label>
                                                </div>
                                            </div>
                                        </React.Fragment>
                                    }
                                    </div>
                                    <div style={{width: "50%", backgroundColor: "#729296", color: "#fff", border: "1px solid #000", margin: "0 5px", padding: "5px 5px"}}>
                                        <div>Request CURL Example:</div>
                                        <div style={{width: "100%"}}><pre style={{width: "100%", whiteSpace: "pre-wrap"}}>{
                                            `curl -i ${
                                                this.state.docs[this.state.docselection.api]
                                                .endpoints[this.state.docselection.endpoint]
                                                .request.headers &&
                                                this.state.docs[this.state.docselection.api]
                                                .endpoints[this.state.docselection.endpoint]
                                                .request.headers.map(header=>{
                                                    return `-H "${header.parameter}: xxxxxxxxxxxxxxxxxxxx"`
                                                })
                                                .join("")
                                            } -X ${
                                                this.state.docs[this.state.docselection.api]
                                                .endpoints[this.state.docselection.endpoint].methods[0]
                                            } http://{${
                                                this.state.docselection.api.toUpperCase()
                                            }-Service-Hostname}${
                                                this.state.docs[this.state.docselection.api]
                                                .endpoints[this.state.docselection.endpoint].url
                                            }${
                                                this.state.docs[this.state.docselection.api]
                                                .endpoints[this.state.docselection.endpoint]
                                                .request.queries &&
                                                "?"+this.state.docs[this.state.docselection.api]
                                                .endpoints[this.state.docselection.endpoint]
                                                .request.queries[this.state.docselection.query].parameter+"="+"xxxxxxxxxxxxxxxx"
                                            }`
                                        }</pre></div>

                                    </div>
                                </div>
                            </div>

                            <div className={`doc-tabcontent${this.state.docselection.tab===1 ? ' active' : ''}`}>
                                <div>Response Type: <b>{
                                    this.state.docs[this.state.docselection.api]
                                    .endpoints[this.state.docselection.endpoint]
                                    .response.type.toUpperCase()
                                }</b></div>
                                <div style={{marginTop: "10px"}}>Response Example</div>
                                <div style={{backgroundColor:"#e0f0c0", padding: "5px 10px", border: "1px solid #000"}}><pre>{
                                    JSON.stringify(
                                        this.state.docs[this.state.docselection.api]
                                        .endpoints[this.state.docselection.endpoint]
                                        .response.example
                                    ,null, 2)
                                }</pre></div>
                            </div>

                        </div>
                    }

                </div>

            </div>
        )
    }
}