import * as joint from 'jointjs';

import autoBind from 'react-autobind';

export default class Diagrammer{

    constructor(_props){
        this.props = _props;
        this.containerElem = document.getElementById(this.props.container);

        this.nodes = [];
        this.links = [];

        this.graph;
        this.paper;

        this.dragInfo = {
            intent: false,
            coordinates: null
        }
        this.linkintent = {
            status: false,
            from: null
        }

        this.gridColors = {
            inner: '#5c5c5c',
            outer: '#1c1c1c'
        }
        this.gridThickness = {
            inner: 1,
            outer: 2
        }

        this.contextmenuoffset = 660;

        autoBind(this);
    }

    initDiagrammer(){
        this.graph = new joint.dia.Graph;

        const paperOptions = {
            el: this.containerElem,
            model: this.graph,
            width: this.containerElem.style.width,
            height: 450,
            defaultRouter: { name: 'metro' },
            background: {
                color: '#6e6867'
            },
            gridSize: 10,
            interactive: function(cellView){
                return (
                    (typeof cellView.model.get('isInteractive')==="boolean" && cellView.model.get('isInteractive')) ||
                    typeof cellView.model.get('isInteractive')!=="boolean"
                );   
            }
        };

        this.paper = new joint.dia.Paper(paperOptions);

        this.paper.scale(this.props.graph.metaInfo.zoom, this.props.graph.metaInfo.zoom);

        this.gridScaleFactors = {
            inner: 1 + (this.props.graph.metaInfo.zoom * 1),
            outer: 10 + (this.props.graph.metaInfo.zoom * 10)
        }
        this.paper.setGrid({
            name: 'doubleMesh',
            args: [
                { color: this.gridColors.inner, thickness: this.gridThickness.inner, scaleFactor: this.gridScaleFactors.inner },
                { color: this.gridColors.outer, thickness: this.gridThickness.outer, scaleFactor: this.gridScaleFactors.outer }
            ]
        }).drawGrid();

        this.paper.on('blank:pointerclick', ()=>{
            this.deselectAllNodes();
            this.props.toggleContextMenu();
            this.stopLinking();
        });
        this.paper.on('link:pointerclick', ()=>{
            this.stopLinking();
            this.props.setSelectedNode(null);
            this.props.toggleContextMenu();
        });

        //For Translating The view
        this.containerElem.addEventListener('mousedown', this.onPaperPointerDown);
        this.containerElem.addEventListener('mouseup', this.onPaperPointerUp);
        this.containerElem.addEventListener('mousemove', this.onPaperPointerMove);

        //For Node Events
        this.paper.on('element:pointerdown', this.onNodePointerDown);
        this.paper.on('element:pointerup', this.onNodePointerUp);
        this.paper.on('cell:pointermove', this.onCellPointerMove);

        //For Node ContextMenu
        this.paper.on('element:contextmenu', this.onNodeContextMenu);
        this.paper.on('link:contextmenu', this.onLinkContextMenu);
        this.paper.on('blank:contextmenu', this.onPaperContextMenu);

        this.containerElem.addEventListener('mousewheel', this.onPaperWheel);
    }
    destroy(){
        this.graph.clear();
        this.paper.remove();

        this.graph = null;
        this.paper = null;

        this.nodes = [];
        this.links = [];

        if(this.containerElem){
            this.containerElem.removeEventListener('mousedown', this.onPaperPointerDown);
            this.containerElem.removeEventListener('mouseup', this.onPaperPointerUp);
            this.containerElem.removeEventListener('mousemove', this.onPaperPointerMove);
            this.containerElem.removeEventListener('mousewheel', this.onPaperWheel);

            this.containerElem = null;
        }
    }

    updateContextOffset(hashcount, timerscount){
        if(hashcount > 1 && !timerscount){
            this.contextmenuoffset = 609 + (hashcount * 51);
        }
        else if((!hashcount || hashcount===1) && timerscount > 0){
            this.contextmenuoffset = 609 + 55 + (timerscount * 136);
        }
        else if(hashcount > 1 && timerscount > 0){
            this.contextmenuoffset = 609 + 55 + (hashcount * 51) + (timerscount * 136);
        }
        else{
            this.contextmenuoffset = 660;
        }
    }

    updateMetaInfo(newMetaInfo){
        this.props = {
            ...this.props,
            graph: {
                ...this.props.graph,
                metaInfo: {
                    ...newMetaInfo
                }
            }
        };

        this.paper.scale(this.props.graph.metaInfo.zoom, this.props.graph.metaInfo.zoom);
        this.paper.translate(this.props.graph.metaInfo.coordinates.x, this.props.graph.metaInfo.coordinates.y);

        this.gridScaleFactors = {
            inner: 1 + (this.props.graph.metaInfo.zoom * 1),
            outer: 10 + (this.props.graph.metaInfo.zoom * 10)
        }
        this.paper.setGrid({
            name: 'doubleMesh',
            args: [
                { color: this.gridColors.inner, thickness: this.gridThickness.inner, scaleFactor: this.gridScaleFactors.inner },
                { color: this.gridColors.outer, thickness: this.gridThickness.outer, scaleFactor: this.gridScaleFactors.outer }
            ]
        }).drawGrid();
    }

    addNode(nodeprops, isEditing){
        const spearator_conditional = `____________________________________________`;

        this.deselectAllNodes();

        const posx = nodeprops.coordinates.x - this.props.graph.metaInfo.coordinates.x;
        const posy = (nodeprops.coordinates.y - this.contextmenuoffset) - this.props.graph.metaInfo.coordinates.y;

        const relativeCoordinates = {
            x: isEditing ? nodeprops.coordinates.x : posx / this.props.graph.metaInfo.zoom,
            y: isEditing ? nodeprops.coordinates.y : posy / this.props.graph.metaInfo.zoom
        };

        if(nodeprops.type==="group"){
            var shapeheight = 100;
            var nodeBodyText = 'Empty Group'
            if(nodeprops.set){
                shapeheight += 30;
                nodeBodyText = `Set: ${nodeprops.set}\n${spearator_conditional}\n\n\n`+nodeBodyText;
            }
            const groupNode = new joint.shapes.standard.HeaderedRectangle();
            groupNode.position(relativeCoordinates.x, relativeCoordinates.y);
            groupNode.size(250, shapeheight);
            const _attr = {
                root: {
                    title: nodeprops.title
                },
                header: {
                    fill: '#357351',
                    stroke: '#000',
                    strokeWidth: 2
                },
                headerText: {
                    text: nodeprops.title,
                    fill: '#fff',
                    fontSize: 14,
                    fontWeight: 'bold',
                    fontVariant: 'small-caps'
                },
                body: {
                    fill: '#1db580',
                    stroke: '#000',
                    strokeWidth: 2
                },
                bodyText: {
                    text: nodeBodyText,
                    fill: '#000',
                    fontSize: 14,
                    fontWeight: 'bold',
                    fontVariant: 'small-caps'
                }
            }
            groupNode.attr(_attr);
            groupNode.addTo(this.graph);

            this.nodes.push({
                nodeprops: nodeprops,
                shape: groupNode
            });
            this.props.translateNode(nodeprops.id, groupNode.attributes.position);
        }
        else if(nodeprops.type==="conditional"){

            var body_text = 'NO CONDITIONS SET';
            var shapeheight = 0;

            if(nodeprops.condition.starttest){
                body_text = '\nTest Event Starts Here';
                shapeheight += 60;
            }
            else if(nodeprops.condition.endtest){
                body_text = '\nTest Event Will End Here';
                shapeheight += 60;
            }

            if(!nodeprops.condition.endtest && !nodeprops.condition.starttest && nodeprops.condition.groupscores){
                if(nodeprops.condition.groupscores.length){
                    body_text = '';
                }
                else if(shapeheight===0 && nodeprops.condition.groupscores.length===0){
                    body_text = '\n\n'+body_text;
                    shapeheight = 70;
                }

                var extragroupconditioncount = 0;
                for(var i=0; i<nodeprops.condition.groupscores.length; i++){
                    if(i<2){
                        const grouptitle = this.nodes.find(node=>node.nodeprops.id===nodeprops.condition.groupscores[i].groupid).nodeprops.title;

                        shapeheight += 150;
                        body_text += `\n${spearator_conditional}\n\n${grouptitle} Score Criteria\n${spearator_conditional}`;

                        if(nodeprops.condition.groupscores[i].na){
                            body_text += `\n\nGroup Not Started Yet`;
                        }
                        else{
                            body_text += `\n\nMin: ${nodeprops.condition.groupscores[i].min}`;
                            body_text += `\nMax: ${nodeprops.condition.groupscores[i].max}`;

                            if(nodeprops.condition.groupscores[i].cumulative){
                                shapeheight += 40;
                                body_text += `\n\nCumulative Scores`;
                            }
                            if(nodeprops.condition.groupscores[i].timerthreshold>0){
                                shapeheight += 40;
                                body_text += `\n\nTimer Threshold: ${nodeprops.condition.groupscores[i].timerthreshold}min`;
                            }
                        }
                    }
                    else{
                        extragroupconditioncount += 1;
                    }
                }
                if(extragroupconditioncount > 0){
                    shapeheight += 40;
                    body_text += `\n\n${spearator_conditional}\n\n... And ${extragroupconditioncount} More Group Conditions`;
                }
                body_text += '\n';
            }

            const conditionalNode = new joint.shapes.standard.HeaderedRectangle();
            conditionalNode.position(relativeCoordinates.x, relativeCoordinates.y);
            conditionalNode.size(250, shapeheight);
            const _attr = {
                root: {
                    title: nodeprops.title
                },
                header: {
                    fill: '#357351',
                    stroke: '#000',
                    strokeWidth: 2
                },
                headerText: {
                    text: nodeprops.title,
                    fill: '#fff',
                    fontSize: 14,
                    fontWeight: 'bold',
                    fontVariant: 'small-caps'
                },
                body: {
                    fill: '#1db580',
                    stroke: '#000',
                    strokeWidth: 2
                },
                bodyText: {
                    text: body_text,
                    fill: '#000',
                    fontSize: 14,
                    fontWeight: 'bold',
                    fontVariant: 'small-caps'
                }
            }
            conditionalNode.attr(_attr);
            conditionalNode.addTo(this.graph);

            this.nodes.push({
                nodeprops: nodeprops,
                shape: conditionalNode
            });
            this.props.translateNode(nodeprops.id, conditionalNode.attributes.position);
        }
    }
    updateNode(nodeprops){
        const spearator_conditional = `____________________________________________`;

        this.nodes.forEach(_node=>{
            if(_node.nodeprops.id===nodeprops.id){
                if(nodeprops.type==="group"){

                    if(nodeprops.set!==_node.nodeprops.set) _node.nodeprops.set=nodeprops.set

                    var nodeWidth = 0;
                    var nodeHeight = nodeprops.testlets.length * 40;
                    var nodeBodyText = '';
                    if(_node.nodeprops.set){
                        nodeHeight += 30;
                        nodeBodyText = `Set: ${nodeprops.set}\n${spearator_conditional}\n\n\n`;
                    }
                    nodeBodyText += nodeprops.testlets.length ? nodeprops.testlets.map((_testlet, _tidx)=>{
                        nodeWidth = nodeWidth < (_testlet.title.length + 20) ? _testlet.title.length + 20 : nodeWidth;
                        return `${_tidx + 1} \u21d2 ${_testlet.title}`;
                    }).join('\n\n\n') : 'Empty Group';

                    _node.shape.attr('bodyText/text', nodeBodyText);
                    _node.shape.resize(250 + nodeWidth, 100 + nodeHeight);
                }
                else if(nodeprops.type==="conditional"){

                    var body_text = '';
                    var shapeheight = 0;
                    
                    if(nodeprops.condition.starttest){
                        body_text += '\nTest Event Starts Here';
                        shapeheight += 60;
                    }
                    else if(nodeprops.condition.endtest){
                        body_text += '\nTest Event Will End Here';
                        shapeheight += 60;
                    }

                    if(!nodeprops.condition.endtest && !nodeprops.condition.starttest){
                        var extragroupconditioncount = 0;
                        for(var i=0; i<nodeprops.condition.groupscores.length; i++){
                            if(i<2){
                                const currentgroup = this.nodes.find(node=>node.nodeprops.id===nodeprops.condition.groupscores[i].groupid);
                                const grouptitle = currentgroup.nodeprops.title;

                                shapeheight += 150;
                                body_text += `\n${spearator_conditional}\n\n${grouptitle} Score Criteria\n${spearator_conditional}`;

                                if(nodeprops.condition.groupscores[i].na){
                                    body_text += `\n\nGroup Not Started Yet`;
                                }
                                else{
                                    body_text += `\n\nMin: ${nodeprops.condition.groupscores[i].min}`;
                                    body_text += `\nMax: ${nodeprops.condition.groupscores[i].max}`;

                                    if(nodeprops.condition.groupscores[i].cumulative){
                                        shapeheight += 40;
                                        body_text += `\n\nCumulative Scores`;
                                    }
                                    if(nodeprops.condition.groupscores[i].timerthreshold>0){
                                        shapeheight += 40;
                                        body_text += `\n\nTimer Threshold: ${nodeprops.condition.groupscores[i].timerthreshold}min`;
                                    }
                                }

                                body_text += '\n';
                            }
                            else{
                                extragroupconditioncount += 1;
                            }
                        }
                        if(extragroupconditioncount > 0){
                            shapeheight += 40;
                            body_text += `\n\n${spearator_conditional}\n\n... And ${extragroupconditioncount} More Group Conditions`;
                        }
                        body_text += '\n';
                    }

                    if(body_text==='\n'){
                        shapeheight += 70;
                        body_text += 'NO CONDITIONS SET\n';
                    }

                    _node.shape.resize(250, shapeheight);

                    _node.shape.attr('bodyText/text', body_text);
                }
            }
        });
    }
    removeNode(nodeid){
        this.deselectAllNodes();

        this.nodes.forEach(_node=>{
            if(_node.nodeprops.id===nodeid){
                this.graph.removeCells(_node.shape);
            }
        });
        this.nodes = this.nodes.filter(_node=>_node.nodeprops.id!==nodeid);
    }
    addLink(linkprops){
        this.deselectAllNodes();
        const linkexists = (this.links && this.links.length && this.links.filter(_link=>_link.linkprops.from===linkprops.from && _link.linkprops.to===linkprops.to).length>0);
        if(!linkexists){
            const newLink = new joint.shapes.standard.Link({
                attrs: {
                    line: {
                        stroke: '#000',
                        strokeWidth: 3
                    }
                }
            });
            newLink.source(this.nodes.filter(_node=>_node.nodeprops.id===linkprops.from)[0].shape);
            newLink.target(this.nodes.filter(_node=>_node.nodeprops.id===linkprops.to)[0].shape);
            const linkid = linkprops.id ? linkprops.id : this.props.addLink(linkprops);
            this.links.push({
                linkprops: {
                    ...linkprops,
                    id: linkid,
                    type: "link"
                },
                shape: newLink
            });
            newLink.addTo(this.graph);
        }
        this.stopLinking();
    }
    removeLink(linkid){
        const linkIndex = this.links.findIndex(_link=>_link.linkprops.id===linkid);
        this.graph.removeCells(this.links[linkIndex].shape);
        this.links.splice(linkIndex, 1);
    }
    removeAllLinks(linkids){
        this.links.forEach(_link=>{
            if(linkids.includes(_link.linkprops.id))
                this.graph.removeCells(_link.shape);
        });
        this.links = this.links.filter(_link=>!linkids.includes(_link.linkprops.id));
    }

    startLinking(nodeid, initCoordinate){
        if(!this.link){
            this.link = new joint.shapes.standard.Link({
                attrs: {
                    line: {
                        stroke: '#e6ba4c',
                        strokeWidth: 2
                    }
                }
            });
        }

        if(nodeid && this.nodes.length>1){
            const fromNode = this.nodes.filter(_node=>_node.nodeprops.id===nodeid)[0];
            this.linkintent = {
                status: true,
                from: fromNode.nodeprops.id
            }
            this.link.source(fromNode.shape);
            this.link.target(new joint.g.Point(initCoordinate.x / this.props.graph.metaInfo.zoom, initCoordinate.y / this.props.graph.metaInfo.zoom));
            this.link.addTo(this.graph);
        }
    }
    stopLinking(){
        this.linkintent = {
            status: false,
            from: null
        }
        if(this.link) this.graph.removeCells(this.link);
        this.link = null;
    }

    deselectAllNodes(){
        this.nodes.forEach(_node=>{
            if(_node.nodeprops.type==="group"){
                _node.shape.attr('header/fill', '#735c1f');
                _node.shape.attr('body/fill', '#edb724');
            }
            else if(_node.nodeprops.type==="conditional"){
                _node.shape.attr('header/fill', '#1e4f5e');
                _node.shape.attr('body/fill', '#2db7e0');
            }
        });
        this.props.setSelectedNode(null);
    }

    deleteAllNodes(){
        this.links.forEach(_link=>{
            this.graph.removeCells(_link.shape);
        });
        this.links = [];
        this.nodes.forEach(_node=>{
            this.graph.removeCells(_node.shape);
        });
        this.nodes = [];
    }

    onNodePointerDown(elemdata, evt, x, y){
        if(evt) evt.preventDefault();
        this.onPaperPointerUp();

        if(this.linkintent.status){
            const targetNode = this.nodes.filter(_node=>elemdata.model.id===_node.shape.attributes.id);
            this.addLink({
                from: this.linkintent.from,
                to: targetNode[0].nodeprops.id
            });
        }
        else{
            const selectedNode = this.nodes.filter(_node=>elemdata.model.id===_node.shape.attributes.id);
            if(selectedNode && selectedNode.length){
                this.deselectAllNodes();
                selectedNode[0].shape.attr('header/fill', '#357351');
                selectedNode[0].shape.attr('body/fill', '#1db580');
                this.props.setSelectedNode(selectedNode[0].nodeprops.id);
            }
        }
        this.props.toggleContextMenu();
    }
    onNodePointerUp(elemdata, evt, x, y){
        if(evt) evt.preventDefault();
        this.onPaperPointerUp();

        const selectedNode = this.nodes.filter(_node=>elemdata.model.id===_node.shape.attributes.id);
        if(selectedNode && selectedNode.length){
            this.props.translateNode(selectedNode[0].nodeprops.id, elemdata.model.attributes.position);
        }

        if(this.link){
            this.link.findView(this.paper).requestConnectionUpdate();
        }
        this.links.forEach(_link=>{
            _link.shape.findView(this.paper).requestConnectionUpdate();
        });
    }

    onCellPointerMove(celldata, evt, x, y){
        if(evt) evt.preventDefault();
        this.onPaperPointerUp();
        /*** Used For Grouping */
        //Only hightlight the group
        // var coordinates = new joint.g.Point(x, y);
        // var elementAbove = celldata.model;
        // var elementBelow = this.graph.findModelsFromPoint(coordinates).find(function(el) {
        //     return (el.id !== elementAbove.id);
        // });

        // // If the two elements are connected already, don't
        // // connect them again (this is application-specific though).
        // if (elementBelow && this.graph.getNeighbors(elementBelow).indexOf(elementAbove) === -1) {
        //     console.log("Attach node to group", elementBelow);

        //     // Move the element to the position before dragging.
        //     //elementAbove.position(evt.data.x, evt.data.y);
        // }
        /********************** */

        if(this.link){
            this.link.findView(this.paper).requestConnectionUpdate();
        }
        this.links.forEach(_link=>{
            _link.shape.findView(this.paper).requestConnectionUpdate();
        });
    }
    
    onNodeContextMenu(elemdata, evt, x, y){
        if(evt) evt.preventDefault();
        const rect = this.containerElem.getBoundingClientRect();
        this.onPaperPointerUp();
        const selectedNode = this.nodes.filter(_node=>elemdata.model.id===_node.shape.attributes.id);
        if(selectedNode && selectedNode.length){
            this.deselectAllNodes();
            selectedNode[0].shape.attr('header/fill', '#357351');
            selectedNode[0].shape.attr('body/fill', '#1db580');
            this.props.setSelectedNode(selectedNode[0].nodeprops.id);
            //I have no clue where this 275 number is coming from. It is some sort of displacement factor for diagrammer which is needed when create node
            this.props.toggleContextMenu({...selectedNode[0].nodeprops}, {x: evt.clientX - rect.left, y: evt.clientY - rect.top + this.contextmenuoffset});
        }
    }
    onLinkContextMenu(elemdata, evt, x, y){
        if(evt) evt.preventDefault();
        const rect = this.containerElem.getBoundingClientRect();
        this.onPaperPointerUp();
        const selectedLink = this.links.filter(_link=>elemdata.model.id===_link.shape.id);
        this.props.toggleContextMenu({...selectedLink[0].linkprops}, {x: evt.clientX - rect.left, y: evt.clientY - rect.top + this.contextmenuoffset});
    }
    onPaperContextMenu(evt, x, y){
        if(evt) evt.preventDefault();
        const rect = this.containerElem.getBoundingClientRect();
        this.onPaperPointerUp();
        this.props.toggleContextMenu({type: "graph"}, {x: evt.clientX - rect.left, y: evt.clientY - rect.top + this.contextmenuoffset});
    }

    onPaperPointerDown(evt){
        if(evt) evt.preventDefault();
        if(!this.dragInfo.intent){
            this.dragInfo = {
                intent: true,
                coordinates: {
                    x: evt.clientX,
                    y: evt.clientY
                }
            }
        }
    }
    onPaperPointerUp(evt){
        if(evt) evt.preventDefault();
        if(this.dragInfo.intent){
            this.dragInfo = {
                intent: false,
                coordinates: null
            }
        }
    }
    onPaperPointerMove(evt){
        if(evt) evt.preventDefault();
        if(this.dragInfo.intent){
            const newX = evt.clientX - this.dragInfo.coordinates.x;
            const newY = evt.clientY - this.dragInfo.coordinates.y;
            this.props.updateGraphMetaInfo({
                coordinates: {
                    x: parseFloat((this.props.graph.metaInfo.coordinates.x + newX).toFixed(2)),
                    y: parseFloat((this.props.graph.metaInfo.coordinates.y + newY).toFixed(2))
                }
            });
            this.dragInfo.coordinates.x = evt.clientX;
            this.dragInfo.coordinates.y = evt.clientY;

            this.paper.setGrid({
                name: 'doubleMesh',
                args: [
                    { color: this.gridColors.inner, thickness: this.gridThickness.inner, scaleFactor: this.gridScaleFactors.inner },
                    { color: this.gridColors.outer, thickness: this.gridThickness.outer, scaleFactor: this.gridScaleFactors.outer }
                ]
            }).drawGrid();
        }

        if(this.linkintent.status){
            const rect = this.containerElem.getBoundingClientRect();
            const posx = (evt.clientX - rect.left) - this.props.graph.metaInfo.coordinates.x; 
            const posy = (evt.clientY - rect.top) - this.props.graph.metaInfo.coordinates.y; 
            this.link.target(new joint.g.Point(posx / this.props.graph.metaInfo.zoom - 20, posy / this.props.graph.metaInfo.zoom));
        }
    }
    onPaperWheel(wheelEvt){
        if(wheelEvt) wheelEvt.preventDefault();
        
        var _mod = 0;
        if(wheelEvt.deltaY < 0){
            _mod = 0.1;
        }
        else if(wheelEvt.deltaY > 0){
            _mod = -0.1;
        }
        var currentZoom = this.props.graph.metaInfo.zoom;
        currentZoom += _mod;
        if(currentZoom > 1) currentZoom = 1;
        else if(currentZoom < 0.2) currentZoom = 0.2;
        this.props.updateGraphMetaInfo({
            zoom: parseFloat(currentZoom.toFixed(2))
        });

        this.gridScaleFactors = {
            inner: 1 + (currentZoom * 1),
            outer: 10 + (currentZoom * 10)
        }
        this.paper.setGrid({
            name: 'doubleMesh',
            args: [
                { color: this.gridColors.inner, thickness: this.gridThickness.inner, scaleFactor: this.gridScaleFactors.inner },
                { color: this.gridColors.outer, thickness: this.gridThickness.outer, scaleFactor: this.gridScaleFactors.outer }
            ]
        }).drawGrid();
    }

}