import React from 'react';

import mapStateToProps from '../../datastore/mapper.js';
import mapDispatchToProps from '../../datastore/dispatcher.js';

import {connect} from 'react-redux';

import {withRouter} from 'react-router-dom';

import { Segment, Message, Grid, Dropdown, Checkbox, Form, TextArea } from 'semantic-ui-react';
import autoBind from 'react-autobind';

import METADATA from '../../utilities/metadata.json';

const textEditorOptions = METADATA.TEXTEDITOR_TYPES;

export class TextEditor extends React.Component{
    constructor(props){
        super();
        this.state={
            showHTMLEditor: false,
            htmlContent: null
        };
        autoBind(this);
    }

    toggleHTMLEditor(toggleStatus){
        const {textEditorFeature} = this.props;

        let html_content = null;
        if(toggleStatus){
            html_content = this.props.getTextEditorFeatureHTML(textEditorFeature.id);
        }
        else{
            this.props.setTextEditorFeatureHTML(textEditorFeature.id, this.state.htmlContent);
        }
        this.setState({
            showHTMLEditor: toggleStatus,
            htmlContent: html_content
        });
    }

    updateHTMLContent(event, {value}){
        const {textEditorFeature} = this.props;

        this.setState({
            htmlContent: value
        }, ()=>{
            this.props.setTextEditorFeatureHTML(textEditorFeature.id, value);
        });
    }
    updateRTEContent(event){
        const {textEditorFeature} = this.props;

        const newcontenthtml = event.target.innerHTML;
        this.setState({
            htmlContent: newcontenthtml
        }, ()=>{
            this.props.setTextEditorFeatureHTML(textEditorFeature.id, newcontenthtml);
        });
    }

    render(){

        const {textEditorFeature} = this.props;

        return(
            <React.Fragment>
                <Message attached='top'>
                    <Grid columns='equal'>
                        <Grid.Column>
                            <div className="featureid_label">Feature ID:</div>
                            <div className="feature_id">{textEditorFeature.id}</div>
                        </Grid.Column>
                        <Grid.Column>
                            <Dropdown
                                id={`${textEditorFeature.id}`}
                                onChange={this.props.updateTextEditorFeatureType}
                                search
                                selection
                                options={textEditorOptions}
                                defaultValue={(textEditorFeature.content && textEditorFeature.content.status) || textEditorOptions[0].value}
                            />
                        </Grid.Column>
                        <Grid.Column>
                            <Checkbox toggle label="HTML Mode" onChange={()=>this.toggleHTMLEditor(!this.state.showHTMLEditor)} />
                        </Grid.Column>
                        <Grid.Column>
                            <span>Allow Student Input: </span>
                            <Checkbox
                                id={`answerable-${textEditorFeature.id}`}
                                toggle={true}
                                onChange={()=>this.props.updateFeatureAllowInput(this.props.textEditorFeature.id, !this.props.textEditorFeature.allowUserInput)}
                                checked={this.props.textEditorFeature.allowUserInput}
                            />
                        </Grid.Column>
                    </Grid>
                </Message>
                <Segment attached>
                    {this.state.showHTMLEditor &&
                        <Form>
                            <TextArea
                                placeholder='Tell us more'
                                value={this.state.htmlContent || ''}
                                onChange={this.updateHTMLContent}
                            />
                        </Form>
                    }
                    <div
                        id={`feature-texteditor-${textEditorFeature.id}`}
                        className={this.state.showHTMLEditor ? 'bbrte-hidden' : ''}
                        onInput={this.updateRTEContent}
                    ></div>
                </Segment>
            </React.Fragment>
        )
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(TextEditor);